import { appStyled } from '../theme';

export const Aspect = appStyled('div', {
    shouldForwardProp: (prop) => prop !== 'ratio',
})<{ ratio: number }>(({ ratio }) => ({
    aspectRatio: ratio.toString(),
    zIndex: 0,
    width: '100%',
    maxHeight: '100%',
    maxWidth: '100%',
    ':fullscreen > div': {
        maxWidth: `calc(100vh * ${ratio})`,
        margin: 'auto',
    },
}));
