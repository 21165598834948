import * as React from 'react';
import { List, ListSubheader, ListItemText } from '@mui/material';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { AccountDevelopmentOptions } from './AccountDevelopmentOptions';
import { useAppSelector } from '../../../hooks/hooks';
import { useAppTheme } from '../../../theme';
import { selectProjectsAndRoles, selectUser } from '../../../store/selectors';
import { useFrame } from '../../../lib/frame-react';
import { SteinRootApiToken } from '../../../clients/stein-root-api';
import { IconButton } from '@mui/material';
import { AppIconLogout } from '../../AppIcons';
import { Link, useLocation } from 'react-router-dom';
import { projectSlugFromLocation } from '../../../utils/location-utils';

export function AccountOptionsPopoverContent(): React.ReactElement {
    const user = useAppSelector(selectUser);
    const projects = useAppSelector(selectProjectsAndRoles);
    const theme = useAppTheme();
    const { useSignOutMutation } = useFrame(SteinRootApiToken);
    const [signOut] = useSignOutMutation();
    const location = useLocation();
    const activeProjectSlug = projectSlugFromLocation(location);

    const sections = [];
    if (user && projects) {
        const projectString = projects.length === 1 ? `${projects[0].name} — ${projects[0].role}` : null;
        sections.push(
            <ListItem key={'user-section'}>
                <ListItemText primary={user.email} secondary={projectString} />
                <Tooltip title="Log Out">
                    <IconButton onClick={signOut}>
                        <AppIconLogout size="12px" />
                    </IconButton>
                </Tooltip>
            </ListItem>,
        );
    }

    const projectOptions = [];
    if (projects && projects.length > 1) {
        projectOptions.push(
            <ListSubheader component="div" key="switch-project-subheader">
                Projects
            </ListSubheader>,
        );

        for (const p of projects) {
            const projectLink = activeProjectSlug
                ? location.pathname.replace(activeProjectSlug, p.slug)
                : /* istanbul ignore next */ `/projects/${p.slug}`;
            projectOptions.push(
                <ListItemButton
                    key={p.id}
                    component={Link}
                    selected={p.slug === activeProjectSlug}
                    to={projectLink}
                    style={{ width: '100%' }}
                >
                    <ListItemText
                        style={{
                            paddingLeft: theme.spacing(2),
                        }}
                        primary={p.name}
                        secondary={p.role}
                    />
                </ListItemButton>,
            );
        }
        sections.push(projectOptions);
    }

    return (
        <List dense data-testid={'account-options-popover-content'}>
            {sections.map((c, i) => {
                if (i < sections.length - 1) {
                    return [c, <Divider key={`div${i}`} />];
                }
                return c;
            })}
            <AccountDevelopmentOptions />
        </List>
    );
}
