// istanbul ignore file
import { addSeconds, differenceInSeconds, isBefore, isAfter } from 'date-fns';
import { Audit, DriverLog, VehicleDevice } from '../../types/stein';

const MOST_VIDEO_UPLOADED_PERCENT_THRESHOLD = 0.6;

function getMinTimestamp(a: Date, b: Date): Date {
    return isBefore(a, b) ? a : b;
}

function getMaxTimestamp(a: Date, b: Date): Date {
    return isAfter(a, b) ? a : b;
}

type AuditStats = {
    isRecording: boolean;
    relevantVideoDurationSeconds: number;
    relevantVideoPercent: number;
    isAnyVideoUploaded: boolean;
    isMostVideoUploaded: boolean;
    hasBeenOnWifiSinceAuditEnded: boolean | null;
};

export function getAuditStats(audit: Audit, driverLogs: DriverLog[]): AuditStats {
    let isRecording = false;
    let relevantVideoDurationSeconds = 0;
    let relevantVideoPercent = 0;
    let isAnyVideoUploaded = false;
    let isMostVideoUploaded = false;
    let hasBeenOnWifiSinceAuditEnded = null; // `null` means we don't know

    if (audit && driverLogs) {
        const auditStartedAt = new Date(audit.startedAt || 0);
        const auditEndedAt = new Date(audit.endedAt || 0);
        isRecording = Boolean(
            auditStartedAt && auditEndedAt && isBefore(auditStartedAt, new Date()) && isAfter(auditEndedAt, new Date()),
        );

        for (let i = 0; i < driverLogs.length; i++) {
            const driverLog = driverLogs[i];

            if (driverLog.vehicleDeviceId !== audit.vehicleDeviceId) {
                continue;
            }

            const driverLogStartedAt = new Date(driverLog.occurredAt || 0);
            const driverLogEndedAt = addSeconds(driverLogStartedAt, driverLog.duration || 0);

            /// clip the start / end timestamps just in case the driver log's video runs
            /// before or after the actual audit session
            const segmentStartedAt = getMaxTimestamp(auditStartedAt, driverLogStartedAt);
            const segmentEndedAt = getMinTimestamp(auditEndedAt, driverLogEndedAt);
            const segmentDurationSeconds = differenceInSeconds(segmentStartedAt, segmentEndedAt);

            if (segmentDurationSeconds > 0) {
                relevantVideoDurationSeconds += segmentDurationSeconds;
            }
        }

        const auditDurationSeconds = differenceInSeconds(auditStartedAt, auditEndedAt);
        relevantVideoPercent = relevantVideoDurationSeconds / auditDurationSeconds;
        isAnyVideoUploaded = driverLogs.length > 0;
        isMostVideoUploaded = relevantVideoPercent > MOST_VIDEO_UPLOADED_PERCENT_THRESHOLD;

        // @ts-expect-error audit.vehicleDevice actually exists
        const vehicleDevice = audit.vehicleDevice as VehicleDevice;
        if (vehicleDevice) {
            const wifiLastConnectedAtStr = vehicleDevice.wifiLastConnectedAt;
            if (wifiLastConnectedAtStr) {
                const wifiLastConnectedAt = new Date(wifiLastConnectedAtStr);
                hasBeenOnWifiSinceAuditEnded = isAfter(auditEndedAt, wifiLastConnectedAt);
            }
        }
    }

    return {
        isRecording: isRecording,
        relevantVideoDurationSeconds: relevantVideoDurationSeconds,
        relevantVideoPercent: relevantVideoPercent,
        isAnyVideoUploaded: isAnyVideoUploaded,
        isMostVideoUploaded: isMostVideoUploaded,
        hasBeenOnWifiSinceAuditEnded: hasBeenOnWifiSinceAuditEnded,
    };
}

export type AuditStatus =
    | 'RECORDING'
    | 'MOST_VIDEO_UPLOADED'
    | 'SOME_VIDEO_UPLOADED'
    | 'ZERO_VIDEO_UPLOADED'
    | 'ZERO_VIDEO_UPLOADED_DESPITE_WIFI'
    | 'NEVER_CONNECTED_TO_WIFI'
    | 'WAITING_FOR_WIFI';

export function getAuditStatus(audit: Audit, driverLogs: DriverLog[]): AuditStatus {
    const auditStats = getAuditStats(audit, driverLogs);

    if (auditStats.isMostVideoUploaded) {
        return 'MOST_VIDEO_UPLOADED';
    }

    if (auditStats.isRecording) {
        return 'RECORDING';
    } else {
        // @ts-expect-error audit.vehicleDevice actually exists
        if (audit.vehicleDevice) {
            // @ts-expect-error audit.vehicleDevice actually exists
            const wifiLastConnectedAtStr = audit.vehicleDevice.wifiLastConnectedAt;

            if (wifiLastConnectedAtStr === undefined) {
                /// do nothing
            } else if (wifiLastConnectedAtStr === null) {
                return 'NEVER_CONNECTED_TO_WIFI';
            } else {
                const wifiLastConnectedAt = new Date(wifiLastConnectedAtStr);
                const auditEndedAt = audit.endedAt ? new Date(audit.endedAt) : null;

                if (auditEndedAt && isAfter(auditEndedAt, wifiLastConnectedAt)) {
                    return 'ZERO_VIDEO_UPLOADED_DESPITE_WIFI';
                } else {
                    return 'WAITING_FOR_WIFI';
                }
            }
        }

        if (auditStats.isAnyVideoUploaded) {
            return 'SOME_VIDEO_UPLOADED';
        } else {
            return 'ZERO_VIDEO_UPLOADED';
        }
    }
}
