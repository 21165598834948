import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useAppNavigate, useAppSelector } from '../../../hooks/hooks';
import { useActiveProject } from '../../../hooks/use-active-project';

import { selectNotificationConfigsByProjectId } from '../../../store/selectors';
import { NotificationConfigCustomEditDialog } from './components/NotificationEditor/NotificationConfigCustomEditDialog';
import { isNotifConfigVariable } from './components/NotificationEditor/notif-config-utils';
import { urlSettings, urlSettingsNotificationShow } from '../../../utils/internal-url-utils';
import {
    NotifConfigContextProviderProject,
    NotifConfigContextProviderUser,
} from './components/NotificationEditor/NotifConfigContext';

export function SettingsNotificationShow(): React.ReactElement {
    const project = useActiveProject();
    const configs = useAppSelector((s) => selectNotificationConfigsByProjectId(s, project.id));
    const navigate = useAppNavigate();

    const filteredConfigs = configs.filter(isNotifConfigVariable);
    const userNotifications = filteredConfigs.filter((c) => Boolean(c.userId));
    const projectNotifications = filteredConfigs.filter((c) => !Boolean(c.userId));

    return (
        <>
            <NotifConfigContextProviderProject>
                <Switch>
                    {projectNotifications.map((nc) => {
                        const path = urlSettingsNotificationShow(project, nc.id);
                        return (
                            <Route
                                key={nc.id}
                                path={path}
                                component={() => (
                                    <NotificationConfigCustomEditDialog
                                        item={nc}
                                        open={true}
                                        onClose={() =>
                                            setTimeout(() => navigate(urlSettings(project, 'notifications')), 1)
                                        }
                                    />
                                )}
                            />
                        );
                    })}
                </Switch>
            </NotifConfigContextProviderProject>
            <NotifConfigContextProviderUser>
                <Switch>
                    {userNotifications.map((nc) => {
                        const path = urlSettingsNotificationShow(project, nc.id);
                        return (
                            <Route
                                key={nc.id}
                                path={path}
                                component={() => (
                                    <NotificationConfigCustomEditDialog
                                        item={nc}
                                        open={true}
                                        onClose={() =>
                                            setTimeout(() => navigate(urlSettings(project, 'my_notifications')), 1)
                                        }
                                    />
                                )}
                            />
                        );
                    })}
                </Switch>
            </NotifConfigContextProviderUser>
        </>
    );
}
