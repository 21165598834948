import { CircularProgress, IconButton } from '@mui/material';
import * as React from 'react';
import { useUpdateDriverLog } from '../../../hooks/hooks';
import { DriverLog } from '../../../types/stein';
import { AppIconRotateVideo } from '../../AppIcons';

type DriverLogRotateMediaButtonProps = {
    driverLog: DriverLog | undefined;
};
export function DriverLogRotateMediaButton({ driverLog }: DriverLogRotateMediaButtonProps): React.ReactElement {
    const [
        updateDriverLog, // This is the mutation trigger
        { isLoading },
    ] = useUpdateDriverLog();

    function handleOnClick(): void {
        //istanbul ignore next
        if (driverLog) {
            const currentRotation = driverLog.mediaRotation || 0;
            updateDriverLog({
                id: driverLog.id,
                mediaRotation: (currentRotation + 180) % 360,
            });
        }
    }

    return (
        <IconButton onClick={handleOnClick} disabled={isLoading} aria-label={'Rotate Video'}>
            {isLoading ? <CircularProgress size={'18px'} color={'inherit'} /> : <AppIconRotateVideo />}
        </IconButton>
    );
}
