import { createTheme, Theme as MaterialTheme } from '@mui/material';
import { ThemeObject } from './index';
import type {} from '@mui/lab/themeAugmentation';

export function createMaterialTheme(theme: ThemeObject): MaterialTheme {
    return createTheme({
        palette: {
            primary: {
                main: theme.palette.primary,
            },
            secondary: {
                main: theme.palette.secondary,
            },
            error: {
                main: theme.palette.error,
            },
            warning: {
                main: theme.palette.warning,
            },
            info: {
                main: theme.palette.info,
            },
            success: {
                main: theme.palette.success,
            },
        },
        colors: theme.colors,
        typography: {
            fontFamily: theme.fontFamily,
        },
        components: theme.components,
    });
}
