import * as React from 'react';
import { Prompt } from 'react-router-dom';
import { noop } from '../utils/noop';
import { randomInternalID } from '../utils/random-internal-id';

type HasStateContext = Set<number>;

const context = React.createContext<(s: React.SetStateAction<HasStateContext>) => void>(noop);

export function HasUnsavedChangesProvider({ children }: { children: React.ReactNode }): React.ReactElement {
    const [changeSet, setChanges] = React.useState<HasStateContext>(new Set<number>());
    return (
        <context.Provider value={setChanges}>
            {children}
            <Prompt when={changeSet.size > 0} message="There are unsaved changes, do you wish to discard them?" />
        </context.Provider>
    );
}

export function useHasUnsavedChanges(hasChanges: boolean): void {
    const setHasUnsavedChanges = React.useContext(context);
    const id = React.useMemo(randomInternalID, []);
    React.useEffect(() => {
        function add(ctx: HasStateContext): HasStateContext {
            return new Set(ctx).add(id);
        }

        function remove(ctx: HasStateContext): HasStateContext {
            const newSet = new Set(ctx);
            newSet.delete(id);
            return newSet;
        }
        setHasUnsavedChanges(hasChanges ? add : remove);

        return () => setHasUnsavedChanges(remove);
    }, [setHasUnsavedChanges, hasChanges, id]);
}
