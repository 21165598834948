import { appStyled } from '../../theme';
import InputBase from '@mui/material/InputBase';
import { Box, CSSObject, InputLabel, TextField, Theme } from '@mui/material';

function applyBaseTypes({ theme, highlight }: FilterBarBaseProps & { theme: Theme }): CSSObject {
    return {
        height: '36px',
        ...(highlight
            ? {
                  backgroundColor: theme.palette.primary.main,
                  borderColor: theme.palette.primary.main,
                  color: theme.colors.toolbar.text,
              }
            : {
                  backgroundColor: theme.colors.toolbar.background,
                  borderColor: theme.colors.toolbar.text,
                  color: theme.colors.toolbar.text,
              }),
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: theme.shape.borderRadius,
    };
}

export const FilterBarBox = appStyled(Box, {
    shouldForwardProp: (prop) => prop !== 'highlight',
})<FilterBarBaseProps>(({ theme, highlight }) => ({
    '&.MuiBox-root': {
        ...applyBaseTypes({ theme, highlight }),
    },
}));

type FilterBarBaseProps = {
    highlight?: boolean;
};

export const FilterBarTextInput = appStyled(TextField, {
    shouldForwardProp: (prop) => prop !== 'highlight',
})<FilterBarBaseProps>(({ theme, highlight }) => ({
    '& .MuiInputBase-root': {
        ...applyBaseTypes({ theme, highlight }),
    },

    '& .Mui-focused': {
        borderColor: theme.palette.primary.main,
        color: theme.colors.toolbar.text,
    },

    '& .MuiInputBase-input': {
        fontSize: 13,
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        minWidth: '80px',
    },
    '& .MuiSvgIcon-root': {
        color: theme.colors.toolbar.text,
    },
    '& .MuiInputLabel-root': {
        fontSize: 13,
        color: theme.colors.toolbar.text,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        transform: 'translate(10px, 10px) scale(1)',
        transition: theme.transitions.create(['transform', 'background-color']),
        backgroundColor: 'rgba(0,0,0,0)',
        borderRadius: theme.shape.borderRadius,
    },
    '& .MuiInputLabel-root.Mui-focused, & .MuiFormLabel-filled': {
        transform: 'translate(10px, -6px) scale(0.65)',
        backgroundColor: theme.colors.toolbar.background,
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '&. MuiButtonBase-root': {
        color: theme.colors.toolbar.text,
    },
}));

export const FilterBarInputBase = appStyled(InputBase, {
    shouldForwardProp: (prop) => prop !== 'highlight',
})<FilterBarBaseProps>(({ theme, highlight }) => {
    return {
        '&.MuiInputBase-root': {
            ...applyBaseTypes({ theme, highlight }),
        },

        '&.Mui-focused': {
            borderColor: theme.palette.primary.main,
            color: theme.colors.toolbar.text,
        },

        '& .MuiInputBase-input': {
            fontSize: 13,
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            minWidth: '80px',
        },
        '& .MuiSvgIcon-root': {
            color: 'inherit',
        },
    };
});

export const FilterBarInputLabel = appStyled(InputLabel)(({ theme }) => ({
    '&.MuiInputLabel-root': {
        fontSize: 13,
        color: theme.colors.toolbar.text,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        transform: 'translate(10px, 10px) scale(1)',
        transition: theme.transitions.create(['transform', 'background-color']),
        backgroundColor: 'rgba(0,0,0,0)',
        borderRadius: theme.shape.borderRadius,
    },
    '&.Mui-focused, &.MuiFormLabel-filled': {
        transform: 'translate(10px, -6px) scale(0.65)',
        backgroundColor: theme.colors.toolbar.background,
    },
}));
