export function define<T>(i: T | null | undefined): T | null {
    if (typeof i === 'undefined') {
        return null;
    }
    return i;
}

export function defineDefault<T>(i: T | null | undefined, d: T): T {
    if (typeof i === 'undefined' || i === null) {
        return d;
    }
    return i;
}

export function lastDefined<T>(defaultValue: T, ...opts: Array<T | null | undefined>): T {
    let out: T = defaultValue;
    opts.forEach((o) => {
        if (typeof o !== 'undefined' && o !== null) {
            out = o;
        }
    });
    return out;
}
