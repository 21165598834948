import { Abilities, AbilitySubject, AbilityActionsForSubject } from '../types/stein';

export function hasAbility<T extends AbilitySubject>(
    ab: Abilities,
    action: AbilityActionsForSubject<T>,
    subject: T,
): boolean {
    return Boolean(ab.can[subject]?.includes(action));
}

export function addAbility<T extends AbilitySubject>(
    ab: Abilities,
    action: AbilityActionsForSubject<T>,
    subject: T,
): Abilities {
    const exists = hasAbility(ab, action, subject);
    if (exists) {
        return ab;
    }

    return {
        can: {
            ...ab.can,
            [subject]: ab.can[subject] ? ab.can[subject]?.concat(action) : [action],
        },
    };
}

export function removeAbility<T extends AbilitySubject>(
    ab: Abilities,
    action: AbilityActionsForSubject<T>,
    subject: T,
): Abilities {
    const exists = hasAbility(ab, action, subject);
    if (!exists) {
        return ab;
    }

    return {
        can: {
            ...ab.can,
            [subject]: ab.can[subject]?.filter((a) => a !== action),
        },
    };
}

export function toggleAbility<T extends AbilitySubject>(
    ab: Abilities,
    action: AbilityActionsForSubject<T>,
    subject: T,
): Abilities {
    return hasAbility(ab, action, subject) ? removeAbility(ab, action, subject) : addAbility(ab, action, subject);
}
