// istanbul ignore file

import * as React from 'react';
import { Box, TextField } from '@mui/material';
import { MultiBlock } from '../../MultiBlock';
import { range } from 'lodash';

export function Sandbox(): React.ReactElement | null {
    const [numItems, setNumItems] = React.useState(2);
    return (
        <Box style={{ width: '100%', height: '600px' }} border={1}>
            <MultiBlock
                items={range(0, numItems).map((s) => (
                    <Box key={s} style={{ backgroundColor: 'black' }} />
                ))}
            />
            <TextField value={numItems} onChange={(e) => setNumItems(parseInt(e.target.value))} />
        </Box>
    );
}
