import * as React from 'react';
import { FormControl, FormControlLabel } from '@mui/material';
import { EVENT_INFRACTION_TOGGLES } from '../../../constants/event-infraction-constants';
import { DriverEvent } from '../../../types/stein';
import { ElementOfArray } from '../../../types/util-types';
import { AutosaveSwitch } from '../../AutosaveSwitch';
import { AutosaveTextInput } from '../../AutosaveTextInput';
import { FilterIcon } from '../../FilterBar/FilterIcon';
import { CalloutBox, CalloutSkeleton } from '../../material/CalloutBox';
import { useUpdateDriverEvent } from '../../../hooks/hooks';
import { useHasPermission } from '../../Authorize/Authorize';

type OtherNotesProps = { event: DriverEvent };
function OtherNotes({ event }: OtherNotesProps): React.ReactElement {
    const canEdit = useHasPermission('DriverEvent', 'update');
    const [
        updateDriverEvent, // This is the mutation trigger
        { isLoading }, // This is the destructured mutation result
    ] = useUpdateDriverEvent();

    function onSave(s: string | null): void {
        updateDriverEvent({
            id: event.id,
            flagOtherNotes: s,
        });
    }

    return (
        <AutosaveTextInput
            label={'Other Infraction'}
            value={event.flagOtherNotes || ''}
            onSave={onSave}
            saving={isLoading}
            disabled={!canEdit}
        />
    );
}

type InfractionToggleProps = {
    event: DriverEvent;
    infraction: ElementOfArray<typeof EVENT_INFRACTION_TOGGLES>;
};

export function InfractionToggle({ event, infraction }: InfractionToggleProps): React.ReactElement {
    const currentValue = Boolean(event[infraction.eventField]);
    const canEdit = useHasPermission('DriverEvent', 'update');

    const [
        updateDriverEvent, // This is the mutation trigger
        { isLoading }, // This is the destructured mutation result
    ] = useUpdateDriverEvent();

    function onSave(): void {
        updateDriverEvent({
            id: event.id,
            [infraction.eventField]: !currentValue,
        });
    }
    return (
        <FormControl fullWidth>
            <FormControlLabel
                labelPlacement={'end'}
                control={
                    <AutosaveSwitch checked={currentValue} onSave={onSave} saving={isLoading} disabled={!canEdit} />
                }
                label={<FilterIcon value={infraction} showName />}
            />
        </FormControl>
    );
}

type DriverEventInfractionsProps = {
    event: DriverEvent | undefined;
};

export function DriverEventInfractions({ event }: DriverEventInfractionsProps): React.ReactElement {
    if (!event) {
        return <CalloutSkeleton name="Infractions" height={254} />;
    }

    return (
        <CalloutBox name={'Infractions'}>
            {EVENT_INFRACTION_TOGGLES.map((infraction) => (
                <InfractionToggle event={event} infraction={infraction} key={infraction.id} />
            ))}
            <div style={{ marginTop: 8 }}>
                <OtherNotes event={event} />
            </div>
        </CalloutBox>
    );
}
