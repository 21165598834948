import * as React from 'react';
import { useAppTheme } from '../../../../theme';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Collapse, Toolbar, Typography } from '@mui/material';
import { useNavigationDrawerContext } from '../../../AppLayout/NavigationDrawerContext';
import { AppIconChevronDown } from '../../../AppIcons';
import { Stack } from '@mui/system';

type SettingsSaveBarProps = {
    onSave: () => void;
    onReset: () => void;
    show: boolean;
    loading: boolean;
    extraButtons?: React.ReactNode;
};

export function SettingsSaveBar({
    onSave,
    onReset,
    show,
    loading,
    extraButtons,
}: SettingsSaveBarProps): React.ReactElement {
    const theme = useAppTheme();
    return (
        <>
            <Box sx={{ height: 60 }} />
            <Collapse in={show || Boolean(extraButtons)}>
                <Box
                    position="fixed"
                    color="primary"
                    bottom={0}
                    width={'90%'}
                    sx={{
                        backgroundColor: theme.palette.grey[100],
                    }}
                >
                    <Toolbar>
                        <Stack direction={'row'} spacing={2}>
                            <LoadingButton onClick={onSave} loading={loading} variant={'contained'} disabled={!show}>
                                {'Save'}
                            </LoadingButton>
                            <Button onClick={onReset} disabled={!show || loading}>
                                {'Reset'}
                            </Button>
                            {extraButtons}
                        </Stack>
                    </Toolbar>
                </Box>
            </Collapse>
        </>
    );
}

type SettingsPageProps = {
    title: React.ReactNode;
    children: React.ReactNode;
};
export function SettingsPage({ title, children }: SettingsPageProps): React.ReactElement {
    return (
        <Box marginLeft={2}>
            <Typography variant="h5" marginBottom={2}>
                {title}
            </Typography>

            {children}
        </Box>
    );
}

export function SettingsMobileNav(): React.ReactElement {
    const { setIsOpen } = useNavigationDrawerContext();

    // istanbul ignore next
    function close(): void {
        // istanbul ignore next
        setIsOpen(true);
    }

    return (
        <Button
            fullWidth
            onClick={close}
            sx={{ display: { sm: 'none' }, cursor: 'pointer', marginBottom: 1 }}
            size={'small'}
            variant={'outlined'}
            endIcon={<AppIconChevronDown />}
        >
            Settings Categories
        </Button>
    );
}
