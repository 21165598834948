// istanbul ignore file

import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Divider, MenuItem, Stack, TextField } from '@mui/material';
import { snakeCase } from 'lodash';
import * as React from 'react';
import { SteinInternalApiClientToken } from '../../../../clients/stein-internal-api';
import { useStagedChanges } from '../../../../hooks/use-staged-changes';
import { useFrame } from '../../../../lib/frame-react';
import { Audit } from '../../../../types/stein';
import { SelectSmall } from '../../../material/SelectSmall';
import { useAuditTagProfile } from './AuditTagProfile';

function Metadata({
    fieldName,
    children,
}: {
    fieldName: string;
    children: React.ReactNode;
}): React.ReactElement | null {
    const { profile } = useAuditTagProfile();

    if (profile?.customMetadataFields.includes(snakeCase(fieldName))) {
        return <>{children}</>;
    }
    return null;
}

export function AuditCustomMetadata({ audit }: { audit: Audit }): React.ReactElement {
    const { useUpdateAuditMetadataMutation } = useFrame(SteinInternalApiClientToken);
    const [updateAuditMetadata, { isLoading: isUpdating }] = useUpdateAuditMetadataMutation();

    const { merged, hasChanges, setChanges, save, reset } = useStagedChanges(updateAuditMetadata, {
        auditSlug: audit.slug,
        customMetadata: audit.customMetadata || {},
    });

    function setMetadataChanges(change: Audit['customMetadata']): void {
        setChanges((c) => ({
            ...c,
            customMetadata: {
                ...audit.customMetadata,
                ...c?.customMetadata,
                ...change,
            },
        }));
    }

    const getTextInputProps = (
        name: string,
    ): {
        value: string;
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    } => {
        return {
            value: merged.customMetadata[name] || '',
            onChange: (e) => {
                const newValue = e.target.value;
                setMetadataChanges({ [name]: newValue });
            },
        };
    };
    const getSelectProps = (
        name: string,
    ): {
        value: string;
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    } => {
        return {
            value: merged.customMetadata[name] || '',
            onChange: (e) => {
                const newValue = e.target.value;
                setMetadataChanges({ [name]: newValue });
            },
        };
    };

    return (
        <Stack spacing={1}>
            <Metadata fieldName={'driverName'}>
                <TextField label={'Driver Name'} size={'small'} {...getTextInputProps('driverName')} />
            </Metadata>

            <Metadata fieldName={'driverAuditType'}>
                <SelectSmall
                    labelId={'select-driver-audit-type'}
                    label={'Driver Audit Type'}
                    {...getSelectProps('driverAuditType')}
                >
                    {renderOptions(DRIVER_AUDIT_TYPE)}
                </SelectSmall>
            </Metadata>

            <Metadata fieldName={'driverFeedback'}>
                <TextField
                    label={'Driver Feedback'}
                    multiline
                    size={'small'}
                    {...getTextInputProps('driverFeedback')}
                />
            </Metadata>
            <Divider />
            <Metadata fieldName={'notetakerName'}>
                <TextField label={'Notetaker Name'} size={'small'} {...getTextInputProps('notetakerName')} />
            </Metadata>

            <Metadata fieldName={'notetakerAuditType'}>
                <SelectSmall
                    labelId={'select-notetaker-audit-type'}
                    label={'Notetaker Audit Type'}
                    {...getSelectProps('notetakerAuditType')}
                >
                    {renderOptions(NOTETAKER_AUDIT_TYPE)}
                </SelectSmall>
            </Metadata>
            <Metadata fieldName={'notetakerFeedback'}>
                <TextField
                    label={'Notetaker Feedback'}
                    multiline
                    size={'small'}
                    {...getTextInputProps('notetakerFeedback')}
                />
            </Metadata>
            <Divider />
            <Metadata fieldName={'auditorName'}>
                <TextField label={'Auditor Name'} size={'small'} {...getTextInputProps('auditorName')} />
            </Metadata>
            <Metadata fieldName={'auditMonth'}>
                <SelectSmall labelId={'select-audit-month'} label={'Audit Month'} {...getSelectProps('auditMonth')}>
                    {renderOptions(AUDIT_MONTHS)}
                </SelectSmall>
            </Metadata>
            <Metadata fieldName={'url'}>
                <TextField label={'URL'} size={'small'} {...getTextInputProps('url')} />
            </Metadata>
            {hasChanges && (
                <Stack direction="row">
                    <Button onClick={reset}>{'Reset'}</Button>
                    <LoadingButton onClick={save} size={'small'} loading={isUpdating} variant={'contained'} fullWidth>
                        {'Save'}
                    </LoadingButton>
                </Stack>
            )}
        </Stack>
    );
}

function renderOptions(items: Readonly<string[]>): React.ReactElement[] {
    return items.map((s) => (
        <MenuItem key={s} value={s}>
            {s}
        </MenuItem>
    ));
}

const AUDIT_MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] as const;

const NOTETAKER_AUDIT_TYPE = [
    'Monthly Notetaker',
    'Trainer Notetaker',
    'OJT Baseline Notetaker',
    'OJT 2nd Audit Notetaker',
    'Training Certification Notetaker',
] as const;

const DRIVER_AUDIT_TYPE = [
    'Monthly Driver',
    'Trainer Driver',
    'OJT Baseline Driver',
    'OJT 2nd Audit Driver',
    'Training Certification Driver',
] as const;
