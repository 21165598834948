import * as React from 'react';
import { FilterBarInputLabel, FilterBarInputBase } from './FilterBarBaseTypes';
import { Box, Button, FormControl, IconButton, ListSubheader, MenuItem, TextField, Tooltip } from '@mui/material';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import { AppIconResetSelection } from '../AppIcons';

import { FilterGroup, FilterValueId, FilterValueType } from '../../store/slices/filterConfigSlice';
import { useFrame } from '../../lib/frame-react';
import { LoggerToken } from '../../lib/frame-tokens';
import { startCase } from 'lodash';
import { FilterIcon } from './FilterIcon';
import { isEqual } from 'lodash';
import { useSearchParam } from '../../hooks/use-filters';
import { Stack } from '@mui/system';

type FilterMultiselectProps = Omit<
    SelectProps<FilterValueId[]>,
    'value' | 'children' | 'label' | 'selected' | 'defaultValue'
> & {
    options: (FilterValueType | FilterGroup)[];
    defaultValue?: FilterValueId[] | null;
    filterName: string;
    label?: string;
    filterable?: boolean;
};

function isFilterValue(val: FilterValueType | FilterGroup): val is FilterValueType {
    return 'id' in val;
}

export function FilterMultiselect({
    filterName,
    options,
    defaultValue = null,
    label,
    filterable = false,
    ...props
}: FilterMultiselectProps): React.ReactElement {
    const log = useFrame(LoggerToken);
    const labelToDisplay = label ? label : startCase(filterName);
    const [selectedIds, setSelected] = useSearchParam(filterName);
    const selected = options.filter(isFilterValue).filter((o) => selectedIds?.includes(o.id));

    const isDefault = isEqual(selectedIds, defaultValue);
    const [filterString, setFilter] = React.useState<string>('');
    const filteredOptions =
        filterable && filterString
            ? options.filter(isFilterValue).filter((o) => o.name.toLowerCase().includes(filterString.toLowerCase()))
            : options;

    const onReset: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setSelected(defaultValue);
        setFilter('');
    };

    React.useEffect(() => {
        if (selectedIds === null && defaultValue !== null) {
            setSelected(defaultValue);
        }
    }, [selectedIds, defaultValue]);

    function onChange(event: SelectChangeEvent<FilterValueId[] | FilterValueId>): void {
        const changed = event?.target.value as FilterValueId[] | FilterValueId | undefined;
        const newValues: FilterValueId[] = [];

        if (Array.isArray(changed)) {
            changed?.forEach((id) => {
                const v = options.filter(isFilterValue).find((v) => v.id === id);

                /* istanbul ignore next */
                if (v) {
                    newValues.push(v.id);
                } else {
                    /* istanbul ignore next */
                    log.warn(
                        `Filter '${filterName}' is trying to select value with id: '${id}' which does not exist in values: `,
                        options,
                    );
                }
            });
        } else {
            /* istanbul ignore else */
            if (typeof changed === 'string') {
                newValues.push(changed);
            }
        }

        setSelected(newValues);
    }

    const id = `filter-multiselect-${labelToDisplay}`;
    return (
        <FormControl>
            <FilterBarInputLabel id={id}>{labelToDisplay}</FilterBarInputLabel>
            <Select
                labelId={id}
                input={<FilterBarInputBase highlight={!isDefault} data-testid={id} />}
                value={selected.map((f) => f.id)}
                onChange={onChange}
                MenuProps={{
                    autoFocus: false,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    transformOrigin: { vertical: 'top', horizontal: 'left' },
                    PaperProps: {
                        style: {
                            maxHeight: '600px',
                        },
                    },
                }}
                renderValue={(selectedIds) => {
                    const selected: FilterValueType[] = [];
                    selectedIds.forEach((id) => {
                        const v = options.filter(isFilterValue).find((v) => 'id' in v && v.id === id);
                        /* istanbul ignore next - this case shoudn't hit, so we'll ignore it in branching tests */
                        if (v && 'id' in v) {
                            selected.push(v);
                        }
                    });
                    if (selected.length == 1) {
                        return selected[0].name;
                    } else if (selected.every((s) => Boolean(s.icon))) {
                        return (
                            <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '.9em' }}>
                                {selected.map((s) => (
                                    <FilterIcon value={s} key={s.id} />
                                ))}
                            </Box>
                        );
                    } else {
                        return `${selected.length} ${labelToDisplay}`;
                    }
                }}
                multiple
                {...props}
            >
                {filterable ? (
                    <Stack direction={'row'} p={1}>
                        <TextField
                            data-testid={`${id}-filter`}
                            label={'Filter'}
                            size={'small'}
                            autoFocus
                            value={filterString}
                            onKeyDown={(e) => {
                                e.stopPropagation();
                            }}
                            onChange={(e) => setFilter(e.target.value)}
                        />
                        <Tooltip title={'Reset Selection'}>
                            <IconButton size={'small'} onClick={onReset} data-testid={`${id}-reset`}>
                                <AppIconResetSelection />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                ) : (
                    <Box
                        sx={{
                            paddingLeft: 1,
                            paddingRight: 2,
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Button
                            size="small"
                            color="primary"
                            data-testid={`${id}-reset`}
                            onClick={onReset}
                            endIcon={<AppIconResetSelection />}
                            fullWidth
                        >
                            {'Reset'}
                        </Button>
                    </Box>
                )}

                {filteredOptions.map((v, i) =>
                    'group' in v ? (
                        <ListSubheader key={i} sx={{ lineHeight: '20px' }}>
                            {v.group}
                        </ListSubheader>
                    ) : (
                        <MenuItem key={i} value={v.id}>
                            <FilterIcon value={v} showName />
                        </MenuItem>
                    ),
                )}
            </Select>
        </FormControl>
    );
}
