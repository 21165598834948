import { navigationSlice } from './slices/navigationSlice';
import { notificationSlice } from './slices/notificationSlice';
import { driverEventsSlice } from './slices/driverEventsSlice';
import { abilitiesSlice } from './slices/abilitiesSlice';
import { filterConfigSlice } from './slices/filterConfigSlice';

export * from './slices/shared-actions';
export const { toggleDebugEnabled, toggleDebugModal } = navigationSlice.actions;
export const { overrideAbility } = abilitiesSlice.actions;
export const { createNotification, dismissNotification, setIsConnected } = notificationSlice.actions;
export const { markAsSeen } = driverEventsSlice.actions;
export const { setActivePreset } = filterConfigSlice.actions;
