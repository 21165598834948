import { Box, CircularProgress, IconButton, SwitchProps, Tooltip } from '@mui/material';
import * as React from 'react';
import { SteinInternalApiClientToken } from '../../../../../clients/stein-internal-api';

import { useFrame } from '../../../../../lib/frame-react';
import { NotifConfigToggleable } from '../../../../../types/stein.notification_config';

import { AppIconSend } from '../../../../AppIcons';
import { AutosaveSwitch } from '../../../../AutosaveSwitch';

type ConfigItemSwitchProps = {
    item: Omit<NotifConfigToggleable, 'id'>;
} & SwitchProps;

export function NotificationConfigSwitch({ item, ...props }: ConfigItemSwitchProps): React.ReactElement | null {
    const { useCreateOrUpdateNotifConfigMutation, useSendTestNotificationMutation } =
        useFrame(SteinInternalApiClientToken);
    const [createOrUpdate, { isLoading }] = useCreateOrUpdateNotifConfigMutation();
    const [sendTest, { isLoading: isSending }] = useSendTestNotificationMutation();
    const enabled = item.enabled || false;

    function onSave(enabled: boolean): void {
        createOrUpdate({ ...item, enabled });
    }

    const disabled = isSending || !enabled;

    return (
        <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            data-testid={`notification-toggle-${item.category}-${item.medium}`}
        >
            <AutosaveSwitch {...props} checked={enabled} onSave={onSave} saving={isLoading} />
            <Tooltip title={'Send test notification'} disableHoverListener={disabled}>
                <span>
                    <IconButton size="small" disabled={disabled} onClick={() => sendTest(item)}>
                        {isSending ? <CircularProgress size={12} /> : <AppIconSend size={12} />}
                    </IconButton>
                </span>
            </Tooltip>
        </Box>
    );
}
