import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User, UserId } from '../../types/stein';
import { setRailsContext } from './shared-actions';

// Define the initial state using that type
const initialState: User = {
    id: 0 as UserId,
    displayName: '',
};

export const userSlice = createSlice({
    name: 'user',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        updateUser(s, a: PayloadAction<User>) {
            return a.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setRailsContext, (s, a) => {
            return a.payload.user;
        });
    },
});
