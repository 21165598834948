import * as React from 'react';
import { EVENT_TYPE_MAP_ID } from '../../constants/event-type-constants';
import { DriverEvent } from '../../types/stein';
import { AppIconBase, AppIconQuestion } from '../AppIcons';

type DriverEventIconProps = {
    event: DriverEvent;
};

export function DriverEventIcon({ event }: DriverEventIconProps): React.ReactElement | null {
    const eventParams = EVENT_TYPE_MAP_ID[event.eventName];
    return eventParams ? <AppIconBase name={eventParams.name} iconClass={eventParams.icon} /> : <AppIconQuestion />;
}
