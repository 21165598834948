import * as React from 'react';

import { SteinInternalApiClientToken } from '../../clients/stein-internal-api';

import { useFrame } from '../../lib/frame-react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import { VehicleDevice, Push } from '../../types/stein';
import { TooltipOptional } from '../material/TooltipOptional';

type ButtonProps = React.ComponentProps<typeof Button>;

type VehiclePushButtonProps = Omit<ButtonProps, 'children'> & {
    vehicle: VehicleDevice;
    confirmation?: React.ReactNode;
    push: Pick<Push, 'customData' | 'category'>;
    tooltip?: React.ReactNode;
    text: string;
};

export function VehiclePushButton({
    vehicle,
    confirmation,
    push,
    tooltip,
    text,
    ...buttonProps
}: VehiclePushButtonProps): React.ReactElement {
    const { useCreatePushMutation } = useFrame(SteinInternalApiClientToken);

    const [createPush, { isLoading }] = useCreatePushMutation();
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);

    function closeModal(): void {
        setModalOpen(false);
    }

    function sendPush(): void {
        closeModal();
        createPush({
            vehicleDeviceId: vehicle.id,
            ...push,
        });
    }

    if (confirmation) {
        return (
            <>
                <TooltipOptional title={text} description={tooltip}>
                    <span>
                        <LoadingButton
                            fullWidth
                            variant="contained"
                            size={'small'}
                            {...buttonProps}
                            onClick={() => setModalOpen(true)}
                            loading={isLoading}
                        >
                            {text}
                        </LoadingButton>
                    </span>
                </TooltipOptional>

                <Dialog open={modalOpen} onClose={closeModal} keepMounted>
                    <DialogTitle>{'Confirmation'}</DialogTitle>
                    <DialogContent>{confirmation}</DialogContent>
                    <DialogActions>
                        <Button onClick={closeModal}>{'No'}</Button>
                        <Button onClick={sendPush} variant={'contained'}>
                            {'Yes'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    return (
        <TooltipOptional title={text} description={tooltip}>
            <span>
                <LoadingButton
                    fullWidth
                    variant="contained"
                    size={'small'}
                    {...buttonProps}
                    onClick={sendPush}
                    loading={isLoading}
                >
                    {text}
                </LoadingButton>
            </span>
        </TooltipOptional>
    );
}
