import {
    AppInstall,
    Audit,
    DriverEvent,
    GeofenceSlug,
    NotifConfigId,
    Project,
    Region,
    VehicleDevice,
} from '../types/stein';
import { EntityOrSlug, EntityOrId } from '../types/util-types';
import { makeQueryString } from './query-string';

const AppInternalUrlSymbol = Symbol();
type ProjectOrSlug = EntityOrSlug<Project>;

export type AppInternalUrl = string & { [AppInternalUrlSymbol]: true };

function toUrl(s: string, params?: Record<string, unknown>): AppInternalUrl {
    const search = params ? `?${makeQueryString(params)}` : '';

    return `${s}${search}` as AppInternalUrl;
}

export function urlProject(project: ProjectOrSlug): AppInternalUrl {
    const slug = typeof project === 'string' ? project : project.slug;
    return toUrl(`/projects/${slug}`) as AppInternalUrl;
}

export function urlProjectAuditCSV(project: ProjectOrSlug): AppInternalUrl {
    return toUrl(`${urlProject(project)}/web_events.csv`) as AppInternalUrl;
}

// Top Level

export const URL_ROOT = toUrl('/');
export const URL_SUPPORT = toUrl('/support');
export const URL_GOD_MODE = toUrl('/god_mode');
export const URL_MDM = toUrl('/god_mode/mdm_servers');
export const URL_SOUND_EFFECTS = toUrl('/sound_effects');
export const URL_APP_BUILDS = toUrl('/sound_effects');
export const URL_SIDEKIQ = toUrl('/sidekiq');
export const URL_SANDBOX = toUrl('/sandbox');
export const URL_V2_ENABLE = toUrl('/v2_enable');
export const URL_V2_DISABLE = toUrl('/v2_disable');

// Test urls
export const URL_UNKNOWN_TEST = toUrl('/unknown');
export const URL_GOD_MODE_ANALYTICS = toUrl('/admin/analytics');
export const URL_UNDER_CONSTRUCTION = toUrl('/under_construction');

export function urlDriverEventList(project: ProjectOrSlug, params?: Record<string, unknown>): AppInternalUrl {
    return toUrl(`${urlProject(project)}/driver_events`, params);
}

export function urlMap(project: ProjectOrSlug): AppInternalUrl {
    return toUrl(`${urlProject(project)}/map`);
}

export function urlGeoSearch(project: ProjectOrSlug, params?: Record<string, string | string[]>): AppInternalUrl {
    return toUrl(`${urlProject(project)}/geosearch`, params);
}

export function urlMonitor(project: ProjectOrSlug, params?: Record<string, string | string[]>): AppInternalUrl {
    return toUrl(`${urlProject(project)}/live`, params);
}

export function urlFacilitiesList(project: ProjectOrSlug): AppInternalUrl {
    return toUrl(`${urlProject(project)}/settings/facilities`);
}

export function urlFacilitiesShow(project: ProjectOrSlug, geofenceId: GeofenceSlug): AppInternalUrl {
    return toUrl(`${urlFacilitiesList(project)}/${geofenceId}`);
}

export function urlVehicleDeviceList(project: ProjectOrSlug, params?: Record<string, unknown>): AppInternalUrl {
    return toUrl(`${urlProject(project)}/vehicle_devices`, params);
}

export function urlAppInstallList(project: ProjectOrSlug, params?: Record<string, unknown>): AppInternalUrl {
    return toUrl(`${urlProject(project)}/devices`, params);
}

type SettingsPage =
    | 'my_notifications'
    | 'my_profile'
    | 'advanced'
    | 'device'
    | 'roles'
    | 'project'
    | 'notifications'
    | 'memberships'
    | 'regions';
export function urlSettings(project: ProjectOrSlug, path?: SettingsPage): AppInternalUrl {
    return toUrl(`${urlProject(project)}/settings${path ? `/${path}` : ''}`);
}

export function urlSettingsNotificationShow(project: ProjectOrSlug, id: NotifConfigId): AppInternalUrl {
    return toUrl(`${urlProject(project)}/settings/notificationconfigs/${id}`);
}

export function urlDiagnostics(project: ProjectOrSlug): AppInternalUrl {
    return toUrl(`${urlProject(project)}/diagnostics`);
}

export function urlGeofences(project: ProjectOrSlug): AppInternalUrl {
    return toUrl(`${urlProject(project)}/geofences`);
}

export function urlAuditList(project: ProjectOrSlug): AppInternalUrl {
    return toUrl(`${urlProject(project)}/audits`);
}

export function urlConfigItemsList(project: ProjectOrSlug): AppInternalUrl {
    return toUrl(`${urlProject(project)}/config_items`);
}

// Dependent

export function urlDriverEventShow(project: ProjectOrSlug, eventOrId: EntityOrId<DriverEvent>): AppInternalUrl {
    const id = typeof eventOrId === 'object' ? eventOrId.id : eventOrId;
    return toUrl(`${urlDriverEventList(project)}/${id}`);
}

export function urlVehicleDeviceShow(
    project: ProjectOrSlug,
    vehicleDeviceOrSlug: EntityOrSlug<VehicleDevice>,
): AppInternalUrl {
    const vehicleDeviceSlug = typeof vehicleDeviceOrSlug === 'string' ? vehicleDeviceOrSlug : vehicleDeviceOrSlug.slug;

    return toUrl(`${urlVehicleDeviceList(project)}/${vehicleDeviceSlug}`);
}

export function urlAppInstallShow(project: ProjectOrSlug, appInstallOrSlug: EntityOrSlug<AppInstall>): AppInternalUrl {
    const appInstallSlug = typeof appInstallOrSlug === 'string' ? appInstallOrSlug : appInstallOrSlug.slug;

    return toUrl(`${urlAppInstallList(project)}?selected=${appInstallSlug}`);
}

export function urlVehicleDeviceTimeline(
    project: ProjectOrSlug,
    vehicleOrSlug: EntityOrSlug<VehicleDevice>,
    params?: Record<string, unknown>,
): AppInternalUrl {
    return toUrl(`${urlVehicleDeviceShow(project, vehicleOrSlug)}/timeline`, params);
}

export function urlRegion(project: ProjectOrSlug, regionOrId: EntityOrId<Region>): AppInternalUrl {
    const regionId = typeof regionOrId === 'number' ? regionOrId : regionOrId.id;

    return toUrl(`${urlDriverEventList(project)}?regions[]=${regionId}`);
}

export function urlAudit(project: ProjectOrSlug, auditOrSlug: EntityOrSlug<Audit>): AppInternalUrl {
    const auditSlug = typeof auditOrSlug === 'string' ? auditOrSlug : auditOrSlug.slug;

    return toUrl(`${urlAuditList(project)}/${auditSlug}`);
}
