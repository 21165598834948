import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { changeLocation } from './shared-actions';
import { DistributiveOmit, DistributivePartialPick } from '../../types/util-types';
import {
    AppNotification,
    AppNotificationCommonInternalProps,
    AppNotificationId,
} from '../../components/Notifications/notificationTypes';
import { toISO8601 } from '../../utils/datetime-utils';
import { randomInternalID } from '../../utils/random-internal-id';

// These are the properties that can be supplied when creating a notification
type AppNotificationOptions = DistributiveOmit<AppNotification, keyof AppNotificationCommonInternalProps>;

export const notificationAdapter = createEntityAdapter<AppNotification>({
    selectId: (e) => e.id,
    sortComparer: (a, b) => (b.createdAt || '').localeCompare(a.createdAt || ''),
});
const selectors = notificationAdapter.getSelectors();

type NotificationStore = {
    isConnected: boolean;
    currentPathname: string | null;
};

function shouldAutoDismiss(s: NotificationStore, n: AppNotificationOptions): boolean {
    switch (n.type) {
        case 'event': {
            for (const c of n.dismissConditions) {
                switch (c.type) {
                    case 'urlVisited': {
                        if (s.currentPathname === c.url) {
                            return true;
                        }
                    }
                }
            }
        }
    }

    return false;
}

export const notificationSlice = createSlice({
    name: 'notifications',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState: {
        ...notificationAdapter.getInitialState(),
        isConnected: false,
        currentPathname: null as string | null,
    },
    reducers: {
        createNotification(s, a: PayloadAction<DistributivePartialPick<AppNotificationOptions, 'id'>>) {
            const { id, ...notificationOpts } = a.payload;

            const notification = {
                id: id || (randomInternalID() as AppNotificationId),
                ...notificationOpts,
            };

            notificationAdapter.addOne(s, {
                ...notification,
                dismissed: shouldAutoDismiss(s, notification),
                acked: shouldAutoDismiss(s, notification),
                createdAt: toISO8601(new Date()),
            });
        },
        updateNotification(s, a: PayloadAction<Partial<AppNotificationOptions> & Pick<AppNotificationOptions, 'id'>>) {
            notificationAdapter.updateOne(s, {
                id: a.payload.id,
                changes: a.payload,
            });
        },
        dismissNotification(s, a: PayloadAction<AppNotificationId>) {
            notificationAdapter.updateOne(s, {
                id: a.payload,
                changes: {
                    dismissed: true,
                },
            });
        },
        ackNotification(s, a: PayloadAction<AppNotificationId>) {
            notificationAdapter.updateOne(s, {
                id: a.payload,
                changes: {
                    acked: true,
                    dismissed: true,
                },
            });
        },
        setIsConnected(s, a: PayloadAction<boolean>) {
            s.isConnected = a.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(changeLocation, (s, a) => {
            s.currentPathname = a.payload.new.pathname;
            selectors.selectAll(s).forEach((n) =>
                notificationAdapter.updateOne(s, {
                    id: n.id,
                    changes: {
                        dismissed: shouldAutoDismiss(s, n) || n.dismissed,
                    },
                }),
            );
        });
    },
});

export const { ...actions } = notificationSlice.actions;
