import { Stack } from '@mui/system';
import * as React from 'react';

import { VehicleDevice } from '../../../types/stein';
import { define } from '../../../utils/undefined-utils';
import { GeofenceChip, RegionChip } from '../../AppChip';
import { CalloutBox, CalloutSkeleton } from '../../material/CalloutBox';
import { BatteryStatusIndicator } from '../../Vehicle/BatteryStatusIndicator';
import { IOSUpdateIndicator } from '../../Vehicle/IOSUpdateIndicator';
import { ObservantUpdateIndicator } from '../../Vehicle/ObservantUpdateIndicator';
import { isVehicleOnline } from '../../Vehicle/vehicle_utils';

type VehicleDeviceStatusBoxProps = {
    vehicle: VehicleDevice | undefined;
};

export function VehicleDeviceStatusBox({ vehicle }: VehicleDeviceStatusBoxProps): React.ReactElement {
    // istanbul ignore next
    if (!vehicle) {
        // istanbul ignore next
        return <CalloutSkeleton name="Device Status" />;
    }

    const isOnline = isVehicleOnline(vehicle);

    return (
        <CalloutBox name="Status">
            <Stack spacing={1}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <strong>{'iOS version: '}</strong>
                    <div>{vehicle.operatingSystemVersion}</div>
                    <IOSUpdateIndicator vehicle={vehicle} />
                </Stack>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <strong>{'App version: '}</strong>
                    <div>
                        {vehicle.lastBundleVersion
                            ? /*istanbul ignore next*/ vehicle.lastBundleVersion
                            : vehicle.lastBundleShortVersion}
                    </div>
                    <ObservantUpdateIndicator vehicle={vehicle} />
                </Stack>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <strong>{'Microphone Permissions: '}</strong>
                    <div>
                        {vehicle.microphonePermissionDenied
                            ? 'Denied'
                            : vehicle.microphonePermissionGranted
                              ? 'Granted'
                              : 'Not Requested'}
                    </div>
                </Stack>

                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <strong>{'Battery: '}</strong>
                    <BatteryStatusIndicator
                        batteryState={define(vehicle.lastBatteryState)}
                        batteryLevel={define(vehicle.lastBatteryLevel)}
                        isOnline={isOnline}
                    />
                </Stack>

                {
                    /*istanbul ignore next*/ vehicle.geofenceIds ? (
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <strong>{'Facilities: '}</strong>
                            {vehicle.geofenceIds.map((gId) => (
                                <GeofenceChip geofenceId={gId} size={'small'} key={gId} />
                            ))}
                        </Stack>
                    ) : null
                }

                {
                    /*istanbul ignore next*/ vehicle.regionId ? (
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <strong>{'Region: '}</strong>
                            <RegionChip regionId={vehicle.regionId} size={'small'} />
                        </Stack>
                    ) : null
                }
            </Stack>
        </CalloutBox>
    );
}
