import * as React from 'react';
import Draggable from 'react-draggable';
import { Box, Card, Fade } from '@mui/material';
import { useIntercom } from '../../services/app-intercom-service/use-intercom';
import { IntercomCallContent } from './IntercomCall';
import { InvisiblePresentation } from '../InvisiblePresentation';
import { useAppTheme } from '../../theme';

export function Intercom(): React.ReactElement | null {
    const { call, pending } = useIntercom();
    const theme = useAppTheme();

    const style: React.CSSProperties = {
        pointerEvents: 'all',
        position: 'absolute',
        cursor: 'move',
        right: 10,
        bottom: 5,
    };

    return (
        <InvisiblePresentation sx={{ zIndex: 2147483004 /* one over intercom*/ }}>
            <Draggable bounds={'parent'}>
                <div style={style}>
                    <Fade in={Boolean(call || pending)}>
                        <Card
                            sx={{
                                display: 'flex',
                                backgroundColor: theme.colors.toolbar.background,
                                color: theme.colors.toolbar.text,
                            }}
                        >
                            <Box
                                sx={{ display: 'flex', flexDirection: 'column', minWidth: 350 }}
                                data-testid={'intercom-call-popover'}
                            >
                                {call ? (
                                    <IntercomCallContent call={call} />
                                ) : /* istanbul ignore next */ pending ? (
                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: 50,
                                            display: 'flex',
                                            alignItems: 'center',
                                            paddingLeft: 2,
                                        }}
                                    >
                                        {'Starting call...'}
                                    </Box>
                                ) : (
                                    <></>
                                )}
                            </Box>
                        </Card>
                    </Fade>
                </div>
            </Draggable>
        </InvisiblePresentation>
    );
}
