import LoadingButton from '@mui/lab/LoadingButton';
import * as React from 'react';
import { SteinInternalApiToken } from '../../../clients/stein-internal-api';
import { useConfirmationDialog } from '../../../hooks/use-confirmation-dialog';
import { useFrame } from '../../../lib/frame-react';

import { VehicleDevice } from '../../../types/stein';
import { toISO8601 } from '../../../utils/datetime-utils';
import { AppIconArchive } from '../../AppIcons';
import { CalloutBox, CalloutSkeleton } from '../../material/CalloutBox';

type VehicleDeviceVisibilityProps = {
    vehicle: VehicleDevice | undefined;
};

export function VehicleDeviceVisibility({ vehicle }: VehicleDeviceVisibilityProps): React.ReactElement {
    const { useUpdateVehicleDeviceMutation } = useFrame(SteinInternalApiToken);
    const [update, { isLoading }] = useUpdateVehicleDeviceMutation();
    const archive = useConfirmationDialog({
        func: () => {
            vehicle
                ? update({
                      id: vehicle.id,
                      archivedAt: toISO8601(new Date()),
                  })
                : /* istanbul ignore next */ null;

            return Promise.resolve();
        },
        message: 'Archived vehicles do not appear in the vehicle list.',
    });

    // istanbul ignore next
    if (!vehicle) {
        // istanbul ignore next
        return <CalloutSkeleton name="Visibility" />;
    }

    return (
        <CalloutBox name="Visibility" data-testid={'visibility-box'}>
            {vehicle.archivedAt ? (
                <LoadingButton
                    variant={'contained'}
                    color={'primary'}
                    size={'small'}
                    loading={isLoading}
                    fullWidth
                    onClick={() =>
                        update({
                            id: vehicle.id,
                            archivedAt: null,
                        })
                    }
                >
                    {'Un-Archive'}
                </LoadingButton>
            ) : (
                <>
                    <LoadingButton
                        variant={'contained'}
                        color={'error'}
                        size={'small'}
                        loading={isLoading}
                        fullWidth
                        onClick={archive.handleShouldConfirm}
                        endIcon={<AppIconArchive />}
                    >
                        {'Archive'}
                    </LoadingButton>
                    {archive.node}
                </>
            )}
        </CalloutBox>
    );
}
