import * as React from 'react';
import { DriverEvent } from '../../../types/stein';
import { CalloutBox, CalloutSkeleton } from '../../material/CalloutBox';

type DriverEventSpeedProps = {
    event: DriverEvent | undefined;
};

export function DriverEventSpeed({ event }: DriverEventSpeedProps): React.ReactElement {
    if (!event) {
        return <CalloutSkeleton name="Speed" />;
    }

    // istanbul ignore next
    const saveValue = event?.speed ? `${event?.speed?.toFixed(1)} MPH` : 'Unknown';

    return (
        <CalloutBox name={'Speed'}>
            <div style={{ display: 'flex', alignItems: 'center' }}>{saveValue}</div>
        </CalloutBox>
    );
}
