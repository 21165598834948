import * as React from 'react';
import NumberFormat, { InputAttributes } from 'react-number-format';
import { NumberFormatCustomProps } from './NumberFormatProps';

export const NumberFormatPhone = React.forwardRef<NumberFormat<InputAttributes>, NumberFormatCustomProps>(
    function NumberFormatPhone(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            value: values.value,
                        },
                    } as React.ChangeEvent<HTMLInputElement>);
                }}
                format="+1 (###) ###-####"
                mask="_"
            />
        );
    },
);
