import * as React from 'react';
import { Button } from '@mui/material';
import { format, startOfDay } from 'date-fns';

import { DateTimePickerPopover } from '../../../DateTimePicker/DateTimePickerPopover';
import { useAppTheme } from '../../../../theme';
import { useDialogState } from '../../../../hooks/use-dialog-state';

type VehicleTimelineDatePickerProps = {
    date: Date;
    onChange: (d: Date) => void;
};

/* istanbul ignore next */
function startOfToday(): Date {
    return startOfDay(new Date());
}

export function VehicleTimelineDatePicker({ date, onChange }: VehicleTimelineDatePickerProps): React.ReactElement {
    const theme = useAppTheme();
    const dialog = useDialogState();

    return (
        <>
            <Button
                variant={'contained'}
                color={'secondary'}
                size={'small'}
                onClick={dialog.onOpen}
                fullWidth={false}
                sx={{
                    color: 'white',
                    backgroundColor: theme.palette.grey[700],
                    padding: 0.5,
                    flex: 1,
                    borderRadius: 1,
                }}
            >
                {`${format(date, 'MMM do H:mm')} + 24h`}
            </Button>
            <DateTimePickerPopover
                date={date}
                onDateChanged={onChange}
                open={dialog.isOpen}
                onClose={dialog.onClose}
                shortcutItems={[
                    {
                        label: 'Today',
                        getValue: startOfToday,
                    },
                ]}
            />
        </>
    );
}
