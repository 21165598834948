import { appStyled } from '../theme';

export const InvisiblePresentation = appStyled('div')(({ theme }) => ({
    zIndex: theme.zIndex.snackbar,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
}));
