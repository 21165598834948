import * as React from 'react';
import { DriverEvent } from '../../../types/stein';
import { useUpdateDriverEvent } from '../../../hooks/hooks';
import { CalloutSkeleton } from '../../material/CalloutBox';
import { AutosaveTextInput } from '../../AutosaveTextInput';
import { useHasPermission } from '../../Authorize/Authorize';

type DriverEventNotesProps = {
    event: DriverEvent | undefined;
};

export function DriverEventNotes({ event }: DriverEventNotesProps): React.ReactElement {
    const [
        updateDriverEvent, // This is the mutation trigger
        { isLoading }, // This is the destructured mutation result
    ] = useUpdateDriverEvent();
    const canEdit = useHasPermission('DriverEvent', 'update');

    if (!event) {
        return <CalloutSkeleton name="Notes" height={100} />;
    }

    function onSave(s: string | null): void {
        // istanbul ignore next
        if (event) {
            updateDriverEvent({
                id: event.id,
                notes: s,
            });
        }
    }

    const safeValue = event.notes || '';

    return (
        <AutosaveTextInput
            label={'Notes'}
            value={safeValue}
            onSave={onSave}
            saving={isLoading}
            multiline
            disabled={!canEdit}
        />
    );
}
