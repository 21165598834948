import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { AbilityConfig, CustomRole, AbilityActionsForSubject, Abilities, AbilitySubject } from '../../types/stein';
import { addAbility, removeAbility } from '../../utils/has-ability';
import { setRailsContext } from './shared-actions';

type AbilitiesStore = {
    config: AbilityConfig;
    overrides: Abilities;
    roles: Array<CustomRole>;
    simulatedRole: CustomRole | null;
};

export type OverrideAbilityArgs<T extends AbilitySubject = AbilitySubject> = {
    subject: T;
    action: AbilityActionsForSubject<T>;
    enabled: boolean;
};

// Define the initial state using that type
const initialState: AbilitiesStore = {
    config: [],
    overrides: {
        can: {},
    },
    roles: [],
    simulatedRole: null,
};

function overrideAbility<T extends AbilitySubject>(
    s: Draft<AbilitiesStore>,
    a: PayloadAction<OverrideAbilityArgs<T>>,
): void {
    const { enabled, action, subject } = a.payload;

    s.overrides = enabled ? addAbility(s.overrides, action, subject) : removeAbility(s.overrides, action, subject);
}

export const abilitiesSlice = createSlice({
    name: 'abilities',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        overrideAbility,
        setSimulatedRole(state, action: PayloadAction<CustomRole | null>) {
            state.simulatedRole = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setRailsContext, (s, a) => {
            s.config = a.payload.abilityConfig;
            s.roles = a.payload.customRoles;
        });
    },
});
