import * as React from 'react';
import {
    WEB_EVENT_CATEGORY_COMMENT,
    WEB_EVENT_CATEGORY_DESCRIPTION,
    WEB_EVENT_CATEGORY_OBJECT_CHANGE,
} from '../../../types/stein.web_event';
import { AppIconActivityEdit, AppIconArrowRight, AppIconComment, AppIconSettings } from '../../AppIcons';
import { ActivityLogItemWebEventType } from '../activity_log_utils';
import { ActivityLogItemBase } from './ActivityLogItemBase';

type ActivityLogItemWebEventProps = {
    webEvent: ActivityLogItemWebEventType;
};

type ActivityLogWebEventChangeProps = Required<Pick<ActivityLogItemWebEventType, 'objectChanges'>>;

function changeName(key: string): string {
    switch (key) {
        case 'system_engagement_manager_state':
            return 'camera';
        case 'mute_state':
            return 'alerts';
        case 'guided_access_enabled':
            return 'guided access';
    }
    return key.replaceAll('_', ' ');
}

function changeValue(key: string, value: unknown): string {
    switch (key) {
        case 'guided_access_enabled':
            return value ? 'enabled' : 'disabled';
        case 'mute_state':
            return value === 'manual' ? 'muted' : 'unmuted';
        case 'system_engagement_manager_state':
            return value === 0 ? 'enabled' : 'disabled';
        case 'mount_orientation':
            return value === 0 ? 'left' : 'right';
    }
    // istanbul ignore next
    return `${value}`;
}

function ActivityLogWebEventChange({ objectChanges }: ActivityLogWebEventChangeProps): React.ReactElement {
    return (
        <>
            {objectChanges.map((c) => (
                <div
                    key={`${c.attributeKey}_${c.newValue}`}
                    style={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}
                >
                    <b>{changeName(c.attributeKey)}</b> <AppIconArrowRight /> {changeValue(c.attributeKey, c.newValue)}
                </div>
            ))}
        </>
    );
}
type ActivityLogWebEventDescriptionProps = Pick<ActivityLogItemWebEventType, 'description'>;

function ActivityLogWebEventDescription({ description }: ActivityLogWebEventDescriptionProps): React.ReactElement {
    return <div>{description}</div>;
}

export function ActivityLogItemWebEvent({ webEvent }: ActivityLogItemWebEventProps): React.ReactElement {
    switch (webEvent.category) {
        case WEB_EVENT_CATEGORY_COMMENT:
            return (
                <ActivityLogItemBase
                    timestamp={webEvent.timestamp}
                    description={
                        <>
                            <div>
                                <b>{webEvent.userDisplayName}</b>
                                {' commented'}
                            </div>
                        </>
                    }
                    Icon={AppIconComment}
                    detail={<ActivityLogWebEventDescription description={webEvent.description} />}
                />
            );
        case WEB_EVENT_CATEGORY_DESCRIPTION:
            return (
                <ActivityLogItemBase
                    timestamp={webEvent.timestamp}
                    description={
                        <>
                            <div>
                                <b>{webEvent.userDisplayName}</b>
                            </div>
                        </>
                    }
                    Icon={AppIconSettings}
                    detail={<ActivityLogWebEventDescription description={webEvent.description} />}
                />
            );
        case WEB_EVENT_CATEGORY_OBJECT_CHANGE:
            return (
                <ActivityLogItemBase
                    timestamp={webEvent.timestamp}
                    description={
                        <>
                            <div>
                                <b>{webEvent.userDisplayName}</b>
                                {' made changes'}
                            </div>
                        </>
                    }
                    Icon={AppIconActivityEdit}
                    detail={
                        <ActivityLogWebEventChange
                            objectChanges={webEvent.objectChanges || /*istanbul ignore next*/ []}
                        />
                    }
                />
            );
    }
}
