// TODO remove ignore Cannot test inside DataGrid, wait for: https://github.com/mui-org/material-ui-x/issues/1151
/* istanbul ignore file */

import * as React from 'react';

import { AppInstall, VehicleDeviceId } from '../../../types/stein';
import { GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';

import { processDataGridCollums } from '../../DataGrid';
import { useAppSelector } from '../../../hooks/hooks';
import { selectDebugEnabled } from '../../../store/selectors';
import { VehicleChip } from '../../AppChip';
import { AppIconMDM, AppIconRefresh, AppIconSettings } from '../../AppIcons';
import { Box, Tooltip } from '@mui/material';
import { useFrame } from '../../../lib/frame-react';
import { SteinInternalApiToken } from '../../../clients/stein-internal-api';

// function BatteryIndicatorCell({ row }: GridRenderCellParams): React.ReactElement {
//     const appInstall = row as AppInstall;

//     return (
//         <BatteryStatusIndicator
//             batteryState={define(appInstall?.batteryState)}
//             batteryLevel={define(appInstall?.batteryLevel)}
//             isOnline={false}
//         />
//     );
// }

function MdmCell({ value }: GridRenderCellParams): React.ReactElement | null {
    const mdmDevice = value as AppInstall['mdmDevice'];
    if (!mdmDevice) {
        return null;
    }

    return (
        <Tooltip title={'Tracked in MDM'}>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <AppIconMDM />
            </Box>
        </Tooltip>
    );
}

function VehicleCell({ value }: GridRenderCellParams): React.ReactElement | null {
    const vehicleDeviceId = value as VehicleDeviceId;
    return vehicleDeviceId ? (
        <VehicleChip vehicleSlugOrId={vehicleDeviceId} size={'small'} tabIndex={-1} />
    ) : (
        <>{'No Assinged Vehicle'}</>
    );
}

function compareStrOrNull(a: string | null, b: string | null): number {
    if (a && !b) {
        return -1;
    } else if (!a && b) {
        return 1;
    } else if (!a && !b) {
        return 0;
    }
    return (a || '').localeCompare(b || '');
}

export function useAppInstallColumns(): GridColDef[] {
    const debug = useAppSelector(selectDebugEnabled);
    const { useRestartMdmDeviceMutation } = useFrame(SteinInternalApiToken);
    const [restartDevice] = useRestartMdmDeviceMutation();

    return React.useMemo(
        () =>
            processDataGridCollums([
                {
                    field: 'mdmDevice',
                    headerName: 'MDM',
                    minWidth: 75,
                    width: 75,
                    renderCell: MdmCell,
                },
                {
                    field: 'serialNumber',
                    headerName: 'Serial #',
                    minWidth: 250,
                    width: 250,
                    valueGetter: (v) => v || 'unknown',
                    sortComparator: compareStrOrNull,
                },
                {
                    field: 'label',
                    headerName: 'Name',
                    minWidth: 250,
                    width: 250,
                    sortComparator: compareStrOrNull,
                },
                {
                    field: 'vehicleDeviceId',
                    headerName: 'Assigned Vehicle',
                    renderCell: VehicleCell,
                    minWidth: 250,
                    width: 250,
                    sortComparator: () => 0,
                    sortable: false,
                },
                {
                    field: 'id',
                    headerName: '',
                    type: 'actions',
                    flex: 0,
                    minWidth: 10,
                    sortable: false,
                    renderHeader: () => <AppIconSettings />,
                    getActions: ({ row }: GridRowParams<AppInstall>) => {
                        const mdmDevice = row.mdmDevice;
                        if (mdmDevice) {
                            return [
                                <GridActionsCellItem
                                    key={'restart_device'}
                                    icon={<AppIconRefresh />}
                                    onClick={() => {
                                        restartDevice({ remoteMdmDeviceId: mdmDevice.remoteMdmDeviceId });
                                    }}
                                    label="Restart Device"
                                    showInMenu
                                />,
                            ];
                        }
                        return [];
                    },
                    filterable: false,
                },
            ]),
        [debug],
    );
}
