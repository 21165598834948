import * as React from 'react';
import { appStyled } from '../../theme';

type FilterBarProps = {
    children: React.ReactNode;
    icons: React.ReactNode;
};

const FilterBarWrapper = appStyled('div')(({ theme }) => ({
    backgroundColor: theme.colors.toolbar.background,
    color: theme.colors.toolbar.text,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    maxWidth: '100%',
    overflow: 'hidden',
    display: 'flex',
}));

const FilterBarFilters = appStyled('div')(({ theme }) => ({
    flexGrow: '2',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(1),
    '& > div': {
        minWidth: '100px',
        flexGrow: '1',
    },
}));

const FilterBarIcons = appStyled('div')({
    flexGrow: '0',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
});

export function FilterBar({ children, icons }: FilterBarProps): React.ReactElement {
    return (
        <FilterBarWrapper>
            <FilterBarFilters>{children}</FilterBarFilters>
            <FilterBarIcons>{icons}</FilterBarIcons>
        </FilterBarWrapper>
    );
}
