import * as React from 'react';
import { useAppSelector } from '../../hooks/hooks';
import { useActiveProjectOptional } from '../../hooks/use-active-project';

import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Paper,
    Tooltip,
} from '@mui/material';
import { AppIconComponent, AppIconDashboard } from '../AppIcons';
import { urlProject, AppInternalUrl } from '../../utils/internal-url-utils';
import { selectAllProjects } from '../../store/selectors';
import { plural } from '../../utils/string-utils';
import { useAppTheme } from '../../theme';
import { useNavigationDrawerContext } from '../AppLayout/NavigationDrawerContext';

import { NavigationItem, NavigationLink, useNavigation } from '../Navigation';
import { Permission } from '../Authorize/PermissionBlock';

type SideNavItemProps = {
    Icon: AppIconComponent;
    text: string;
    url: AppInternalUrl;
    external?: boolean;
};

function SideNavItem({ Icon, text, ...item }: SideNavItemProps): React.ReactElement {
    const { isOpen } = useNavigationDrawerContext();
    const location = useLocation();
    const content = (
        <>
            {isOpen ? (
                <>
                    <ListItemIcon sx={{ minWidth: 38 }}>
                        <Icon />
                    </ListItemIcon>
                    <ListItemText primary={text} />
                </>
            ) : (
                <Tooltip title={text} placement={'right'}>
                    <ListItemIcon sx={{ minWidth: 38 }}>
                        <Icon />
                    </ListItemIcon>
                </Tooltip>
            )}
        </>
    );

    if (item.external) {
        return (
            <ListItem disablePadding>
                <ListItemButton component={'a'} href={item.url} target={'_blank'}>
                    {content}
                </ListItemButton>
            </ListItem>
        );
    } else {
        return (
            <ListItem disablePadding selected={location.pathname.startsWith(item.url)}>
                <ListItemButton component={RouterLink} to={item.url}>
                    {content}
                </ListItemButton>
            </ListItem>
        );
    }
}

function AuthedSideNavItem({ debugOnly, auth, feature, ...sideNavProps }: NavigationLink): React.ReactElement {
    return (
        <Permission debugOnly={debugOnly} auth={auth} feature={feature}>
            <SideNavItem {...sideNavProps} />
        </Permission>
    );
}

function Subheader({ text }: { text: string }): React.ReactElement {
    const theme = useAppTheme();
    const { isOpen } = useNavigationDrawerContext();

    return (
        <ListSubheader
            sx={{
                lineHeight: '20px',
                paddingTop: theme.spacing(1.5),
                paddingBottom: theme.spacing(1.5),
                backgroundColor: 'inherit',
            }}
        >
            {isOpen ? (
                text
            ) : (
                <Tooltip title={text} placement={'right'}>
                    <div style={{ textAlign: 'center' }}>{'—'}</div>
                </Tooltip>
            )}
        </ListSubheader>
    );
}

function NavItem(props: NavigationItem): React.ReactElement {
    const subItems = props.subItems?.map((ni) => <NavItem {...ni} key={ni.text} />);
    if (props.type === 'section') {
        return (
            <>
                <Subheader text={props.text} />
                {subItems}
            </>
        );
    } else {
        return <AuthedSideNavItem {...props} />;
    }
}

export function SelectActiveProject(): React.ReactElement {
    const projects = useAppSelector(selectAllProjects);

    return (
        <>
            <Subheader text={plural(projects.length, 'Dashboard', 'Dashboards')} />

            {projects.map((p) => (
                <SideNavItem Icon={AppIconDashboard} url={urlProject(p)} text={p.name} key={p.id} />
            ))}
        </>
    );
}

export function SideNavigation(): React.ReactElement {
    const hasProject = useActiveProjectOptional();
    const theme = useAppTheme();
    const nav = useNavigation();

    return (
        <Paper
            style={{
                height: '100%',
                width: '100%',
                boxShadow: 'none',
                backgroundColor: theme.colors.page.background,
                color: theme.colors.page.text,
            }}
            square
        >
            <List dense>
                {hasProject ? null : <SelectActiveProject />}
                {nav.map((n, i) => (
                    <NavItem {...n} key={i} />
                ))}
            </List>
        </Paper>
    );
}
