import * as React from 'react';

import { EVENT_ANALYSIS } from '../../../constants/event-analysis-constants';

import { DriverEvent } from '../../../types/stein';
import { CalloutSkeleton } from '../../material/CalloutBox';
import { FilterSelect } from '../../FilterBar/FilterSelect';
import { useUpdateDriverEvent } from '../../../hooks/hooks';
import { useHasPermission } from '../../Authorize/Authorize';

type DriverEventAnalysisProps = {
    event: DriverEvent | undefined;
};

export function DriverEventAnalysis({ event }: DriverEventAnalysisProps): React.ReactElement {
    const canEdit = useHasPermission('DriverEvent', 'update');

    const [
        updateDriverEvent, // This is the mutation trigger
        { isLoading }, // This is the destructured mutation result
    ] = useUpdateDriverEvent();

    if (!event) {
        return <CalloutSkeleton name={'Analysis'} />;
    }

    // istanbul ignore next
    const safeValue = event.analysis || '';

    return (
        <FilterSelect
            label={'Analysis'}
            value={safeValue}
            options={EVENT_ANALYSIS}
            onChange={(analysis) => updateDriverEvent({ id: event.id, analysis })}
            saving={isLoading}
            disabled={!canEdit}
            fullWidth
        />
    );
}
