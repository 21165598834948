/* 
istanbul ignore file 
Most of the complexity here is about the graph and the tooltips, which are very hard to test and not vital
*/
import { Tooltip } from '@mui/material';
import * as React from 'react';

import { CartesianGrid, Label, Area, AreaChart, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { Threshold } from '../../../../../types/stein';
import { TooltipContent } from '../../../../TooltipContent';
import { scaleLinear } from 'd3-scale';

const X_AXIS_MIN = 0;
const X_AXIS_MAX = 100;
const LINE_PROPS = {
    isAnimationActive: false,
} as const;

type ThresholdChartProps = {
    thresholds: Threshold[];
};

type ThresholdImplicit = Pick<Threshold, 'speed'> & {
    overThreshold: [number, number] | null;
};

type ThresholdTooltip = {
    title: string;
    children: React.ReactElement;
};

type AxisMap = {
    x: number;
    y: number;
    height: number;
    width: number;
    domain: [number, number];
};
type ChartWrapper = {
    state: {
        xAxisMap: AxisMap[];
        yAxisMap: AxisMap[];
    };
};

export function ThresholdChart({ thresholds }: ThresholdChartProps): React.ReactElement {
    const [tooltip, setTooltip] = React.useState<ThresholdTooltip | null>(null);
    const gridRef = React.useRef<ChartWrapper>(null);

    const DURATION_MAX = Math.ceil(thresholds.reduce((maxDuration, t) => Math.max(maxDuration, t.maxDuration), 0) + 2);
    const X_DOMAIN = [X_AXIS_MIN, X_AXIS_MAX];
    const Y_DOMAIN = [0, DURATION_MAX];

    const data: ThresholdImplicit[] = thresholds.map((threshold) => ({
        speed: threshold.speed,
        overThreshold: [threshold.maxDuration, DURATION_MAX],
    }));

    if (thresholds.length > 0) {
        // Adds a solid horizontal line that holds the maxDuration until the end of the chart
        const lastThreshold = data[thresholds.length - 1];
        data.push({
            speed: X_AXIS_MAX,
            overThreshold: lastThreshold.overThreshold,
        });
    }

    return (
        <Tooltip title={tooltip ? <TooltipContent {...tooltip} /> : ''} followCursor disableInteractive={!tooltip}>
            <div>
                <ResponsiveContainer width="100%" height={200}>
                    <AreaChart
                        style={{ cursor: 'crosshair' }}
                        width={730}
                        height={250}
                        data={data}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                        stackOffset={'expand'}
                        // @ts-expect-error this ref is not actually an exported type from recharts
                        ref={gridRef}
                        onMouseEnter={() =>
                            setTooltip({
                                title: 'Will Not Alert',
                                children: (
                                    <>
                                        <div>{'Speed: XX'}</div>
                                        <div>{'Duration: XX'}</div>
                                    </>
                                ),
                            })
                        }
                        onMouseLeave={() => setTooltip(null)}
                        onMouseMove={(event) => {
                            const state = gridRef.current?.state;
                            if (!state) {
                                setTooltip(null);
                                return;
                            }
                            const { xAxisMap, yAxisMap } = gridRef.current?.state;

                            const xAxis = xAxisMap[0];
                            const yAxis = yAxisMap[0];

                            if (!xAxis || !yAxis || !event?.chartX || !event?.chartY) {
                                setTooltip(null);
                                return;
                            }

                            const xScale = scaleLinear().domain(xAxis.domain).range([0, xAxis.width]);
                            const yScale = scaleLinear().domain(yAxis.domain).range([yAxis.height, 0]);

                            const speed = xScale.invert(event.chartX - xAxis.x).toFixed(1);
                            const duration = yScale.invert(event.chartY - yAxis.y).toFixed(1);

                            setTooltip((t) =>
                                t
                                    ? {
                                          ...t,
                                          children: (
                                              <>
                                                  <div>{`Speed: ${speed}`}</div>
                                                  <div>{`Duration: ${duration}`}</div>
                                              </>
                                          ),
                                      }
                                    : {
                                          title: 'Will Not Alert',
                                          children: (
                                              <>
                                                  <div>{`Speed: ${speed}`}</div>
                                                  <div>{`Duration: ${duration}`}</div>
                                              </>
                                          ),
                                      },
                            );
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="speed" domain={X_DOMAIN} type="number" tickFormatter={(v) => `${v} MPH`} />
                        <YAxis type="number" dataKey={'overThreshold'} domain={Y_DOMAIN}>
                            <Label
                                value="Duration (sec)"
                                position="left"
                                offset={-10}
                                angle={270}
                                style={{ textAnchor: 'middle' }}
                            />
                        </YAxis>
                        <Area
                            onMouseMove={() => setTooltip((t) => (t ? { ...t, title: '🚨 Will Alert 🚨' } : null))}
                            onMouseLeave={() => setTooltip((t) => (t ? { ...t, title: 'Will Not Alert' } : null))}
                            dataKey={'overThreshold'}
                            strokeOpacity={0}
                            fill={'#f5b042'}
                            {...LINE_PROPS}
                            name={'Will Alert'}
                        />

                        <ReferenceLine />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </Tooltip>
    );
}
