import * as React from 'react';

export function useIsMountedRef(): React.RefObject<boolean> {
    const ref = React.useRef<boolean>(true);
    React.useEffect(
        () => () => {
            ref.current = false;
        },
        [],
    );
    return ref;
}
