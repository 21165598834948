import * as React from 'react';
import { addSeconds } from 'date-fns';
import { useAppTheme } from '../../../../theme';
import { toTimestampMs } from '../../../../utils/datetime-utils';
import { useTimelineContext } from './TimelineComponents/TimelineProvider';
import { TimelineSpan } from './TimelineComponents/TimelineComponents';
import { DriverLog } from '../../../../types/stein';
import { differenceInSeconds } from 'date-fns';
import { Tooltip } from '@mui/material';
import { DriverLogUploadStatusType } from '../../../../types/stein.application_types';

type TimelineSourcesProps = {
    driverLogs: DriverLog[];
    prefix: string;
};

function uploadStatus(status: DriverLogUploadStatusType | null | undefined): string {
    if (!status) {
        return 'is ready to play';
    }

    switch (status) {
        case 'preparing':
            return 'is preparing';
        case 'on_device':
            return 'is recorded. Request this video using the video upload request button.';
        case 'pending':
            return 'upload request has been acknowledged and should start uploading when possible.';
        case 'uploading':
            return 'is uploading.';
        case 'ready':
            return 'is ready to play.';
        case 'failed':
            return 'failed to upload.';
    }
}

function resolveStatus(dl: DriverLog | null): DriverLogUploadStatusType {
    return dl?.uploadStatus || 'ready';
}

export function TimelineSources({ driverLogs: rawDriverLogs, prefix }: TimelineSourcesProps): React.ReactElement {
    const theme = useAppTheme();
    const { setCursorTime, commitCursorTime } = useTimelineContext();

    const processedDriverLogs = React.useMemo(() => {
        const logs: DriverLog[] = [];
        let prev: DriverLog | null = null;
        rawDriverLogs.forEach((dl, idx) => {
            const prevStartTime = new Date(prev?.occurredAt || 0);
            const prevEndTime = addSeconds(prevStartTime, prev?.duration || 0);
            const prevStatus = resolveStatus(prev);
            const currentStartTime = new Date(dl?.occurredAt || /* istanbul ignore next */ 0);
            const currentEndTime = addSeconds(currentStartTime, dl?.duration || /* istanbul ignore next */ 0);
            const currentStatus = resolveStatus(dl);

            if (!prev) {
                prev = { ...dl };
            } else if (prevStatus != currentStatus) {
                logs.push(prev);
                prev = { ...dl };
            } else if (Math.abs(differenceInSeconds(prevEndTime, currentStartTime)) <= 2) {
                prev.duration = differenceInSeconds(currentEndTime, prevStartTime);
            } else {
                logs.push(prev);
                prev = { ...dl };
            }

            if (idx === rawDriverLogs.length - 1) {
                logs.push(prev);
            }
        });
        return logs;
    }, [rawDriverLogs]);

    return (
        <>
            {processedDriverLogs.map((dl) => {
                const startTime = toTimestampMs(new Date(dl.occurredAt || /* istanbul ignore next */ 0));
                let color = theme.palette.warning;
                if (!dl.uploadStatus || dl.uploadStatus === 'ready') {
                    color = theme.palette.primary;
                } else if (dl.uploadStatus === 'failed') {
                    color = theme.palette.error;
                }
                return (
                    <Tooltip
                        title={`${prefix} ${uploadStatus(dl.uploadStatus)}`}
                        key={dl.id}
                        placement={'top'}
                        followCursor
                    >
                        <TimelineSpan
                            startTime={startTime}
                            durationSeconds={dl.duration || /* istanbul ignore next */ 0}
                            onMouseMove={
                                /* istanbul ignore next */ () => {
                                    // istanbul ignore next
                                    setCursorTime(startTime);
                                }
                            }
                            onClick={commitCursorTime}
                            sx={{
                                backgroundColor: color.light,
                                '&:hover': {
                                    backgroundColor: color.dark,
                                },
                            }}
                        />
                    </Tooltip>
                );
            })}
        </>
    );
}
