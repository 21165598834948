import * as React from 'react';
import { DriverEvent } from '../../types/stein';

export type ActivityLogContext = {
    driverEvent?: DriverEvent;
};

const context = React.createContext<ActivityLogContext>({});

export function useActivityLogContext(): ActivityLogContext {
    return React.useContext(context);
}

type ActivityLogContextProviderProps = ActivityLogContext & {
    children: React.ReactElement;
};

export function ActivityLogContextProvider({ children, ...ctx }: ActivityLogContextProviderProps): React.ReactElement {
    return <context.Provider value={ctx}>{children}</context.Provider>;
}
