import { FilterValueType } from '../store/slices/filterConfigSlice';
import { Geofence, Region, VehicleDevice, VehicleModel } from '../types/stein';
import { NonNullableMap } from '../types/util-types';

export function filterOptionsFromVehicleModels(models: VehicleModel[]): FilterValueType[] {
    return models.map((m) => ({
        id: `${m.id}`,
        name: m.name,
    }));
}

export function filterOptionsFromRegions(regions: Region[]): FilterValueType[] {
    return regions.map((r) => ({
        id: `${r.id}`,
        name: r.name,
    }));
}

export function filterOptionsFromGeofences(geofences: Geofence[]): FilterValueType[] {
    return geofences.map((r) => ({
        id: `${r.id}`,
        name: r.name,
        color: r.color ? r.color : undefined,
        icon: r.iconName || undefined,
    }));
}

export function filterOptionsFromVehicles(vehicles: VehicleDevice[]): FilterValueType[] {
    return vehicles.map((r) => ({
        id: r.slug,
        name: r.name,
    }));
}

export function filterIncludes(
    selectedFilters: string[] | undefined,
    val: string | number | undefined | null,
): boolean {
    if (!selectedFilters || !selectedFilters.length) {
        return true;
    }

    //istanbul ignore else
    if (val === null || typeof val === 'undefined') {
        return selectedFilters.includes('');
    } else if (typeof val === 'string') {
        return selectedFilters.includes(val);
    } else if (typeof val === 'number') {
        return selectedFilters.includes(val.toString());
    } else {
        return false;
    }
}

export function filterIncludesSome(
    selectedFilters: string[] | undefined,
    vals: Array<string | number | undefined | null>,
): boolean {
    if (!selectedFilters || !selectedFilters.length) {
        return true;
    }

    return vals.some((val) => filterIncludes(selectedFilters, val));
}

function toString(vals: Array<string | number | undefined | null>): string[] {
    return vals.map((v) => v?.toString()).filter(filterUndefinedAndNull);
}

export function filterIncludesEvery(
    selectedFilters: string[] | undefined,
    vals: Array<string | number | undefined | null>,
): boolean {
    if (!selectedFilters || !selectedFilters.length) {
        return true;
    }

    return selectedFilters.every((val) => filterIncludes(toString(vals), val));
}

export function filterUndefinedAndNull<T>(s: T | null | undefined): s is T {
    return typeof s !== 'undefined' && s !== null;
}

export type GeofenceLocated = Geofence & Required<NonNullableMap<Pick<Geofence, 'latitude' | 'longitude' | 'radius'>>>;
export function filterGeofenceLocated(g: Geofence): g is GeofenceLocated {
    return Boolean(g && g.longitude && g.latitude && g.radius);
}
