import { IconButton } from '@mui/material';
import * as React from 'react';
import { AppIconPictureInPicture } from '../../AppIcons';
import { usePictureInPicture } from '../videoPlayerHooks';

type PictureInPictureButtonProps = {
    videoRef: React.RefObject<HTMLVideoElement>;
};

export function PictureInPictureButton({ videoRef }: PictureInPictureButtonProps): React.ReactElement {
    const { enterPictureInPicture, exitPictureInPicture, isPictureInPicture } = usePictureInPicture(videoRef);

    function onClick(): void {
        isPictureInPicture ? exitPictureInPicture() : enterPictureInPicture();
    }

    return (
        <IconButton onClick={onClick}>
            <AppIconPictureInPicture />
        </IconButton>
    );
}
