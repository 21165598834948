import * as React from 'react';
import { appStyled } from '../../theme';
import { useNavigationDrawerContext } from './NavigationDrawerContext';
import MuiDrawer from '@mui/material/Drawer';
import { useOnMount } from '../../hooks/hooks';
import { ClickAwayListener, IconButton, ListItem } from '@mui/material';
import { AppIconChevronUp } from '../AppIcons';

const SideNavigationDesktopWrapper = appStyled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minWidth: '160px',
    maxWidth: '160px',
    borderRight: '1px solid',
    borderColor: theme.palette.divider,
}));

type DrawerProps = {
    children: React.ReactNode;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

/* istanbul ignore next */
function MobileDrawer({ isOpen, setIsOpen, children }: DrawerProps): React.ReactElement {
    // istanbul ignore next
    function closeDrawer(): void {
        setIsOpen(false);
    }

    useOnMount(closeDrawer);

    // istanbul ignore next
    function close(): void {
        setIsOpen(false);
    }
    return (
        <>
            <MuiDrawer variant={'temporary'} anchor={'top'} open={isOpen}>
                <ClickAwayListener
                    onClickAway={/* istanbul ignore next */ () => /* istanbul ignore next */ setIsOpen(false)}
                >
                    <div data-testid={'sidenav-mobile'}>
                        {children}
                        <div style={{ padding: 10, display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton onClick={close}>
                                <AppIconChevronUp />
                            </IconButton>
                        </div>
                    </div>
                </ClickAwayListener>
            </MuiDrawer>
        </>
    );
}

export function SideNavigationSub({
    children,
    title,
}: {
    children: React.ReactElement;
    title: string;
}): React.ReactElement {
    const { isDesktop, isOpen, setIsOpen } = useNavigationDrawerContext();

    return isDesktop ? (
        <SideNavigationDesktopWrapper>{children}</SideNavigationDesktopWrapper>
    ) : (
        /* istanbul ignore next */ <MobileDrawer isOpen={isOpen} setIsOpen={setIsOpen}>
            <ListItem sx={{ paddingTop: 2 }}>{title}</ListItem>
            {children}
        </MobileDrawer>
    );
}
