import * as React from 'react';

import { SideNavigation } from '../SideNavigation';
import MuiDrawer from '@mui/material/Drawer';
import { CSSObject } from '@mui/system';
import { ClickAwayListener, IconButton, styled, Theme } from '@mui/material';
import { AppIconChevronDown, AppIconChevronLeft, AppIconChevronRight } from '../AppIcons';
import { IconButtonSimple } from '../material/IconButtonSimple';
import { useAppSelector, useOnMount } from '../../hooks/hooks';
import { useNavigationDrawerContext } from './NavigationDrawerContext';

const drawerWidth = 210;
const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(6)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(6)} + 1px)`,
    },
});

const DrawerFooter = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 1),
    backgroundColor: theme.colors.page.background,
    color: theme.colors.page.text,
    borderTop: '#83939C',
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const DesktopDrawerBase = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    zIndex: 0,
    boxShadow: theme.shadows[1],
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': {
            ...openedMixin(theme),
            top: theme.mixins.toolbar.minHeight,
            backgroundColor: theme.colors.page.background,
            text: theme.colors.page.text,
            height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
        },
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': {
            ...closedMixin(theme),
            top: theme.mixins.toolbar.minHeight,
            backgroundColor: theme.colors.page.background,
            text: theme.colors.page.text,
            height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
        },
    }),
}));

type DrawerProps = { isOpen: boolean; setIsOpen: React.Dispatch<React.SetStateAction<boolean>> };

function DesktopDrawer({ isOpen, setIsOpen }: DrawerProps): React.ReactElement {
    useOnMount(() => setIsOpen(true));
    const isLoaded = useAppSelector((s) => s.navigation.initialized);
    return (
        <DesktopDrawerBase variant={'permanent'} anchor={'left'} open={isOpen}>
            {isLoaded ? (
                <div
                    style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                    data-testid={'sidenav-desktop'}
                >
                    <div style={{ flexGrow: 2 }}>
                        <SideNavigation />
                    </div>
                    <DrawerFooter>
                        <IconButtonSimple onClick={() => setIsOpen((o) => !o)} sx={{ paddingLeft: 1 }}>
                            {isOpen ? <AppIconChevronLeft /> : <AppIconChevronRight />}
                        </IconButtonSimple>
                    </DrawerFooter>
                </div>
            ) : null}
        </DesktopDrawerBase>
    );
}

function MobileDrawer({ isOpen, setIsOpen }: DrawerProps): React.ReactElement {
    useOnMount(() => setIsOpen(false));
    return (
        <>
            <MuiDrawer variant={'temporary'} anchor={'bottom'} open={isOpen}>
                <ClickAwayListener
                    onClickAway={/* istanbul ignore next */ () => /* istanbul ignore next */ setIsOpen(false)}
                >
                    <div data-testid={'sidenav-mobile'}>
                        <SideNavigation />
                        <div style={{ padding: 10 }}>
                            <IconButton onClick={() => setIsOpen(false)}>
                                <AppIconChevronDown />
                            </IconButton>
                        </div>
                    </div>
                </ClickAwayListener>
            </MuiDrawer>
        </>
    );
}

export function NavigationDrawer(): React.ReactElement {
    const { isDesktop, isOpen, setIsOpen } = useNavigationDrawerContext();
    return isDesktop ? (
        <DesktopDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
    ) : (
        <MobileDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
    );
}
