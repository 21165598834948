import { IconButton } from '@mui/material';
import * as React from 'react';
import { useFrame } from '../../../lib/frame-react';
import { LoggerToken } from '../../../lib/frame-tokens';
import { MultiVideoController } from '../../../lib/multi-video/multi-video';
import { AppIconComponent, AppIconPause, AppIconPlay } from '../../AppIcons';
import { useHotkey } from '../../HotkeyProvider';
import { useMultiVideoState } from '../../VideoPlayerMulti/VideoPlayerMulti';
import { useVideoPlaybackState } from '../videoPlayerHooks';
import { VideoRef } from '../VideoTypes';

type PlayButtonProps = {
    videoRef: VideoRef;
};

type UsePlayButtonRes = {
    Icon: AppIconComponent;
    onToggle: () => void;
};

function useVideoPlayButtonHotkeys(togglePlayback: () => void): void {
    useHotkey(
        'space',
        togglePlayback,
        {
            description: 'Play or pause video',
        },
        [togglePlayback],
    );
}

function getVideoIcon(state: 'playing' | 'paused'): AppIconComponent {
    return state === 'playing' ? AppIconPause : AppIconPlay;
}

export function useVideoPlayButton(videoRef: VideoRef): UsePlayButtonRes {
    const log = useFrame(LoggerToken);
    const state = useVideoPlaybackState(videoRef);
    const onToggle = React.useCallback(() => {
        if (!videoRef.current) {
            log.error('[VideoPlayButton] Trying to play null video');
            return;
        }
        state === 'playing' ? videoRef.current.pause() : videoRef.current.play();
    }, [state, videoRef.current]);
    useVideoPlayButtonHotkeys(onToggle);
    const Icon = getVideoIcon(state);
    return {
        Icon,
        onToggle,
    };
}

export function VideoPlayButton({ videoRef }: PlayButtonProps): React.ReactElement {
    const { Icon, onToggle } = useVideoPlayButton(videoRef);
    return (
        <IconButton onClick={onToggle}>
            <Icon />
        </IconButton>
    );
}

export function useVideoMultiPlayButton(controller: MultiVideoController): UsePlayButtonRes {
    const { playbackState } = useMultiVideoState(controller);
    const onToggle = React.useCallback(() => {
        playbackState === 'playing' ? controller.pause() : controller.play();
    }, [playbackState, controller]);
    useVideoPlayButtonHotkeys(onToggle);
    const Icon = getVideoIcon(playbackState);
    return {
        Icon,
        onToggle,
    };
}

export function MultiVideoPlayButton({ controller }: { controller: MultiVideoController }): React.ReactElement {
    const { Icon, onToggle } = useVideoMultiPlayButton(controller);

    return (
        <IconButton onClick={onToggle}>
            <Icon />
        </IconButton>
    );
}
