import {
    ActivityLogAnnouncement,
    ActivityLogNotification,
    ActivityLogResponse,
    ActivityLogVersion,
    ActivityLogWebEvent,
    ActivityObjectChange,
} from '../../types/stein-internal-api';
import { differenceInSeconds, parseISO } from 'date-fns';

export type ActivityLogItemNotificationType = ActivityLogNotification & {
    type: 'notification';
    timestamp: Date;
};

export type ActivityLogItemAnnouncementType = ActivityLogAnnouncement & {
    type: 'announcement';
    timestamp: Date;
};

export type ActivityLogItemVersionType = ActivityLogVersion & {
    type: 'version';
    timestamp: Date;
};

export type ActivityLogItemWebEventType = ActivityLogWebEvent & {
    type: 'webEvent';
    timestamp: Date;
};

export type ActivityLogItem =
    | ActivityLogItemVersionType
    | ActivityLogItemAnnouncementType
    | ActivityLogItemNotificationType
    | ActivityLogItemWebEventType;

export function groupAndSortActivity(json: ActivityLogResponse): Array<ActivityLogItem> {
    const announcementItems = createAnnouncementItems(json.announcements || []);
    const versionItems = createVersionGroupItems(json.versions || []);
    const notifItems = createNotificationItems(json.notifications || []);
    const webEventItems = createWebEventGroupItems(
        json.webEvents?.filter((e) => Boolean(e.objectChanges?.length)) || [],
    );
    const webEventComments = createWebEventGroupItems(json.webEvents?.filter((e) => Boolean(e.description)) || []);

    const items = versionItems.concat(notifItems, announcementItems, webEventItems, webEventComments);
    return items.sort((a, b) => b.timestamp.valueOf() - a.timestamp.valueOf());
}

function createAnnouncementItems(announcements: Array<ActivityLogAnnouncement>): Array<ActivityLogItem> {
    return announcements.map((announcement) => ({
        type: 'announcement',
        timestamp: parseISO(announcement.createdAt),
        ...announcement,
    }));
}

function createNotificationItems(notifications: Array<ActivityLogNotification>): Array<ActivityLogItem> {
    return notifications.map((notification) => {
        const timestamp = notification.sentAt || notification.createdAt;
        return {
            type: 'notification',
            timestamp: parseISO(timestamp),
            ...notification,
        };
    });
}

function createVersionGroupItems(versions: Array<ActivityLogVersion>): Array<ActivityLogItem> {
    const groups = createGroups(versions);

    return groups.map((group) => ({
        type: 'version',
        timestamp: parseISO(group[0].createdAt),
        ...group[0],
        obsrChanges: group.reduce((arr: ActivityLogVersion['obsrChanges'], item) => {
            let dedupedAttributes = [...arr];
            item.obsrChanges.forEach((change) => {
                dedupedAttributes = arr.filter((existingChange) => existingChange.attribute !== change.attribute);
                dedupedAttributes.push(change);
            });
            return dedupedAttributes;
        }, []),
    }));
}

function createWebEventGroupItems(webEvents: Array<ActivityLogWebEvent>): Array<ActivityLogItem> {
    const groups = createGroups(webEvents);

    return groups.map((group) => ({
        type: 'webEvent',
        timestamp: parseISO(group[0].createdAt),
        ...group[0],
        objectChanges: group.reduce((arr: ActivityObjectChange[], item) => {
            const dedupedAttributes = [...arr];
            item.objectChanges?.forEach((change) => {
                // changes are sorted decending date, so keys should not be overwritten.
                const keyExists = arr.find((ec) => ec.attributeKey === change.attributeKey);
                if (!keyExists) {
                    dedupedAttributes.push(change);
                }
            });
            return dedupedAttributes;
        }, []),
    }));
}

function createGroups<T extends ActivityLogWebEvent | ActivityLogVersion>(logs: Array<T>): Array<T>[] {
    const groups = [];
    let currentGroup: Array<T> = [];

    logs.forEach((log) => {
        if (currentGroup.length === 0) {
            currentGroup.push(log);
            return;
        }

        // if it's a different user, create a new group
        if (currentGroup[0].userDisplayName !== log.userDisplayName) {
            groups.push(currentGroup);
            currentGroup = [log];
            return;
        }

        // group events made by the same user within 2 minutes
        const secondsSinceLastWebEvent = differenceInSeconds(
            parseISO(currentGroup[0].createdAt),
            parseISO(log.createdAt),
        );
        if (secondsSinceLastWebEvent < 60 * 2) {
            currentGroup.push(log);
            return;
        }

        // otherwise, create a new group
        groups.push(currentGroup);
        currentGroup = [log];
    });

    // Append the remaining group
    if (currentGroup.length) {
        groups.push(currentGroup);
    }

    // filter out any empty groups
    return groups.filter((group) => {
        return group.length > 0;
    });
}
