// istanbul ignore file

import { Stack } from '@mui/system';
import * as React from 'react';
import { appStyled } from '../../theme';
import { preventClickthrough } from '../../utils/click-utils';

const VideoSingleControlBase = appStyled('div')(({ theme }) => ({
    position: 'absolute',
    width: '100%',
    height: 'auto',
    bottom: '0',
    backgroundColor: 'rgba(255,255,255,0.4)',
    display: 'flex',
    padding: theme.spacing(0.25),
    alignItems: 'center',
}));

type VideoSingleControlBarProps = {
    children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

export function VideoSingleControlBar({ children, ...props }: VideoSingleControlBarProps): React.ReactElement {
    return (
        <VideoSingleControlBase data-testid={'video-control-bar'} {...props}>
            <Stack
                direction="row"
                display={'flex'}
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
                width={'100%'}
            >
                {children}
            </Stack>
        </VideoSingleControlBase>
    );
}

const VideoMultiControlBase = appStyled('div')(({ theme }) => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
}));

const BottomControls = appStyled('div')<{ backgroundColor: string }>(({ theme, backgroundColor }) => ({
    position: 'absolute',
    bottom: '10px',
    backgroundColor,
    display: 'flex',
    padding: theme.spacing(0.5),
    alignItems: 'center',
    borderRadius: 3,
    height: '30px',
    fontSize: '16px',
}));

const BottomRightControls = appStyled('div')<{ backgroundColor: string }>(({ theme, backgroundColor }) => ({
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    backgroundColor,
    display: 'flex',
    padding: theme.spacing(0.5),
    alignItems: 'center',
    borderRadius: 3,
    minHeight: '30px',
    fontSize: '16px',
}));

const TopRightControls = appStyled('div')<{ backgroundColor: string }>(({ theme, backgroundColor }) => ({
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor,
    display: 'flex',
    padding: theme.spacing(0.5),
    alignItems: 'center',
    borderRadius: 3,
    minHeight: '30px',
    fontSize: '16px',
}));

type VideoMultiControlProps = {
    bottom?: React.ReactNode;
    bottomRight?: React.ReactNode;
    topRight?: React.ReactNode;
    backgroundColor?: string;
    onClick?: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

export const VideoMultiControl = React.forwardRef(function VideoMultiControl(
    { bottom, bottomRight, topRight, backgroundColor, ...props }: VideoMultiControlProps,
    ref: React.ForwardedRef<HTMLDivElement>,
): React.ReactElement {
    // istanbul ignore next
    const bg = backgroundColor ? backgroundColor : 'rgba(255,255,255,0.4)';
    return (
        <VideoMultiControlBase {...props} ref={ref}>
            {bottom ? (
                <BottomControls onClick={preventClickthrough} backgroundColor={bg}>
                    {bottom}
                </BottomControls>
            ) : /*istanbul ignore next*/ null}
            {bottomRight ? (
                <BottomRightControls onClick={preventClickthrough} backgroundColor={bg}>
                    {bottomRight}
                </BottomRightControls>
            ) : /*istanbul ignore next*/ null}
            {topRight ? (
                <TopRightControls onClick={preventClickthrough} backgroundColor={bg}>
                    {topRight}
                </TopRightControls>
            ) : null}
        </VideoMultiControlBase>
    );
});
