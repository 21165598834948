// istanbul ignore file
import * as React from 'react';
import { Skeleton, Stack, Tab, Tooltip, Typography } from '@mui/material';
import { useFrame } from '../../../lib/frame-react';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import { AuditReq } from '../../../types/stein-internal-api';
import { useParams } from 'react-router-dom';
import { ToParams } from '../../../types/util-types';
import { Audit, AuditSlug, AuditTag, DateTimestampMs } from '../../../types/stein';
import { VehicleChip } from '../../AppChip';
import { addSeconds, format } from 'date-fns';
import { CopyToClipboardButton } from '../../material/CopyToClipboard';
import { useMultiVideoController, useMultiVideoState } from '../../VideoPlayerMulti/VideoPlayerMulti';
import { Box } from '@mui/material';
import { AuditTagProfileProvider } from './components/AuditTagProfile';
import { AuditTagProfileSelect } from './components/AuditTagProfileSelect';
import { AuditTagListAdd } from './components/AuditTagListAdd';
import { toTimestampMs } from '../../../utils/datetime-utils';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { AuditCustomMetadata } from './components/AuditCustomMetadata';
import { useHasFeature } from '../../Authorize/Feature';
import { AuditTagList } from './components/AuditTagList';
import { MultiVideoController } from '../../../lib/multi-video/multi-video';
import { AuditShowHotkeys } from './components/AuditShowHotkeys';
import { ErrorPage } from '../ErrorPage';
import { AuditTagCreateProvider } from './components/AuditTagCreateProvider';
import { useAppTheme } from '../../../theme';
import { TimelineProvider } from '../VehicleTimelineShow/VehicleTimelineScrubber/TimelineComponents/TimelineProvider';
import {
    TimelinePoint,
    TimelineRow,
} from '../VehicleTimelineShow/VehicleTimelineScrubber/TimelineComponents/TimelineComponents';
import { Scrubber } from '../VehicleTimelineShow/VehicleTimelineScrubber';
import { useAuditTagBehaviorColor } from './components/AuditTagBehaviorIcon';
import { TooltipContent } from '../../TooltipContent';
import { auditTagBehaviorToHumanReadable, auditTagCategoryToHumanReadable } from './components/AuditTagConstants';
import { videoSegmentFromDriverLogs } from '../../../lib/multi-video/video-segment';
import { VideoPlayerMultiTrack } from '../../VideoPlayerMulti/VideoPlayerMultiTrack';

function useRequest(): AuditReq {
    const params = useParams<ToParams<{ auditSlug: AuditSlug }>>();

    return React.useMemo(() => {
        const req: AuditReq = {
            auditSlug: params.auditSlug as AuditSlug,
        };

        return req;
    }, [params.auditSlug]);
}

function Playhead({ playheadTime }: { playheadTime: DateTimestampMs | undefined }): React.ReactElement | null {
    const theme = useAppTheme();

    if (!playheadTime) {
        return null;
    }

    return (
        <TimelinePoint
            time={playheadTime}
            sx={{
                height: '100%',
            }}
        >
            <Box
                sx={{
                    width: '2px',
                    height: '100%',
                    backgroundColor: theme.palette.grey[400],
                }}
            />
        </TimelinePoint>
    );
}

function AuditTagTimelineItem({
    tag,
    controller,
}: {
    tag: AuditTag;
    controller: MultiVideoController;
}): React.ReactElement {
    const color = useAuditTagBehaviorColor(tag.behavior);
    const seekTime = toTimestampMs(addSeconds(new Date(tag.startedAt), -2));

    return (
        <Tooltip
            title={
                <TooltipContent title={auditTagBehaviorToHumanReadable(tag.behavior)}>
                    {auditTagCategoryToHumanReadable(tag.category)}
                    <p>Click to seek to 2s before tag.</p>
                </TooltipContent>
            }
        >
            <TimelinePoint
                key={tag.id}
                time={toTimestampMs(new Date(tag.startedAt))}
                sx={{
                    height: '100%',
                    cursor: 'pointer',
                }}
                onClick={() => controller.seek(seekTime)}
            >
                <Box
                    sx={{
                        width: '3px',
                        borderRadius: '2px',
                        height: '100%',
                        backgroundColor: color,
                    }}
                ></Box>
            </TimelinePoint>
        </Tooltip>
    );
}

export function AuditShow(): React.ReactElement {
    const { useGetAuditQuery, useGetAuditTagsQuery } = useFrame(SteinInternalApiClientToken);
    const req = useRequest();
    const theme = useAppTheme();

    const { data: auditTagRes } = useGetAuditTagsQuery(req);
    const { data: auditRes, isLoading } = useGetAuditQuery(req);

    const segments = React.useMemo(
        () => [videoSegmentFromDriverLogs(auditRes?.audit.driverLogs || [])],
        [auditRes?.audit.driverLogs],
    );
    const controller = useMultiVideoController(segments);

    const { playbackTime, startTime, endTime } = useMultiVideoState(controller);

    if (!auditRes) {
        return isLoading ? (
            <Skeleton>
                <Typography variant="h5" marginBottom={2} />
            </Skeleton>
        ) : (
            <ErrorPage type={'not-found'} content={'Audit Not Found'} />
        );
    }

    const overlays: React.ReactNode[] = [];

    if (playbackTime) {
        overlays.push(
            <Box
                key={'timestamp'}
                sx={{
                    position: 'absolute',
                    bottom: 10,
                    left: 10,
                    backgroundColor: 'white',
                    opacity: 0.75,
                    borderRadius: 1,
                    px: 1,
                    fontSize: 12,
                }}
            >
                {format(playbackTime, 'MM/dd/yy HH:mm:ss')}
            </Box>,
        );
    }

    return (
        <AuditTagProfileProvider>
            <AuditTagCreateProvider audit={auditRes.audit} existingTags={auditTagRes?.auditTags || []}>
                <Stack spacing={1}>
                    <Stack spacing={1} direction={'row'} alignItems={'center'}>
                        {auditRes.audit.startedAt && auditRes.audit.endedAt && (
                            <div style={{ fontSize: 16 }}>{`${format(
                                new Date(auditRes.audit.startedAt),
                                'E, MMM do (h:mm aa',
                            )} - ${format(new Date(auditRes.audit.endedAt), 'h:mm aa)')} audit of`}</div>
                        )}

                        {auditRes.audit.vehicleDeviceId && (
                            <VehicleChip vehicleSlugOrId={auditRes.audit.vehicleDeviceId} size={'small'} />
                        )}
                        <CopyToClipboardButton
                            text={auditRes.audit.slug}
                            size={'16px'}
                            tooltipProps={{
                                title: 'Copy Audit URL to Clipboard',
                            }}
                        />
                    </Stack>

                    <Stack direction={'row'} spacing={2}>
                        <VideoPlayerMultiTrack controller={controller} loading={isLoading} />
                        <Box flex={1}>
                            {startTime && endTime ? (
                                <TimelineProvider
                                    startTime={startTime}
                                    durationMs={endTime - startTime}
                                    controller={controller}
                                >
                                    <Box
                                        sx={{
                                            height: '25px',
                                            width: '100%',
                                            position: 'relative',
                                            overflow: 'hidden',
                                            backgroundColor: theme.palette.grey[200],
                                        }}
                                    >
                                        <Playhead playheadTime={playbackTime} />
                                        <Scrubber />
                                        <TimelineRow sx={{ height: '100%' }}>
                                            {auditTagRes?.auditTags.map((tag) => (
                                                <AuditTagTimelineItem key={tag.id} tag={tag} controller={controller} />
                                            ))}
                                        </TimelineRow>
                                    </Box>
                                </TimelineProvider>
                            ) : null}
                        </Box>
                        <Box minWidth={420}>
                            <Stack>
                                <AuditTagProfileSelect />
                                {typeof playbackTime !== 'undefined' && (
                                    <SidePanel
                                        audit={auditRes.audit}
                                        playbackTime={playbackTime}
                                        auditTags={auditTagRes?.auditTags || []}
                                        controller={controller}
                                    />
                                )}
                            </Stack>
                        </Box>
                    </Stack>
                </Stack>
                <AuditShowHotkeys video={controller} />
            </AuditTagCreateProvider>
        </AuditTagProfileProvider>
    );
}

type SidePanelProps = {
    audit: Audit;
    playbackTime: DateTimestampMs;
    auditTags: AuditTag[];
    controller: MultiVideoController;
};

function SidePanel({ audit, playbackTime, auditTags, controller }: SidePanelProps): React.ReactElement {
    const [visiblePanel, setVisiblePanel] = React.useState('1');
    const showMetadata = useHasFeature('audit_custom_metadata');

    const handleChange = (event: React.SyntheticEvent, newValue: string): void => {
        setVisiblePanel(newValue);
    };

    return (
        <>
            <TabContext value={visiblePanel}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList
                        onChange={handleChange}
                        sx={{
                            minHeight: 0,
                        }}
                    >
                        <Tab
                            label="Existing Tags"
                            value="1"
                            sx={{
                                minHeight: 0,
                                minWidth: 0,
                                paddingY: 1,
                                paddingX: 1.5,
                            }}
                        />
                        <Tab
                            label="Add Tags"
                            value="2"
                            sx={{
                                minHeight: 0,
                                minWidth: 0,
                                paddingY: 1,
                                paddingX: 1.5,
                            }}
                        />
                        {showMetadata && (
                            <Tab
                                label="Metadata"
                                value="3"
                                sx={{
                                    minHeight: 0,
                                    minWidth: 0,
                                    paddingY: 1,
                                    paddingX: 1.5,
                                }}
                            />
                        )}
                    </TabList>
                </Box>

                <TabPanel value={'1'} sx={{ padding: 1 }}>
                    <AuditTagList audit={audit} playbackTime={playbackTime} tags={auditTags || []} video={controller} />
                </TabPanel>
                <TabPanel value={'2'} sx={{ padding: 1 }}>
                    <AuditTagListAdd audit={audit} playbackTime={playbackTime} existingAuditTags={auditTags || []} />
                </TabPanel>
                <TabPanel value={'3'} sx={{ padding: 1 }}>
                    <AuditCustomMetadata audit={audit} />
                </TabPanel>
            </TabContext>
        </>
    );
}
