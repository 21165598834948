import { Slider, SliderProps } from '@mui/material';
import * as React from 'react';

export function VideoSlider(props: SliderProps): React.ReactElement {
    return (
        <Slider
            {...props}
            size="small"
            componentsProps={{
                thumb: {
                    // @ts-expect-error this is not defined in the type, but it works
                    ['data-testid']: 'videoseek-thumb',
                },
            }}
            sx={{
                color: 'rgba(0,0,0,0.87)',
                height: 4,
                '& .MuiSlider-thumb': {
                    width: 8,
                    height: 8,
                    transition: '30ms linear',
                    '&:before': {
                        boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
                    },
                    '&:hover, &.Mui-focusVisible': {
                        boxShadow: '0px 0px 0px 8px rgb(0 0 0 / 16%)',
                    },
                    '&.Mui-active': {
                        width: 20,
                        height: 20,
                    },
                },
                '& .MuiSlider-track': {
                    transition: '0ms linear',
                },
                '& .MuiSlider-rail': {
                    opacity: 0.28,
                },
            }}
        />
    );
}
