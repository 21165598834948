import { Box } from '@mui/material';
import * as React from 'react';
import { ToolbarOffset } from '../components/AppLayout';
import { ApplicationBarWrapper } from '../components/ApplicationBar/ApplicationBar';
import { ThemeProvider } from '../theme';

type UJSRootProps = {
    children: string;
};

export default function UJSRoot(props: UJSRootProps): React.ReactElement {
    return (
        <ThemeProvider>
            <ApplicationBarWrapper />
            <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
                <ToolbarOffset />
                <Box sx={{ p: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 2 }}>
                    <div
                        style={{ maxWidth: '600px', width: '600px' }}
                        dangerouslySetInnerHTML={{ __html: props.children }}
                    />
                </Box>
            </Box>
        </ThemeProvider>
    );
}
