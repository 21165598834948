import * as React from 'react';
import { FilterBar } from '../../FilterBar';

import { FilterMultiselect } from '../../FilterBar/FilterMultiselect';

import { useAppSelector } from '../../../hooks/hooks';
import { useActiveProjectSlug } from '../../../hooks/use-active-project';

import {
    selectDebugEnabled,
    selectFilterConfig,
    selectGeofencesByProjectSlug,
    selectRegionsByProjectSlug,
    selectVehicleModelsByProjectSlug,
    selectVehiclesByProjectSlug,
} from '../../../store/selectors';
import { VehicleDevice, VehicleDeviceId } from '../../../types/stein';

import { DataGrid } from '../../DataGrid';
import { useHistory } from 'react-router-dom';

import {
    filterIncludes,
    filterIncludesEvery,
    filterIncludesSome,
    filterOptionsFromGeofences,
    filterOptionsFromRegions,
    filterOptionsFromVehicleModels,
} from '../../../utils/filter-utils';
import { urlVehicleDeviceShow } from '../../../utils/internal-url-utils';

import { BATTERY_STATE } from '../../../constants/battery-status-constants';

import { FilterReset } from '../../FilterBar/FilterReset';
import { useVehicleDeviceColumns } from './useVehicleDeviceColumns';
import { useFrame } from '../../../lib/frame-react';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import { VehicleDeviceBulkActions } from './VehicleDeviceBulkActions';
import { isVehicleOnline, isWifiConnected } from '../../Vehicle/vehicle_utils';

import {
    GridSortModel,
    DATA_GRID_PRO_PROPS_DEFAULT_VALUES,
    GridRowClassNameParams,
    GridRowId,
} from '@mui/x-data-grid-pro';
import { useFilters } from '../../../hooks/use-filters';
import { useGlobalSearch } from '../../GlobalSearch';
import { isAfter, isToday, subDays } from 'date-fns';
import { isRoughlyNow } from '../../../utils/datetime-utils';
import { Permission } from '../../Authorize/PermissionBlock';

const SORT_MODEL: GridSortModel = [{ field: 'name', sort: 'asc' }];

const LAST_SEEN_FUNCTIONS = {
    online: isRoughlyNow,
    offline: (d: Date) => !isRoughlyNow(d),
    today: isToday,
    week: (d: Date | number) => isAfter(d, subDays(new Date(), 7)),
    month: (d: Date | number) => isAfter(d, subDays(new Date(), 30)),
    all: () => true,
} as const;

type LastSeenKey = keyof typeof LAST_SEEN_FUNCTIONS;

export function VehicleDeviceList(): React.ReactElement {
    const history = useHistory();
    const columns = useVehicleDeviceColumns();
    const filterString = useGlobalSearch('Filters vehicles by name');

    const projectSlug = useActiveProjectSlug();
    const { filters } = useFilters();

    const [selectedIds, setSelectedIds] = React.useState<Array<GridRowId>>([]);

    const vehicles = useAppSelector((s) => selectVehiclesByProjectSlug(s, projectSlug));
    const regions = useAppSelector((s) => selectRegionsByProjectSlug(s, projectSlug));
    const vehicleModels = useAppSelector((s) => selectVehicleModelsByProjectSlug(s, projectSlug));
    const geofences = useAppSelector((s) => selectGeofencesByProjectSlug(s, projectSlug));
    const debug = useAppSelector(selectDebugEnabled);

    const { options: filterOptions } = useAppSelector((s) => selectFilterConfig(s, 'vehicleDevices'));

    const { useGetVehicleDevicesQuery } = useFrame(SteinInternalApiClientToken);
    useGetVehicleDevicesQuery({ projectSlug, isBackground: false });

    const onRowChosen = React.useCallback(
        function onRowChosenCallback(v: VehicleDevice): void {
            history.push(urlVehicleDeviceShow(projectSlug, v.slug));
        },
        [projectSlug],
    );

    const hasFilters = Object.keys(filters).length > 0;

    const filteredVehicles = React.useMemo(
        () =>
            vehicles
                .filter((v) => {
                    const godModeTags = [];
                    v.appNeedsUpdate && godModeTags.push('app_update');
                    v.belowMinRequiredOperatingSystemVersion && godModeTags.push('ios_update');

                    const statusTags = [];

                    if (typeof v.lastBatteryLevel === 'number') {
                        v.lastBatteryLevel < 0.5 && statusTags.push('low');
                        v.lastBatteryState != BATTERY_STATE.FULL &&
                            v.lastBatteryState != BATTERY_STATE.CHARGING &&
                            statusTags.push('unplugged');
                    }

                    v.muted ? statusTags.push('muted') : statusTags.push('unmuted');

                    !isWifiConnected(v) && statusTags.push('disconnected');

                    !v.cameraEnabled ? statusTags.push('cameraDisabled') : null;

                    const seen = filters.seen?.length ? filters.seen[0] : /* istanbul ignore next */ undefined;
                    const lastSeenFn = seen
                        ? LAST_SEEN_FUNCTIONS[seen as LastSeenKey]
                        : /* istanbul ignore next */ LAST_SEEN_FUNCTIONS['week'];

                    if (!v.lastSeenAt || !lastSeenFn(new Date(v.lastSeenAt))) {
                        return false;
                    }

                    const include =
                        filterIncludes(filters.calibration, v.calibrationStatus) &&
                        filterIncludes(filters.regions, v.regionId) &&
                        filterIncludes(filters.models, v.vehicleModelId) &&
                        filterIncludesSome(filters.facilities, v.geofenceIds || []) &&
                        filterIncludesEvery(filters.status, statusTags) &&
                        filterIncludesSome(filters.godMode, godModeTags) &&
                        !Boolean(v.archivedAt);

                    if (!include) {
                        return false;
                    }

                    return (
                        !filterString ||
                        /* istanbul ignore next */ v.name.toLowerCase().includes(filterString.toLowerCase())
                    );
                })
                .map((v) => ({
                    ...v,
                    url: urlVehicleDeviceShow(projectSlug, v),
                })),
        [vehicles, filters, filterString],
    );

    return (
        <>
            <span data-testid={'page-vehicle-index'} />
            <FilterBar
                icons={
                    <>
                        {hasFilters ? (
                            <FilterReset filterContextName={'vehicleDevices'} />
                        ) : /* istanbul ignore next */ null}
                    </>
                }
            >
                <FilterMultiselect filterName={'regions'} options={filterOptionsFromRegions(regions)} />
                {vehicleModels.length ? (
                    <FilterMultiselect filterName={'models'} options={filterOptionsFromVehicleModels(vehicleModels)} />
                ) : null}
                <FilterMultiselect filterName={'facilities'} options={filterOptionsFromGeofences(geofences)} />
                <FilterMultiselect filterName={'status'} options={filterOptions.status} />
                <Permission debugOnly>
                    <FilterMultiselect filterName={'godMode'} options={filterOptions.godMode} />
                </Permission>

                <FilterMultiselect
                    filterName={'seen'}
                    defaultValue={['week']}
                    options={[
                        {
                            name: 'Online Now',
                            id: 'online',
                        },
                        {
                            name: 'Online Today',
                            id: 'today',
                        },
                        {
                            name: 'Online This Week',
                            id: 'week',
                        },
                        {
                            name: 'Online This Month',
                            id: 'month',
                        },
                        {
                            name: 'Offline',
                            id: 'offline',
                        },
                        {
                            name: 'Show All',
                            id: 'all',
                        },
                    ]}
                    multiple={false}
                />
                <VehicleDeviceBulkActions vehicleIds={selectedIds as VehicleDeviceId[]} />
            </FilterBar>

            <DataGrid
                checkboxSelection
                onRowSelectionModelChange={setSelectedIds}
                hideFooter
                onRowChosen={onRowChosen}
                localeText={{
                    noRowsLabel: 'No Matching Vehicles',
                }}
                columns={columns}
                getRowClassName={getRowClassName}
                sortModel={SORT_MODEL}
                rows={filteredVehicles}
                rowHeight={debug ? /* istanbul ignore next */ 100 : DATA_GRID_PRO_PROPS_DEFAULT_VALUES.rowHeight}
            />
        </>
    );
}

function getRowClassName({ row }: GridRowClassNameParams): string {
    return isVehicleOnline(row) ? 'enabled' : 'disabled';
}
