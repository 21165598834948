import * as React from 'react';

import { AppIconRemoveItem } from '../../../AppIcons';
import { IconButton, lighten, ListItem, ListItemButton } from '@mui/material';
import { useNotificationItemContext } from '..';
import { useAppTheme } from '../../../../theme';
import { Project } from '../../../../types/stein';
import { Box, Stack } from '@mui/system';
import { AppInternalUrl } from '../../../../utils/internal-url-utils';
import { useAppNavigate } from '../../../../hooks/hooks';

type NotificationItemEventProps = {
    icon?: React.ReactNode;
    children: React.ReactNode;
    dismissable?: boolean;
    autoDismissMs?: number;
    autoAckMs?: number;
    project?: Project;
    type?: 'success' | 'error';
    url?: AppInternalUrl;
};

export function NotificationItemWrapper({
    icon,
    children,
    dismissable,
    autoDismissMs,
    autoAckMs,
    type,
    project,
    url,
}: NotificationItemEventProps): React.ReactElement {
    const { dismiss, ack, notification, isSnackbar } = useNotificationItemContext();
    const theme = useAppTheme();

    const showDismissOptions = dismissable && Boolean(dismiss);

    React.useEffect(() => {
        if (dismiss && autoDismissMs) {
            const timeout = setTimeout(dismiss, autoDismissMs);
            return () => clearTimeout(timeout);
        }
    }, [dismiss, autoDismissMs]);

    React.useEffect(() => {
        if (ack && autoAckMs) {
            const timeout = setTimeout(ack, autoAckMs);
            return () => clearTimeout(timeout);
        }
    }, [ack, autoAckMs]);

    const navigate = useAppNavigate();

    const contents = (
        <Stack>
            {project ? (
                <Box paddingLeft={1} paddingTop={1} fontSize={10} color={theme.palette.grey[900]}>
                    {`${project.name}`}
                </Box>
            ) : null}
            <div style={{ display: 'flex', flex: 1, paddingRight: showDismissOptions ? theme.spacing(8) : 0 }}>
                <div
                    style={{
                        width: theme.spacing(1),
                        backgroundColor: type ? theme.palette[type].light : 'inherit',
                    }}
                ></div>

                <div
                    style={{
                        padding: theme.spacing(1),
                        flex: 1,
                        display: 'flex',
                    }}
                >
                    {icon ? (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                paddingRight: theme.spacing(2),
                            }}
                        >
                            {icon}
                        </div>
                    ) : null}
                    {children}
                </div>
            </div>
        </Stack>
    );

    return (
        <ListItem
            data-testid={'notification-item'}
            disablePadding
            style={{
                minWidth: '360px',
                maxWidth: '360px',
                zIndex: 9999,
                ...(isSnackbar
                    ? {}
                    : {
                          backgroundColor: notification.acked ? 'inherit' : lighten(theme.palette.warning.light, 0.4),
                          '&:hover': {
                              backgroundColor: lighten(theme.palette.warning.light, 0.8),
                          },
                      }),
            }}
            secondaryAction={
                showDismissOptions ? (
                    <IconButton edge="end" aria-label="delete" size={'small'} onClick={dismiss}>
                        <AppIconRemoveItem />
                    </IconButton>
                ) : null
            }
        >
            {url ? (
                <ListItemButton sx={{ padding: 0 }} onClick={() => navigate(url)} data-testid={'notification-button'}>
                    {contents}
                </ListItemButton>
            ) : (
                <>{contents}</>
            )}
        </ListItem>
    );
}
