import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { appStyled, useAppTheme } from '../../theme';
import { ObservantLogo } from './ObservantLogo';
import { AccountOptions } from './AccountOptions';
import { Notifications } from '../Notifications/NotificationAppBar';
import { useNavigationDrawerContext } from '../AppLayout/NavigationDrawerContext';
import { AppIconMenu, AppIconSearch } from '../AppIcons';
import { ApplicationBarIcon } from './ApplicationBarIcon';
import { useOmnibar } from '../OmniBar/Omnibar';
import { IconButton } from '@mui/material';

const ApplicationBarSpacing = appStyled('div')`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const IconGroup = appStyled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export function ApplicationBarWrapper({ children }: { children?: React.ReactNode }): React.ReactElement {
    const theme = useAppTheme();

    return (
        <AppBar position="fixed" data-testid={'appbar'}>
            <Toolbar
                style={{
                    backgroundColor: theme.colors.appbar.background,
                }}
            >
                <ApplicationBarSpacing>
                    <ObservantLogo />
                    {children}
                </ApplicationBarSpacing>
            </Toolbar>
        </AppBar>
    );
}

export function ApplicationBar(): React.ReactElement {
    const theme = useAppTheme();
    const { isDesktop, setIsOpen } = useNavigationDrawerContext();
    const { setOmnibarOpen } = useOmnibar();

    return (
        <ApplicationBarWrapper>
            <IconGroup>
                <IconButton onClick={() => setOmnibarOpen(true)} sx={{ color: theme.colors.appbar.text }}>
                    <AppIconSearch />
                </IconButton>
                <Notifications />
                <AccountOptions />
                {isDesktop ? null : (
                    <ApplicationBarIcon onClick={() => setIsOpen(true)}>
                        <AppIconMenu />
                    </ApplicationBarIcon>
                )}
            </IconGroup>
        </ApplicationBarWrapper>
    );
}
