import * as React from 'react';

export function IOSUpdateNeededSvg(): React.ReactElement {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 50 50">
            <style type="text/css"></style>
            <path
                d="M15,3C8.4,3,3,8.4,3,15v20c0,6.6,5.4,12,12,12h20c6.6,0,12-5.4,12-12V15c0-6.6-5.4-12-12-12H15z M15,5h20
   c5.5,0,10,4.5,10,10v20c0,5.5-4.5,10-10,10H15C9.5,45,5,40.5,5,35V15C5,9.5,9.5,5,15,5z M11.6,18.1c-0.7,0-1.2,0.5-1.2,1.2
   c0,0.6,0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2C12.8,18.6,12.3,18.1,11.6,18.1z M22,18.6c-3.6,0-5.9,2.5-5.9,6.6c0,4.1,2.2,6.7,5.9,6.7
   c3.7,0,5.9-2.6,5.9-6.7C27.9,21.2,25.7,18.6,22,18.6z M35,18.6c-2.8,0-4.6,1.5-4.6,3.8c0,1.9,1,3,3.3,3.5l1.6,0.4
   c1.7,0.4,2.4,1,2.4,1.9c0,1.1-1.2,1.9-2.7,1.9c-1.7,0-2.9-0.8-3-2h-2c0.1,2.3,2,3.7,4.8,3.7c3,0,4.9-1.5,4.9-3.9
   c0-1.9-1.1-3-3.7-3.6l-1.4-0.3c-1.7-0.4-2.3-0.9-2.3-1.8c0-1.1,1-1.9,2.6-1.9c1.5,0,2.5,0.7,2.7,1.9h1.9
   C39.4,20.1,37.5,18.6,35,18.6z M22,20.5c2.4,0,3.9,1.9,3.9,4.8c0,2.9-1.5,4.8-3.9,4.8c-2.4,0-3.9-1.9-3.9-4.8
   C18.1,22.3,19.6,20.5,22,20.5z M10.7,22.1v9.6h1.9v-9.6H10.7z"
            />
            <g>
                <g>
                    <path
                        className="st0"
                        d="M47.4,15.9H34.1c-1.2,0-2-1.3-1.4-2.4l6.6-11.6c0.6-1.1,2.2-1.1,2.8,0l6.6,11.6
           C49.4,14.5,48.6,15.9,47.4,15.9z"
                    />
                    <path
                        d="M47.4,16.9H34.1c-0.9,0-1.8-0.5-2.3-1.3c-0.5-0.8-0.5-1.8,0-2.6l6.6-11.6C39,0.5,39.8,0,40.8,0c1,0,1.8,0.5,2.3,1.3
           L49.7,13c0.5,0.8,0.5,1.8,0,2.6C49.2,16.4,48.3,16.9,47.4,16.9z M40.8,2c-0.1,0-0.4,0-0.5,0.3l-6.6,11.6c-0.2,0.3-0.1,0.5,0,0.6
           c0.1,0.1,0.2,0.3,0.5,0.3h13.2c0.3,0,0.5-0.2,0.5-0.3c0.1-0.1,0.2-0.3,0-0.6L41.3,2.3C41.1,2,40.9,2,40.8,2z"
                    />
                </g>
                <g>
                    <g>
                        <path d="M40.8,13.8c0.5,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.7-0.8-0.7c-0.5,0-0.8,0.3-0.8,0.7C40,13.5,40.3,13.8,40.8,13.8z" />
                    </g>
                    <g>
                        <rect x="40.1" y="5" width="1.2" height="6.2" />
                    </g>
                </g>
            </g>
        </svg>
    );
}
