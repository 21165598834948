import * as React from 'react';
import { useForceRefresh } from '../../hooks/use-force-refresh';
import { useFrame } from '../../lib/frame-react';
import { WindowToken } from '../../lib/frame-tokens';

import type { VideoRef } from './VideoTypes';

// istanbul ignore file
// a lot of this can't be unit tested easily as we can't manually emit from a video element and jsdom doesn't
// support video

type VideoPlaybackState = 'playing' | 'paused';

function isPlaying(video: HTMLVideoElement | null): boolean {
    if (!video) {
        return false;
    }
    return !video.paused && !video.ended && video.readyState > 2;
}

export function useVideoHasError(videoRef: VideoRef): boolean {
    const [error, setError] = React.useState<boolean>(false);

    React.useEffect(() => {
        function handleError(e: unknown): void {
            // @ts-expect-error this does work
            setError(e.srcElement.src !== window.location.href);
        }
        function clearError(): void {
            setError(false);
        }
        if (videoRef.current) {
            videoRef.current.addEventListener('error', handleError);
            videoRef.current.addEventListener('loadstart', clearError);
            videoRef.current.addEventListener('canplay', clearError);
            videoRef.current.addEventListener('play', clearError);
            videoRef.current.addEventListener('playign', clearError);
            videoRef.current.addEventListener('ended', clearError);

            return () => {
                videoRef.current?.removeEventListener('error', handleError);
                videoRef.current?.removeEventListener('loadstart', clearError);
                videoRef.current?.removeEventListener('canplay', clearError);
                videoRef.current?.removeEventListener('play', clearError);
                videoRef.current?.removeEventListener('playign', clearError);
            };
        }
    }, [videoRef.current]);

    return error;
}

export function useVideoPlaybackState(ref: VideoRef): VideoPlaybackState {
    const [state, setState] = React.useState<VideoPlaybackState>(isPlaying(ref.current) ? 'playing' : 'paused');

    React.useEffect(() => {
        if (!ref.current) {
            return;
        }

        function onPlay(): void {
            setState('playing');
        }

        function onPause(): void {
            setState('paused');
        }

        ref.current.addEventListener('play', onPlay);
        ref.current.addEventListener('pause', onPause);
        ref.current.addEventListener('ended', () => {
            onPause();
        });
        ref.current.addEventListener('abort', () => {
            onPause();
        });

        return () => {
            ref.current?.removeEventListener('play', onPlay);
            ref.current?.removeEventListener('pause', onPause);
            ref.current?.removeEventListener('ended', onPause);
            ref.current?.removeEventListener('abort', onPause);
        };
    }, [ref.current]);

    return state;
}

type UseFullScreenResult = {
    enterFullScreen: () => void;
    exitFullScreen: () => void;
    isFullScreen: boolean;
};

export function useFullScreen(ref: React.RefObject<HTMLElement>): UseFullScreenResult {
    const window = useFrame(WindowToken);
    const [isFullScreen, setIsFullScreen] = React.useState<boolean>(Boolean(window.document.fullscreenElement));
    function enterFullScreen(): void {
        if (ref.current) {
            ref.current.requestFullscreen();
        }
    }

    function exitFullScreen(): void {
        window.document.exitFullscreen();
    }

    React.useEffect(() => {
        function onFullScreenChange(): void {
            setIsFullScreen(Boolean(window.document.fullscreenElement));
        }
        document.addEventListener('fullscreenchange', onFullScreenChange);
        return () => {
            window.document.removeEventListener('fullscreenchange', onFullScreenChange);
        };
    }, [ref.current]);

    return {
        enterFullScreen,
        exitFullScreen,
        isFullScreen,
    };
}

type PipContext = [HTMLVideoElement | null, () => void];
const pipContext = React.createContext<PipContext>([null, /* istanbul ignore next */ () => null]);

export function PictureInPictureProvider({ children }: { children: React.ReactNode }): React.ReactElement {
    const window = useFrame(WindowToken);
    const refresh = useForceRefresh();
    const pipElement = window.document.pictureInPictureElement as HTMLVideoElement | null;
    const context: PipContext = React.useMemo(() => [pipElement, refresh], [pipElement, refresh]);
    return <pipContext.Provider value={context}>{children}</pipContext.Provider>;
}

function usePipContext(): PipContext {
    return React.useContext(pipContext);
}

export const PipProvider = pipContext;

type UsePictureInPictureResult = {
    enterPictureInPicture: () => void;
    exitPictureInPicture: () => void;
    isPictureInPicture: boolean;
};

export function usePictureInPicture(ref: VideoRef): UsePictureInPictureResult {
    const [activePipElement, refreshPip] = usePipContext();
    const window = useFrame(WindowToken);

    const isPictureInPicture = Boolean(activePipElement && activePipElement === ref.current);

    function enterPictureInPicture(): void {
        if (ref.current && !activePipElement) {
            ref.current.requestPictureInPicture();
        }
    }

    function exitPictureInPicture(): void {
        window.document.exitPictureInPicture();
    }

    // This function is is important because `refreshPip` is the same object throughout the
    // application scope, so two components call usePictureInPicture on the same element,
    // the following removeEventListener call will remove the listener for both of them.
    function scopedRefresh(): void {
        refreshPip();
    }

    React.useEffect(() => {
        if (ref.current) {
            ref.current.addEventListener('enterpictureinpicture', scopedRefresh);
            ref.current.addEventListener('leavepictureinpicture', scopedRefresh);
            return () => {
                ref.current?.removeEventListener('enterpictureinpicture', scopedRefresh);
                ref.current?.removeEventListener('leavepictureinpicture', scopedRefresh);
            };
        }
    }, [ref.current, scopedRefresh]);

    return {
        enterPictureInPicture,
        exitPictureInPicture,
        isPictureInPicture,
    };
}

type UseVideoVolumeResult = {
    muted: boolean;
    setMuted: (m: boolean) => void;
};

export function useVideoVolume(ref: VideoRef): UseVideoVolumeResult {
    const [muted, _setMuted] = React.useState<boolean>(ref.current?.muted || false);

    const setMuted = React.useCallback(
        (b: boolean) => {
            if (ref.current) {
                ref.current.defaultMuted = b;
                ref.current.muted = b;
                _setMuted(b);
            }
        },
        [ref.current],
    );

    return {
        muted,
        setMuted,
    };
}

type UsePlaybackResult = {
    setProgress: (percent: number) => void;
    progress: number;
};

export function usePlaybackPosition(ref: VideoRef): UsePlaybackResult {
    const [progress, _setProgress] = React.useState<number>(
        ref.current && ref.current.duration ? ref.current.currentTime / ref.current.duration : 0,
    );

    React.useEffect(() => {
        const interval = setInterval(function () {
            if (ref.current) {
                _setProgress(ref.current.currentTime / ref.current.duration);
            }
        }, 30);

        return () => clearInterval(interval);
    }, [ref.current]);

    function setProgress(percent: number): void {
        if (ref.current) {
            ref.current.currentTime = ref.current.duration * percent;
            _setProgress(percent);
        }
    }

    return {
        setProgress,
        progress,
    };
}

type UsePlaybackRate = {
    setPlaybackRate: React.Dispatch<React.SetStateAction<number>>;
    playbackRate: number;
};

export function usePlaybackRate(ref: VideoRef): UsePlaybackRate {
    const [playbackRate, _setPlaybackRate] = React.useState<number>(ref.current?.playbackRate || 1);

    const setPlaybackRate = React.useCallback(
        function setPlaybackRate(n: React.SetStateAction<number>) {
            // Most browsers have a max playback rate of 16x
            if (typeof n === 'number') {
                return _setPlaybackRate(Math.min(n || 1, 16));
            } else {
                return _setPlaybackRate((s) => Math.min(n(s) || 1, 16));
            }
        },
        [_setPlaybackRate],
    );

    React.useEffect(() => {
        function handleOnRateUpdate(): void {
            if (ref.current) {
                setPlaybackRate(ref.current.playbackRate || 1);
            }
        }

        ref.current?.addEventListener('ratechange', handleOnRateUpdate);
        return () => {
            ref.current?.removeEventListener('ratechange', handleOnRateUpdate);
        };
    }, [ref.current]);

    React.useEffect(() => {
        if (ref.current && ref.current.playbackRate !== playbackRate) {
            ref.current.playbackRate = playbackRate;
        }
    }, [ref.current, playbackRate]);

    return {
        setPlaybackRate,
        playbackRate,
    };
}
