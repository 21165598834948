import { combineReducers } from '@reduxjs/toolkit';
import { createToken, createPlugin } from '../lib/frame';

import { SteinInternalApiToken } from '../clients/stein-internal-api';
import { SteinRootApiToken } from '../clients/stein-root-api';

import { projectsSlice } from './slices/projectsSlice';
import { userSlice } from './slices/userSlice';
import { navigationSlice } from './slices/navigationSlice';
import { abilitiesSlice } from './slices/abilitiesSlice';
import { filterConfigSlice } from './slices/filterConfigSlice';
import { notificationSlice } from './slices/notificationSlice';
import { driverEventsSlice } from './slices/driverEventsSlice';
import { vehiclesSlice } from './slices/vehiclesSlice';
import { regionsSlice } from './slices/regionsSlice';
import { driverLogsSlice } from './slices/driverLogsSlice';
import { geofencesSlice } from './slices/geofencesSlice';
import { configItemsSlice } from './slices/configItemsSlice';
import { notificationConfigsSlice } from './slices/notificationConfigsSlice';
import { audioSlice } from './slices/audioSlice';
import { vehicleModelsSlice } from './slices/vehicleModelsSlice';

export const AppReducerToken = createToken<AppReducer>('AppReducerToken');

export const AppReducerPlugin = createPlugin(({ resolve }) => {
    const steinInternalAPI = resolve(SteinInternalApiToken);
    const steinRootAPI = resolve(SteinRootApiToken);

    return combineReducers({
        projects: projectsSlice.reducer,
        user: userSlice.reducer,
        navigation: navigationSlice.reducer,
        driverEvents: driverEventsSlice.reducer,
        driverLogs: driverLogsSlice.reducer,
        abilities: abilitiesSlice.reducer,
        filterConfig: filterConfigSlice.reducer,
        notification: notificationSlice.reducer,
        vehicles: vehiclesSlice.reducer,
        vehicleModels: vehicleModelsSlice.reducer,
        regions: regionsSlice.reducer,
        geofences: geofencesSlice.reducer,
        notificationConfigs: notificationConfigsSlice.reducer,
        configItems: configItemsSlice.reducer,
        audio: audioSlice.reducer,
        [steinInternalAPI.reducerPath]: steinInternalAPI.reducer,
        [steinRootAPI.reducerPath]: steinRootAPI.reducer,
    });
});

export type AppReducer = ReturnType<typeof AppReducerPlugin>;
