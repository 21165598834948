import * as React from 'react';

import { VehicleDevice } from '../../types/stein';
import { isRoughlyNow } from '../../utils/datetime-utils';
import { RegionChip, VehicleChip } from '../AppChip';
import { AppIconVehicle } from '../AppIcons';
// import { CalibrationStatusChip } from './CalibrationStatusChip';
import { CalloutBox, CalloutSkeleton } from '../material/CalloutBox';
import { appStyled } from '../../theme';
import { AlertsMutedIndicator } from './AlertsMutedIndicator';
import { BatteryStatusIndicator } from './BatteryStatusIndicator';
import { IOSUpdateIndicator } from './IOSUpdateIndicator';
import { ObservantUpdateIndicator } from './ObservantUpdateIndicator';
import { WifiStatusIndicator } from './WifiStatusIndicator';
import { define } from '../../utils/undefined-utils';
import { VehicleLastSeen } from './VehicleLastSeen';

type VehicleStatusCalloutProps = {
    vehicle: VehicleDevice | undefined;
};
export function VehicleStatusCallout({ vehicle }: VehicleStatusCalloutProps): React.ReactElement {
    if (!vehicle) {
        return <CalloutSkeleton name={'Vehicle'} icon={<AppIconVehicle />} />;
    }

    return <VehicleStatusBase {...vehicle} />;
}

type VehicleStatusBaseProps = VehicleDevice;

const Spacing = appStyled('div')(() => ({
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
}));

function VehicleStatusBase(vehicle: VehicleStatusBaseProps): React.ReactElement {
    const isOnline = typeof vehicle.lastSeenAt === 'string' && isRoughlyNow(new Date(vehicle.lastSeenAt));

    return (
        <CalloutBox name={'Vehicle'} icon={<AppIconVehicle />}>
            <Spacing>
                <VehicleChip vehicleSlugOrId={vehicle.slug} size={'small'} />

                {vehicle.regionId && <RegionChip regionId={vehicle.regionId} size={'small'} />}

                <AlertsMutedIndicator vehicle={vehicle} />

                <BatteryStatusIndicator
                    batteryState={define(vehicle.lastBatteryState)}
                    batteryLevel={define(vehicle.lastBatteryLevel)}
                    isOnline={isOnline}
                />

                {/* <CalibrationStatusChip status={vehicle.calibrationStatus} isOnline={isOnline} /> */}

                <IOSUpdateIndicator vehicle={vehicle} size={'24px'} showVersionIfNoUpdate />

                <ObservantUpdateIndicator vehicle={vehicle} size={'24px'} />

                <WifiStatusIndicator vehicle={vehicle} />

                <VehicleLastSeen vehicle={vehicle} />
            </Spacing>
        </CalloutBox>
    );
}
