// istanbul ignore file

import EventEmitter from '@foxify/events';
import { DateStrISO8601, DateTimestampMs } from '../../types/stein';
import { VideoTrack } from './video-track';
import { toISO8601 } from '../../utils/datetime-utils';
import { Timer } from '../../utils/timer';

export type MultiVideoControllerState = {
    playbackTime: DateTimestampMs;
    playbackState: 'playing' | 'paused';
    startTime: DateTimestampMs;
    endTime: DateTimestampMs;
    tracks: VideoTrack[];
};

type VideoControllerEvents = {
    stateChanged: (t: MultiVideoControllerState) => void;
};

const MAX_FPS = 15;
const FRAME_TIME = 1000 / MAX_FPS;

export class MultiVideoController extends EventEmitter<VideoControllerEvents> {
    private state: MultiVideoControllerState = {
        playbackTime: 0 as DateTimestampMs,
        playbackState: 'paused',
        startTime: 0 as DateTimestampMs,
        endTime: 0 as DateTimestampMs,
        tracks: [],
    };

    private frameTimer: Timer;
    private playbackTimer: Timer;

    private playbackTimeWhenNoVideoStarted: DateTimestampMs = 0 as DateTimestampMs;

    constructor() {
        super();
        this.frameTimer = new Timer();
        this.playbackTimer = new Timer();
    }

    setTracks(tracks: VideoTrack[]): void {
        const startTime = Math.min(...tracks.map((t) => t.startTime)) as DateTimestampMs;
        const endTime = Math.max(...tracks.map((t) => t.endTime)) as DateTimestampMs;

        this.updateState({
            tracks,
            startTime,
            endTime,
        });

        this.startUpdate();
    }

    get tracks(): VideoTrack[] {
        return this.state.tracks;
    }

    get primaryTrack(): VideoTrack | null {
        return this.state.tracks[0];
    }

    get followerTracks(): VideoTrack[] {
        return this.state.tracks.slice(1);
    }

    play(): void {
        this.primaryTrack?.play();
        this.updateState({ playbackState: 'playing' });
    }

    pause(): void {
        this.primaryTrack?.pause();
        this.updateState({ playbackState: 'paused' });
    }

    seek(playbackTime: DateTimestampMs): void {
        this.primaryTrack?.seek(playbackTime);
        for (const t of this.followerTracks) {
            t.seek(playbackTime);
        }
        this.updateState({ playbackTime });
        this.playbackTimeWhenNoVideoStarted = playbackTime;
        this.playbackTimer.restart();
    }

    updatePlayback = (): void => {
        if (this.playbackState === 'paused') {
            requestAnimationFrame(this.updatePlayback);
            return;
        }
        // Don't run this too fast
        if (this.frameTimer.elapsed() < FRAME_TIME) {
            requestAnimationFrame(this.updatePlayback);
            return;
        }
        this.frameTimer.restart();

        const videoPlaybackTime = this.primaryTrack?.getPlaybackTime();
        const playbackState = this.playbackState;

        // If playback time is set in the video, update it in the controller and sync the follower tracks.
        if (videoPlaybackTime) {
            this.updateState({ playbackTime: videoPlaybackTime });
            for (const t of this.followerTracks) {
                t.seek(videoPlaybackTime);
            }
            requestAnimationFrame(this.updatePlayback);
            this.playbackTimeWhenNoVideoStarted = (videoPlaybackTime + 400) as DateTimestampMs;
            this.playbackTimer.restart();
            return;
        }

        // If playback time is not set by the video, but the controller is playing,
        // advance the playback time on all tracks
        if (playbackState === 'playing' && !videoPlaybackTime) {
            const elapsedMs = this.playbackTimer.elapsed();
            const currentPlaybackTime = (this.playbackTimeWhenNoVideoStarted + elapsedMs) as DateTimestampMs;

            for (const t of this.tracks) {
                t.seek(currentPlaybackTime as DateTimestampMs);
            }
            this.updateState({ playbackTime: currentPlaybackTime });

            requestAnimationFrame(this.updatePlayback);
            return;
        }

        // make sure this loop keeps running until the controller is stopped
        requestAnimationFrame(this.updatePlayback);
    };

    startUpdate = (): void => {
        requestAnimationFrame(this.updatePlayback);
    };

    public get playbackTime(): DateTimestampMs {
        return this.state.playbackTime || (0 as DateTimestampMs);
    }

    public get playbackState(): 'playing' | 'paused' {
        return this.state.playbackState;
    }

    public get currentTime(): DateStrISO8601 | undefined {
        if (this.state.playbackTime) {
            return toISO8601(new Date(this.state.playbackTime));
        }
        // else if (this.state.startTime) {
        //     return toISO8601(new Date(this.state.startTime));
        // }

        return undefined;
    }

    private updateState(s: Partial<MultiVideoControllerState>): void {
        this.state = {
            ...this.state,
            ...s,
        };

        this.emit('stateChanged', this.state);
    }
}
