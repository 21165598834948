import { Stack } from '@mui/system';
import * as React from 'react';
import { useFrame } from '../../../lib/frame-react';
import { LoggerToken } from '../../../lib/frame-tokens';
import { AppIconComponent, AppIconCustomAnnouncement, AppIconGenericNotification } from '../../AppIcons';
import { ActivityLogItemAnnouncementType } from '../activity_log_utils';
import { ActivityLogItemBase } from './ActivityLogItemBase';

function AnnouncementVerb({ announcement }: ActivityLogItemAnnouncementProps): React.ReactElement {
    const log = useFrame(LoggerToken);
    switch (announcement.category) {
        case 'custom_announcement':
            return <>{' played in vehicle'}</>;
        // istanbul ignore next
        default:
            // istanbul ignore next
            log.warn('[AnnouncementVerb] trying to apply verb to unknown medium', { announcement });
            // istanbul ignore next
            return <>{' notification sent'}</>;
    }
}

function getMedium(medium: ActivityLogItemAnnouncementType['category']): { Icon: AppIconComponent; name: string } {
    switch (medium) {
        case 'custom_announcement':
            return {
                Icon: AppIconCustomAnnouncement,
                name: 'Voice Alert',
            };
        // istanbul ignore next
        default:
            // istanbul ignore next
            return {
                Icon: AppIconGenericNotification,
                name: 'Unknown',
            };
    }
}

function AnnoucenementDescription({ announcement }: ActivityLogItemAnnouncementProps): React.ReactElement {
    switch (announcement.category) {
        case 'custom_announcement':
            return <>{`"${announcement.announcementMessage}"`}</>;
        // istanbul ignore next
        default:
            // istanbul ignore next
            return <>{'notification sent'}</>;
    }
}

type ActivityLogItemAnnouncementProps = {
    announcement: ActivityLogItemAnnouncementType;
};

export function ActivityLogItemAnnouncement({ announcement }: ActivityLogItemAnnouncementProps): React.ReactElement {
    const medium = getMedium(announcement.category);

    return (
        <ActivityLogItemBase
            timestamp={announcement.timestamp}
            description={
                <Stack>
                    <div>
                        <b>{medium.name}</b>
                        <AnnouncementVerb announcement={announcement} />
                    </div>
                    <div>{`sent by: ${announcement.userDisplayName}`}</div>
                </Stack>
            }
            Icon={medium.Icon}
            detail={<AnnoucenementDescription announcement={announcement} />}
        />
    );
}
