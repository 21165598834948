import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { appStyled } from '../../theme';
import { Card, Collapse, Slide } from '@mui/material';
import { NotificationItem } from './NotificationItem';
import { TransitionGroup } from 'react-transition-group';
import { AppNotification } from './notificationTypes';
import { selectNotifications } from '../../store/selectors';
import { notificationSlice } from '../../store/slices/notificationSlice';

const SnackbarPosition = appStyled('div')(({ theme }) => ({
    position: 'fixed',
    right: theme.spacing(1),
    top: parseInt(`${theme.mixins.toolbar.minHeight}`) + parseInt(theme.spacing(2)),
    zIndex: 1000,
}));

type SnackbarItemProps = {
    notification: AppNotification;
    show: boolean;
};

const SnackCard = appStyled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    zIndex: theme.zIndex.snackbar,
}));

export function SnackbarItem({ notification, show }: SnackbarItemProps): React.ReactElement {
    const dispatch = useAppDispatch();

    const dismiss = React.useCallback(() => {
        dispatch(notificationSlice.actions.dismissNotification(notification.id));
    }, [notification.id]);

    const ack = React.useCallback(
        () => dispatch(notificationSlice.actions.ackNotification(notification.id)),
        [notification.id],
    );

    return (
        <Collapse in={show} mountOnEnter unmountOnExit>
            <Slide direction={'left'} in={show}>
                <SnackCard>
                    <NotificationItem isSnackbar notification={notification} onDismiss={dismiss} onAck={ack} />
                </SnackCard>
            </Slide>
        </Collapse>
    );
}

export function NotificationSnackbar(): React.ReactElement {
    const notifications = useAppSelector(selectNotifications);

    return (
        <SnackbarPosition data-testid="notification-snackbar">
            <TransitionGroup>
                {notifications.map((n) => (
                    <SnackbarItem notification={n} key={n.id} show={!n.dismissed} />
                ))}
            </TransitionGroup>
        </SnackbarPosition>
    );
}
