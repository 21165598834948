import { createListenerMiddleware, ThunkDispatch, AnyAction, ListenerMiddlewareInstance } from '@reduxjs/toolkit';
import { Howl } from 'howler';
import { createPlugin, createToken } from '../lib/frame';
import { setRailsContext } from '../store/actions';
import { SoundEffectId } from '../types/stein';

type EffectName = SoundEffectId | string;

export type SFX = Record<EffectName, Howl>;
export type SFXListener = ListenerMiddlewareInstance<unknown, ThunkDispatch<unknown, unknown, AnyAction>, unknown>;

export const SFXToken = createToken<SFX>('SFXToken');
export const SFXListenerToken = createToken<SFXListener>('SFXListenerToken');

export const DEFAULT_SFX: SFX = {
    eventReceived: new Howl({
        volume: 0.5,
        src: [
            '/assets/browser_notifications/bamboo_bounce-a4e4992a9f141522be1be9586a3c546fed8d7dd0f5aae6490503a91fcd8f12b6.mp3',
        ],
    }),
};
export const SFXListenerPlugin = createPlugin<SFXListener>(({ resolve }) => {
    const effects = resolve(SFXToken);
    const listener = createListenerMiddleware();

    listener.startListening({
        actionCreator: setRailsContext,
        effect: async (action) => {
            action.payload.soundEffects.forEach((e) => {
                // istanbul ignore next
                if (e.fileUrl) {
                    effects[e.id] = new Howl({ src: [e.fileUrl], volume: 0.5 });
                }
            });
        },
    });

    return listener;
});
