// istanbul ignore file
import { useAuditTagProfile } from './AuditTagProfile';
import { getHotkeysForProfile } from './AuditTagConstants';

import { MultiVideoController } from '../../../../lib/multi-video/multi-video';
import { useHotkey } from '../../../HotkeyProvider';
import { useAuditTagCreateContext } from './AuditTagCreateProvider';

type AuditShowHotkeysProps = {
    video: MultiVideoController;
};

export function AuditShowHotkeys({ video }: AuditShowHotkeysProps): null {
    const { profile } = useAuditTagProfile();
    const { setTagToCreate } = useAuditTagCreateContext();

    useHotkey(
        '*',
        (e) => {
            if (e.ctrlKey || e.altKey || e.metaKey) {
                return;
            }
            if (profile) {
                const hotkeys = getHotkeysForProfile(profile);
                const category =
                    hotkeys[e.code.replace('Digit', '').replace('Numpad', '')] || hotkeys[e.key.toLowerCase()];

                if (category && video.currentTime) {
                    e.preventDefault();
                    setTagToCreate({
                        startedAt: video.currentTime,
                        category,
                        behavior: e.shiftKey ? 'minor_negative' : 'major_positive',
                        comment: '',
                    });
                }
            }
        },
        {
            description: 'Create the respective tag (shift for negative behavior)',
            keyDisplay: `[optional shift] + [hotkey]`,
            enabled: Boolean(profile),
        },
        [profile, setTagToCreate, video],
    );

    return null;
}
