// istanbul ignore file

import * as React from 'react';
import { DriverEvent } from '../../../types/stein';
import { CalloutSkeleton } from '../../material/CalloutBox';

import { useFrame } from '../../../lib/frame-react';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import LoadingButton from '@mui/lab/LoadingButton';

type DriverEventGodModeProps = {
    event: DriverEvent | undefined;
};

export function DriverEventGodMode({ event }: DriverEventGodModeProps): React.ReactElement {
    const { useUndiscardAndReprocessMutation } = useFrame(SteinInternalApiClientToken);

    const [reprocess, { isLoading: isReprocessing }] = useUndiscardAndReprocessMutation();

    if (!event) {
        return <CalloutSkeleton name="God Mode" height={100} />;
    }

    return (
        <LoadingButton
            fullWidth
            color={'error'}
            variant={'contained'}
            onClick={() => reprocess({ driverEventId: event.id })}
            loading={isReprocessing}
            disabled={!event.discardReason}
        >
            {'Undiscard and Reprocess'}
        </LoadingButton>
    );
}
