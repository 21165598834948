import * as React from 'react';

import { VehicleDevice } from '../../../types/stein';
import { CalloutBox, CalloutSkeleton } from '../../material/CalloutBox';
import { useFrame } from '../../../lib/frame-react';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import { ActivityLog } from '../../ActivityLog/ActivityLog';
import { groupAndSortActivity } from '../../ActivityLog/activity_log_utils';
import { TextField } from '@mui/material';
import { AppIconSend } from '../../AppIcons';
import { Box } from '@mui/system';
import LoadingButton from '@mui/lab/LoadingButton';

type VehicleDeviceActivityLogProps = {
    vehicle: VehicleDevice;
};

function VehicleDeviceActivityLogCore({ vehicle }: VehicleDeviceActivityLogProps): React.ReactElement {
    const { useGetActivityLogQuery, useAddActivityCommentMutation } = useFrame(SteinInternalApiClientToken);
    const [_addComment, { isLoading: isAddingComment }] = useAddActivityCommentMutation();
    const [comment, setComment] = React.useState('');

    async function addComment(): Promise<void> {
        try {
            await _addComment({
                vehicleSlug: vehicle.slug,
                activity: {
                    comment,
                },
            }).unwrap();
        } catch (e) {
            // noop
        }

        setComment('');
    }
    const { data, isLoading } = useGetActivityLogQuery({ type: 'vehicle_device', id: vehicle.id });

    if (isLoading) {
        // istanbul ignore next
        return <CalloutSkeleton name={'Activity Log'} />;
    }

    return (
        <CalloutBox name={'Activity Log'}>
            <Box display={'flex'}>
                <TextField
                    size="small"
                    fullWidth
                    sx={{ flex: 1, marginRight: 1 }}
                    id={'vehicle_status_comment'}
                    label={'Status Comment'}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />
                <LoadingButton onClick={addComment} loading={isAddingComment} size={'small'} variant={'outlined'}>
                    <AppIconSend />
                </LoadingButton>
            </Box>

            {data && <ActivityLog items={groupAndSortActivity(data)} />}
        </CalloutBox>
    );
}

export function VehicleDeviceActivityLog({ vehicle }: Partial<VehicleDeviceActivityLogProps>): React.ReactElement {
    // istanbul ignore next
    if (!vehicle) {
        // istanbul ignore next
        return <CalloutSkeleton name={'Activity Log'} />;
    }

    return <VehicleDeviceActivityLogCore vehicle={vehicle} />;
}
