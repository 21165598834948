import { toCamelCase } from '../lib/case-transforms';
import { RailsContext } from '../store/slices/shared-actions';
import { CustomRole } from '../types/stein';

// Define a type for the slice state
export type RawRailsContext = Omit<RailsContext, 'customRoles'> & {
    custom_roles: Array<Omit<CustomRole, 'abilities'> & { abilities: string }>;
};

export function processRailsContext(rc: RawRailsContext): RailsContext {
    return {
        ...toCamelCase(rc),
        customRoles: rc.custom_roles.map((cr) => ({
            ...toCamelCase(cr),
            abilities: JSON.parse(cr.abilities),
        })),
    } as RailsContext;
}
