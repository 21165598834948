import * as React from 'react';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import { useAppSelector } from '../../../hooks/hooks';
import { useActiveProject } from '../../../hooks/use-active-project';
import { useFrame } from '../../../lib/frame-react';
import { DataGrid, debugDataGridColumn, processDataGridCollums } from '../../DataGrid';
import { SettingsPage } from './components/SettingsShared';
import {
    GridRenderCellParams,
    DATA_GRID_PRO_PROPS_DEFAULT_VALUES,
    GridActionsCellItem,
    GridRowParams,
    GridRowClassNameParams,
    useGridApiRef,
    GridColDef,
} from '@mui/x-data-grid-pro';
import { MenuItem, TextField, Stack, CircularProgress } from '@mui/material';
import { CustomRoleId, CustomRole, UserId, CustomMembershipId, InviteId } from '../../../types/stein';
import { toTitleCase } from '../../../lib/case-transforms';
import { appStyled } from '../../../theme';
import { CalloutBox } from '../../material/CalloutBox';
import LoadingButton from '@mui/lab/LoadingButton';
import { AppIconRemoveItem, AppIconSend, AppIconSettings } from '../../AppIcons';
import { selectDebugEnabled } from '../../../store/selectors';
import { useHotkey } from '../../HotkeyProvider';

type Member = {
    displayName: string;
    customRoleId: CustomRoleId;
} & (
    | {
          id: CustomMembershipId;
          status: 'member';
          userId: UserId;
          hidden: boolean;
      }
    | {
          id: InviteId;
          status: 'invited';
      }
);

const RoleCell = React.memo(
    function RoleCell(member: Member): React.ReactElement {
        const project = useActiveProject();
        const { useGetCustomMembershipsQuery, useUpdateCustomMembershipMutation, useUpdateInviteMutation } =
            useFrame(SteinInternalApiClientToken);
        const { data } = useGetCustomMembershipsQuery({ projectSlug: project.slug });

        const [updateCustomMembership, { isLoading: isMemberLoading }] = useUpdateCustomMembershipMutation();
        const [updateInvite, { isLoading: isInviteLoading }] = useUpdateInviteMutation();
        const roles = data?.customRoles || /* istanbul ignore next */ [];

        return (
            <>
                <CustomTextField
                    select
                    value={member.customRoleId}
                    fullWidth
                    label={'edit role'}
                    size={'small'}
                    variant={'outlined'}
                    onChange={(e) => {
                        if (member.status === 'invited') {
                            updateInvite({
                                id: member.id,
                                customRoleId: parseInt(e.target.value) as CustomRoleId,
                                projectSlug: project.slug,
                            });
                        } else {
                            updateCustomMembership({
                                id: member.id,
                                customRoleId: parseInt(e.target.value) as CustomRoleId,
                                projectSlug: project.slug,
                            });
                        }
                    }}
                    inputProps={{
                        fontSize: 2,
                    }}
                >
                    {roles.map((r) => (
                        <MenuItem key={r.id} value={r.id}>
                            {toTitleCase(r.name)}
                        </MenuItem>
                    ))}
                </CustomTextField>

                {isMemberLoading || isInviteLoading ? <CircularProgress size={14} /> : null}
            </>
        );
    },
    (prev, next) => prev.id === next.id && prev.status === next.status && prev.customRoleId === next.customRoleId,
);

function renderRole({ row }: GridRenderCellParams): React.ReactElement {
    return <RoleCell {...row} />;
}

const CustomTextField = appStyled(TextField)({
    '& .MuiFormLabel-root': {
        display: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        display: 'none',
    },
    '& .MuiInputBase-input': {
        border: 0,
        position: 'relative',
        backgroundColor: 'inherit',
        padding: '1px 5px 1px 12px',
        '&:focus': { backgroundColor: 'inherit', border: 0, boxShadow: 0 },
    },
});

function useMembersDataGridColumns(): [GridColDef<Member>[], Member | null] {
    const project = useActiveProject();
    const debugEnabled = useAppSelector(selectDebugEnabled);
    const { useGetCustomMembershipsQuery } = useFrame(SteinInternalApiClientToken);
    const { data } = useGetCustomMembershipsQuery({ projectSlug: project.slug });
    const { useCancelInviteMutation, useRemoveCustomMembershipMutation } = useFrame(SteinInternalApiClientToken);
    const [cancelInvite] = useCancelInviteMutation();
    const [removeCustomMembership] = useRemoveCustomMembershipMutation();

    const [memberToDelete, setMemberToDelete] = React.useState<Member | null>(null);

    const roles = data?.customRoles || [];

    const roleMap = roles.reduce(
        (acc, r) => ({
            ...acc,
            [r.id]: toTitleCase(r.name),
        }),
        {} as Record<CustomRoleId, string>,
    );

    return React.useMemo(
        () => [
            processDataGridCollums<Member>([
                {
                    field: 'displayName',
                    headerName: 'Name',
                    flex: 2,
                    sortable: true,
                },
                {
                    field: 'customRoleId',
                    headerName: 'Role',
                    flex: 1,
                    sortable: true,
                    valueOptions: roles.map((r) => ({ value: r.id, label: roleMap[r.id] })),
                    renderCell: renderRole,
                    valueFormatter: (value) => {
                        return roleMap[value];
                    },
                    type: 'singleSelect',
                },
                debugDataGridColumn(
                    {
                        field: 'hidden',
                        headerName: 'Hidden',
                        flex: 0,
                        sortable: true,
                        type: 'boolean',
                    },
                    debugEnabled,
                ),
                {
                    field: 'status',
                    headerName: 'Status',
                    flex: 1,
                    sortable: true,
                    valueFormatter: (value) => toTitleCase(value),
                    valueOptions: ['invited', 'member'],
                    type: 'singleSelect',
                },
                {
                    field: 'id',
                    headerName: '',
                    type: 'actions',
                    flex: 0,
                    minWidth: 10,
                    sortable: false,
                    renderHeader: () => <AppIconSettings />,
                    getActions: ({ row }: GridRowParams<Member>) => {
                        if (row.status === 'member') {
                            return [
                                <GridActionsCellItem
                                    key={'remove-member'}
                                    icon={<AppIconRemoveItem />}
                                    onClick={() => {
                                        setMemberToDelete(row);
                                        removeCustomMembership({ membershipId: row.id, projectSlug: project.slug });
                                    }}
                                    label="Remove Member"
                                    showInMenu
                                />,
                            ];
                        }

                        return [
                            <GridActionsCellItem
                                key={'remove-invite'}
                                icon={<AppIconRemoveItem />}
                                onClick={() => {
                                    setMemberToDelete(row);
                                    cancelInvite({ inviteId: row.id, projectSlug: project.slug });
                                }}
                                label="Cancel Invite"
                                showInMenu
                            />,
                        ];
                    },
                    filterable: false,
                },
            ]),
            memberToDelete,
        ],
        [memberToDelete, setMemberToDelete, cancelInvite, removeCustomMembership, roles],
    );
}

const { rowHeight, columnHeaderHeight } = DATA_GRID_PRO_PROPS_DEFAULT_VALUES;

export function ProjectMembers(): React.ReactElement {
    const project = useActiveProject();
    const { useGetCustomMembershipsQuery } = useFrame(SteinInternalApiClientToken);
    const { data, isLoading } = useGetCustomMembershipsQuery({ projectSlug: project.slug });

    const [columns, memberToDelete] = useMembersDataGridColumns();

    const gridRef = useGridApiRef();

    useHotkey(
        '⌘+f, ctrl+f',
        // istanbul ignore next
        () => {
            // istanbul ignore next
            if (gridRef.current) {
                // istanbul ignore next
                gridRef.current.showFilterPanel('displayName');
            }
        },
        {
            description: 'Show filter options for the member name column',
        },
        [gridRef],
    );

    const users: Member[] = data?.customMemberships
        ? data?.customMemberships.map((m) => ({
              id: m.id,
              displayName: m.user.displayName,
              userId: m.user.id,
              customRoleId: m.customRoleId,
              hidden: m.hidden,
              status: 'member',
          }))
        : [];

    const invites: Member[] = data?.invites
        ? data.invites
              .filter((i) => i)
              .map((i) => ({
                  id: i.id,
                  displayName: i.email,
                  customRoleId: i.customRoleId,
                  status: 'invited',
              }))
        : [];

    const members = users.concat(invites);

    const roles = data?.customRoles || [];

    return (
        <SettingsPage title={`${project.name} Members`}>
            <Stack spacing={2}>
                <div style={{ height: columnHeaderHeight + rowHeight * 10 }}>
                    <DataGrid
                        apiRef={gridRef}
                        rows={members}
                        columns={columns}
                        disableColumnFilter={false}
                        disableColumnMenu={false}
                        isLoading={isLoading}
                        getRowClassName={({ row }: GridRowClassNameParams<Member>) =>
                            row.status === memberToDelete?.status && row.id === memberToDelete?.id ? 'error' : ''
                        }
                        localeText={{
                            noRowsLabel: 'No Members',
                        }}
                    />
                </div>
                <NewInviteForm roles={roles} />
            </Stack>
        </SettingsPage>
    );
}

function NewInviteForm({ roles }: { roles: CustomRole[] }): React.ReactElement {
    const [email, setEmail] = React.useState<string>('');
    const [role, setRole] = React.useState<CustomRoleId | ''>('');
    const project = useActiveProject();

    const { useCreateInviteMutation } = useFrame(SteinInternalApiClientToken);
    const [createInvite, { isLoading }] = useCreateInviteMutation();

    async function onSubmit(): Promise<void> {
        // istanbul ignore next
        if (role) {
            try {
                await createInvite({ projectSlug: project.slug, email, customRoleId: role }).unwrap();
                setEmail('');
                setRole('');
            } catch {}
        }
    }

    return (
        <CalloutBox name="Send Invitation">
            <p>{'Invite a new member to your team'}</p>
            <Stack direction={'row'} spacing={2}>
                <TextField
                    label={'Email'}
                    type={'email'}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{ flex: 2 }}
                />

                <TextField
                    sx={{ flex: 1 }}
                    select
                    label={'Role'}
                    value={role}
                    onChange={(e) => setRole(parseInt(e.target.value) as CustomRoleId)}
                >
                    {roles.map((r) => (
                        <MenuItem key={r.id} value={r.id}>
                            {toTitleCase(r.name)}
                        </MenuItem>
                    ))}
                </TextField>

                <LoadingButton
                    variant={'contained'}
                    startIcon={<AppIconSend />}
                    disabled={!email || !role}
                    onClick={onSubmit}
                    loading={isLoading}
                >
                    {'Invite'}
                </LoadingButton>
            </Stack>
        </CalloutBox>
    );
}
