import * as React from 'react';

import { DateRange, DateRangePicker, SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { DateTimePicker, LocalizationProvider, PickersShortcutsItem } from '@mui/x-date-pickers';
import { FilterBarTextInput } from './FilterBarBaseTypes';
import { useFilterDateRange, useFilterDateTime } from '../../hooks/use-filters';
import { subDays, subHours } from 'date-fns';

// istanbul ignore next
function getNow(): Date {
    return new Date();
}

// istanbul ignore next
function getOneHourAgo(): Date {
    return subHours(new Date(), 1);
}

const dateTimeShortcuts: PickersShortcutsItem<Date>[] = [
    {
        label: 'Now',
        getValue: getNow,
    },
    {
        label: 'One Hour Ago',
        getValue: getOneHourAgo,
    },
];

export function FilterDateTimePicker(): React.ReactElement {
    const { date, setDateTime, isDefault } = useFilterDateTime();

    // istanbul ignore next
    function onDateTimeChange(d: Date | null): void {
        setDateTime(d ? d : new Date());
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} localeText={{ start: 'Start Date', end: 'End Date' }}>
            <DateTimePicker
                value={date}
                sx={{
                    flexGrow: 1,
                }}
                label={'Date ± 1 hour'}
                slots={{ textField: FilterBarTextInput }}
                slotProps={{
                    textField: {
                        size: 'small',
                        // @ts-expect-error highlight is not real
                        highlight: !isDefault,
                    },
                    shortcuts: {
                        items: dateTimeShortcuts,
                    },
                }}
                onChange={onDateTimeChange}
            />
        </LocalizationProvider>
    );
}

// istanbul ignore next
function last24Hours(): [Date, Date] {
    const now = new Date();
    return [subHours(now, 24), now];
}

// istanbul ignore next
function lastWeek(): [Date, Date] {
    const now = new Date();
    return [subDays(now, 7), now];
}

// istanbul ignore next
function resetRange(): [null, null] {
    return [null, null];
}

const dateRangeShortCuts: PickersShortcutsItem<DateRange<Date>>[] = [
    {
        label: 'Last 24 hours',
        getValue: last24Hours,
    },
    {
        label: 'Last Week',
        getValue: lastWeek,
    },
    { label: 'Reset', getValue: resetRange },
];

export function FilterDateRangePicker(): React.ReactElement {
    const { selected, setSelected, isDefault } = useFilterDateRange();

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} localeText={{ start: 'Start Date', end: 'End Date' }}>
            <DateRangePicker
                value={selected}
                sx={{
                    flexGrow: 1,
                }}
                label={'Date Range'}
                slots={{ field: SingleInputDateRangeField, textField: FilterBarTextInput }}
                slotProps={{
                    textField: {
                        size: 'small',
                        // @ts-expect-error highlight is not real
                        highlight: !isDefault,
                    },
                    shortcuts: {
                        items: dateRangeShortCuts,
                    },
                }}
                onChange={setSelected}
            />
        </LocalizationProvider>
    );
}
