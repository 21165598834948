// istanbul ignore file
import * as React from 'react';
import { IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';

import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { useAppTheme } from '../../../theme';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { setActivePreset } from '../../../store/actions';
import { useFilters } from '../../../hooks/use-filters';
import { AppIconDownload, AppIconMenu, AppIconResetSelection } from '../../AppIcons';
import { DriverEventsRequest } from '../../../types/stein-internal-api';
import { makeSearch } from '../../../clients/stein-internal-api';

type DriverEventSettingsProps = {
    isDefaultView: boolean;
    req: DriverEventsRequest;
};

const FILTER_CONTEXT_NAME = 'driverEvents';

export function DriverEventSettings({ isDefaultView, req }: DriverEventSettingsProps): React.ReactElement | null {
    const theme = useAppTheme();
    const { updateFilters, setFilters } = useFilters();
    const { presets, activePresetIndex } = useAppSelector((s) => s.filterConfig[FILTER_CONTEXT_NAME]);
    const activePreset = presets ? presets[activePresetIndex] : null;

    const dispatch = useAppDispatch();

    // istanbul ignore next
    function downloadCsv(): void {
        window.open(`/api/internal/driver_events.csv?${makeSearch(req)}`);
    }

    return (
        <PopupState variant="popover">
            {(popupState) => (
                <div>
                    <IconButton
                        {...bindTrigger(popupState)}
                        style={{ color: activePresetIndex ? theme.palette.primary.main : theme.palette.common.white }}
                        size={'small'}
                    >
                        <AppIconMenu />
                    </IconButton>

                    <Menu
                        {...bindMenu(popupState)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <MenuItem onClick={downloadCsv}>
                            <ListItemIcon>
                                <AppIconDownload size={'14px'} />
                            </ListItemIcon>
                            Export CSV
                        </MenuItem>
                        <div style={{ marginLeft: theme.spacing(2) }}>
                            <Typography variant="caption" display="block" gutterBottom>
                                {'Filter presets'}
                            </Typography>
                        </div>
                        {presets.map((p, i) => (
                            <MenuItem
                                selected={i === activePresetIndex}
                                key={p.name}
                                onClick={() => {
                                    dispatch(
                                        setActivePreset({
                                            filterContextName: FILTER_CONTEXT_NAME,
                                            presetIndex: i,
                                        }),
                                    );
                                    updateFilters(p.filters);
                                    popupState.close();
                                }}
                            >
                                {p.name}
                            </MenuItem>
                        ))}
                        {!isDefaultView && activePreset ? (
                            <MenuItem onClick={() => setFilters(activePreset.filters)}>
                                <ListItemIcon>
                                    <AppIconResetSelection size={'14px'} />
                                </ListItemIcon>
                                Reset Filters
                            </MenuItem>
                        ) : null}
                    </Menu>
                </div>
            )}
        </PopupState>
    );
}
