import { createSlice } from '@reduxjs/toolkit';
import { DateStrISO8601 } from '../../types/stein';
import { toISO8601 } from '../../utils/datetime-utils';
import { changeLocation, setRailsContext } from './shared-actions';

type Navigation = {
    debugPermitted: boolean;
    debugEnabled: boolean;
    debugUseAbilityOverrides: boolean;
    pageLoadedAt: DateStrISO8601;
    initialized: boolean;
    locationChangeToggle: boolean;
};

const DEBUG_ENABLED_KEY = 'debugEnabled';

// Define the initial state using that type
const initialState: Navigation = {
    debugPermitted: false,
    debugEnabled: localStorage.getItem(DEBUG_ENABLED_KEY) === 'true',
    initialized: false,
    debugUseAbilityOverrides: false,
    pageLoadedAt: toISO8601(new Date()),
    locationChangeToggle: false,
};

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        toggleDebugEnabled(s) {
            s.debugEnabled = s.debugPermitted && !s.debugEnabled;
            localStorage.setItem(DEBUG_ENABLED_KEY, `${s.debugEnabled}`);
        },
        toggleDebugModal(s) {
            s.debugUseAbilityOverrides = !s.debugUseAbilityOverrides;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setRailsContext, (s, a) => {
            s.debugPermitted = a.payload.debugEnabled;
            s.initialized = true;
        });
        builder.addCase(changeLocation, (s) => {
            s.locationChangeToggle = !s.locationChangeToggle;
        });
    },
});
