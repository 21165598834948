import { useLocation } from 'react-router-dom';
import { useActiveProjectOptional } from '../../hooks/use-active-project';
import { useConfigItems } from '../../hooks/use-config-items';
import { Ability } from '../../types/stein';
import { ConfigItemFeatureKey } from '../../types/stein.config_items';
import {
    urlDiagnostics,
    urlGeofences,
    urlDriverEventList,
    urlSettings,
    urlVehicleDeviceList,
    URL_SUPPORT,
    URL_GOD_MODE,
    urlAuditList,
    URL_GOD_MODE_ANALYTICS,
    urlAppInstallList,
    urlConfigItemsList,
    AppInternalUrl,
    urlMap,
    urlFacilitiesList,
    urlGeoSearch,
    urlMonitor,
    URL_SANDBOX,
} from '../../utils/internal-url-utils';
import {
    AppIconVehicle,
    AppIconComponent,
    AppIconDiagnostics,
    AppIconDocumentation,
    AppIconDriverGuide,
    AppIconGeofences,
    AppIconGodMode,
    AppIconLogs,
    AppIconEvents,
    AppIconSettings,
    AppIconSupport,
    AppIconMap,
    AppIconAudit,
    AppIconResources,
    AppIconMobile,
    AppIconAnalytics,
    AppIconUser,
    AppIconNotification,
    AppIconProjectNotification,
    AppIconRoles,
    AppIconDevice,
    AppIconProject,
    AppIconFacilities,
    AppIconRegions,
    AppIconGlobe,
    AppIconLiveVideo,
    AppIconSandbox,
} from '../AppIcons';

export type NavigationLink = {
    type: 'link';
    text: string;
    Icon: AppIconComponent;
    subItems?: NavigationItem[];

    external?: boolean;
    url: AppInternalUrl;

    debugOnly?: boolean;
    auth?: Ability;
    feature?: ConfigItemFeatureKey;
};

type NavigationSection = {
    type: 'section';
    text: string;
    subItems?: NavigationItem[];
};

export type NavigationItem = NavigationLink | NavigationSection;

export function useNavigation(): NavigationItem[] {
    const project = useActiveProjectOptional();
    const getConfigItem = useConfigItems();
    const resourcesURL = getConfigItem('resources_url');
    const projectSlug = project?.slug;

    const items: NavigationItem[] = [];

    if (project && projectSlug) {
        items.push(
            {
                type: 'section',
                text: project.name,
            },
            {
                type: 'link',
                text: 'Events',
                Icon: AppIconEvents,
                auth: {
                    subject: 'DriverEvent',
                    action: 'list',
                },
                url: urlDriverEventList(projectSlug),
            },
            {
                type: 'link',
                text: 'Vehicles',
                Icon: AppIconVehicle,
                auth: {
                    subject: 'VehicleDevice',
                    action: 'list',
                },
                url: urlVehicleDeviceList(projectSlug),
            },
            {
                type: 'link',
                text: 'Devices',
                Icon: AppIconMobile,
                auth: {
                    subject: 'AppInstall',
                    action: 'list',
                },
                url: urlAppInstallList(projectSlug),
                debugOnly: true,
            },
            {
                type: 'link',
                text: 'Audits',
                Icon: AppIconAudit,
                auth: {
                    subject: 'Audit',
                    action: 'list',
                },
                url: urlAuditList(projectSlug),
                feature: 'audits_tab_enabled',
                debugOnly: true,
            },
            {
                type: 'link',
                text: 'Map',
                Icon: AppIconMap,
                auth: {
                    subject: 'VehicleDevice',
                    action: 'show_map',
                },
                url: urlMap(projectSlug),
            },
            {
                type: 'link',
                text: 'Geo Search',
                Icon: AppIconGlobe,
                auth: {
                    subject: 'VehicleDevice',
                    action: 'geosearch',
                },
                url: urlGeoSearch(projectSlug),
            },
            {
                type: 'link',
                text: 'Live Video',
                Icon: AppIconLiveVideo,
                auth: {
                    subject: 'VehicleDevice',
                    action: 'start_video_call',
                },
                url: urlMonitor(projectSlug),
            },
            {
                type: 'link',
                text: 'Settings',
                Icon: AppIconSettings,
                url: urlSettings(projectSlug),
                subItems: [
                    {
                        type: 'section',
                        text: 'User',
                        subItems: [
                            {
                                type: 'link',
                                text: 'Profile',
                                url: urlSettings(projectSlug, 'my_profile'),
                                Icon: AppIconUser,
                            },
                            {
                                type: 'link',
                                text: 'My Alerts',
                                url: urlSettings(projectSlug, 'my_notifications'),
                                Icon: AppIconNotification,
                            },
                        ],
                    },
                    {
                        type: 'section',
                        text: project.name,
                        subItems: [
                            {
                                type: 'link',
                                text: 'Project',
                                url: urlSettings(projectSlug, 'project'),
                                Icon: AppIconProject,
                            },
                            {
                                type: 'link',
                                text: 'Alerts',
                                url: urlSettings(projectSlug, 'notifications'),
                                Icon: AppIconProjectNotification,
                            },
                            {
                                type: 'link',
                                text: 'Members',
                                url: urlSettings(projectSlug, 'memberships'),
                                Icon: AppIconUser,
                            },
                            {
                                type: 'link',
                                text: 'Roles',
                                url: urlSettings(projectSlug, 'roles'),
                                Icon: AppIconRoles,
                            },
                            {
                                type: 'link',
                                text: 'Devices',
                                url: urlSettings(projectSlug, 'device'),
                                Icon: AppIconDevice,
                            },
                            {
                                type: 'link',
                                text: 'Facilities',
                                url: urlFacilitiesList(projectSlug),
                                Icon: AppIconFacilities,
                            },
                            {
                                type: 'link',
                                text: 'Regions',
                                url: urlSettings(projectSlug, 'regions'),
                                Icon: AppIconRegions,
                            },
                            {
                                type: 'link',
                                text: 'Advanced',
                                url: urlSettings(projectSlug, 'advanced'),
                                Icon: AppIconSettings,
                            },
                        ],
                    },
                ],
            },
        );

        if (resourcesURL) {
            items.push({
                type: 'link',
                text: 'Resources',
                Icon: AppIconResources,
                url: resourcesURL.stringValue as AppInternalUrl,
                external: true,
            });
        }

        items.push(
            {
                type: 'link',
                text: 'Sandbox',
                Icon: AppIconSandbox,
                debugOnly: true,
                url: URL_SANDBOX,
            },
            {
                type: 'link',
                text: 'Config Items',
                Icon: AppIconLogs,
                debugOnly: true,
                url: urlConfigItemsList(projectSlug),
            },
            {
                type: 'link',
                text: 'Diagnostics',
                Icon: AppIconDiagnostics,
                debugOnly: true,
                url: urlDiagnostics(projectSlug),
            },
            {
                type: 'link',
                text: 'Facilities',
                Icon: AppIconGeofences,
                debugOnly: true,
                url: urlGeofences(projectSlug),
            },
            {
                type: 'link',
                text: 'God Mode',
                Icon: AppIconGodMode,
                debugOnly: true,
                url: URL_GOD_MODE,
            },
            {
                type: 'link',
                text: 'Analytics',
                Icon: AppIconAnalytics,
                debugOnly: true,
                url: URL_GOD_MODE_ANALYTICS,
            },
        );
    }

    items.push(
        {
            type: 'section',
            text: 'Help',
        },
        {
            type: 'link',
            text: 'Support',
            Icon: AppIconSupport,
            url: URL_SUPPORT,
        },
        {
            type: 'link',
            text: 'Documentation',
            Icon: AppIconDocumentation,
            url: 'https://www.notion.so/observant/a40908e282d5485a8209792e87daa1c3' as AppInternalUrl,
            external: true,
        },
    );

    if (projectSlug) {
        items.push({
            type: 'link',
            text: 'Driver Guide',
            Icon: AppIconDriverGuide,
            url: 'https://observant.notion.site/Driver-guide-c8c0bcf8102e4743ac4d2271c9de7d77' as AppInternalUrl,
            external: true,
        });
    }

    return items;
}

export function useSubNavigation(): NavigationItem[] | undefined {
    const nav = useNavigation();
    const location = useLocation();
    const mainItem = nav.find((n) => {
        if (n.type === 'link') {
            return location.pathname.startsWith(n.url);
        }
    });

    return mainItem?.subItems;
}
