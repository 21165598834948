import * as React from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { SteinInternalApiClientToken } from '../clients/stein-internal-api';
import { useAppSelector } from '../hooks/hooks';
import { useFrame } from '../lib/frame-react';
import { ArchiveRequest } from '../types/stein-internal-api';
import { AppIconZipArchive } from './AppIcons';
import { selectNotificationById } from '../store/selectors';
import { AppNotificationId } from './Notifications/notificationTypes';

type ExportArchiveButtonProps = ArchiveRequest;

export function ExportArchiveButton({ ...req }: ExportArchiveButtonProps): React.ReactElement {
    const { useLazyStartArchiveQuery } = useFrame(SteinInternalApiClientToken);
    const [startArchive, { data: archive }] = useLazyStartArchiveQuery();

    const notification = useAppSelector((s) => selectNotificationById(s, archive?.id as AppNotificationId | undefined));

    // This function
    function onStart(): void {
        startArchive(req);
    }

    const isPending = Boolean(notification?.type === 'archive' && notification.status === 'pending');

    return (
        <LoadingButton
            onClick={onStart}
            loading={isPending}
            variant="outlined"
            size="small"
            endIcon={<AppIconZipArchive />}
        >
            {Boolean(isPending) ? 'Preparing Export...' : 'Export Zip Archive'}
        </LoadingButton>
    );
}
