import * as React from 'react';

import { Checkbox, FormControlLabel, FormGroup, FormLabel, Tooltip } from '@mui/material';
import { Abilities, AbilityConfigItem } from '../../types/stein';
import { AppIconInfo } from '../AppIcons';
import { OverrideAbilityArgs } from '../../store/slices/abilitiesSlice';
import { useAppSelector } from '../../hooks/hooks';
import { useAppTheme } from '../../theme';
import { hasAbility } from '../../utils/has-ability';

type AbilitySelectionProps = {
    abilities: Abilities;
    onChange: (a: OverrideAbilityArgs) => unknown;
};

export function AbilitySelection({ abilities, onChange }: AbilitySelectionProps): React.ReactElement {
    const configAbilities = useAppSelector((s) => s.abilities.config);
    return (
        <>
            {configAbilities.map((a) => (
                <AbilitySelectionItem item={a} onChange={onChange} key={a.subject} abilities={abilities} />
            ))}
        </>
    );
}

type AbilitySelectionItemProps = {
    abilities: Abilities;
    item: AbilityConfigItem;
    onChange: (a: OverrideAbilityArgs) => unknown;
};

function AbilitySelectionItem({ item, abilities, onChange }: AbilitySelectionItemProps): React.ReactElement {
    const theme = useAppTheme();
    const actions = item.actions ? (
        <FormGroup row>
            {item.actions.map((a) => {
                const enabled = hasAbility(abilities, a, item.subject);
                return (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={enabled}
                                onChange={onChange.bind(null, {
                                    subject: item.subject,
                                    action: a,
                                    enabled: !enabled,
                                })}
                            />
                        }
                        label={a}
                        key={a}
                    />
                );
            })}
        </FormGroup>
    ) : null;

    const customActions = item.customActions ? (
        <>
            <FormLabel sx={{ paddingRight: theme.spacing(1) }}>{'Specific Actions'}</FormLabel>
            {item.customActions.map((ca) => {
                const enabled = hasAbility(abilities, ca.symbol, item.subject);
                return (
                    <div key={ca.symbol}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={enabled}
                                    onChange={onChange.bind(null, {
                                        subject: item.subject,
                                        action: ca.symbol,
                                        enabled: !enabled,
                                    })}
                                />
                            }
                            label={
                                <Tooltip title={ca.description} placement="bottom-start" arrow>
                                    <div>{ca.name}</div>
                                </Tooltip>
                            }
                        />
                    </div>
                );
            })}
        </>
    ) : null;

    return (
        <div>
            <Tooltip title={item.description} placement="right">
                <h4 style={{ display: 'inline-flex', alignItems: 'center', marginBottom: theme.spacing(1) }}>
                    {item.subject} <AppIconInfo />
                </h4>
            </Tooltip>
            <div style={{ paddingLeft: theme.spacing(2) }}>
                {actions}
                {customActions}
            </div>
        </div>
    );
}
