import { OutlinedInputProps } from '@mui/material';
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { snakeCase } from 'lodash';
import * as React from 'react';
import { TooltipOptional } from './TooltipOptional';
import { InputWithActionProps, useInputAction } from './useInputAction';

type TextInputWithActionProps = Omit<OutlinedInputProps, 'sx' | 'value'> &
    InputWithActionProps & {
        tooltip?: React.ReactNode;
    };

export function TextInputWithAction({
    value,
    label,
    loading,
    onAction,
    Icon,
    tooltip,
    ...props
}: TextInputWithActionProps): React.ReactElement {
    const { handleKeyDown, handleValueChange, displayValue, endAdornment } = useInputAction({
        value,
        loading,
        onAction,
        Icon,
    });

    const labelId = `actionable-text-${snakeCase(label)}`;

    const content = (
        <FormControl fullWidth>
            <InputLabel htmlFor={labelId}>{label}</InputLabel>
            <OutlinedInput
                onKeyDown={handleKeyDown}
                id={labelId}
                value={displayValue}
                onChange={(e) => handleValueChange(e.target.value)}
                label={label}
                {...props}
                endAdornment={endAdornment}
            />
        </FormControl>
    );

    return (
        <TooltipOptional title={label} description={tooltip}>
            {content}
        </TooltipOptional>
    );
}
