import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectProps,
    Stack,
    Typography,
} from '@mui/material';
import { useFrame } from '../../../lib/frame-react';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import LoadingButton from '@mui/lab/LoadingButton';
import { VehicleDeviceSlug } from '../../../types/stein';
import { addMinutes } from 'date-fns';
import { useActiveProjectSlug } from '../../../hooks/use-active-project';
import { format } from 'date-fns';
import { getTimezone, toISO8601, toTimestampMs } from '../../../utils/datetime-utils';

export function CreateVideoUploadRequest({
    vehicleDeviceSlug,
    date,
}: {
    vehicleDeviceSlug: VehicleDeviceSlug;
    date: Date;
}): React.ReactElement {
    const projectSlug = useActiveProjectSlug();
    const [show, setShow] = React.useState(false);
    const { useCreateVideoUploadRequestMutation } = useFrame(SteinInternalApiClientToken);
    const [createVideoUploadRequest, { isLoading }] = useCreateVideoUploadRequestMutation();
    const [startTime, setStartTime] = React.useState(date);
    const [endTime, setEndTime] = React.useState(addMinutes(date, TIME_INTERVAL_STEP_MINUTES));
    React.useEffect(() => {
        setStartTime(date);
        setEndTime(addMinutes(date, TIME_INTERVAL_STEP_MINUTES));
    }, [date]);

    async function handleCreate(): Promise<void> {
        await createVideoUploadRequest({
            projectSlug,
            videoUploadRequest: {
                vehicleDeviceSlug,
                startTime: toISO8601(startTime),
                endTime: toISO8601(endTime),
            },
        }).unwrap();
        setShow(false);
    }

    return (
        <>
            <Dialog open={show} onClose={/* istanbul ignore next */ () => setShow(false)}>
                <DialogTitle>Fetch Video</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <div>{`Fetch all video segments recorded in this vehicle from:`}</div>
                        <FormControl fullWidth size="small">
                            <InputLabel id={'start-time-select'}>Start Time</InputLabel>
                            <TimeSelect
                                labelId="start-time-select"
                                label={'Start Time'}
                                date={startTime}
                                onDateChanged={setStartTime}
                            />
                        </FormControl>
                        <FormControl fullWidth size="small">
                            <InputLabel id={'end-time-select'}>End Time</InputLabel>
                            <TimeSelect
                                labelId="end-time-select"
                                label={'End Time'}
                                date={endTime}
                                onDateChanged={setEndTime}
                                minTime={startTime}
                            />
                        </FormControl>
                        <Typography variant={'caption'}>{`Dates are shown in ${getTimezone()} time.`}</Typography>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShow(false)}>Cancel</Button>
                    <LoadingButton loading={isLoading} variant={'contained'} onClick={handleCreate}>
                        Request
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Button onClick={() => setShow(true)} size={'small'} variant={'contained'}>
                {'Fetch video'}
            </Button>
        </>
    );
}

const TIME_INTERVAL_STEP_MINUTES = 30;
const TIME_INTERVALS: number[] = [];
for (let minutes = 0; minutes <= 24 * 60; minutes += TIME_INTERVAL_STEP_MINUTES) {
    TIME_INTERVALS.push(minutes);
}

function TimeSelect({
    date,
    onDateChanged,
    minTime,
    ...props
}: {
    date: Date;
    minTime?: Date;
    onDateChanged: (d: Date) => void;
} & SelectProps<number>): React.ReactElement {
    const minTimestamp = minTime ? toTimestampMs(minTime) : 0;
    const intervals = React.useMemo(
        () => TIME_INTERVALS.map((minutes) => toTimestampMs(addMinutes(date, minutes))),
        [date],
    );
    return (
        <Select {...props} value={toTimestampMs(date)} onChange={(e) => onDateChanged(new Date(e.target.value))}>
            {intervals.map((ts) => (
                <MenuItem value={ts} key={ts} disabled={ts < minTimestamp}>
                    {format(ts, 'LLL do, hh:mm bbb')}
                </MenuItem>
            ))}
        </Select>
    );
}
