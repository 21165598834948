import { Typography } from '@mui/material';
import * as React from 'react';
import { DriverEvent } from '../../../types/stein';
import { CalloutBox, CalloutSkeleton } from '../../material/CalloutBox';
import { format } from 'date-fns-tz';
import { formatDistanceToNow } from 'date-fns';

type DriverEventTimestampProps = {
    event: DriverEvent | undefined;
};

export function DriverEventTimestamp({ event }: DriverEventTimestampProps): React.ReactElement {
    if (!event?.processedAt) {
        return <CalloutSkeleton name="Timestamp" />;
    }

    const processedAt = new Date(event.processedAt);

    return (
        <CalloutBox name={'Timestamp'}>
            <div>{format(processedAt, 'EE, MMM do, hh:mm:ss aa zzz')}</div>
            <Typography variant={'caption'}>{formatDistanceToNow(processedAt, { addSuffix: true })}</Typography>
        </CalloutBox>
    );
}
