/* istanbul ignore file */

import * as React from 'react';
import { formatDistance } from 'date-fns';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import { useAppNavigate, useAppSelector } from '../../../hooks/hooks';
import { useActiveProjectSlug } from '../../../hooks/use-active-project';
import { useFrame } from '../../../lib/frame-react';
import { selectVehiclesByProjectSlug } from '../../../store/selectors';
import { Audit, AuditSlug, DateStrUTCTimestamp, VehicleDeviceSlug } from '../../../types/stein';
import { formatLocalizedDateTime } from '../../../utils/datetime-utils';
import { filterOptionsFromVehicles } from '../../../utils/filter-utils';
import { VehicleChip } from '../../AppChip';
import { getAuditStatus } from '../../Audit/audit-utils';
import { DataGrid, processDataGridCollums } from '../../DataGrid';
import { FilterBar } from '../../FilterBar';
import { FilterMultiselect } from '../../FilterBar/FilterMultiselect';
import { FilterDateRangePicker } from '../../FilterBar/FilterDateRangePicker';
import { AuditsRequest } from '../../../types/stein-internal-api';
import { shouldIncludeFilter, useFilters } from '../../../hooks/use-filters';
import { AuditBulkActions } from './AuditBulkActions';
import { toTitleCase } from '../../../lib/case-transforms';
import { urlAudit } from '../../../utils/internal-url-utils';
import { useGlobalSearch } from '../../GlobalSearch';
import { GridRowId } from '@mui/x-data-grid-pro';

const AUDIT_COLUMNS = processDataGridCollums<Audit>([
    {
        field: 'vehicleDevice',
        headerName: 'Vehicle',
        renderCell: ({ value }) => <VehicleChip vehicleSlugOrId={value.slug} size={'small'} />,
        valueFormatter: (value) => {
            // @ts-expect-error for some reason value is typed as never
            return value.name;
        },
        flex: 2,
        sortable: false,
    },
    {
        field: 'status',
        headerName: 'Status',
        renderCell: ({ row }) => <>{toTitleCase(getAuditStatus(row, []))}</>,
        flex: 2,
        sortable: false,
    },
    {
        field: 'duration',
        headerName: 'Duration',
        renderCell: ({ row }) => <>{formatDistance(new Date(row.startedAt || 0), new Date(row.endedAt || 0))}</>,
        flex: 2,
        sortable: false,
    },
    {
        field: 'startedAt',
        headerName: 'Timestamp',
        valueFormatter: (value) => formatLocalizedDateTime(new Date(value)),
        flex: 2,
        sortable: false,
    },
]);

function useRequest(): AuditsRequest {
    const projectSlug = useActiveProjectSlug();
    const { filters } = useFilters();
    const { vehicles, dateRange } = filters;

    return React.useMemo(() => {
        const req: AuditsRequest = {
            projectSlug,
        };

        if (shouldIncludeFilter(vehicles)) {
            req.vehicleDevices = vehicles as VehicleDeviceSlug[];
        }

        if (shouldIncludeFilter(dateRange)) {
            if (dateRange[0]) {
                req.startTime = dateRange[0] as DateStrUTCTimestamp;
            }

            if (dateRange[1]) {
                req.endTime = dateRange[1] as DateStrUTCTimestamp;
            }
        }
        return req;
    }, [projectSlug, vehicles, dateRange]);
}

export function AuditIndex(): React.ReactElement {
    const projectSlug = useActiveProjectSlug();
    const { useGetAuditsQuery } = useFrame(SteinInternalApiClientToken);
    const [selectedSlugs, setSelectedSlugs] = React.useState<Array<GridRowId>>([]);
    const navigate = useAppNavigate();
    const filterString = useGlobalSearch('Filter loaded audits by vehicle name');

    const req = useRequest();

    function onRowChosen(d: Audit): void {
        navigate(urlAudit(projectSlug, d));
    }

    const { data, isLoading } = useGetAuditsQuery(req);
    const vehicles = useAppSelector((s) => selectVehiclesByProjectSlug(s, projectSlug));
    const audits = data?.audits ? data?.audits : [];

    const filteredAudits = React.useMemo(() => {
        if (!filterString) {
            return audits;
        }

        return audits.filter((a) => {
            const vehicle = vehicles.find((v) => v.id === a.vehicleDeviceId);
            return vehicle?.name.toLowerCase().includes(filterString.toLowerCase());
        });
    }, [audits, filterString]);

    return (
        <>
            <span data-testid={'page-audit-list'} />
            <FilterBar icons={<></>}>
                <FilterMultiselect filterName={'vehicles'} options={filterOptionsFromVehicles(vehicles)} />
                <FilterDateRangePicker />
                <AuditBulkActions
                    auditSlugs={selectedSlugs as AuditSlug[]}
                    startTime={req.startTime}
                    endTime={req.endTime}
                />
            </FilterBar>
            <DataGrid
                rows={filteredAudits}
                onRowSelectionModelChange={(e) => {
                    setSelectedSlugs(e);
                }}
                getRowId={(r) => r.slug}
                columns={AUDIT_COLUMNS}
                disableColumnFilter
                disableColumnMenu
                isLoading={isLoading}
                checkboxSelection
                disableMultipleColumnsFiltering
                disableMultipleRowSelection
                onRowChosen={onRowChosen}
                localeText={{
                    noRowsLabel: 'No Matching Audits',
                }}
            />
        </>
    );
}
