import * as React from 'react';

import { VehicleDevice } from '../../../types/stein';
import { CalloutBox, CalloutSkeleton } from '../../material/CalloutBox';
import { VehicleCalibrationSnapshot } from '../../Vehicle/VehicleCalibrationSnapshot';

type VehicleDeviceCalibrationBoxProps = {
    vehicle: VehicleDevice | undefined;
};

export function VehicleDeviceCalibrationBox({ vehicle }: VehicleDeviceCalibrationBoxProps): React.ReactElement | null {
    // istanbul ignore next
    if (!vehicle) {
        // istanbul ignore next
        return <CalloutSkeleton name="Calibration Snapshot" />;
    }

    if (!vehicle?.lastInitialCalibrationSnapshot || !vehicle?.lastAbsenceSnapshot) {
        return null;
    }

    return (
        <CalloutBox name="Calibration Snapshot">
            <VehicleCalibrationSnapshot
                start={vehicle?.lastInitialCalibrationSnapshot}
                end={vehicle?.lastAbsenceSnapshot}
            />
        </CalloutBox>
    );
}
