import { IconButton, Menu, MenuItem } from '@mui/material';
import * as React from 'react';
import { usePlaybackRate } from '../videoPlayerHooks';
import { VideoRef } from '../VideoTypes';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useHotkey } from '../../HotkeyProvider';

type VideoPlaybackRateSelectrops = {
    videoRef: VideoRef;
    components?: {
        Button?: React.FC<{
            onClick: (e: React.SyntheticEvent) => void;
            children: React.ReactNode;
        }>;
    };
};

export function VideoPlaybackRateSelect({ videoRef, components }: VideoPlaybackRateSelectrops): React.ReactElement {
    const { setPlaybackRate, playbackRate } = usePlaybackRate(videoRef);
    const ref = React.useRef<HTMLDivElement>(null);

    const ButtonComponent = components?.Button || IconButton;

    /* istanbul ignore next */
    function incrementRate(): void {
        setPlaybackRate((r) => Math.min(r + 0.25, 10));
    }

    /* istanbul ignore next */
    function decrementRate(): void {
        setPlaybackRate((r) => Math.max(r - 0.25, 0.25));
    }

    useHotkey(
        'shift + .',
        incrementRate,
        {
            description: 'Increase video playback rate',
        },
        [setPlaybackRate],
    );

    useHotkey(
        'shift + ,',
        decrementRate,
        {
            description: 'Decrease video playback rate',
        },
        [setPlaybackRate],
    );

    return (
        <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
                <div ref={ref} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 5 }}>
                    <ButtonComponent {...bindTrigger(popupState)}>{`${playbackRate}x`}</ButtonComponent>
                    <Menu
                        {...bindMenu(popupState)}
                        container={ref.current}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    >
                        {[0.15, 0.25, 0.5, 0.75, 1, 1.5, 2, 4, 5, 10, 16].map((rate) => {
                            /* istanbul ignore next */
                            function setRate(): void {
                                popupState.close();
                                setPlaybackRate(rate);
                            }
                            return (
                                <MenuItem key={rate} onClick={setRate}>
                                    {`${rate}x`}
                                </MenuItem>
                            );
                        })}
                    </Menu>
                </div>
            )}
        </PopupState>
    );
}
