import * as React from 'react';
import { addMinutes, differenceInMilliseconds, format, millisecondsToMinutes, minutesToMilliseconds } from 'date-fns';
import { useAppTheme } from '../../../../theme';
import { toTimestampMs } from '../../../../utils/datetime-utils';
import { useTimelineContext } from './TimelineComponents/TimelineProvider';
import { DateTimestampMs } from '../../../../types/stein';
import { TimelinePoint } from './TimelineComponents/TimelineComponents';
import { Box } from '@mui/system';

export function TimeMarks(): React.ReactElement {
    const { startTime, visibleWidthPx, durationMs, visibleDurationMs } = useTimelineContext();
    const visibleDurationMin = millisecondsToMinutes(visibleDurationMs);
    const minutesPerPixel = visibleDurationMin / visibleWidthPx;
    const offset = minutesToMilliseconds(roundNearest5(minutesPerPixel * 45));
    const intervals = getTimeIntervals(
        (startTime + offset) as DateTimestampMs,
        durationMs,
        roundNearest5(minutesPerPixel * 160),
    );

    return (
        <>
            {intervals.map((time) => (
                <Mark time={time} key={time} />
            ))}
        </>
    );
}

function roundNearest5(num: number): number {
    return Math.round(num / 5) * 5;
}

function getTimeIntervals(startTime: DateTimestampMs, durationMs: number, intervalMinutes: number): DateTimestampMs[] {
    const intervals: DateTimestampMs[] = [];
    let cursor = new Date(startTime);
    while (differenceInMilliseconds(cursor, startTime) < durationMs) {
        intervals.push(toTimestampMs(cursor));
        cursor = addMinutes(cursor, intervalMinutes);
    }
    return intervals;
}

function Mark({ time }: { time: DateTimestampMs }): React.ReactElement | null {
    const theme = useAppTheme();

    return (
        <TimelinePoint
            time={time}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                color: theme.palette.grey[600],
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    width: '1px',
                    height: '100%',
                    backgroundColor: theme.palette.grey[600],
                }}
            />
            <Box
                sx={{
                    fontSize: '12px',
                }}
            >
                {format(time, 'h:mm bbb')}
            </Box>
        </TimelinePoint>
    );
}
