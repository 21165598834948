import { filterUnique } from './filter-unique';
import { Abilities, AbilitySubject } from '../types/stein';

export function mergeAbilities(a: Abilities, b: Abilities): Abilities {
    const abilities = Object.assign({}, a);

    const subjectKeys = Object.keys(b.can).concat(Object.keys(a.can)).filter(filterUnique) as Array<AbilitySubject>;

    for (const subject of subjectKeys) {
        const acan = a.can[subject] || [];
        const bcan = b.can[subject] || [];
        // @ts-expect-error For some reason abilities does not recognize that acan and bcan on
        abilities.can[subject] = acan.concat(bcan).filter(filterUnique);
    }

    return abilities;
}
