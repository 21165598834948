import * as React from 'react';
import QRCode from 'react-qr-code';

export type QRPayload = { action: string } & Record<string, unknown>;

type AppQrCodeProps = {
    payload: QRPayload;
};

export function AppQrCode({ payload }: AppQrCodeProps): React.ReactElement {
    const encodedPayload = encodeURIComponent(btoa(JSON.stringify(payload, null, 1)));

    const value = `https://observantai.com/scan?v=${encodedPayload}`;

    return <QRCode value={value} data-testid={`payload:${encodedPayload}`} />;
}
