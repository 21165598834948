import * as React from 'react';

import Popover from '@mui/material/Popover';

import { bindTrigger, bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import { ApplicationBarIcon } from './ApplicationBarIcon';

type ApplicationBarPopoverProps = {
    icon: React.ReactElement;
    name: string;
    children: React.ReactNode;
};

export function ApplicationBarPopover({ icon, children, name }: ApplicationBarPopoverProps): React.ReactElement {
    const popupState = usePopupState({ variant: 'popover', popupId: 'demo-popup-popover' });

    return (
        <div>
            <ApplicationBarIcon aria-label={name} {...bindTrigger(popupState)}>
                {icon}
            </ApplicationBarIcon>
            <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    transform: 'translateY(10px)',
                }}
            >
                <div style={{ minWidth: 360, maxWidth: 360, width: '100%' }}>{children}</div>
            </Popover>
        </div>
    );
}
