import * as React from 'react';
import { TileLayer } from 'react-leaflet';

const TILE_URL =
    'https://api.mapbox.com/styles/v1/jpteasdale-obs/clg56oh74000001srn1gvsubs/tiles/512/{z}/{x}/{y}?access_token=pk.eyJ1IjoianB0ZWFzZGFsZS1vYnMiLCJhIjoiY2xnNTZta2FjMDBpMzNlcGNuMDhidGxicyJ9.ADeVO_9kYrEN6q8CNtPhMA';

export function MapTiles(): React.ReactElement {
    return <TileLayer url={TILE_URL} />;
}

// Attribution: '© <a href="https://www.mapbox.com/contribute/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>
