// istanbul ignore file

import * as React from 'react';

import { ConfigurationItem, VehicleDeviceId } from '../../../types/stein';
import { GetProjectConfigItemsReq, GetVehicleConfigItemsRequest } from '../../../types/stein-internal-api';

import { useFrame } from '../../../lib/frame-react';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';

import {
    Box,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Button,
    Typography,
} from '@mui/material';
import { EditProjectConfigItem, EditVehicleConfigItem } from './EditConfigItem';

import { useActiveProject, useActiveProjectSlug } from '../../../hooks/use-active-project';
import { NewProjectConfigItem, NewVehicleConfigItem } from './NewConfigItem';
import { useSearchParamNumber } from '../../../hooks/use-filters';
import { useAppSelector } from '../../../hooks/hooks';
import { selectVehiclesByProjectSlug } from '../../../store/selectors';
import { AppIconResetSelection } from '../../AppIcons';

type Requests = {
    vehicleReq?: GetVehicleConfigItemsRequest;
    projectReq: GetProjectConfigItemsReq;
};

function useRequestParams(vehicleDeviceId: VehicleDeviceId | null): Requests {
    const project = useActiveProject();
    const projectId = project.id;

    const vehicleReq = React.useMemo(
        () =>
            vehicleDeviceId
                ? {
                      vehicleDeviceId,
                  }
                : undefined,
        [vehicleDeviceId],
    );

    const projectReq = React.useMemo(
        () => ({
            projectId,
        }),
        [projectId],
    );

    return {
        vehicleReq,
        projectReq,
    };
}

function sortByAttributeKey(a: ConfigurationItem, b: ConfigurationItem): number {
    if (a.attributeKey < b.attributeKey) return -1;
    if (a.attributeKey > b.attributeKey) return 1;
    return 0;
}

function ConfigItemList({ configItems }: { configItems: ConfigurationItem[] }): React.ReactElement {
    return (
        <>
            {configItems
                .slice()
                .sort(sortByAttributeKey)
                .map((ci) =>
                    ci.vehicleDeviceId ? (
                        <EditVehicleConfigItem configurationItem={ci} key={ci.id} />
                    ) : (
                        <EditProjectConfigItem configurationItem={ci} key={ci.id} />
                    ),
                )}
        </>
    );
}

export function ConfigItemListPage(): React.ReactElement {
    const projectSlug = useActiveProjectSlug();
    const [vehicleId, setVehicleId] = useSearchParamNumber('vehicleId');
    const req = useRequestParams(vehicleId as VehicleDeviceId | null);
    const { useLazyGetVehicleConfigItemsQuery, useGetProjectConfigItemsQuery } = useFrame(SteinInternalApiClientToken);
    const [trigger, { data: vehicleItems, isFetching: isVehicleFetching }] = useLazyGetVehicleConfigItemsQuery();
    const { data: projectItems, isFetching: isProjectLoading } = useGetProjectConfigItemsQuery(req.projectReq);
    const vehicles = useAppSelector((s) => selectVehiclesByProjectSlug(s, projectSlug));

    React.useEffect(() => {
        if (req.vehicleReq) {
            trigger(req.vehicleReq);
        }
    }, [req.vehicleReq]);

    return (
        <Stack spacing={1}>
            <Stack direction={'row'} spacing={1}>
                <FormControl style={{ flexGrow: 2 }} size={'small'}>
                    <InputLabel id="tag-profile-select">{'Select Vehicle'}</InputLabel>
                    <Select
                        labelId="tag-profile-select"
                        label={'Select Vehicle'}
                        value={vehicleId || ''}
                        onChange={(e) => {
                            // @ts-expect-error ignore this
                            const newId = parseInt(e.target.value);
                            setVehicleId(newId);
                        }}
                    >
                        {vehicles.map((v) => (
                            <MenuItem value={v.id} key={v.id}>
                                {v.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button disabled={!vehicleId} onClick={() => setVehicleId(null)} size="small">
                    <AppIconResetSelection />
                </Button>
            </Stack>
            {isProjectLoading || isVehicleFetching ? (
                <Box display={'flex'} justifyContent={'center'}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Typography variant={'h5'}>{`Showing configuration items for ${
                        vehicleId ? 'selected vehicle' : 'project'
                    }`}</Typography>

                    <ConfigItemList
                        configItems={
                            vehicleId ? vehicleItems?.configurationItems || [] : projectItems?.configurationItems || []
                        }
                    />
                </>
            )}

            {vehicleId ? (
                <NewVehicleConfigItem vehicleDeviceId={vehicleId as VehicleDeviceId} />
            ) : (
                <NewProjectConfigItem projectId={req.projectReq.projectId} />
            )}
        </Stack>
    );
}
