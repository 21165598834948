import * as React from 'react';
import Draggable from 'react-draggable';
import { Box, Fade, keyframes, Paper, Stack } from '@mui/material';

import { InvisiblePresentation } from './InvisiblePresentation';
import { useAppSelector } from '../hooks/hooks';
import { useFrame } from '../lib/frame-react';
import { SteinInternalApiClientToken } from '../clients/stein-internal-api';
import { useAppTheme } from '../theme';
import { alpha } from '@mui/material/styles';
import { AppIconSimulate } from './AppIcons';
import { toTitleCase } from '../lib/case-transforms';
import LoadingButton from '@mui/lab/LoadingButton';

const waveKeyframe = keyframes`
0% {
  transform: translateX(-100%);
}
75% {
  transform: translateX(100%);
}
100% {
  transform: translateX(100%);
}
`;

const draggableStyle: React.CSSProperties = {
    pointerEvents: 'all',
    position: 'absolute',
    cursor: 'move',
    top: 10,
    left: '50%' /* position the left edge of the element at the middle of the parent */,
};

export function SimulatedRole(): React.ReactElement | null {
    const theme = useAppTheme();
    const role = useAppSelector((s) => s.abilities.simulatedRole);
    const { useRoleSimulationEndMutation } = useFrame(SteinInternalApiClientToken);
    const [endSimulation, { isLoading }] = useRoleSimulationEndMutation();

    if (!role) {
        return null;
    }

    return (
        <InvisiblePresentation
            sx={{
                backgroundColor: alpha(theme.colors.icon.logo, 0.1),
                '&::after': {
                    animation: `${waveKeyframe} 1.6s linear 0.5s infinite`,
                    background:
                        'linear-gradient(90deg, transparent, ${alpha(theme.colors.icon.logo, 0.1)}, transparent)',
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    top: 0,
                },
            }}
        >
            <Draggable bounds={'parent'} positionOffset={{ x: '-50%', y: 0 }}>
                <div style={draggableStyle}>
                    <Fade in={Boolean(role)}>
                        <Paper>
                            <Box paddingX={2} paddingY={1}>
                                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                    <AppIconSimulate size={14} />
                                    <div>{`Simulating Role: ${toTitleCase(role.name)}`}</div>
                                    <LoadingButton size={'small'} onClick={() => endSimulation()} loading={isLoading}>
                                        {'End'}
                                    </LoadingButton>
                                </Stack>
                            </Box>
                        </Paper>
                    </Fade>
                </div>
            </Draggable>
        </InvisiblePresentation>
    );
}
