// istanbul ignore file

import * as React from 'react';
import { useMeasure } from 'react-use';
import { Permission } from '../Authorize/PermissionBlock';

const ASPECT_RATIO = 4 / 3;

function computeNumColumns(
    width: number,
    height: number,
    numItems: number,
): { numColumns: number; widthToUse: number } {
    let numColumns = 1;
    let maxVideoWidth = 0;

    for (let cols = 1; cols <= numItems; cols++) {
        const videoWidth = width / cols;

        const numHigh = Math.ceil(numItems / cols);
        const maxWidthForHeight = (height / numHigh) * ASPECT_RATIO;

        const widthToUse = Math.min(maxWidthForHeight, videoWidth);

        if (widthToUse > maxVideoWidth) {
            maxVideoWidth = widthToUse;
            numColumns = cols;
        }
    }

    return { numColumns, widthToUse: maxVideoWidth };
}

export function MultiBlock({ items }: { items: React.ReactNode[] }): React.ReactElement {
    const [ref, { width, height }] = useMeasure<HTMLDivElement>();
    const { numColumns, widthToUse } = computeNumColumns(width, height, items.length);

    const numRows = Math.ceil(items.length / numColumns);

    return (
        <>
            <div
                ref={ref}
                style={{
                    width: '100%',
                    maxWidth: '100%',
                    height: '100%',
                    maxHeight: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {items.map((item, idx) => (
                    <div
                        key={idx}
                        style={{
                            width: `${widthToUse}px`,
                            minWidth: `${widthToUse}px`,
                            maxWidth: `${widthToUse}px`,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{
                                margin: 1,
                                aspectRatio: ASPECT_RATIO,
                                width: `100%`,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'black',
                                borderRadius: '5px',
                                color: 'white',
                            }}
                        >
                            {item}
                        </div>
                    </div>
                ))}
            </div>
            <Permission debugOnly>
                <pre>{JSON.stringify({ width, height, numColumns, numRows }, null, 1)}</pre>
            </Permission>
        </>
    );
}
