// istanbul ignore file
import * as React from 'react';
import { useConfigItems } from '../../../../hooks/use-config-items';
import { useSearchParamSingular } from '../../../../hooks/use-filters';
import { noop } from '../../../../utils/noop';
import { AuditTagProfile, AUDIT_TAG_PROFILES } from './AuditTagConstants';

type AuditTagProfileContext = {
    profile: AuditTagProfile | null;
    setActiveProfile: (v: string) => void;
    allowedProfiles: AuditTagProfile[];
};

const auditTagProfile = React.createContext<AuditTagProfileContext>({
    profile: null,
    setActiveProfile: noop,
    allowedProfiles: [],
});

export function AuditTagProfileProvider({ children }: { children: React.ReactNode }): React.ReactElement {
    const getConfigItem = useConfigItems();

    const profileWhitelist = getConfigItem('audit_tag_profile_whitelist');
    const allowedProfiles = AUDIT_TAG_PROFILES.filter(
        (p) => profileWhitelist?.jsonValue && profileWhitelist.jsonValue.includes(p.id),
    );
    const [profileId, setProfileId] = useSearchParamSingular('profile');
    const profile = AUDIT_TAG_PROFILES.find((p) => p.id === profileId) || allowedProfiles[0];
    return (
        <auditTagProfile.Provider value={{ profile, setActiveProfile: setProfileId, allowedProfiles }}>
            {children}
        </auditTagProfile.Provider>
    );
}

export function useAuditTagProfile(): AuditTagProfileContext {
    return React.useContext(auditTagProfile);
}
