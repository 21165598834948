// istanbul ignore file
import { AuditTagBehavior, AuditTagCategory } from '../../../../types/stein';

export const AUDIT_TAG_CATEGORY = {
    BOOKMARK: 1,
    VISUAL_OBSTRUCTION: 2,
    HEARING_OBSTRUCTION: 3,
    PERSONAL_DEVICE: 4,
    NOTE_TAKER: 5,
    HANDS_OFF_WHEEL: 6,
    OTHER: 7,
    ENGAGEMENT_PROTOCOL: 8,
    CREEPS_AND_STOP_POINTS: 9,
    MANEUVERS_AND_HAZARDS: 10,
    VERBAL_NOTE_CONFIRMATIONS: 11,
    PASSENGER_PROFESSIONALISM: 12,
    TRAFFIC_SIGNS_AND_LAWS: 13,
    SAFE_MANUAL_DRIVING: 14,
    SCANNING_THE_ROAD: 15,
    MIRROR_CHECK_FOR_HARDBRAKE: 16,
    HANDS_9_AND_3: 17,
    DRIVER_NOTE_CALLOUTS: 18,
    TKO_ADHERENCE: 19,
    DRIVER_ENGAGEMENT_PROTOCOL: 20,
    HABITUAL_SCANNING: 21,
    MANEUVER_SCANNING: 22,
    ROAD_PROFESSIONALISM: 23,
    ENGAGEMENT_AND_TKO_ADHERENCE: 24,
} as const;

export const TAG_PROFILE_AUTO_PAIRS: AuditTagProfile = {
    id: 'auto-double',
    title: 'Auto Double Rider',
    customMetadataFields: [
        'driver_name',
        'driver_audit_type',
        'driver_feedback',
        'notetaker_name',
        'notetaker_audit_type',
        'notetaker_feedback',
        'auditor_name',
        'audit_month',
        'url',
    ],
    groups: [
        {
            groupName: 'Driver Tags',
            categories: [
                AUDIT_TAG_CATEGORY.DRIVER_ENGAGEMENT_PROTOCOL,
                AUDIT_TAG_CATEGORY.TRAFFIC_SIGNS_AND_LAWS,
                AUDIT_TAG_CATEGORY.SAFE_MANUAL_DRIVING,
                AUDIT_TAG_CATEGORY.SCANNING_THE_ROAD,
                AUDIT_TAG_CATEGORY.MIRROR_CHECK_FOR_HARDBRAKE,
                AUDIT_TAG_CATEGORY.HANDS_9_AND_3,
                AUDIT_TAG_CATEGORY.DRIVER_NOTE_CALLOUTS,
                AUDIT_TAG_CATEGORY.TKO_ADHERENCE,
            ],
        },
        {
            groupName: 'Passenger Tags',
            categories: [
                AUDIT_TAG_CATEGORY.ENGAGEMENT_PROTOCOL,
                AUDIT_TAG_CATEGORY.CREEPS_AND_STOP_POINTS,
                AUDIT_TAG_CATEGORY.MANEUVERS_AND_HAZARDS,
                AUDIT_TAG_CATEGORY.VERBAL_NOTE_CONFIRMATIONS,
                AUDIT_TAG_CATEGORY.PASSENGER_PROFESSIONALISM,
            ],
        },
    ],
} as const;

export const TAG_PROFILE_AUTO_SINGLE_RIDER: AuditTagProfile = {
    id: 'auto-single',
    title: 'Auto Single Rider',
    customMetadataFields: ['driver_name', 'driver_audit_type', 'driver_feedback', 'auditor_name', 'audit_month', 'url'],
    groups: [
        {
            categories: [
                AUDIT_TAG_CATEGORY.HANDS_9_AND_3,
                AUDIT_TAG_CATEGORY.HABITUAL_SCANNING, // NEW!
                AUDIT_TAG_CATEGORY.MANEUVER_SCANNING, // NEW!
                AUDIT_TAG_CATEGORY.TRAFFIC_SIGNS_AND_LAWS,
                AUDIT_TAG_CATEGORY.ROAD_PROFESSIONALISM, // this might be the only new one
                AUDIT_TAG_CATEGORY.ENGAGEMENT_AND_TKO_ADHERENCE, // combination of AUDIT_TAG_CATEGORY.DRIVER_ENGAGEMENT_PROTOCOL and AUDIT_TAG_CATEGORY.TKO_ADHERENCE
            ],
        },
    ],
} as const;

export const TAG_PROFILE_MANUAL_SINGLE_RIDER: AuditTagProfile = {
    id: 'manual-single',
    title: 'Manual Single Rider',
    customMetadataFields: ['driver_name', 'driver_audit_type', 'driver_feedback', 'auditor_name', 'audit_month', 'url'],
    groups: [
        {
            categories: [
                AUDIT_TAG_CATEGORY.HANDS_9_AND_3,
                AUDIT_TAG_CATEGORY.HABITUAL_SCANNING, // is this AUDIT_TAG_CATEGORY.SCANNING_THE_ROAD
                AUDIT_TAG_CATEGORY.MANEUVER_SCANNING, // is this AUDIT_TAG_CATEGORY.MANEUVERS_AND_HAZARDS
                AUDIT_TAG_CATEGORY.TRAFFIC_SIGNS_AND_LAWS,
                AUDIT_TAG_CATEGORY.ROAD_PROFESSIONALISM, // this might be the only new one
            ],
        },
    ],
} as const;

export const TAG_PROFILE_GENERIC: AuditTagProfile = {
    id: 'generic',
    title: 'Generic',
    customMetadataFields: ['driver_name', 'driver_feedback'],
    groups: [
        {
            categories: [
                AUDIT_TAG_CATEGORY.SCANNING_THE_ROAD,
                AUDIT_TAG_CATEGORY.SAFE_MANUAL_DRIVING,
                AUDIT_TAG_CATEGORY.TRAFFIC_SIGNS_AND_LAWS,
                AUDIT_TAG_CATEGORY.OTHER,
            ],
        },
    ],
} as const;

export const AUDIT_TAG_PROFILES = [
    TAG_PROFILE_AUTO_PAIRS,
    TAG_PROFILE_AUTO_SINGLE_RIDER,
    TAG_PROFILE_MANUAL_SINGLE_RIDER,
    TAG_PROFILE_GENERIC,
] as const;

export type AuditTagProfile = Readonly<{
    id: string;
    title: string;
    customMetadataFields: readonly string[];
    groups: readonly { groupName?: string; categories: readonly AuditTagCategory[] }[];
}>;

export const auditTagCategoryToHumanReadable = function (category: AuditTagCategory): string {
    switch (category) {
        case AUDIT_TAG_CATEGORY.BOOKMARK:
            return 'Bookmark';
        case AUDIT_TAG_CATEGORY.VISUAL_OBSTRUCTION:
            return 'Visual obstruction';
        case AUDIT_TAG_CATEGORY.HEARING_OBSTRUCTION:
            return 'Hearing obstruction';
        case AUDIT_TAG_CATEGORY.PERSONAL_DEVICE:
            return 'Personal device';
        case AUDIT_TAG_CATEGORY.NOTE_TAKER:
            return 'Note-taker issue';
        case AUDIT_TAG_CATEGORY.HANDS_OFF_WHEEL:
            return 'Hands off wheel';
        case AUDIT_TAG_CATEGORY.ENGAGEMENT_PROTOCOL:
            return 'Engagement protocol';
        case AUDIT_TAG_CATEGORY.CREEPS_AND_STOP_POINTS:
            return 'Creeps and stop points';
        case AUDIT_TAG_CATEGORY.MANEUVERS_AND_HAZARDS:
            return 'Maneuvers and hazards';
        case AUDIT_TAG_CATEGORY.VERBAL_NOTE_CONFIRMATIONS:
            return 'Verbal note confirmations';
        case AUDIT_TAG_CATEGORY.PASSENGER_PROFESSIONALISM:
            return 'Passenger professionalism';
        case AUDIT_TAG_CATEGORY.TRAFFIC_SIGNS_AND_LAWS:
            return 'Traffic signs and laws';
        case AUDIT_TAG_CATEGORY.SAFE_MANUAL_DRIVING:
            return 'Safe manual driving';
        case AUDIT_TAG_CATEGORY.SCANNING_THE_ROAD:
            return 'Scanning the road';
        case AUDIT_TAG_CATEGORY.MIRROR_CHECK_FOR_HARDBRAKE:
            return 'Mirror check for hardbrake';
        case AUDIT_TAG_CATEGORY.HANDS_9_AND_3:
            return 'Hands 9 and 3';
        case AUDIT_TAG_CATEGORY.DRIVER_NOTE_CALLOUTS:
            return 'Driver note callouts';
        case AUDIT_TAG_CATEGORY.TKO_ADHERENCE:
            return 'TKO adherence';
        case AUDIT_TAG_CATEGORY.DRIVER_ENGAGEMENT_PROTOCOL:
            return 'Driver engagement protocol';
        case AUDIT_TAG_CATEGORY.HABITUAL_SCANNING:
            return 'Habitual scanning';
        case AUDIT_TAG_CATEGORY.MANEUVER_SCANNING:
            return 'Maneuver scanning';
        case AUDIT_TAG_CATEGORY.ROAD_PROFESSIONALISM:
            return 'Road professionalism';
        case AUDIT_TAG_CATEGORY.ENGAGEMENT_AND_TKO_ADHERENCE:
            return 'Engagement and TKO adherence';
        case AUDIT_TAG_CATEGORY.OTHER:
            return 'Other';
        default:
            return 'Unknown';
    }
};

export const auditTagCategoryHelperText = function (category: AuditTagCategory): string | null {
    switch (category) {
        case AUDIT_TAG_CATEGORY.ENGAGEMENT_PROTOCOL:
            return 'Followed the engagement protocol?';
        case AUDIT_TAG_CATEGORY.CREEPS_AND_STOP_POINTS:
            return 'Communicated creep points and stop points with the driver as well as upcoming traffic light status relevant to AV routing and speed?';
        case AUDIT_TAG_CATEGORY.MANEUVERS_AND_HAZARDS:
            return "Communicated upcoming maneuvers, potential hazards on the road (pedestrians, cyclists, obstructions), and if the AV's intent was clear/not clear of hazards?";
        case AUDIT_TAG_CATEGORY.VERBAL_NOTE_CONFIRMATIONS:
            return 'Communicated or confirmed AV behavior note categories, comments, and data collection?';
        case AUDIT_TAG_CATEGORY.PASSENGER_PROFESSIONALISM:
            return 'Maintained professionalism throughout the drive?';
        case AUDIT_TAG_CATEGORY.TRAFFIC_SIGNS_AND_LAWS:
            return 'Obeyed traffic signs, signals, and other traffic laws (speed limit, restricted turns, etc.)?';
        case AUDIT_TAG_CATEGORY.SAFE_MANUAL_DRIVING:
            return 'Maintained a safe following distance, pedestrians, and cyclists/motorists when driving manually?';
        case AUDIT_TAG_CATEGORY.SCANNING_THE_ROAD:
            return 'Scanned the road, blind spots, and mirrors habitually and before maneuvers (both autonomous and manual)?';
        case AUDIT_TAG_CATEGORY.MIRROR_CHECK_FOR_HARDBRAKE:
            return 'Checked appropriate mirrors during all hard brake events? (verbal indicator highly suggested)';
        case AUDIT_TAG_CATEGORY.HANDS_9_AND_3:
            return 'Kept hands at 9 & 3 in autonomous mode and manual mode?';
        case AUDIT_TAG_CATEGORY.DRIVER_NOTE_CALLOUTS:
            return 'Communicated and responded to the passenger in a professional manner? (in regard to AV behaviors and data collection)';
        case AUDIT_TAG_CATEGORY.TKO_ADHERENCE:
            return 'Safety of TKO & adherence to TKO Guidelines';
        case AUDIT_TAG_CATEGORY.DRIVER_ENGAGEMENT_PROTOCOL:
            return 'Followed the engagement protocol?';
        case AUDIT_TAG_CATEGORY.HABITUAL_SCANNING:
            return 'Scanned the road, blind spots, and mirrors habitually.';
        case AUDIT_TAG_CATEGORY.MANEUVER_SCANNING:
            return 'Scanned the road, blind spots, and mirrors during maneuvers.';
        case AUDIT_TAG_CATEGORY.ROAD_PROFESSIONALISM:
            return 'Maintained a safe following distance, pedestrians, and cyclists/motorists.';
        case AUDIT_TAG_CATEGORY.ENGAGEMENT_AND_TKO_ADHERENCE:
            return 'Adhered to Safe Engagement Protocol and TKO Guidelines';
        default:
            return null;
    }
};

export function tagGroupAndIndexToHotkey(groupIdx: number, tagIdx: number): string {
    if (groupIdx === 0) {
        return `${tagIdx + 1}`;
    }

    return 'qwertyuiop'.charAt(tagIdx);
}

export function getHotkeysForProfile(profile: AuditTagProfile): Record<string, AuditTagCategory> {
    const obj: Record<string, AuditTagCategory> = {};
    profile.groups.forEach((group, groupIdx) => {
        group.categories.forEach((category, idx) => {
            obj[tagGroupAndIndexToHotkey(groupIdx, idx)] = category;
        });
    });

    return obj;
}

export const AUDIT_TAG_BEHAVIORS = ['major_positive', 'minor_negative', 'major_negative'] as const;

export function auditTagBehaviorToHumanReadable(behavior: AuditTagBehavior): string {
    switch (behavior) {
        case 'major_positive':
            return 'Positive behavior';
        case 'major_negative':
            return 'Negative behavior';
        case 'minor_negative':
            return 'Minor negative behavior';
    }
}
