import * as React from 'react';

import { GlobalStyles as GlobalStylesBase, css } from '@mui/material';

import emotionNormalize from 'emotion-normalize';
import { useAppTheme } from '.';

// ...
export function GlobalStyles(): React.ReactElement {
    const theme = useAppTheme();
    return (
        <GlobalStylesBase
            styles={css`
                ${emotionNormalize}
                body {
                    font-family: ${theme.typography.fontFamily};
                }

                html,
                body,
                div,
                p,
                a,
                span {
                    box-sizing: border-box;
                }

                .leaflet-invisible-marker {
                    height: 0 !important;
                    width: 0 !important;
                    margin-top: 0 !important;
                    margin-left: 0 !important;
                }

                .show-sibling-on-hover + div {
                    visibility: hidden;
                }
                .show-sibling-on-hover:hover + div {
                    visibility: visible;
                }
            `}
        />
    );
}
