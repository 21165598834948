// istanbul ignore file
import * as React from 'react';

import { useAuditTagProfile } from './AuditTagProfile';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { AuditTagProfile } from './AuditTagConstants';
import { SelectChangeEvent } from '@mui/material';

export function AuditTagProfileSelect(): React.ReactElement {
    const { profile, setActiveProfile, allowedProfiles } = useAuditTagProfile();

    function handleChange(e: SelectChangeEvent<AuditTagProfile['id']>): void {
        setActiveProfile(e.target.value);
    }

    return (
        <FormControl>
            <InputLabel id="tag-profile-select">{'Tag Profile'}</InputLabel>
            <Select
                labelId="tag-profile-select"
                id="demo-simple-select"
                value={profile?.id || ''}
                label="Tag Profile"
                onChange={handleChange}
                variant={'outlined'}
                size={'small'}
            >
                {allowedProfiles.map(({ title, id }) => (
                    <MenuItem value={id} key={id}>
                        {title}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
