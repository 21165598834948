import * as React from 'react';

import { NotificationItemWrapper } from './internal/NotificationItemWrapper';
import { AppNotificationTypePushMulti } from '../notificationTypes';
import { LinearProgress, Typography, DialogTitle, DialogActions, Button, DialogContentText } from '@mui/material';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import { useFrame } from '../../../lib/frame-react';
import { useAppDispatch } from '../../../hooks/hooks';
import { NotificationItemDetailDialog } from './internal/NotificationItemDetail';
import { DialogContent } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNotificationItemContext } from '.';
import { VehicleDeviceId } from '../../../types/stein';
import { VehicleGrid } from '../../Vehicle/VehicleGrid';
import { useAppTheme } from '../../../theme';
import { plural } from '../../../utils/string-utils';
import { notificationSlice } from '../../../store/slices/notificationSlice';

type NotificationItemPushMultiProps = {
    notification: AppNotificationTypePushMulti;
};

function pushDetails(notification: AppNotificationTypePushMulti): {
    title: string;
    detail?: string;
} {
    switch (notification.args.category) {
        case 'custom_announcement':
            return {
                title: 'Voice Alert',
                detail: `Message: "${notification.args.announcementMessage}"`,
            };
    }

    // istanbul ignore next
    return {
        title: 'Push',
    };
}

function VehicleSection({ title, vehicleIds }: { title: string; vehicleIds: VehicleDeviceId[] }): React.ReactElement {
    const theme = useAppTheme();
    return (
        <div style={{ paddingTop: theme.spacing(1) }}>
            <div style={{ ...theme.typography.caption }}>{title}</div>
            <VehicleGrid vehicleIds={vehicleIds} />
        </div>
    );
}

export function NotificationItemPushMulti({ notification }: NotificationItemPushMultiProps): React.ReactElement {
    const theme = useAppTheme();
    const { useCreatePushMultiMutation } = useFrame(SteinInternalApiClientToken);
    const [createPush, { isLoading: isResendingErrors, isSuccess: didResend }] = useCreatePushMultiMutation();

    const projectSlug = notification.args.projectSlug;
    const { pending, successes, failures } = notification;

    const numPending = pending.length;
    const numSucceded = successes.length;
    const numFailed = failures.length;
    const totalNum = numPending + numSucceded + numFailed;

    const isCompletelySuccessful = numSucceded === totalNum;
    const isComplete = numFailed + numSucceded === totalNum;

    const { title, detail } = pushDetails(notification);
    const vehicleForm = plural(totalNum, 'vehicle', 'vehicles');

    return (
        <NotificationItemWrapper
            dismissable={isComplete}
            autoDismissMs={isCompletelySuccessful ? 1000 : undefined}
            type={isCompletelySuccessful ? 'success' : numFailed ? 'error' : undefined}
        >
            <div>
                <Typography>{title}</Typography>
                {detail && <Typography variant="caption">{detail}</Typography>}
                {!isComplete ? <LinearProgress /> : null}

                {isCompletelySuccessful ? (
                    <Typography
                        variant="caption"
                        display={'block'}
                        color={theme.palette.success.dark}
                    >{`Succesfully sent to ${totalNum} ${vehicleForm}`}</Typography>
                ) : (
                    <>
                        {!isComplete ? (
                            <Typography
                                variant="caption"
                                display={'block'}
                            >{`${numPending}/${totalNum} ${vehicleForm} pending`}</Typography>
                        ) : null}
                        {numSucceded ? (
                            <Typography
                                variant="caption"
                                display={'block'}
                                color={theme.palette.success.dark}
                            >{`${numSucceded} succeeded`}</Typography>
                        ) : null}
                        {numFailed ? (
                            <Typography
                                variant="caption"
                                display={'block'}
                                color={theme.palette.error.main}
                            >{`${numFailed} failed`}</Typography>
                        ) : null}
                    </>
                )}

                <NotificationItemDetailDialog>
                    {(onClose) => {
                        const { dismiss } = useNotificationItemContext();
                        const dispatch = useAppDispatch();
                        function retryErrors(): void {
                            createPush({
                                ...notification.args,
                                vehicleDeviceIds: failures,
                                projectSlug,
                            });
                        }

                        React.useEffect(() => {
                            if (didResend) {
                                onClose();
                                // istanbul ignore next
                                dismiss && dismiss();
                                dispatch(
                                    notificationSlice.actions.updateNotification({
                                        id: notification.id,
                                        retried: true,
                                    }),
                                );
                            }
                        }, [didResend]);

                        return (
                            <>
                                <DialogTitle>{`${title} Status`}</DialogTitle>
                                <DialogContent>
                                    {detail && <DialogContentText>{detail}</DialogContentText>}
                                    {numPending ? (
                                        <VehicleSection title={'Pending delivery to:'} vehicleIds={pending} />
                                    ) : null}
                                    {numSucceded ? (
                                        <VehicleSection title={'Successfully delivered to:'} vehicleIds={successes} />
                                    ) : null}
                                    {numFailed ? (
                                        <VehicleSection title={'Failed to deliver to:'} vehicleIds={failures} />
                                    ) : null}
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={onClose}>{'Close'}</Button>
                                    {numFailed ? (
                                        <LoadingButton
                                            loading={isResendingErrors}
                                            onClick={retryErrors}
                                            variant={'contained'}
                                            disabled={!isComplete || notification.retried}
                                        >
                                            {notification.retried
                                                ? 'Retry already sent'
                                                : `Retry in ${numFailed} failed ${plural(
                                                      numFailed,
                                                      'vehicle',
                                                      'vehicles',
                                                  )}`}
                                        </LoadingButton>
                                    ) : null}
                                </DialogActions>
                            </>
                        );
                    }}
                </NotificationItemDetailDialog>
            </div>
        </NotificationItemWrapper>
    );
}
