// istanbul ignore file

import { Box, Paper, TextField, Fade, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useOnMount } from '../hooks/hooks';
import { useAppTheme } from '../theme';
import { AppIconClose } from './AppIcons';
import { useHotkey } from './HotkeyProvider';
import { IconButtonSimple } from './material/IconButtonSimple';
import { noop } from '../utils/noop';

type GlobalSearch = {
    searchString: string;
    setSearchDescription: (a: React.SetStateAction<string>) => void;
};

const context = React.createContext<GlobalSearch>({
    searchString: '',
    setSearchDescription: noop,
});

type GlobalSearchProps = {
    children: React.ReactNode;
};
export function GlobalSearch({ children }: GlobalSearchProps): React.ReactElement {
    const [searchString, setSearchString] = React.useState<string>('');
    const [searchDescription, setSearchDescription] = React.useState<string>('');
    const [_show, setShow] = React.useState<boolean>(false);
    const theme = useAppTheme();
    const ref = React.useRef<HTMLInputElement>();

    const enabled = searchDescription.length > 0;
    const show = enabled && _show;

    React.useEffect(() => {
        setShow(false);
    }, [enabled]);

    React.useEffect(() => {
        if (!show) {
            setSearchString('');
        } else {
            ref.current?.focus();
        }
    }, [show, ref.current]);

    useHotkey(
        '⌘+f, ctrl+f',
        (e) => {
            if (enabled) {
                setShow((e) => !e);
                if (!show) {
                    e.preventDefault();
                    e.stopPropagation();
                }
            }
        },
        {
            description: searchDescription,
            enabled,
            unwrap: true,
            enableOnTags: ['INPUT'],
        },
        [setShow, enabled, show],
    );

    useHotkey(
        'escape',
        () => setShow(false),
        {
            description: 'Close global search',
            enabled,
            unwrap: true,
            enableOnTags: ['INPUT'],
        },
        [setShow],
    );

    return (
        <context.Provider value={{ searchString, setSearchDescription }}>
            {children}
            <Fade in={show}>
                <Box sx={{ position: 'fixed', top: 10, right: 100, zIndex: theme.zIndex.modal }}>
                    <Paper sx={{ padding: 2 }}>
                        <Typography variant={'caption'}>{searchDescription}</Typography>
                        <Stack direction={'row'} spacing={1}>
                            {show && (
                                <TextField
                                    fullWidth
                                    variant={'standard'}
                                    value={searchString}
                                    onChange={(e) => setSearchString(e.target.value)}
                                    size={'small'}
                                    inputRef={ref}
                                    inputProps={{
                                        'aria-label': 'global-search-input',
                                    }}
                                />
                            )}
                            <IconButtonSimple onClick={() => setShow(false)}>
                                <AppIconClose />
                            </IconButtonSimple>
                        </Stack>
                        <Typography variant={'caption'} color={'GrayText'}>
                            {'press cmd+f again for browser search'}
                        </Typography>
                    </Paper>
                </Box>
            </Fade>
        </context.Provider>
    );
}

export function useGlobalSearch(description: string): string {
    const { searchString, setSearchDescription } = React.useContext(context);

    useOnMount(() => {
        setSearchDescription(description);
        return () => {
            setSearchDescription('');
        };
    });

    return searchString;
}
