import * as React from 'react';

import { ConfigItemFeatureKey } from '../../types/stein.config_items';
import { Ability, AbilitySubject } from '../../types/stein';
import { DebugMaybe } from './Debug/DebugBlock';
import { FeatureMaybe } from './Feature';
import { AuthorizeMaybe } from './Authorize';

export type DebugInformation = {
    feature?: ConfigItemFeatureKey;
    auth?: Ability<AbilitySubject>;
    debugOnly?: boolean;
};

type DebugBlockProps = {
    children: React.ReactNode;
} & DebugInformation;

export function Permission({ children, feature, debugOnly, auth }: DebugBlockProps): React.ReactElement | null {
    return (
        <DebugMaybe debugOnly={debugOnly}>
            <FeatureMaybe feature={feature}>
                <AuthorizeMaybe auth={auth}>{children}</AuthorizeMaybe>
            </FeatureMaybe>
        </DebugMaybe>
    );
}
