import * as React from 'react';
import { Skeleton } from '@mui/material';
import { FullScreenButton } from '../../VideoPlayer/VideoControls/FullScreenButton';
import { PictureInPictureButton } from '../../VideoPlayer/VideoControls/PictureInPictureButton';
import { VideoPlaybackRateSelect } from '../../VideoPlayer/VideoControls/PlaybackRateSelect';
import { VideoPlayButton } from '../../VideoPlayer/VideoControls/VideoPlayButton';
import { DriverLogRotateMediaButton } from '../../VideoPlayer/VideoControls/DriverLogRotateMediaButton';
import { VideoSeek } from '../../VideoPlayer/VideoControls/VideoSeek';
import { VideoPlayerBase } from '../../VideoPlayer/VideoPlayerBase';
import { useAppSelector } from '../../../hooks/hooks';
import { selectDriverLogById } from '../../../store/selectors';
import { DriverLogId } from '../../../types/stein';
import { VideoSingleControlBar } from '../../VideoPlayer/VideoControlLayouts';

type DriverLogVideoProps = {
    driverLogId: DriverLogId;
};

export function DriverLogVideo({ driverLogId }: DriverLogVideoProps): React.ReactElement {
    const driverLog = useAppSelector((s) => selectDriverLogById(s, driverLogId));
    const vidRef = React.useRef<HTMLVideoElement>(null);
    const vidContainerRef = React.useRef<HTMLDivElement>(null);

    if (typeof driverLog === 'undefined' || !driverLog.url) {
        return (
            <Skeleton variant="rectangular" width="100%" data-testid={'video-skeleton'}>
                <div style={{ paddingTop: '57%' }} />
            </Skeleton>
        );
    }

    // istanbul ignore next line - we can't test a video in
    return (
        <VideoPlayerBase
            data-testid={'driverlog-video'}
            src={driverLog.url}
            flipped={driverLog.mediaRotation === 180}
            controls={
                <VideoSingleControlBar>
                    <VideoPlayButton videoRef={vidRef} />
                    <FullScreenButton targetRef={vidContainerRef} />
                    <PictureInPictureButton videoRef={vidRef} />
                    <VideoSeek
                        startTime={driverLog.occurredAt ? new Date(driverLog.occurredAt) : undefined}
                        videoRef={vidRef}
                    />
                    <DriverLogRotateMediaButton driverLog={driverLog} />
                    <VideoPlaybackRateSelect videoRef={vidRef} />
                </VideoSingleControlBar>
            }
            containerRef={vidContainerRef}
            videoRef={vidRef}
        />
    );
}
