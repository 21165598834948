import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { urlFacilitiesList, urlFacilitiesShow, urlSettings } from '../../../utils/internal-url-utils';
import { useActiveProject } from '../../../hooks/use-active-project';

import { useLocation, Link as RouterLink, Switch, Redirect } from 'react-router-dom';
import { appStyled } from '../../../theme';
import { MyProfile } from './SettingsMyProfile';
import { AppRoute } from '../../Routes';
import { ProjectProfile } from './SettingsProjectProfile';
import { MyNotifications } from './SettingsMyNotifications';
import { ProjectNotifications } from './SettingsProjectNotifications';
import { SettingsNotificationShow } from './SettingsNotificationShow';
import { ProjectMembers } from './SettingsProjectMembers';
import { SettingsProjectRoles } from './SettingsProjectRoles';
import { SettingsProjectDevice } from './SettingsProjectDevice';
import { SettingsProjectAdvanced } from './SettingsProjectAdvanced';
import { SideNavigationSub } from '../../AppLayout/SideNavigationSub';
import { NavigationDrawerProvider } from '../../AppLayout/NavigationDrawerContext';
import { SettingsMobileNav } from './components/SettingsShared';
import { NavigationItem, useSubNavigation } from '../../Navigation';
import { ProjectFacilityShow } from './SettingsFacilitiesShow';
import { GeofenceSlug } from '../../../types/stein';
import { SettingsFacilitiesList } from './SettingsFacilitiesList';
import { SettingsRegions } from './SettingsRegions';

const P_FACILITY = ':geofenceId' as GeofenceSlug;

function SettingsNavItem(props: NavigationItem): React.ReactElement | null {
    const location = useLocation();
    const subItems = props.subItems?.map((i) => <SettingsNavItem {...i} key={i.text} />);

    if (props.type === 'section') {
        return (
            <>
                <ListSubheader>{props.text}</ListSubheader>
                {subItems}
            </>
        );
    } else {
        return (
            <ListItem disablePadding selected={location.pathname.startsWith(props.url)}>
                <ListItemButton component={RouterLink} to={props.url}>
                    <ListItemIcon style={{ minWidth: '30px' }}>
                        <props.Icon />
                    </ListItemIcon>
                    <ListItemText primary={props.text} />
                </ListItemButton>
            </ListItem>
        );
    }
}

const ContentWrapper = appStyled('div')({
    width: '100%',
    display: 'flex',
    minHeight: 'calc(100vh - 64px)',
});

const PageWrapper = appStyled('div')(({ theme }) => ({
    flex: 2,
    maxWidth: '100vw',
    [theme.breakpoints.not('xs')]: {
        maxWidth: 'calc(100vw - 210px)',
    },
}));

export function SettingsPage(): React.ReactElement {
    const project = useActiveProject();
    const projectSlug = project.slug;
    const settings = useSubNavigation();

    return (
        <ContentWrapper>
            <NavigationDrawerProvider>
                <SideNavigationSub title={'Settings Categories'}>
                    <List dense>{settings?.map((s) => <SettingsNavItem {...s} key={s.text} />)}</List>
                </SideNavigationSub>

                <PageWrapper data-testid={'settings-page-content'}>
                    <SettingsMobileNav />

                    <Switch>
                        <Redirect exact path={urlSettings(projectSlug)} to={urlSettings(projectSlug, 'my_profile')} />
                        <AppRoute exact path={urlSettings(projectSlug, 'my_profile')} component={MyProfile} />
                        <AppRoute
                            exact
                            path={urlSettings(projectSlug, 'my_notifications')}
                            component={MyNotifications}
                            subject={'UserSettings'}
                            action={'manage_personal_notifications'}
                        />
                        <AppRoute
                            exact
                            path={urlSettings(projectSlug, 'project')}
                            component={ProjectProfile}
                            subject={'ProjectSettings'}
                            action={'update_project'}
                        />
                        <AppRoute
                            exact
                            path={urlSettings(projectSlug, 'notifications')}
                            component={ProjectNotifications}
                            subject={'ProjectSettings'}
                            action={'manage_project_notifications'}
                        />
                        <AppRoute
                            exact
                            path={urlSettings(projectSlug, 'memberships')}
                            component={ProjectMembers}
                            subject={'ProjectSettings'}
                            action={'manage_members'}
                        />
                        <AppRoute
                            exact
                            path={urlSettings(projectSlug, 'roles')}
                            component={SettingsProjectRoles}
                            subject={'ProjectSettings'}
                            action={'manage_roles'}
                        />
                        <AppRoute
                            exact
                            path={urlSettings(projectSlug, 'device')}
                            component={SettingsProjectDevice}
                            subject={'ProjectSettings'}
                            action={'manage_device_settings'}
                        />
                        <AppRoute
                            exact
                            path={urlSettings(projectSlug, 'advanced')}
                            component={SettingsProjectAdvanced}
                            subject={'ProjectSettings'}
                            action={'manage_advanced_settings'}
                        />
                        <AppRoute
                            exact
                            path={urlSettings(projectSlug, 'regions')}
                            component={SettingsRegions}
                            subject={'ProjectSettings'}
                            action={'manage_regions'}
                        />
                        <AppRoute
                            exact
                            path={urlFacilitiesList(projectSlug)}
                            component={SettingsFacilitiesList}
                            subject={'Geofence'}
                            action={'list'}
                        />
                        <AppRoute
                            exact
                            path={urlFacilitiesShow(projectSlug, P_FACILITY)}
                            component={ProjectFacilityShow}
                            subject={'Geofence'}
                            action={'update'}
                        />
                    </Switch>
                    <SettingsNotificationShow />
                </PageWrapper>
            </NavigationDrawerProvider>
        </ContentWrapper>
    );
}
