import * as React from 'react';
import { Stack, TextField } from '@mui/material';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import { useActiveProject } from '../../../hooks/use-active-project';
import { useStagedChanges, UpdateStagedChangesAction } from '../../../hooks/use-staged-changes';
import { useFrame } from '../../../lib/frame-react';
import { Project } from '../../../types/stein';
import { UpdateProjectRequest } from '../../../types/stein-internal-api';

import { SettingsPage, SettingsSaveBar } from './components/SettingsShared';
import { Permission } from '../../Authorize/PermissionBlock';

export function ProjectProfile(): React.ReactElement | null {
    const project = useActiveProject();
    const { useUpdateProjectMutation } = useFrame(SteinInternalApiClientToken);
    const [updateProject, { isLoading }] = useUpdateProjectMutation();
    const { merged, hasChanges, setChanges, save } = useStagedChanges(updateProject, project);

    // istanbul ignore next
    if (!project) {
        // istanbul ignore next
        return null;
    }

    function reset(): void {
        setChanges(null);
    }

    const shared = {
        changes: merged,
        onChange: setChanges,
    };

    return (
        <>
            <div data-testid={'page-settings-project-profile'} />
            <SettingsPage title={`${project?.name} Project Profile`}>
                <Stack spacing={2}>
                    <Field label="Project Name" attributeKey={'name'} {...shared} />
                    <Field label="Slack Bot User OAuth Access Token" attributeKey={'slackBotToken'} {...shared} />
                    <Field label="Slack Channel" attributeKey={'slackChannel'} {...shared} />
                    <Field label="Group Email for Audit Issues" attributeKey={'auditIssuesGroupEmail'} {...shared} />
                    <Permission debugOnly>
                        <Stack spacing={2}>
                            <Field label="App bundle identifier" attributeKey={'appBundleIdentifier'} {...shared} />
                            <Field label="App name" attributeKey={'appName'} {...shared} />
                        </Stack>
                    </Permission>
                </Stack>
            </SettingsPage>
            <SettingsSaveBar show={hasChanges} onSave={save} onReset={reset} loading={isLoading} />
        </>
    );
}

type FieldProps = {
    changes: UpdateProjectRequest;
    onChange: (c: UpdateStagedChangesAction<UpdateProjectRequest>) => void;
    attributeKey: keyof Project;
    label: string;
};

function Field({ changes, onChange, attributeKey, label }: FieldProps): React.ReactElement {
    return (
        <TextField
            fullWidth
            label={label}
            value={changes[attributeKey] || ''}
            onChange={(e) => {
                // create a reference to the variable because the event target might die before the callback
                const value = e.target.value;
                onChange((p) => {
                    return { ...p, [attributeKey]: value };
                });
            }}
            variant="outlined"
        />
    );
}
