import * as React from 'react';

import { ApplicationBarPopover } from '../ApplicationBarPopover';
import { AccountOptionsPopoverContent } from './AccountOptionsPopoverContent';

import { useAppTheme } from '../../../theme';

import { AppIconUser } from '../../AppIcons';

function ThemedButton(): React.ReactElement {
    const theme = useAppTheme();

    return <AppIconUser color={theme.colors.appbar.text} />;
}

export function AccountOptions(): React.ReactElement {
    return (
        <ApplicationBarPopover icon={<ThemedButton />} name={'Account Options'}>
            <AccountOptionsPopoverContent />
        </ApplicationBarPopover>
    );
}
