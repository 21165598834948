import * as React from 'react';
import { Stack, Tooltip } from '@mui/material';

import { DriverEvent } from '../../../types/stein';
import { CalloutBox, CalloutSkeleton } from '../../material/CalloutBox';
import { AppIconAlertsMuted, AppIconSoundEffect } from '../../AppIcons';
import { useAppSelector } from '../../../hooks/hooks';

type DriverEventAlertProps = {
    event: DriverEvent | undefined;
};

export function DriverEventAlert({ event }: DriverEventAlertProps): React.ReactElement {
    const { soundEffects } = useAppSelector((s) => s.audio);

    if (!event) {
        return <CalloutSkeleton name={'In Cabin Alert'} />;
    }

    const sound = soundEffects.find((e) => e.id === event.soundEffectId);
    const muted = event.muted || !sound || sound?.isMute;

    return (
        <CalloutBox name={'In Cabin Alert'}>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                {muted ? (
                    /*istanbul ignore next */ <Tooltip title={'Alert was muted.'}>
                        <AppIconAlertsMuted />
                    </Tooltip>
                ) : sound ? (
                    <>
                        <AppIconSoundEffect />
                        <strong>{sound.name}</strong>
                        {event.soundEffectPlaybackDuration ? (
                            <div>{`played for ${Math.round(event.soundEffectPlaybackDuration * 100) / 100}s`}</div>
                        ) : /*istanbul ignore next */ null}
                    </>
                ) : (
                    /*istanbul ignore next */ <>{'no sound effect'}</>
                )}
            </Stack>
        </CalloutBox>
    );
}
