import * as React from 'react';

import { FormGroup, FormControlLabel, MenuItem, SelectChangeEvent, Stack } from '@mui/material';

import { VehicleDevice, VehicleModelId } from '../../../types/stein';
import { AppIconSimulate, AppIconCameraSnapshot, AppIconScreenshot, AppIconTrash } from '../../AppIcons';

import { AutosaveSwitch } from '../../AutosaveSwitch';

import { CalloutBox, CalloutSkeleton } from '../../material/CalloutBox';
import { SelectWithLabel } from '../../material/SelectWithLabel';
import { SteinInternalApiClientToken, SteinInternalApiToken } from '../../../clients/stein-internal-api';
import { useFrame } from '../../../lib/frame-react';
import { VehiclePushButton } from '../../Vehicle/VehiclePushButton';
import { TooltipOptional } from '../../material/TooltipOptional';
import { useAppSelector } from '../../../hooks/hooks';
import { selectVehicleModelsByProjectSlug } from '../../../store/selectors';
import { useActiveProjectSlug } from '../../../hooks/use-active-project';
import { Permission } from '../../Authorize/PermissionBlock';
import { useConfirmationDialog } from '../../../hooks/use-confirmation-dialog';
import LoadingButton from '@mui/lab/LoadingButton';

type VehicleDeviceConfigurationBoxProps = {
    vehicle: VehicleDevice | undefined;
};

export function VehicleDeviceConfigurationBox({ vehicle }: VehicleDeviceConfigurationBoxProps): React.ReactElement {
    // istanbul ignore next
    if (!vehicle) {
        // istanbul ignore next
        return <CalloutSkeleton name="Configuration" />;
    }

    return (
        <CalloutBox name="Configuration">
            <Stack spacing={1.5}>
                <FormGroup>
                    <Permission auth={{ subject: 'VehicleDevice', action: 'mute_alerts_and_events' }}>
                        <VehicleUpdateToggle
                            label={'Mute Alerts & Events'}
                            vehicle={vehicle}
                            attribute={'muted'}
                            tooltip={
                                'Disabling alerts & events will turn off distraction & drowiness tracking, in-cabin audio alerts (including voice alerts), and team notifications.'
                            }
                        />
                    </Permission>
                    <Permission auth={{ subject: 'VehicleDevice', action: 'send_push' }}>
                        <VehicleUpdateToggle
                            label={'Camera'}
                            vehicle={vehicle}
                            attribute={'cameraEnabled'}
                            tooltip={
                                'Disabling the camera will disable reporting of neglect events, tamper events, sensor covered events, and anything else that requires the iPhone camera.'
                            }
                        />
                    </Permission>
                    <Permission auth={{ subject: 'VehicleDevice', action: 'manage_maintenance_attributes' }}>
                        <VehicleUpdateToggle
                            label={'Guided Access'}
                            vehicle={vehicle}
                            attribute={'guidedAccessEnabled'}
                            tooltip={
                                'Enabling Guided Access ensures that iPhone is locked to the Observant iOS app, and that iPhone should be monitored for tampering & sensor covered events.'
                            }
                        />
                    </Permission>
                    {
                        // istanbul ignore next
                        vehicle?.lastBundleVersionToI && vehicle.lastBundleVersionToI > 274 ? (
                            <Permission auth={{ subject: 'VehicleDevice', action: 'manage_maintenance_attributes' }}>
                                <VehicleUpdateToggle
                                    label={'System Test Mode'}
                                    vehicle={vehicle}
                                    attribute={'systemTestMode'}
                                    tooltip={
                                        'Puts the Observant app into a special mode to make it easier for customers to do focused tests of the Observant system.'
                                    }
                                />
                            </Permission>
                        ) : null
                    }
                </FormGroup>
                <Permission auth={{ subject: 'VehicleDevice', action: 'manage_maintenance_attributes' }}>
                    <MountOrientationSelect vehicle={vehicle} />
                    {/* <CalibrationStatusSelect vehicle={vehicle} /> */}
                    <VehicleModelSelect vehicleDevice={vehicle} />
                </Permission>
                <Permission auth={{ subject: 'VehicleDevice', action: 'send_test_alert' }}>
                    <VehiclePushButton
                        vehicle={vehicle}
                        push={{ category: 'test_alert_eye_closure' }}
                        color="error"
                        confirmation={
                            <>
                                <p>{`This will sound a loud alert in the cabin of ${vehicle.name}.`}</p>
                                <p>
                                    {`Are you sure the vehicle is not in motion, and that all vehicle occupants are aware of the incoming alert?`}
                                </p>
                            </>
                        }
                        text={'Test Eye Closure Alert'}
                    />
                </Permission>

                <Permission auth={{ subject: 'VehicleDevice', action: 'restart_ios_app' }}>
                    <VehiclePushButton
                        vehicle={vehicle}
                        push={{ customData: { killShowLast: true } }}
                        color="error"
                        confirmation={`Are you sure you want to restart Observant in ${vehicle.name}?`}
                        text={'Restart iOS App'}
                        tooltip={
                            <>
                                {
                                    'Restarts the Observant iOS app. An app restart typically takes under 5 seconds. The iOS Home screen will appear briefly.'
                                }
                            </>
                        }
                    />
                </Permission>
                <RestartDeviceButton vehicle={vehicle} />
                <Permission debugOnly>
                    <Stack spacing={1}>
                        <VehiclePushButton
                            vehicle={vehicle}
                            startIcon={<AppIconCameraSnapshot />}
                            push={{ customData: { snapshotRequested: true } }}
                            text={'Take Camera Snapshot'}
                            confirmation={'You sure?'}
                        />
                        <VehiclePushButton
                            vehicle={vehicle}
                            startIcon={<AppIconCameraSnapshot />}
                            push={{ customData: { videoRequested: true } }}
                            text={'Take Video Clip'}
                            confirmation={'You sure?'}
                        />

                        <VehiclePushButton
                            vehicle={vehicle}
                            startIcon={<AppIconScreenshot />}
                            push={{ customData: { screenshotRequested: true } }}
                            text={'Take Screenshot'}
                            confirmation={'You sure?'}
                        />
                        <VehiclePushButton
                            vehicle={vehicle}
                            startIcon={<AppIconScreenshot />}
                            push={{ category: 'app_diagnostic_snapshot_requested' }}
                            text={'Take Diagnostic Snapshot'}
                            confirmation={'You sure?'}
                        />
                        <VehiclePushButton
                            vehicle={vehicle}
                            startIcon={<AppIconScreenshot />}
                            push={{ category: 'absence_snapshot_requested' }}
                            text={'Take Absence Snapshot'}
                            confirmation={'You sure?'}
                        />
                        <VehiclePushButton
                            vehicle={vehicle}
                            startIcon={<AppIconScreenshot />}
                            push={{ customData: { resetFaceHistory: true } }}
                            text={'Reset Face History'}
                            confirmation={'You sure?'}
                        />

                        <VehiclePushButton
                            vehicle={vehicle}
                            startIcon={<AppIconSimulate />}
                            push={{ customData: { generateDriverEvent: { eventName: 'Distraction' } } }}
                            text={'Trigger Distraction'}
                            confirmation={
                                <>
                                    <p>{`This will sound a loud alert in the cabin of ${vehicle.name}.`}</p>
                                    <p>
                                        {`Are you sure the vehicle is not in motion, and that all vehicle occupants are aware of the incoming alert?`}
                                    </p>
                                </>
                            }
                        />

                        <VehiclePushButton
                            vehicle={vehicle}
                            startIcon={<AppIconSimulate />}
                            push={{ customData: { generateDriverEvent: { eventName: 'Eye closure' } } }}
                            text={'Trigger Eye closure'}
                            confirmation={
                                <>
                                    <p>{`This will sound a loud alert in the cabin of ${vehicle.name}.`}</p>
                                    <p>
                                        {`Are you sure the vehicle is not in motion, and that all vehicle occupants are aware of the incoming alert?`}
                                    </p>
                                </>
                            }
                        />

                        <VehiclePushButton
                            vehicle={vehicle}
                            startIcon={<AppIconSimulate />}
                            push={{ customData: { generateDriverEvent: { eventName: 'Eye closure early warning' } } }}
                            text={'Trigger Early Fatigue'}
                            confirmation={
                                <>
                                    <p>{`This will sound a loud alert in the cabin of ${vehicle.name}.`}</p>
                                    <p>
                                        {`Are you sure the vehicle is not in motion, and that all vehicle occupants are aware of the incoming alert?`}
                                    </p>
                                </>
                            }
                        />
                        <VehiclePushButton
                            vehicle={vehicle}
                            startIcon={<AppIconTrash />}
                            push={{ category: 'discard_until_geofence' }}
                            text={'Discard Events until Geofenced'}
                            confirmation={
                                <>
                                    <p>{`You are about to disable all events from this vehicle until it has returned to a maintenance facility.`}</p>
                                    <p>{`This cannot be easily undone. Are you sure you want to do this?`}</p>
                                </>
                            }
                        />
                    </Stack>
                </Permission>
            </Stack>
        </CalloutBox>
    );
}

type VehicleUpdateToggleProps = {
    label: string;
    vehicle: VehicleDevice;
    attribute: 'muted' | 'cameraEnabled' | 'guidedAccessEnabled' | 'continuousRecordingEnabled' | 'systemTestMode';
    tooltip?: React.ReactNode;
};

function VehicleUpdateToggle({ label, vehicle, attribute, tooltip }: VehicleUpdateToggleProps): React.ReactElement {
    const { useUpdateVehicleDeviceMutation } = useFrame(SteinInternalApiToken);
    const [update, { isLoading }] = useUpdateVehicleDeviceMutation();
    const checked = vehicle[attribute] || false;

    function onSave(newValue: boolean): void {
        update({
            id: vehicle.id,
            [attribute]: newValue,
        });
    }

    return (
        <div>
            <TooltipOptional title={label} description={tooltip}>
                <FormControlLabel
                    labelPlacement={'end'}
                    control={<AutosaveSwitch checked={checked} onSave={onSave} saving={isLoading} />}
                    label={label}
                />
            </TooltipOptional>
        </div>
    );
}

type ControlProps = {
    vehicle: VehicleDevice;
};

function RestartDeviceButton({ vehicle }: ControlProps): React.ReactElement {
    const { useRestartMdmDeviceHackMutation } = useFrame(SteinInternalApiClientToken);
    const [_restartDevice, { isLoading: isRestarting }] = useRestartMdmDeviceHackMutation();

    const restartDevice = useConfirmationDialog({
        func: () => _restartDevice({ idOrSlug: vehicle.id }),
        message: 'Are you sure you want to restart the device in this vehicle?',
    });

    return (
        <Permission feature="remote_restart_device" auth={{ subject: 'AppInstall', action: 'restart_device' }}>
            <LoadingButton
                size="small"
                fullWidth
                variant={'contained'}
                color="error"
                onClick={restartDevice.handleShouldConfirm}
                loading={isRestarting}
            >
                Restart Device
            </LoadingButton>
            {restartDevice.node}
        </Permission>
    );
}

function MountOrientationSelect({ vehicle }: ControlProps): React.ReactElement {
    const { useUpdateVehicleDeviceMutation } = useFrame(SteinInternalApiToken);
    const [update, { isLoading }] = useUpdateVehicleDeviceMutation();

    function handleChange(e: SelectChangeEvent<number>): void {
        // istanbul ignore next
        if (typeof e.target.value === 'number') {
            update({
                id: vehicle.id,
                mountOrientation: e.target.value,
            });
        }
    }

    const value = typeof vehicle.mountOrientation === 'number' ? vehicle.mountOrientation : 0;
    return (
        <SelectWithLabel
            label={'Mount Orientation'}
            value={value}
            onChange={handleChange}
            disabled={isLoading}
            tooltip={'You may need to restart the iOS app after changing this value.'}
        >
            <MenuItem value={0}>{'Camera Left'}</MenuItem>
            <MenuItem value={1}>{'Camera Right'}</MenuItem>
        </SelectWithLabel>
    );
}

// function CalibrationStatusSelect({ vehicle }: ControlProps): React.ReactElement {
//     const { useUpdateVehicleDeviceMutation } = useFrame(SteinInternalApiToken);
//     const [update, { isLoading }] = useUpdateVehicleDeviceMutation();

//     function handleChange(e: SelectChangeEvent<CalibrationStatus>): void {
//         // istanbul ignore next
//         if (typeof e.target.value === 'string') {
//             update({
//                 id: vehicle.id,
//                 calibrationStatus: e.target.value as CalibrationStatus,
//             });
//         }
//     }

//     return (
//         <SelectWithLabel
//             label={'Calibration Status'}
//             value={vehicle.calibrationStatus || /* istanbul ignore next */ ''}
//             onChange={handleChange}
//             disabled={isLoading}
//         >
//             {CALIBRATION_STATUS.map((s) => (
//                 <MenuItem value={s.id} key={s.id}>
//                     <CalibrationStatusChip status={s.id} disableTooltip isOnline={false} />
//                 </MenuItem>
//             ))}
//         </SelectWithLabel>
//     );
// }

function VehicleModelSelect({ vehicleDevice }: { vehicleDevice: VehicleDevice }): React.ReactElement | null {
    const { useUpdateVehicleDeviceMutation } = useFrame(SteinInternalApiToken);
    const projectSlug = useActiveProjectSlug();
    const [update, { isLoading }] = useUpdateVehicleDeviceMutation();
    const models = useAppSelector((s) => selectVehicleModelsByProjectSlug(s, projectSlug));

    function handleChange(e: SelectChangeEvent<VehicleModelId>): void {
        // istanbul ignore next
        const newValue = typeof e.target.value === 'number' ? e.target.value : null;

        update({
            id: vehicleDevice.id,
            vehicleModelId: newValue,
        });
    }

    return models.length ? (
        <SelectWithLabel
            label={'Vehicle Model'}
            value={vehicleDevice.vehicleModelId || /* istanbul ignore next */ ''}
            onChange={handleChange}
            disabled={isLoading}
        >
            <MenuItem value={''}>{'-- None --'}</MenuItem>
            {models.map((s) => (
                <MenuItem value={s.id} key={s.id}>
                    {s.name}
                </MenuItem>
            ))}
        </SelectWithLabel>
    ) : null;
}
