import { ElementOfArray } from '../types/util-types';

export const INFRACTION_VISUAL = {
    name: 'Visual obstruction',
    id: 'flag_visual_obstruction',
    icon: 'fa-mask',
    color: '#dc3545',
    eventField: 'flagVisualObstruction',
} as const;

export const INFRACTION_HEARING = {
    name: 'Hearing obstruction',
    id: 'flag_hearing_obstruction',
    icon: 'fa-head-side-headphones',
    color: '#dc3545',
    eventField: 'flagHearingObstruction',
} as const;

export const INFRACTION_PERSONAL_DEVICE = {
    name: 'Personal device',
    id: 'flag_personal_device',
    icon: 'fa-mobile-alt',
    color: '#dc3545',
    eventField: 'flagPersonalDevice',
} as const;

export const INFRACTION_NOTE_TAKER = {
    name: 'Note-taker issue',
    id: 'flag_note_taker',
    icon: 'fa-user-friends',
    color: '#dc3545',
    eventField: 'flagNoteTaker',
} as const;

export const INFRACTION_HANDS_OFF = {
    name: 'Hands off wheel',
    id: 'flag_hands_off_wheel',
    icon: 'fa-steering-wheel',
    color: '#dc3545',
    eventField: 'flagHandsOffWheel',
} as const;

export const INFRACTION_OTHER = {
    name: 'Other',
    id: 'flag_other_notes',
    icon: 'fa-quote-left',
    color: '#dc3545',
    eventField: 'flagOtherNotes',
} as const;

export const EVENT_INFRACTION_TOGGLES = [
    INFRACTION_VISUAL,
    INFRACTION_HEARING,
    INFRACTION_PERSONAL_DEVICE,
    INFRACTION_NOTE_TAKER,
    INFRACTION_HANDS_OFF,
] as const;

export const EVENT_INFRACTIONS = [...EVENT_INFRACTION_TOGGLES, INFRACTION_OTHER] as const;

export type EventInfraction = ElementOfArray<typeof EVENT_INFRACTIONS>;
export type EventInfractionId = EventInfraction['id'];
