import * as React from 'react';

import { AppLayout } from './AppLayout';
import { GlobalProviders } from './GlobalProviders';
import { DebugLocation } from './Authorize/Debug/DebugLocation';
import { Routes } from './Routes';
import { ErrorReporting } from './ErrorReporting';

// RootComponent is the top level component of our app.
export default function RootComponent(): React.ReactElement {
    return (
        <ErrorReporting>
            <GlobalProviders>
                <AppLayout>
                    <Routes />
                    <DebugLocation />
                </AppLayout>
            </GlobalProviders>
        </ErrorReporting>
    );
}

RootComponent.displayName = 'RootComponent';
