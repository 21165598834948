import * as React from 'react';
import { useActiveProject } from '../../../hooks/use-active-project';
import { SettingsPage } from './components/SettingsShared';
import { useFrame } from '../../../lib/frame-react';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import { CalloutBox } from '../../material/CalloutBox';
import {
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    Tooltip,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { toTitleCase } from '../../../lib/case-transforms';
import { AbilitySelectionGrid } from '../../AbilitySelection/AbilitySelectionGrid';
import { appStyled } from '../../../theme';
import { range } from 'lodash';
import { AppIconSimulate } from '../../AppIcons';
import { TooltipContent } from '../../TooltipContent';
import { useConfigItems } from '../../../hooks/use-config-items';
import { CustomRole } from '../../../types/stein';
import { Stack } from '@mui/system';

const RoleBox = appStyled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    '&:hover': {
        backgroundColor: theme.palette.grey[100],
    },
}));

function DefaultRoleSelect({ customRoles }: { customRoles: CustomRole[] }): React.ReactElement {
    const project = useActiveProject();
    const { useSetProjectConfigItemsMutation } = useFrame(SteinInternalApiClientToken);
    const getConfigItem = useConfigItems();
    const customRoleId = getConfigItem('default_custom_role_id');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [setConfigItems, { isLoading: isThresholdSaving }] = useSetProjectConfigItemsMutation();

    return (
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Default Role</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={customRoleId?.integerValue || ''}
                label="Default Role"
                onChange={(e) =>
                    setConfigItems({
                        configurationItems: [
                            { attributeKey: 'default_custom_role_id', integerValue: e.target.value as number },
                        ],
                        projectId: project.id,
                    })
                }
            >
                {customRoles.map((r) => (
                    <MenuItem value={r.id} key={r.id}>
                        {toTitleCase(r.name)}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText>This will be the role used when new users are added to the project.</FormHelperText>
        </FormControl>
    );
}

export function SettingsProjectRoles(): React.ReactElement {
    const project = useActiveProject();
    const { useGetCustomMembershipsQuery, useRoleSimulationStartMutation } = useFrame(SteinInternalApiClientToken);
    const { data, isLoading } = useGetCustomMembershipsQuery({ projectSlug: project.slug });

    const [startSimulation] = useRoleSimulationStartMutation();

    return (
        <>
            <div data-testid={'page-settings-project-roles'} />
            <SettingsPage title={`${project.name} Project Roles`}>
                <Stack spacing={2}>
                    {isLoading ? (
                        <CalloutBox name={'Roles'}>
                            <div style={{ columnCount: 2, columnRule: '1px solid' }}>
                                {range(8).map((i) => (
                                    <Skeleton height={40} key={i} />
                                ))}
                            </div>
                        </CalloutBox>
                    ) : (
                        <CalloutBox name={'Roles'}>
                            <div style={{ columnCount: 2, columnRule: '1px solid' }}>
                                {data?.customRoles.map((r) => (
                                    <RoleBox key={r.id} data-testid={`role-${r.id}`}>
                                        <div style={{ flex: 1 }}>{toTitleCase(r.name)}</div>
                                        <Tooltip
                                            title={'Cannot delete a role that has members.'}
                                            disableHoverListener={!r.hasMembers}
                                        >
                                            <div style={{ flex: 0 }}>
                                                <LoadingButton disabled={r.hasMembers}>{'Delete'}</LoadingButton>
                                            </div>
                                        </Tooltip>
                                        <Tooltip
                                            title={
                                                <TooltipContent title={'Simulate Role'}>
                                                    {"Temporarily assume this role and it's permissions."}
                                                </TooltipContent>
                                            }
                                        >
                                            <div style={{ flex: 0 }}>
                                                <IconButton onClick={() => startSimulation({ customRoleId: r.id })}>
                                                    <AppIconSimulate size={16} />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </RoleBox>
                                ))}
                            </div>
                        </CalloutBox>
                    )}
                    <DefaultRoleSelect customRoles={data?.customRoles || []} />
                </Stack>
                <AbilitySelectionGrid roles={data?.customRoles || []} loading={isLoading} />
            </SettingsPage>
        </>
    );
}
