import * as React from 'react';

import { AppIconZipArchive, AppIconDownload } from '../../AppIcons';
import { Chip, LinearProgress, ListItemText } from '@mui/material';

import { useFrame } from '../../../lib/frame-react';
import { NotificationItemWrapper } from './internal/NotificationItemWrapper';

import { useNotificationItemContext } from '.';
import { WindowToken } from '../../../lib/frame-tokens';
import { AppNotificationTypeArchive } from '../notificationTypes';
import { NoStyleLink } from '../../InvisibleLink';

function ArchiveStatusInProgress({ progress }: { progress: number }): React.ReactElement {
    return (
        <>
            <ListItemText primary={`Archive In Progress`} />
            <LinearProgress variant="determinate" value={progress} />
        </>
    );
}

function ArchiveStatusFinished({ url }: { url: string }): React.ReactElement {
    const { ack } = useNotificationItemContext();
    const window = useFrame(WindowToken);

    function onDownload(): void {
        window.open(url, '_blank');
        ack && ack();
    }

    return (
        <>
            <ListItemText primary={`Archive Ready To Download`} />
            <NoStyleLink href={url} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Chip
                    icon={
                        <div style={{ fontSize: '12px' }}>
                            <AppIconDownload />
                        </div>
                    }
                    label="Download"
                    variant="outlined"
                    onClick={onDownload}
                    size="small"
                />
            </NoStyleLink>
        </>
    );
}

function ArchiveStatusError(): React.ReactElement {
    return <ListItemText primary={`Error Creating Archive`} />;
}

function ArchiveStatus({ notification }: NotificationItemArchiveProps): React.ReactElement {
    switch (notification.status) {
        case 'pending':
            return <ArchiveStatusInProgress progress={notification.progress} />;
        case 'success':
            return <ArchiveStatusFinished url={notification.url} />;
        case 'error':
            return <ArchiveStatusError />;
    }
}

type NotificationItemArchiveProps = {
    notification: AppNotificationTypeArchive;
};

export function NotificationItemArchive({ notification }: NotificationItemArchiveProps): React.ReactElement {
    const isError = notification.status === 'error';
    const isSuccess = notification.status === 'success';
    return (
        <NotificationItemWrapper
            dismissable={Boolean(notification.status !== 'pending')}
            icon={<AppIconZipArchive color={isError ? 'red' : isSuccess ? 'green' : 'orange'} />}
        >
            <div style={{ flexDirection: 'column', display: 'flex' }}>
                <ArchiveStatus notification={notification} />
            </div>
        </NotificationItemWrapper>
    );
}
