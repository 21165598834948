import * as React from 'react';
import { useAppSelector } from '../../../../../hooks/hooks';
import { useActiveProject } from '../../../../../hooks/use-active-project';

import { selectNotificationConfigsByProjectId, selectUser } from '../../../../../store/selectors';
import {
    NotifConfigCategory,
    NotifConfigMedium,
    NotifConfig,
    NotifConfigToggleable,
    NOTIF_CONFIG_CATEGORY_BOOLEAN,
    NOTIF_CONFIG_CATEGORY_VARIABLE,
    NOTIF_CONFIG_MEDIUMS_USER,
    NOTIF_CONFIG_MEDIUMS_PROJECT,
    NOTIF_CONFIG_MEDIUM_REQUIRES_USER,
    NOTIF_CONFIG_MEDIUM_REQUIRES_PROJECT,
} from '../../../../../types/stein.notification_config';
import { noop } from '../../../../../utils/noop';

type NotificationConfigContext = {
    mediums: Readonly<Array<NotifConfigMedium>>;
    scope: 'user' | 'project';
    categoryToggle: Readonly<Array<NotifConfigToggleable['category']>>;
    categoryVariable: Readonly<Array<NotifConfigCategory>>;
    getRequiredKeyName: (s: NotifConfigMedium) => string | null;
    getRequiredPropValue: (s: NotifConfigMedium) => string | null;
    existingConfigs: Readonly<Array<NotifConfig>>;
    newNotificationConfigParams: Pick<NotifConfig, 'userId' | 'projectId'>;
};

const context = React.createContext<NotificationConfigContext>({
    mediums: [],
    scope: 'user',
    categoryToggle: [],
    categoryVariable: [],
    getRequiredKeyName: noop,
    getRequiredPropValue: noop,
    existingConfigs: [],
    newNotificationConfigParams: {
        userId: null,
        projectId: null,
    },
});

export function NotifConfigContextProviderUser({ children }: { children: React.ReactNode }): React.ReactElement {
    const project = useActiveProject();
    const userConfigs = useAppSelector((s) => selectNotificationConfigsByProjectId(s, project.id)).filter((c) =>
        Boolean(c.userId),
    );
    const user = useAppSelector(selectUser);

    return (
        <context.Provider
            value={{
                scope: 'user',
                mediums: NOTIF_CONFIG_MEDIUMS_USER,
                categoryToggle: NOTIF_CONFIG_CATEGORY_BOOLEAN,
                categoryVariable: NOTIF_CONFIG_CATEGORY_VARIABLE,
                getRequiredPropValue: (prop) => {
                    const key = NOTIF_CONFIG_MEDIUM_REQUIRES_USER[prop];
                    // istanbul ignore next
                    if (!key) {
                        // istanbul ignore next
                        return null;
                    }
                    return user[key] || null;
                },
                getRequiredKeyName: (prop) => NOTIF_CONFIG_MEDIUM_REQUIRES_USER[prop],
                existingConfigs: userConfigs,
                newNotificationConfigParams: {
                    projectId: project.id,
                    userId: user.id,
                },
            }}
        >
            {children}
        </context.Provider>
    );
}

export function NotifConfigContextProviderProject({ children }: { children: React.ReactNode }): React.ReactElement {
    const project = useActiveProject();
    const projectConfigs = useAppSelector((s) => selectNotificationConfigsByProjectId(s, project.id)).filter(
        (c) => !c.userId,
    );

    return (
        <context.Provider
            value={{
                scope: 'project',
                mediums: NOTIF_CONFIG_MEDIUMS_PROJECT,
                categoryToggle: NOTIF_CONFIG_CATEGORY_BOOLEAN,
                categoryVariable: NOTIF_CONFIG_CATEGORY_VARIABLE,
                getRequiredPropValue: (prop) => {
                    const key = NOTIF_CONFIG_MEDIUM_REQUIRES_PROJECT[prop];
                    // istanbul ignore next
                    if (!key) {
                        // istanbul ignore next
                        return null;
                    }
                    return project[key] || null;
                },
                getRequiredKeyName: (prop) => NOTIF_CONFIG_MEDIUM_REQUIRES_PROJECT[prop],
                existingConfigs: projectConfigs,
                newNotificationConfigParams: {
                    projectId: project.id,
                },
            }}
        >
            {children}
        </context.Provider>
    );
}

export function useNotifConfigContext(): NotificationConfigContext {
    return React.useContext(context);
}
