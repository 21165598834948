import * as React from 'react';
import { DriverEvent } from '../../../types/stein';

type DriverEventDurationProps = {
    event: DriverEvent | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function DriverEventDuration({ event }: DriverEventDurationProps): React.ReactElement {
    // temporarily disabling showing event duration for 12/15/22 demo
    // when you undo this, remove .skip from this string it.skip.each(driverEventDurationCases) in
    // app/javascript/web-application/components/pages/DriverEventShow/__tests__/DriverEventDuration.test.tsx
    return <React.Fragment />;

    // if (!event) {
    //     return <CalloutSkeleton name={'Event Duration'} />;
    // }

    // const minDuration = `${event?.minDistractionDuration?.toFixed(1)}s`;
    // const maxDuration = `${event?.maxDistractionDuration?.toFixed(1)}s`;

    // const eventName = event.eventName as EventTypeName;

    // if (eventName === 'Distraction' || eventName === 'Severe fatigue') {
    //     return (
    //         <CalloutBox name={'Event Duration'}>
    //             <div style={{ display: 'flex', alignItems: 'center' }}>
    //                 {`${minDuration} - ${maxDuration}`}
    //                 <InfoTip
    //                     text={`Driver ${neglectPreterite(
    //                         eventName,
    //                     )} for at least ${minDuration} and up to ${maxDuration} before they were alerted`}
    //                 />
    //             </div>
    //         </CalloutBox>
    //     );
    // }

    // return (
    //     <CalloutBox name={'Event Duration'}>
    //         <Typography variant="caption"> {`${eventName} events do not have a duration`}</Typography>
    //     </CalloutBox>
    // );
}
