import React from 'react';

export function useDialogState(): { isOpen: boolean; onOpen: () => void; onClose: () => void } {
    const [isOpen, setOpen] = React.useState(false);

    return {
        isOpen,
        onClose: () => setOpen(false),
        onOpen: () => setOpen(true),
    };
}
