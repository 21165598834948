import { EventTypeName } from '../../../constants/event-type-constants';

export function neglectPreterite(s: EventTypeName): string {
    switch (s) {
        case 'Distraction':
            return 'had eyes away from road';
        case 'Severe fatigue':
            return 'displayed signs of severe fatigue';
        default:
            return 'unknown';
    }
}
