import { useMediaQuery } from '@mui/material';
import * as React from 'react';
import { useOnLocationChange } from '../../hooks/hooks';
import { useAppTheme } from '../../theme';
import { noop } from '../../utils/noop';

type NavigationDrawerContext = {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isDesktop: boolean;
};
const sideNavContext = React.createContext<NavigationDrawerContext>({
    isOpen: false,
    setIsOpen: noop,
    isDesktop: true,
});

export function NavigationDrawerProvider({ children }: { children: React.ReactNode }): React.ReactElement {
    const theme = useAppTheme();
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    useOnLocationChange(() => {
        if (!isDesktop) {
            setIsOpen(false);
        }
    });

    return (
        <sideNavContext.Provider
            value={{
                isOpen,
                setIsOpen,
                isDesktop,
            }}
        >
            {children}
        </sideNavContext.Provider>
    );
}

export function useNavigationDrawerContext(): NavigationDrawerContext {
    return React.useContext(sideNavContext);
}
