import * as React from 'react';
import { appStyled } from '../../theme';
import { ActivityLogItem } from './activity_log_utils';
import { ActivityLogItemNotification } from './items/ActivityLogItemNotification';
import { ActivityLogItemVersion } from './items/ActivityLogItemVersion';
import { ActivityLogContext, ActivityLogContextProvider } from './ActivityLogContext';
import { ActivityLogItemAnnouncement } from './items/ActivityLogItemAnnouncement';
import { ActivityLogItemWebEvent } from './items/ActivityLogWebEvent';

const ActivityLogWrapper = appStyled('div')({
    display: 'table',
    fontSize: '0.75em',
});

type ActivityLogProps = ActivityLogContext & {
    items: Array<ActivityLogItem>;
};

export function ActivityLog({ items, ...context }: ActivityLogProps): React.ReactElement {
    return (
        <ActivityLogContextProvider {...context}>
            <>
                <ActivityLogWrapper>
                    {items.length ? (
                        items.map((item, i) => {
                            switch (item.type) {
                                case 'version':
                                    return <ActivityLogItemVersion version={item} key={i} />;
                                case 'webEvent':
                                    return <ActivityLogItemWebEvent webEvent={item} key={i} />;
                                case 'notification':
                                    return <ActivityLogItemNotification notification={item} key={i} />;
                                case 'announcement':
                                    return <ActivityLogItemAnnouncement announcement={item} key={i} />;
                            }
                        })
                    ) : (
                        <div>{'No activity'}</div>
                    )}
                </ActivityLogWrapper>
            </>
        </ActivityLogContextProvider>
    );
}
