import * as React from 'react';
import { IconButton } from '@mui/material';

import { AppIconResetSelection } from '../AppIcons';
import { useAppTheme } from '../../theme';
import { useAppSelector } from '../../hooks/hooks';
import { FilterContextName } from '../../store/slices/filterConfigSlice';
import { selectFilterConfig } from '../../store/selectors';
import { useFilters } from '../../hooks/use-filters';

type FilterResetProps = {
    filterContextName: FilterContextName;
};

export function FilterReset({ filterContextName }: FilterResetProps): React.ReactElement {
    const theme = useAppTheme();
    const { setFilters } = useFilters();
    const { activePreset } = useAppSelector((s) => selectFilterConfig(s, filterContextName));

    return (
        <IconButton
            style={{ color: theme.palette.common.white }}
            size={'small'}
            onClick={() => setFilters(activePreset.filters)}
        >
            <AppIconResetSelection />
        </IconButton>
    );
}
