// istanbul ignore file
import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { GeoResult, useLocationSearch } from '../hooks/useLocationSearch';
import { FilterBarTextInput } from './FilterBar/FilterBarBaseTypes';
import { SxProps } from '@mui/material';

export function AddressInput({
    label,
    value,
    onChange,
    sx,
}: {
    label: string;
    value: GeoResult | null;
    onChange: (v: GeoResult | null) => void;
    sx: SxProps | undefined;
}): React.ReactElement {
    const [inputValue, setInputValue] = React.useState('');
    const { data: options } = useLocationSearch(inputValue);

    return (
        <Autocomplete
            id="address search"
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.address)}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            size="small"
            value={value}
            noOptionsText="No locations"
            sx={sx}
            onChange={(event: unknown, newValue: GeoResult | null) => {
                onChange(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => <FilterBarTextInput {...params} label={label} fullWidth />}
            renderOption={(props, option) => {
                return (
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item sx={{ display: 'flex', width: 44 }}>
                                <LocationOnIcon sx={{ color: 'text.secondary' }} />
                            </Grid>
                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                <Typography variant="body2" color="text.secondary">
                                    {option.address}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
}
