import { Chip, Grid } from '@mui/material';
import * as React from 'react';
import { VehicleDeviceId } from '../../types/stein';
import { VehicleChip } from '../AppChip';

type VehicleGridProps = {
    vehicleIds: Array<VehicleDeviceId>;
};

const VEHICLES_TO_SHOW = 11;
export function VehicleGrid({ vehicleIds }: VehicleGridProps): React.ReactElement {
    const [numToShow, setNumToShow] = React.useState<number>(VEHICLES_TO_SHOW);
    const toShow = vehicleIds.slice(0, numToShow);
    return (
        <Grid container spacing={1}>
            {toShow.map((id) => (
                <Grid item key={id}>
                    <VehicleChip vehicleSlugOrId={id} size={'small'} />
                </Grid>
            ))}
            {vehicleIds.length > numToShow ? (
                <Grid item>
                    <Chip
                        size="small"
                        color="primary"
                        label={`Show all ${vehicleIds.length}`}
                        onClick={() => setNumToShow(vehicleIds.length)}
                    ></Chip>
                </Grid>
            ) : null}
        </Grid>
    );
}
