import * as React from 'react';

import { VehicleDevice } from '../../../types/stein';

import { Button } from '@mui/material';

import { AppInstallRegistrationDialog } from '../../AppInstallRegistrationDialog';
import { Permission } from '../../Authorize/PermissionBlock';

type VehicleDeviceAppInstallLinkProps = {
    vehicle: VehicleDevice | undefined;
};

export function VehicleDeviceAppInstallLink({ vehicle }: VehicleDeviceAppInstallLinkProps): React.ReactElement {
    const [open, setOpen] = React.useState(false);

    // istanbul ignore next
    function handleClose(): void {
        // istanbul ignore next
        setOpen(false);
    }

    return (
        <Permission debugOnly>
            <Button variant={'contained'} onClick={() => setOpen((s) => !s)} fullWidth>
                {'Link Device'}
            </Button>
            <AppInstallRegistrationDialog vehicleDeviceId={vehicle?.id} open={open} onClose={handleClose} />
        </Permission>
    );
}
