import * as React from 'react';
import { useAppSelector } from '../../hooks/hooks';
import { useActiveProjectSlugOptional } from '../../hooks/use-active-project';

import { selectDebugEnabled, selectHasFeature } from '../../store/selectors';
import { appStyled } from '../../theme';

import { ConfigItemFeatureKey } from '../../types/stein.config_items';
import { CalloutBox } from '../material/CalloutBox';

type MaybeFeatureProps = {
    children: React.ReactNode;
    feature?: ConfigItemFeatureKey | undefined;
};

const FeatureDebugBase = appStyled(CalloutBox)(({ theme }) => ({
    '&.MuiPaper-root': {
        borderColor: theme.colors.debugBlock.feature.border,
        backgroundColor: theme.colors.debugBlock.feature.background,
        borderStyle: 'dashed',
    },
}));

export function FeatureMaybe({ children, feature }: MaybeFeatureProps): React.ReactElement {
    if (feature) {
        return <Feature name={feature}>{children}</Feature>;
    }
    return <>{children}</>;
}

type FeatureProps = {
    children: React.ReactNode;
    name: ConfigItemFeatureKey;
};

function Feature({ children, name }: FeatureProps): React.ReactElement | null {
    const hasFeature = useHasFeature(name);
    const debugEnabled = useAppSelector(selectDebugEnabled);

    if (debugEnabled) {
        return (
            <FeatureDebugBase name={name} disablePadding enableHighlight>
                {children}
            </FeatureDebugBase>
        );
    } else if (hasFeature) {
        return <>{children}</>;
    }
    return null;
}

export function useHasFeature(name: ConfigItemFeatureKey): boolean {
    const projectSlug = useActiveProjectSlugOptional();

    return useAppSelector((s) =>
        projectSlug ? selectHasFeature(s, name, projectSlug) : /*istanbul ignore next*/ false,
    );
}

export function useHasFeatureOpt(name: ConfigItemFeatureKey | undefined): boolean {
    const projectSlug = useActiveProjectSlugOptional();

    return useAppSelector(
        (s) =>
            typeof name === 'undefined' ||
            (projectSlug ? selectHasFeature(s, name, projectSlug) : /*istanbul ignore next*/ false),
    );
}
