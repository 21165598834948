import { AppReduxStoreToken } from '../store';
import { changeLocation } from '../store/actions';
import { FrameResolver } from './frame';
import { HistoryToken } from './frame-tokens';

export function watchFrameLocation({ resolve }: FrameResolver): void {
    const history = resolve(HistoryToken);
    const store = resolve(AppReduxStoreToken);

    store.dispatch(
        changeLocation({
            new: history.location,
        }),
    );

    history.listen((loc) => {
        return store.dispatch(
            changeLocation({
                new: loc,
            }),
        );
    });
}
