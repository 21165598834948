import { createPlugin, newApp } from './lib/frame';
import { ReactRootToken, convertToReactApp, FrameReactApp } from './lib/frame-react';
import {
    EnvToken,
    FetchToken,
    WindowToken,
    LoggerToken,
    HistoryToken,
    ActionCableToken,
    PathPrefixToken,
} from './lib/frame-tokens';

import RootComponent from './components/RootComponent';
import { AppReduxStorePlugin, AppReduxStoreToken } from './store';
import {
    SteinInternalApiToken,
    SteinInternalApiPlugin,
    SteinInternalApiClientToken,
} from './clients/stein-internal-api';
import { AppReducerToken, AppReducerPlugin } from './store/reducer';
import { SteinRootApiToken, SteinRootApiPlugin } from './clients/stein-root-api';
import { createBrowserHistory } from 'history';
import { watchFrameLocation } from './lib/frame-location';
import { LicenseInfo } from '@mui/x-license';
import * as ActionCable from '@rails/actioncable';
import { SFXToken, DEFAULT_SFX, SFXListenerToken, SFXListenerPlugin } from './services/app-sfx-plugin';
import { startActionCableSubscription } from './services/app-action-cable-subscription';
import { DataListenerPlugin, DataListenerToken } from './services/app-data-listener';
import { NotificationListenerPlugin, NotificationListenerToken } from './services/app-notification-listener';
import {
    IntercomServicePlugin,
    IntercomServiceToken,
    TwilioVideoConnectToken,
    TwilioAudioDeviceToken,
} from './services/app-intercom-service';

import { connect } from 'twilio-video';
import { Device } from '@twilio/voice-sdk';
import { IntercomListenerPlugin, IntercomListenerToken } from './services/app-intercom-listener';
import { AnalyticsMiddlewarePlugin, AnalyticsMiddlewareToken } from './services/app-analytics-listener';
import { AnalyticsPlugin, AnalyticsToken } from './lib/frame-analytics';
import { VideoCallServicePlugin, VideoCallServiceToken } from './services/video-call-service/video-call-service';

LicenseInfo.setLicenseKey(
    '758e566008881ed6080fbe700dd25c1aTz03ODE2NixFPTE3MzA5Njg3OTQwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=',
);

export function getApp(): FrameReactApp {
    const app = newApp();
    app.register(FetchToken, window.fetch);
    app.register(EnvToken, {});
    app.register(ReactRootToken, RootComponent);
    app.register(AppReducerToken, AppReducerPlugin);
    app.register(AppReduxStoreToken, AppReduxStorePlugin);
    app.register(SteinInternalApiToken, SteinInternalApiPlugin);
    app.register(SteinInternalApiClientToken, SteinInternalApiPlugin);
    app.register(SteinRootApiToken, SteinRootApiPlugin);
    app.register(WindowToken, window);
    app.register(PathPrefixToken, '/');
    app.register(SFXToken, DEFAULT_SFX);
    app.register(SFXListenerToken, SFXListenerPlugin);
    app.register(TwilioVideoConnectToken, connect);
    app.register(TwilioAudioDeviceToken, Device);
    app.register(IntercomServiceToken, IntercomServicePlugin);
    app.register(VideoCallServiceToken, VideoCallServicePlugin);
    app.register(NotificationListenerToken, NotificationListenerPlugin);
    app.register(DataListenerToken, DataListenerPlugin);
    app.register(IntercomListenerToken, IntercomListenerPlugin);
    app.register(AnalyticsToken, AnalyticsPlugin);
    app.register(AnalyticsMiddlewareToken, AnalyticsMiddlewarePlugin);
    app.register(
        ActionCableToken,
        createPlugin(
            /* istanbul ignore next -- we are lazy loading this specifically so its not called in tests */
            () => ActionCable.createConsumer(),
        ),
    );

    app.register(
        HistoryToken,
        createPlugin(
            /* istanbul ignore next -- we are lazy loading this specifically so its not called in tests */
            ({ resolve }) =>
                createBrowserHistory({
                    basename: resolve(PathPrefixToken),
                }),
        ),
    );
    app.register(LoggerToken, console);
    app.invoke(watchFrameLocation);
    app.invoke(startActionCableSubscription);

    return convertToReactApp(app);
}
