import * as React from 'react';

import { NotificationItemWrapper } from './internal/NotificationItemWrapper';
import { AppNotificationTypeGeneric } from '../notificationTypes';
import { ListItemText } from '@mui/material';

type NotificationItemGenericProps = {
    notification: AppNotificationTypeGeneric;
};

export function NotificationItemGeneric({ notification }: NotificationItemGenericProps): React.ReactElement {
    return (
        <NotificationItemWrapper autoAckMs={3000} type={notification.kind}>
            <div style={{ flexDirection: 'column', display: 'flex' }}>
                <ListItemText
                    secondaryTypographyProps={{
                        component: 'div',
                    }}
                    primary={notification.title}
                    secondary={notification.content?.map((s) => <div key={s}>{s}</div>)}
                />
            </div>
        </NotificationItemWrapper>
    );
}
