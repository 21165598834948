// istanbul ignore file there isn't any logic here
import * as React from 'react';
import { Box, keyframes } from '@mui/material';

const noiseKeyframe = keyframes`
0% {
    transform: translateX(0px,0px); }
10% {
    transform: translate(-100px, 100px);
}
20% {
    transform: translate(150px, -100px);
}
30% {
    transform: translate(-100px,100px);
}
40% {
    transform: translate(100px, -150px);
}
50% {
    transform: translate(-100px, 200px);
}
60% {
    transform: translate(-200px, -100px);
}
70% {
    transform: translateY(50px, 100px);
}
80% {
    transform: translate(100px, -150px);
}
90% {
    transform: translate(0px, 200px);
}
100% {
    transform: translate(-100px, 100px);
}
`;

export function Noise(): React.ReactElement | null {
    return (
        <Box
            sx={{
                position: 'absolute',
                top: '-500px',
                right: '-500px',
                bottom: '-500px',
                left: '-500px',
                background: 'transparent url(/images/noise.png) 0 0',
                backgroundSize: '320px 320px',
                opacity: 0.35,
                animation: `${noiseKeyframe} 1s steps(8, end) infinite both`,
            }}
        />
    );
}
