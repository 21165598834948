import * as React from 'react';

import Tooltip from '@mui/material/Tooltip';
import { TooltipContent } from '../TooltipContent';

type TooltipProps = React.ComponentProps<typeof Tooltip>;

type TooltipOptionalProps = Omit<TooltipProps, 'title'> & {
    title?: string;
    description?: React.ReactNode;
};

export function TooltipOptional({ title, description, children, ...props }: TooltipOptionalProps): React.ReactElement {
    return title && description ? (
        <Tooltip title={<TooltipContent title={title}>{description}</TooltipContent>} {...props} placement={'right'}>
            {children}
        </Tooltip>
    ) : (
        children
    );
}
