/* istanbul ignore file */
import * as React from 'react';

import { Dialog, DialogContent, DialogProps, DialogTitle, MenuItem, Stack, TextField } from '@mui/material';
import { useActiveProjectSlug } from '../../../hooks/use-active-project';
import { useAppSelector } from '../../../hooks/hooks';
import { selectVehiclesByProjectSlug } from '../../../store/selectors';
import { AppInstall, VehicleDeviceId } from '../../../types/stein';
import { useFrame } from '../../../lib/frame-react';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import LoadingButton from '@mui/lab/LoadingButton';
import { AppIconDevice } from '../../AppIcons';

type EditAppInstallDialogProps = Omit<DialogProps, 'open'> & {
    appInstall?: AppInstall;
};

export function EditAppInstallDialog({ appInstall, ...props }: EditAppInstallDialogProps): React.ReactElement {
    const projectSlug = useActiveProjectSlug();
    const vehicles = useAppSelector((s) => selectVehiclesByProjectSlug(s, projectSlug));
    const { useUpdateAppInstallMutation } = useFrame(SteinInternalApiClientToken);
    const [update, { isLoading }] = useUpdateAppInstallMutation();

    const [newVehicleId, setNewVehicleId] = React.useState<VehicleDeviceId | null>(appInstall?.vehicleDeviceId || null);
    const [newLabel, setNewLabel] = React.useState<string>(appInstall?.label || '');

    const newVehicle = vehicles.find((v) => v.id === newVehicleId && v.id !== appInstall?.vehicleDeviceId);

    const open = Boolean(appInstall);
    React.useEffect(() => {
        if (open) {
            setNewVehicleId(appInstall?.vehicleDeviceId || null);
            setNewLabel(appInstall?.label || '');
        }
    }, [open]);

    function handleChange(): void {
        if (appInstall) {
            update({
                id: appInstall.id,
                vehicleDeviceId: newVehicleId,
                label: newLabel,
            });
        }
    }

    return (
        <Dialog {...props} open={open} maxWidth={'md'}>
            <DialogTitle>{'Edit Device Configuration'}</DialogTitle>
            <DialogContent
                sx={{ width: '500px', maxWidth: '100%' }}
                data-testid={'edit-app-install-dialog'}
                data-saving={isLoading}
            >
                <Stack spacing={2} mt={1}>
                    <TextField
                        id="change-app-install-nale"
                        label={'Device Name'}
                        value={newLabel}
                        onChange={(e) => {
                            setNewLabel(e.target.value);
                        }}
                        fullWidth
                    />
                    <TextField
                        id="select-vehicle-for-app-install"
                        label={'Assigned Vehicle'}
                        helperText={
                            newVehicle?.appInstall ? (
                                <div>
                                    <p>
                                        <span>{`${newVehicle.name} is currently paired with `}</span>
                                        {newVehicle?.appInstall.label ? (
                                            <strong>{newVehicle?.appInstall.label}</strong>
                                        ) : newVehicle?.appInstall.serialNumber ? (
                                            <strong>{newVehicle?.appInstall.serialNumber}</strong>
                                        ) : (
                                            'another device'
                                        )}
                                    </p>
                                    <p>
                                        {`Saving now will replace that device in ${newVehicle.name} with this one, and future events it emits will be lost.`}
                                    </p>
                                </div>
                            ) : undefined
                        }
                        value={newVehicleId || ''}
                        onChange={(e) => {
                            setNewVehicleId(parseInt(e.target.value) as VehicleDeviceId);
                        }}
                        fullWidth
                        select
                    >
                        <MenuItem value={''}> - None - </MenuItem>
                        {vehicles.map((v) => (
                            <MenuItem key={v.id} value={v.id}>
                                <Stack spacing={1} direction={'row'} alignItems={'center'}>
                                    <span>{v.name}</span>
                                    {v.appInstall ? <AppIconDevice /> : null}
                                </Stack>
                            </MenuItem>
                        ))}
                    </TextField>
                    <LoadingButton loading={isLoading} onClick={handleChange} fullWidth variant="contained">
                        Save
                    </LoadingButton>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}
