import { FrameResolver } from '../lib/frame';
import { AppReduxStoreToken } from '../store';
import { ActionCableToken, LoggerToken } from '../lib/frame-tokens';
import { actionCableDriverEvent, setIsConnected } from '../store/actions';
import { ActionCableDriverEvent } from '../types/stein';
import { SnakeCasedProperties } from 'type-fest';
import { toCamelCase } from '../lib/case-transforms';
import { EVENT_TYPE_MAP_ID } from '../constants/event-type-constants';

type RawEvent = SnakeCasedProperties<ActionCableDriverEvent>;

export function startActionCableSubscription({ resolve }: FrameResolver): void {
    const log = resolve(LoggerToken);
    const store = resolve(AppReduxStoreToken);
    const cable = resolve(ActionCableToken);

    cable?.subscriptions.create('DriverEventNotificationsChannel', {
        received: function (rawData: RawEvent) {
            const event = toCamelCase(rawData) as ActionCableDriverEvent;
            if (!EVENT_TYPE_MAP_ID[event.eventName]) {
                log.error('received actioncable event for unknown eventName', event);
                return;
            }

            store.dispatch(actionCableDriverEvent(event));
        },
        initialized: function () {
            log.debug('initialized');
        },
        connected: function () {
            store.dispatch(setIsConnected(true));
        },
        disconnected: function () {
            store.dispatch(setIsConnected(false));
        },
        rejected: function () {
            log.debug('rejected');
        },
    });
}
