import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Project } from '../../types/stein';
import { setRailsContext } from './shared-actions';

export const projectsAdapter = createEntityAdapter<Project>({
    sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const projectsSlice = createSlice({
    name: 'projects',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState: projectsAdapter.getInitialState(),
    reducers: {
        setProject(s, a: PayloadAction<Project>) {
            return projectsAdapter.setOne(s, a.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setRailsContext, (s, a) => {
            projectsAdapter.setAll(s, a.payload.projects);
        });
    },
});
