// istanbul ignore file
import { AnalyticsBrowser } from '@segment/analytics-next';

import { createPlugin, createToken } from '../frame';
import { EnvToken } from '../frame-tokens';

export type AppAnalytics = AnalyticsBrowser;

export const AnalyticsToken = createToken<AppAnalytics>('AnalyticsToken');

export const AnalyticsPlugin = createPlugin<AppAnalytics>(({ resolve }) => {
    const env = resolve(EnvToken);

    if (!env.SEGMENT_JAVASCRIPT_WRITE_KEY) {
        console.error('No SEGMENT_JAVASCRIPT_WRITE_KEY in environment');
    }

    const analytics = AnalyticsBrowser.load({ writeKey: env.SEGMENT_JAVASCRIPT_WRITE_KEY || '0000' });

    // @ts-expect-error just set this for debugging purposes
    window.analytics = analytics;

    return analytics;
});
