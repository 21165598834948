import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectProps } from '@mui/material/Select';

export function SelectSmall(props: SelectProps): React.ReactElement {
    return (
        <FormControl size="small">
            <InputLabel id={props.labelId}>{props.label}</InputLabel>
            <Select {...props} />
        </FormControl>
    );
}
