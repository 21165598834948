import * as React from 'react';
import { MapContainer, FeatureGroup } from 'react-leaflet';
import { useAppSelector } from '../../../hooks/hooks';
import { useActiveProjectSlug } from '../../../hooks/use-active-project';

import { useForceRefresh } from '../../../hooks/use-force-refresh';
import { selectGeofencesByProjectSlug, selectVehiclesByProjectSlug } from '../../../store/selectors';
import { MapTiles } from '../../Map/MapTiles';
import { AppIconRefresh, AppIconRemoveItem } from '../../AppIcons';
import { appStyled } from '../../../theme';
import { filterGeofenceLocated } from '../../../utils/filter-utils';
import { AutoCenter } from '../../Map/AutoCenter';
import { Box, Button, FormLabel, ListItem, ListItemButton, ListSubheader, Slider, Stack } from '@mui/material';
import { CustomControl } from '../../Map/CustomControl';
import { MapInteraction } from '../../Map/MapInteraction';
import { GeofenceMarker } from '../../Map/GeofenceMarker';
import { useFrame } from '../../../lib/frame-react';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import { AddressInput } from '../../AddressSelector';
import { MapCircleEditor } from '../../Map/CircleEditor';
import { FilterDateTimePicker } from '../../FilterBar/FilterDateRangePicker';
import { FilterBar } from '../../FilterBar';
import { useFilterDateTime, useFilterGeoLocation } from '../../../hooks/use-filters';
import LoadingButton from '@mui/lab/LoadingButton';
import { toISO8601 } from '../../../utils/datetime-utils';
import { DriverEventMarker } from '../../Map/DriverEventMarker';
import { VehicleDevice, VehicleDeviceId } from '../../../types/stein';
import { IconButtonSimple } from '../../material/IconButtonSimple';
import { PingMarker } from '../../Map/PingMarker';
import { GeosearchReq } from '../../../types/stein-internal-api';
import { addHours, subHours } from 'date-fns';
import { GeoResult } from '../../../hooks/useLocationSearch';

const MapWrapper = appStyled('div')({
    height: '100%',
    flex: 2,
    '& .leaflet-div-icon': {
        background: 'none',
        border: 'none',
    },
});

const SideBar = appStyled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minWidth: '200px',
    width: '200px',
    maxWidth: '200px',
    borderRight: '1px solid',
    borderColor: theme.palette.divider,
    marginRight: 1,
}));

// istanbul ignore next
function resolveDateToNow(d: Date | null | undefined): Date {
    return d ? d : new Date();
}

export function GeoSearchPage(): React.ReactElement {
    const { useLazyGeosearchQuery } = useFrame(SteinInternalApiClientToken);
    const projectSlug = useActiveProjectSlug();
    const { date } = useFilterDateTime();
    const [selectedVehicleId, setSelectedVehicleId] = React.useState<VehicleDeviceId>();

    const { useGetVehicleDevicesQuery } = useFrame(SteinInternalApiClientToken);
    useGetVehicleDevicesQuery({ projectSlug, isBackground: false });

    const geofences = useAppSelector((s) => selectGeofencesByProjectSlug(s, projectSlug)).filter(filterGeofenceLocated);
    const refresh = useForceRefresh();
    const [autoMap, setAutoMap] = React.useState(true);
    const { location, setLocation } = useFilterGeoLocation({
        latitude: 33.4942,
        longitude: 111.9261,
        radius: 1000,
    });

    const [geosearch, { data, isFetching }] = useLazyGeosearchQuery();

    const driverEvents = data?.driverEvents || [];
    const filteredDriverEvents = driverEvents.filter(
        (de) => !selectedVehicleId || de.vehicleDeviceId === selectedVehicleId,
    );

    const filteredPings = (data?.pings || []).filter(
        (de) => !selectedVehicleId || de.vehicleDeviceId === selectedVehicleId,
    );
    const vehicleMap = useAppSelector((s) => selectVehiclesByProjectSlug(s, projectSlug)).reduce(
        (obj, v) => {
            obj[v.id] = v;
            return obj;
        },
        {} as Record<number, VehicleDevice>,
    );

    const shownVehicles = React.useMemo(() => {
        const vehicleSet = new Set<VehicleDeviceId>();
        data?.driverEvents.forEach((de) => de.vehicleDeviceId && vehicleSet.add(de.vehicleDeviceId));
        data?.pings.forEach((de) => de.vehicleDeviceId && vehicleSet.add(de.vehicleDeviceId));
        return Array.from(vehicleSet);
    }, [data]);

    //istanbul ignore next
    function handleAddressSelected(g: GeoResult): void {
        if (g) {
            setLocation({
                ...location,
                latitude: g.latitude,
                longitude: g.longitude,
            });
        }
    }

    function resetVehicleSelection(): void {
        setSelectedVehicleId(undefined);
    }

    //istanbul ignore next
    function enableAutoMap(): void {
        //istanbul ignore next
        setAutoMap(true);
    }

    //istanbul ignore next
    function disableAutoMap(): void {
        //istanbul ignore next
        setAutoMap(false);
    }

    return (
        <Box sx={{ display: 'flex', height: '100%' }} data-testid={'page-geosearch'}>
            <SideBar>
                <ListSubheader>Vehicles</ListSubheader>
                {shownVehicles.map((vId) => (
                    <ListItem
                        key={vId}
                        disableGutters
                        disablePadding
                        secondaryAction={
                            vId === selectedVehicleId ? (
                                <IconButtonSimple
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        paddingRight: '15px',
                                    }}
                                    onClick={resetVehicleSelection}
                                >
                                    <AppIconRemoveItem />
                                </IconButtonSimple>
                            ) : undefined
                        }
                    >
                        <ListItemButton
                            onClick={() =>
                                vId === selectedVehicleId ? resetVehicleSelection() : setSelectedVehicleId(vId)
                            }
                            selected={vId === selectedVehicleId}
                        >
                            {vehicleMap[vId]?.name}
                        </ListItemButton>
                    </ListItem>
                ))}
            </SideBar>
            <Stack spacing={1} flexGrow={1} padding={1}>
                <FilterBar icons={[]}>
                    <Stack spacing={1}>
                        <Stack direction={'row'} spacing={1}>
                            <AddressInput
                                sx={{ flexGrow: 2 }}
                                label={'Jump to Address'}
                                value={null}
                                onChange={handleAddressSelected}
                            />
                            <FilterDateTimePicker />
                        </Stack>

                        <Stack direction={'row'} spacing={2}>
                            <FormLabel sx={{ color: 'inherit' }}>Radius: </FormLabel>
                            <Slider
                                min={50}
                                max={10000}
                                size={'small'}
                                value={location.radius || /* istanbul ignore next*/ 0}
                                onChange={
                                    /* istanbul ignore next*/ (_, radius) => {
                                        // istanbul ignore next
                                        if (typeof radius === 'number') {
                                            // istanbul ignore next
                                            setLocation({ ...location, radius });
                                        }
                                    }
                                }
                            />
                            <LoadingButton
                                variant={'contained'}
                                size={'small'}
                                loading={isFetching}
                                sx={{
                                    color: 'white',
                                    '&.MuiLoadingButton-loading': {
                                        backgroundColor: 'white',
                                    },
                                }}
                                onClick={() => {
                                    const req: GeosearchReq = {
                                        projectSlug,
                                        ...location,
                                        startTime: toISO8601(subHours(resolveDateToNow(date), 1)),
                                        endTime: toISO8601(addHours(resolveDateToNow(date), 1)),
                                    };
                                    geosearch(req);
                                }}
                            >
                                Search
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </FilterBar>

                <MapWrapper>
                    <MapContainer
                        worldCopyJump={true}
                        attributionControl={false}
                        zoom={13}
                        center={[location.latitude, location.longitude]}
                        style={{
                            height: '100%',
                        }}
                        whenReady={refresh}
                    >
                        {!autoMap ? (
                            /*istanbul ignore next*/ <CustomControl position="topright">
                                <Button color="inherit" onClick={enableAutoMap} sx={{ backgroundColor: 'white' }}>
                                    <AppIconRefresh />
                                </Button>
                            </CustomControl>
                        ) : null}
                        <MapTiles />
                        <AutoCenter
                            position={{
                                latitude: location.latitude,
                                longitude: location.longitude,
                            }}
                            enable={true}
                        />
                        <MapCircleEditor
                            circle={location}
                            onChange={
                                /* istanbul ignore next */ (c) => {
                                    // istanbul ignore next
                                    setLocation({ ...location, ...c });
                                }
                            }
                        />
                        <FeatureGroup>
                            {filteredDriverEvents.map((de) => (
                                <DriverEventMarker driverEvent={de} key={de.id} />
                            ))}
                        </FeatureGroup>
                        <FeatureGroup>
                            {filteredPings.map((de) => (
                                <PingMarker ping={de} key={de.id} />
                            ))}
                        </FeatureGroup>
                        <FeatureGroup eventHandlers={{ add: refresh, remove: refresh }}>
                            {geofences.map((g) => (
                                <GeofenceMarker key={g.id} geofence={g} vehicles={[]} />
                            ))}
                        </FeatureGroup>

                        <MapInteraction onInteraction={disableAutoMap} />
                    </MapContainer>
                </MapWrapper>
            </Stack>
        </Box>
    );
}
