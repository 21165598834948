import * as React from 'react';
import { appStyled, useAppTheme } from '../../theme';

const LogoWrapper = appStyled('div')`
    line-height: 1;
`;

export function ObservantLogo(): React.ReactElement {
    const theme = useAppTheme();
    const size = 24;
    return (
        <LogoWrapper>
            <svg
                style={{
                    fill: theme.colors.icon.logo,
                }}
                width={size}
                height={size}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 249.41 249.41"
            >
                <path d="M246.4,190.53H230.63a124.71,124.71,0,1,0-211.85,0H3a3,3,0,0,0-3,3V246.4a3,3,0,0,0,3,3H246.4a3,3,0,0,0,3-3V193.54A3,3,0,0,0,246.4,190.53ZM47.63,124.7a77.08,77.08,0,1,1,117.18,65.83h0l-.73.43-2.1,1.21-1.06.57q-1.12.6-2.28,1.17l-.79.39c-.89.42-1.78.82-2.69,1.21l-1,.44c-.76.32-1.52.62-2.3.91l-.81.31c-1,.36-2,.7-3,1l-1,.29c-.81.25-1.62.48-2.44.7l-.89.24c-1.06.26-2.13.51-3.2.74l-.81.15c-.88.18-1.77.33-2.67.48l-.92.14c-1.12.17-2.24.32-3.38.43l-.52,0c-1,.1-2,.17-3,.23l-.94.05c-1.17.05-2.34.09-3.53.09h0c-1.18,0-2.36,0-3.53-.09l-.94-.05c-1-.06-2-.13-3-.23l-.53,0c-1.13-.11-2.25-.26-3.37-.43l-.92-.14c-.9-.15-1.79-.3-2.68-.48l-.8-.15c-1.08-.23-2.14-.48-3.2-.74l-.89-.24c-.82-.22-1.63-.45-2.44-.69l-1-.3c-1-.32-2-.66-3-1l-.82-.31c-.77-.29-1.53-.59-2.29-.91l-1.06-.44c-.9-.39-1.79-.79-2.68-1.21l-.8-.39c-.76-.38-1.52-.77-2.27-1.17l-1.06-.57c-.71-.4-1.41-.8-2.1-1.21l-.73-.43h0A77,77,0,0,1,47.63,124.7Z" />
            </svg>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1363.67 249.41"
                height={size}
                style={{
                    fill: theme.colors.icon.text,
                }}
            >
                <path d="M95.27,124.39c.13-44.34,33.7-76.95,79-76.75,44.24.18,77.59,33.41,77.51,77.22-.09,44.15-33.76,76.8-79.08,76.66C128.31,201.39,95.14,168.35,95.27,124.39Zm124.2.39c0-27.24-19.76-47.87-45.83-47.92s-46.4,20.9-46.32,47.61c.08,27.48,20.16,47.9,47.13,47.94C199.5,172.44,219.5,151.29,219.47,124.78Z" />
                <path d="M309.55,191.64c-.34,2.61-.62,4.74-1,7.44-9.46,0-18.72.1-28-.13-1.06,0-2.33-1.71-3-2.91-.48-.86-.14-2.18-.14-3.29q0-68.42,0-136.85c0-7.53.05-7.53,7.74-7.54,6.7,0,13.4.18,20.09,0,3.94-.13,5.21,1.42,5.13,5.22-.19,9.09-.09,18.17-.07,27.26,0,2,.17,3.9.3,6.68,16.41-9.83,33.05-12,50.45-6.5,12.11,3.83,21.69,11.37,28.92,21.66,16.31,23.23,15,56.95-2.8,77.78C367.91,203,341.64,207,309.55,191.64Zm.81-51.82c0,2.78.38,5.62-.07,8.32-1.78,10.5,4.68,15.55,12.69,19.64a34.26,34.26,0,0,0,5.18,2,31.31,31.31,0,0,0,34.14-10.13c7.66-9.72,8.36-26.42,1.56-37.25-7-11.2-19-16.18-32.24-13.39-15.27,3.24-21.26,10.5-21.26,25.79Z" />
                <path d="M992.33,88.89c.29-3.21.48-5.35.74-8.2,9.53,0,19-.08,28.4.12.92,0,2.15,1.58,2.6,2.69s.13,2.55.13,3.85V198.77h-31c-.27-2.32-.53-4.6-.89-7.78-15.79,11.39-32.83,13.15-50.59,7.62A55.85,55.85,0,0,1,912.62,178a65.12,65.12,0,0,1,.2-76.92c9.33-12.5,21.84-20.13,37.24-22.36C965,76.59,979.22,79.42,992.33,88.89Zm-.79,51.27c0-4.82.11-9.63-.06-14.44a8.9,8.9,0,0,0-1.32-4.6c-7.49-10.74-25.86-15.92-38.14-10.77-12.56,5.25-18.37,15.46-19,28.34-.58,12.29,4.24,22.81,15.43,28.78,11.56,6.16,23.35,4.95,34.4-2.06,5.94-3.76,10.14-8.51,8.75-16.38C991.12,146.16,991.54,143.12,991.54,140.16Z" />
                <path d="M1169.46,198.81H1137c-.12-2.21-.34-4.34-.34-6.47,0-18.36.08-36.72-.1-55.08-.09-10-1.06-20.06-11.19-25.61-10.93-6-21.55-3.74-31.71,2.45-.79.48-1.58,1-2.35,1.47-4.93,3.31-7.31,7.4-7.17,13.86.48,21.13,0,42.28.28,63.42,0,4.68-.93,6.64-6.11,6.38-8.69-.44-17.41-.12-26.63-.12-.15-2.5-.36-4.48-.36-6.46,0-35.42.06-70.85-.13-106.27,0-4.79,1.64-6.07,6.11-5.85,5.94.31,11.91.17,17.86,0,5-.11,10.14-.57,7.86,7.17,6.83-2.84,12.87-6.29,19.36-7.9,32.35-8,62,12.57,66.09,45.49a129.26,129.26,0,0,1,1,15C1169.52,159.57,1169.46,178.83,1169.46,198.81Z" />
                <path d="M561.45,148.13c1,11.08,6.42,18.51,15.42,23,17.14,8.44,31.68,2.45,46.16-10.56,5.31,5.81,10.67,11.4,15.57,17.37.75.91,0,4.19-1.16,5.35-10.09,10.44-22.78,16-37,17.73-30,3.7-58.78-10.18-68-40-7.12-23.08-4-45,12.13-63.78,15-17.45,34.67-22.63,56.6-17.18,21.22,5.27,33.91,20,39.59,40.63,1.79,6.52,1.85,13.52,2.56,20.32.55,5.27-1.42,7.41-7.27,7.32-22.53-.35-45.07-.15-67.61-.15ZM563,123.75h46.23a22,22,0,0,0-21-19A24.62,24.62,0,0,0,563,123.75Z" />
                <path d="M507.33,90.49c-4.59,7.09-9,13.73-13.21,20.45-1.84,2.91-3.71,2.08-6,.69-7.91-4.72-16.41-7.62-25.51-4.73-3.25,1-7.12,3.9-8.25,6.87-1.75,4.59,3,6.71,6.52,8.17,6.34,2.62,13,4.5,19.36,7.08,5.15,2.08,10.4,4.21,15,7.23,21.14,13.79,21.21,42.35.08,56.23-17,11.14-35.51,11.27-54.18,5.62-7.72-2.34-14.88-6.58-22.23-10.1-2.9-1.38-3.4-3.42-1.37-6.25,3.8-5.27,7.41-10.67,10.94-16.12,1.77-2.75,3.2-3.27,6.15-1.12a45.18,45.18,0,0,0,27.61,9,26.84,26.84,0,0,0,11.21-2.46c6.05-3,6.34-9.9.48-13.25-5.43-3.11-11.51-5.1-17.31-7.56-6.32-2.68-13-4.74-18.91-8.11-21.35-12.14-22.65-39.32-2.84-54,12.92-9.59,27.6-11.5,43-9.13C488.15,80.59,498.15,83.43,507.33,90.49Z" />
                <path d="M765,80.66c11.26,0,21.62-.17,32,.21,1.42.06,3.2,2.72,4,4.53,8.81,19.22,17.46,38.51,26.16,57.78,1,2.18,2,4.34,3.53,7.6,1.9-4,3.46-7,4.87-10.14,8.15-18.09,16.43-36.13,24.31-54.34,1.94-4.49,4.45-6,9.24-5.81,8.7.38,17.44.11,27.15.11-.9,2.52-1.35,4.22-2.08,5.8-16.45,35.71-33,71.37-49.32,107.14-1.88,4.12-4.26,5.1-8.66,5.8-13.33,2.13-19.26-3.64-24.36-15.66-13.89-32.77-29.48-64.82-44.38-97.16C766.73,84.89,766.09,83.21,765,80.66Z" />
                <path d="M1268.3,108.12c-11.51,0-22.29.2-33-.1-4.36-.13-5.88,1.11-5.8,5.54.25,14.1,0,28.19.21,42.29.17,10.29,5.5,15.72,15.82,16.37a65.26,65.26,0,0,0,11.69-.41c3.66-.43,5.54.63,6.08,4.43.92,6.41,2.34,12.75,3.11,19.18.17,1.37-1.33,4.1-2.41,4.3-17,3.12-33.78,3.23-49-6.52-11.34-7.26-16.83-18.25-17.49-31.32-.77-15-.81-30-.91-45-.13-20.77.1-41.55-.13-62.32-.06-4.92,1.51-6.52,6.39-6.25a167.43,167.43,0,0,0,19.53,0c5.45-.32,7.78,1.2,7.32,7.1-.52,6.82,0,13.72-.14,20.57-.08,3.63,1.31,4.86,4.93,4.78,9.49-.2,19,.06,28.47-.19,4-.11,5.71.83,5.5,5.19C1268.05,92.91,1268.3,100.13,1268.3,108.12Z" />
                <path d="M701.58,198.81H669.2c-.11-2.06-.31-3.85-.31-5.65,0-35.43.07-70.86-.11-106.29,0-4.71,1-6.64,6.13-6.38,8.3.42,16.64.11,25.28.11v9C706.3,86.55,711.84,82.56,718,81c7.41-1.91,15.34-1.87,23.07-2.5,3-.24,4.46,1.3,4.07,4.45-1,7.72-1.85,15.45-2.75,23.18-.5,4.24-3.48,2.94-5.91,2.57-12.72-2-23.12,2.47-31.38,11.82a14.79,14.79,0,0,0-3.36,8.88c-.29,21-.16,41.92-.16,62.88Z" />
            </svg>
        </LogoWrapper>
    );
}
