import React from 'react';
import { useMap } from 'react-leaflet';

export function AutoZoom({ zoom }: { zoom: number }): React.ReactElement {
    const map = useMap();

    React.useEffect(() => {
        // istanbul ignore next
        if (map && zoom) {
            map.setZoom(zoom);
        }
    }, [map, zoom]);

    return <></>;
}
