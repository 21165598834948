export type FrameToken<T> = { name: string; __type: T };
export type DecoratorFunc<T> = (dep: T, r: FrameResolver) => T;
export type FrameResolver = {
    resolve: <T>(token: FrameToken<T>) => T;
};

export type FrameApp = {
    register: <T>(token: FrameToken<T>, dep: T | FramePlugin<T>) => void;
    decorate: <T>(token: FrameToken<T>, decorator: DecoratorFunc<T>) => void;
    invoke: (fn: InvokeFunc) => void;
    start: () => FrameResolver;
};

type InvokeFunc = (r: FrameResolver) => void;
type ProvideFunc<T> = (r: FrameResolver) => T;
type FramePlugin<T> = {
    (r?: FrameResolver): T;
    __plugin__: boolean;
};

export function createToken<T = never>(
    name: string & (T extends never ? 'You must provide a type to createToken' : string),
): FrameToken<T> {
    return {
        name,
        // @ts-expect-error this will never be used, it is here as a type placeholder
        /* tslint:disable */ __type: null /* tslint:enable */,
    };
}

export function createPlugin<T>(fn: ProvideFunc<T>): FramePlugin<T> {
    return Object.assign(fn, { __plugin__: true });
}

export function newApp(): FrameApp {
    const cached: { [index: string]: unknown } = {};
    const deps: { [index: string]: unknown } = {};
    const decorators: { [index: string]: Array<unknown> } = {};
    const plugins: { [index: string]: ProvideFunc<unknown> } = {};
    const invokeFns: Array<InvokeFunc> = [];

    function register<T>(token: FrameToken<T>, dep: T | FramePlugin<T>): void {
        if (plugins.hasOwnProperty(token.name)) {
            delete plugins[token.name];
        }
        if (deps.hasOwnProperty(token.name)) {
            delete deps[token.name];
        }

        if (typeof dep === 'function' && '__plugin__' in dep) {
            plugins[token.name] = dep;
        } else {
            deps[token.name] = dep;
        }
    }

    function decorate<T>(token: FrameToken<T>, decorator: DecoratorFunc<T>): void {
        if (decorators.hasOwnProperty(token.name)) {
            decorators[token.name].push(decorator);
        } else {
            decorators[token.name] = [decorator];
        }
    }

    function _applyDecorators<T>(token: FrameToken<T>, dep: T): T {
        if (decorators.hasOwnProperty(token.name)) {
            // @ts-expect-error we don't store the type, but we need to return it.
            return decorators[token.name].reduce((dep, fn) => fn(dep, { resolve }), dep);
        }
        return dep;
    }

    function _lookup<T>(token: FrameToken<T>): T {
        if (deps.hasOwnProperty(token.name)) {
            return _applyDecorators(token, deps[token.name]) as T;
        }

        if (plugins.hasOwnProperty(token.name)) {
            // @ts-expect-error we don't store the type, but we need to return it.
            const typedPlugin: ProvideFunc<T> = plugins[token.name];
            const plugDep = typedPlugin({ resolve });
            return _applyDecorators(token, plugDep) as T;
        }

        throw new Error(`Token '${token.name}' is not registered.`);
    }

    function resolve<T>(token: FrameToken<T>): T {
        if (cached.hasOwnProperty(token.name)) {
            // @ts-expect-error we don't store the type, but we need to return it.
            return cached[token.name];
        }

        const dep = _lookup(token);
        cached[token.name] = dep;
        return dep;
    }

    function invoke(fn: InvokeFunc): void {
        invokeFns.push(fn);
    }

    function start(): FrameResolver {
        for (const p of invokeFns) {
            p({ resolve });
        }

        return { resolve };
    }

    return {
        register,
        decorate,
        invoke,
        start,
    };
}
