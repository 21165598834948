import { Howl } from 'howler';
import * as React from 'react';
import { useFrame } from '../lib/frame-react';
import { SFXToken } from '../services/app-sfx-plugin';
import { SoundEffectId } from '../types/stein';

type HowlState = {
    state: 'play' | 'pause' | 'stop';
};

export function useHowlerState(howl: Howl | undefined): HowlState {
    const [state, setState] = React.useState<'play' | 'pause' | 'stop'>('stop');

    const play = (): void => setState('play');
    const stop = (): void => setState('stop');

    React.useEffect(() => {
        howl?.on('play', play);
        howl?.on('end', stop);
        howl?.on('stop', stop);

        return () => {
            howl?.off('play', play);
            howl?.off('end', stop);
            howl?.off('stop', stop);
        };
    }, [howl]);

    return { state };
}

export function useSoundEffect(id: SoundEffectId): [Howl | undefined, HowlState] {
    const sfx = useFrame(SFXToken);
    const howl = sfx[id];
    const state = useHowlerState(howl);

    return [howl, state];
}
