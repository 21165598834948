import * as React from 'react';
import { createPlugin, createToken, FrameApp } from '../frame';
import type { FrameToken, FrameResolver } from '../frame';

export const ReactRootToken = createToken<React.ComponentType>('ReactRootToken');

type FrameReactAppStarted = {
    render: () => React.ReactElement<unknown>;
} & FrameResolver;

export type FrameReactApp = Omit<FrameApp, 'start'> & {
    start: () => FrameReactAppStarted;
};

export function convertToReactApp(app: FrameApp): FrameReactApp {
    app.register(ReactToken, ReactPlugin);

    function start(): FrameReactAppStarted {
        const res = app.start();
        const react = res.resolve(ReactToken);
        return {
            ...res,
            render: react.render,
        };
    }
    return {
        ...app,
        start,
    };
}

const ReactToken = createToken<{
    render: () => React.ReactElement<unknown>;
}>('ReactToken');

const ReactPlugin = createPlugin(({ resolve }) => {
    const RootComponent = resolve(ReactRootToken);
    RootComponent.displayName = `ReactRootToken_in_ReactPluginRoot<${RootComponent.displayName}>`;

    function render(): React.ReactElement<unknown> {
        return (
            <FrameProvider app={{ resolve }}>
                <RootComponent />
            </FrameProvider>
        );
    }

    return {
        render,
    };
});

const noopApp: FrameResolver = {
    resolve: function () {
        throw new Error('Only call `useFrame` from a child of FrameProvider with a provided app.');
    },
};

const FrameContext = React.createContext<FrameResolver>(noopApp);

type FrameProviderProps = {
    app: FrameResolver;
    children: React.ReactNode;
};
export function FrameProvider({ app, children }: FrameProviderProps): React.ReactElement {
    return <FrameContext.Provider value={app}>{children}</FrameContext.Provider>;
}

export function useFrame<T>(token: FrameToken<T>): T {
    const app = React.useContext(FrameContext);
    return app.resolve(token);
}
