import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Region, RegionId } from '../../types/stein';
import { setRailsContext } from './shared-actions';

export const regionsAdapter = createEntityAdapter<Region>({
    sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const regionsSlice = createSlice({
    name: 'regions',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState: regionsAdapter.getInitialState(),
    reducers: {
        setMany(state, action: PayloadAction<Region[]>) {
            regionsAdapter.setMany(state, action.payload);
        },
        delete(state, action: PayloadAction<RegionId>) {
            regionsAdapter.removeOne(state, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setRailsContext, (s, a) => {
            regionsAdapter.setAll(s, a.payload.regions);
        });
    },
});
