import { Box } from '@mui/system';
import * as React from 'react';
import { appStyled } from '../theme';
import { BoxProps } from '@mui/material';

type LayerProps = {
    xOffset?: number;
    yOffset?: number;
} & BoxProps;

export const UIStackLayer = appStyled(Box, {
    shouldForwardProp: (prop) => prop !== 'xOffset' && prop !== 'yOffset',
})<LayerProps>(({ xOffset = 0, yOffset = 0 }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: '24px',
    height: '100%',
    transform: `translate(${xOffset}px, ${yOffset}px)`,
}));

type IconLayerProps = LayerProps & {
    icon: React.ReactNode;
};

export function UIStackIconLayer({ icon, ...props }: IconLayerProps): React.ReactElement {
    return <UIStackLayer {...props}>{icon}</UIStackLayer>;
}

type UIStackProps = {
    children: React.ReactNode;
};

export const UIStack = React.forwardRef<HTMLDivElement, UIStackProps>(function UIStack(
    { children, ...props },
    ref,
): React.ReactElement {
    return (
        <div
            ref={ref}
            {...props}
            style={{
                position: 'relative',
                height: '100%',
                width: '24px',
            }}
        >
            {children}
        </div>
    );
});
