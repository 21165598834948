import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DriverLog } from '../../types/stein';

export const driverLogAdapter = createEntityAdapter<DriverLog>({
    selectId: (e) => e.id,
    sortComparer: (a, b) => (a.occurredAt || '').localeCompare(b.occurredAt || ''),
});

export const driverLogsSlice = createSlice({
    name: 'driverLogs',
    initialState: driverLogAdapter.getInitialState(),
    reducers: {
        driverLogsRecieved(state, action: PayloadAction<DriverLog[]>) {
            driverLogAdapter.setMany(state, action.payload);
        },
        driverLogsUpdated(state, action: PayloadAction<DriverLog[]>) {
            driverLogAdapter.updateMany(
                state,
                action.payload.map((l) => ({
                    id: l.id,
                    changes: l,
                })),
            );
        },
    },
});
