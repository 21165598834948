import { IconButton } from '@mui/material';
import * as React from 'react';
import { AppIconFullScreen, AppIconMinimize } from '../../AppIcons';
import { useFullScreen } from '../videoPlayerHooks';

type FullScreenButtonProps = {
    targetRef: React.RefObject<HTMLElement>;
};

export function FullScreenButton({ targetRef }: FullScreenButtonProps): React.ReactElement {
    const { enterFullScreen, exitFullScreen, isFullScreen } = useFullScreen(targetRef);

    function onClick(): void {
        isFullScreen ? exitFullScreen() : enterFullScreen();
    }

    return <IconButton onClick={onClick}>{isFullScreen ? <AppIconMinimize /> : <AppIconFullScreen />}</IconButton>;
}
