import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, List, TextField } from '@mui/material';

import { useHotkey } from '../HotkeyProvider';
import { useAppNavigate, useAppSelector } from '../../hooks/hooks';
import {
    selectAllProjects,
    selectDebugEnabled,
    selectHasFeature,
    selectHasPermission,
    selectVehiclesByProjectSlug,
} from '../../store/selectors';
import { useActiveProjectSlugOptional } from '../../hooks/use-active-project';
import { AppIconEvents, AppIconProject, AppIconVehicle } from '../AppIcons';
import {
    AppInternalUrl,
    urlDriverEventList,
    urlProject,
    urlVehicleDeviceShow,
    urlVehicleDeviceTimeline,
} from '../../utils/internal-url-utils';
import { NavigationItem, useNavigation } from '../Navigation';
import { OmnibarItem, OmnibarResult } from './OmnibarItem';
import { noop } from '../../utils/noop';
import { useHasPermission } from '../Authorize/Authorize';

type OmnibarContext = {
    setOmnibarOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setOmnibarSearch: React.Dispatch<React.SetStateAction<string>>;
};

const context = React.createContext<OmnibarContext>({
    setOmnibarOpen: noop,
    setOmnibarSearch: noop,
});

export function OmnibarProvider({ children }: { children: React.ReactNode }): React.ReactElement {
    const projectSlug = useActiveProjectSlugOptional();
    const [dialogOpen, setOmnibarOpen] = React.useState<boolean>(false);
    const [searchText, setOmnibarSearch] = React.useState<string>('');
    function close(): void {
        setOmnibarOpen(false);
    }
    // istanbul ignore next
    function toggle(): void {
        // istanbul ignore next
        setOmnibarOpen((s) => !s);
    }
    useHotkey(
        '⌘+k, ctrl+k',
        toggle,
        {
            description: 'Toggle OmniSearch',
            unwrap: true,
            enableOnTags: ['INPUT'],
        },
        [setOmnibarOpen],
    );

    const debugEnabled = useAppSelector(selectDebugEnabled);
    const state = useAppSelector((s) => s);

    function reduceNav(n: NavigationItem[], path: OmnibarResult[] = []): OmnibarResult[] {
        return n.reduce((obj, v) => {
            if (v.type === 'link') {
                if (v.debugOnly && !debugEnabled) {
                    return obj;
                }
                if (
                    !debugEnabled &&
                    v.auth &&
                    (!projectSlug || !selectHasPermission(state, v.auth.action, v.auth.subject, projectSlug))
                ) {
                    return obj;
                }
                //istanbul ignore next
                const blockDueToFeature =
                    v.feature && (!projectSlug || !selectHasFeature(state, v.feature, projectSlug));
                //istanbul ignore next
                if (!debugEnabled && blockDueToFeature) {
                    //istanbul ignore next
                    return obj;
                }
                const item: OmnibarResult = {
                    Icon: v.Icon,
                    name: v.text,
                    parentItems: path,
                    url: v.url,
                    subItems: [],
                    debug: {
                        feature: v.feature,
                        debugOnly: v.debugOnly,
                        auth: v.auth,
                    },
                };
                const subItems = reduceNav(v.subItems || [], path.concat(item));
                item.subItems = subItems;
                return obj.concat(item, subItems);
            }
            return obj.concat(...reduceNav(v.subItems || [], path));
        }, [] as OmnibarResult[]);
    }

    const searchItems: OmnibarResult[] = [];
    const navigation = useNavigation();

    searchItems.push(...reduceNav(navigation));

    const projects = useAppSelector(selectAllProjects);
    searchItems.push(
        ...projects.map((p) => ({
            Icon: AppIconProject,
            name: p.name,
            url: urlProject(p.slug),
        })),
    );

    const vehicles = useAppSelector((s) => (projectSlug ? selectVehiclesByProjectSlug(s, projectSlug) : []));
    const canSeeEvents = useHasPermission('DriverEvent', 'list');
    const canSeeTimeline = useHasPermission('VehicleDevice', 'view_timeline');

    if (projectSlug) {
        searchItems.push(
            ...vehicles.map((v) => {
                const subItems: OmnibarResult[] = [];
                if (debugEnabled || canSeeEvents) {
                    subItems.push({
                        name: 'Events',
                        url: urlDriverEventList(projectSlug, { vehicles: [v.slug] }),
                        Icon: AppIconEvents,
                        debug: {
                            auth: {
                                subject: 'DriverEvent',
                                action: 'list',
                            },
                        },
                    });
                }
                if (debugEnabled || canSeeTimeline) {
                    subItems.push({
                        name: 'Timeline',
                        url: urlVehicleDeviceTimeline(projectSlug, v.slug),
                        Icon: AppIconEvents,
                        debug: {
                            auth: {
                                subject: 'VehicleDevice',
                                action: 'view_timeline',
                            },
                        },
                    });
                }

                return {
                    Icon: AppIconVehicle,
                    name: v.name,
                    url: urlVehicleDeviceShow(projectSlug, v.slug),
                    subItems,
                };
            }),
        );
    }

    const searchResults = searchText
        ? searchItems.filter((si) => si.name.toLowerCase().includes(searchText.toLowerCase()))
        : [];

    const nav = useAppNavigate();
    function goToUrl(u: AppInternalUrl): void {
        // istanbul ignore next
        if (u.startsWith('http')) {
            // istanbul ignore next
            window.open(u, '_blank')?.focus();
        } else {
            nav(u);
        }
        close();
    }

    function handleQuickSearch(e: React.KeyboardEvent<HTMLDivElement>): void {
        // istanbul ignore next
        if (e.key === 'Enter') {
            // istanbul ignore next
            e.preventDefault();
            // istanbul ignore next
            e.stopPropagation();
            // istanbul ignore next
            if (searchResults.length) {
                // istanbul ignore next
                goToUrl(searchResults[0].url);
            }
        }
    }

    return (
        <context.Provider value={{ setOmnibarSearch, setOmnibarOpen }}>
            {children}
            <Dialog open={dialogOpen} onClose={close} maxWidth={'md'} fullWidth>
                <DialogContent sx={{ minHeight: '80vh', maxHeight: '80vh' }}>
                    <TextField
                        size="small"
                        fullWidth
                        autoFocus
                        label={'Search'}
                        helperText={'Open with ⌘ + k or ctrl + k'}
                        onFocus={(event) => {
                            event.target.select();
                        }}
                        onKeyDown={handleQuickSearch}
                        value={searchText}
                        onChange={(e) => setOmnibarSearch(e.target.value)}
                    />
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }} dense data-testid={'omnibar-results'}>
                        {searchResults.map((res, i) => (
                            <OmnibarItem {...res} key={`${res.url}_${i}`} goToUrl={goToUrl} />
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close}>Close</Button>
                </DialogActions>
            </Dialog>
        </context.Provider>
    );
}

export function useOmnibar(): OmnibarContext {
    return React.useContext(context);
}
