import * as React from 'react';
import { appStyled } from '../../../theme';
import { useAppSelector } from '../../../hooks/hooks';

import { CalloutBox } from '../../material/CalloutBox';

const DebugBlockBase = appStyled(CalloutBox)(({ theme }) => ({
    '&.MuiPaper-root': {
        borderColor: theme.colors.debugBlock.dev.border,
        backgroundColor: theme.colors.debugBlock.dev.background,
        borderStyle: 'dashed',
    },
}));

type DebugBlockProps = {
    children?: React.ReactNode;
    name?: string;
    disablePadding?: boolean;
};

function DebugBlock({ children, name, disablePadding }: DebugBlockProps): React.ReactElement | null {
    const { debugEnabled, debugPermitted } = useAppSelector((s) => s.navigation);
    if (!debugPermitted || !debugEnabled) {
        return null;
    }

    // istanbul ignore next
    const safeName = name ? name : 'debug';

    return (
        <DebugBlockBase disablePadding={disablePadding} name={safeName} enableHighlight>
            {children}
        </DebugBlockBase>
    );
}

type DebugMaybeProps = {
    children: React.ReactNode;
    debugOnly?: boolean | undefined;
};

export function DebugMaybe({ children, debugOnly }: DebugMaybeProps): React.ReactElement {
    if (debugOnly) {
        return <DebugBlock disablePadding>{children}</DebugBlock>;
    }

    return <>{children}</>;
}
