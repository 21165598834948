import * as React from 'react';
import { ThemeProvider } from '../theme';
import { useFrame } from '../lib/frame-react';
import { Provider as ReduxProvider } from 'react-redux';
import { AppReduxStoreToken } from '../store';
import { HistoryToken } from '../lib/frame-tokens';
import { Router } from 'react-router-dom';
import { IntercomProvider } from '../services/app-intercom-service/use-intercom';
import { PictureInPictureProvider } from './VideoPlayer/videoPlayerHooks';
import { HasUnsavedChangesProvider } from '../hooks/use-has-unsaved-changes';
import { HotkeyProvider } from './HotkeyProvider';
import { GlobalSearch } from './GlobalSearch';

type GlobalProvidersProps = {
    children: React.ReactNode;
};

export function GlobalProviders({ children }: GlobalProvidersProps): React.ReactElement {
    const reduxStore = useFrame(AppReduxStoreToken);
    const history = useFrame(HistoryToken);
    return (
        <ReduxProvider store={reduxStore}>
            <ThemeProvider>
                <PictureInPictureProvider>
                    <IntercomProvider>
                        <Router history={history}>
                            <HotkeyProvider>
                                <GlobalSearch>
                                    <HasUnsavedChangesProvider>{children}</HasUnsavedChangesProvider>
                                </GlobalSearch>
                            </HotkeyProvider>
                        </Router>
                    </IntercomProvider>
                </PictureInPictureProvider>
            </ThemeProvider>
        </ReduxProvider>
    );
}
