import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import * as React from 'react';
import { SteinInternalApiClientToken } from '../../../../../clients/stein-internal-api';

import { toTitleCase } from '../../../../../lib/case-transforms';
import { useFrame } from '../../../../../lib/frame-react';
import { useAppTheme } from '../../../../../theme';
import { NOTIF_CONFIG_CATEGORY_NAME, NOTIF_CONFIG_MEDIUM_NAME } from '../../../../../types/stein.notification_config';
import { AppIconWarningCircle } from '../../../../AppIcons';

import { CalloutBox } from '../../../../material/CalloutBox';
import { TooltipOptional } from '../../../../material/TooltipOptional';
import { isNotifConfigToggleable, isNotifConfigVariable } from './notif-config-utils';
import { useNotifConfigContext } from './NotifConfigContext';
import { NotificationConfigCustom } from './NotificationConfigCustom';
import { NotificationConfigSwitch } from './NotificationConfigToggleSwitch';

export function NotificationEditor(): React.ReactElement | null {
    const props = useNotifConfigContext();
    const theme = useAppTheme();
    const { useCreateCustomNotificationConfigMutation } = useFrame(SteinInternalApiClientToken);
    const [createCustomNotification, { isLoading: isCreating }] = useCreateCustomNotificationConfigMutation();

    return (
        <>
            <Stack spacing={2}>
                <CalloutBox name={'Alert Toggles'}>
                    <Box display={'flex'}>
                        <Table sx={{ flex: 1 }} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    {props.mediums.map((m) => (
                                        <TableCell key={m} align={'center'}>
                                            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                {NOTIF_CONFIG_MEDIUM_NAME[m]}
                                                {props.getRequiredKeyName(m) && !props.getRequiredPropValue(m) ? (
                                                    <Tooltip
                                                        title={`You need a ${toTitleCase(
                                                            `${props.scope} ${props.getRequiredKeyName(m)}`,
                                                        )}`}
                                                    >
                                                        <AppIconWarningCircle color={theme.palette.error.main} />
                                                    </Tooltip>
                                                ) : null}
                                            </Box>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.categoryToggle.map((c) => (
                                    <TableRow key={c} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align={'center'}>
                                            {NOTIF_CONFIG_CATEGORY_NAME[c]}
                                        </TableCell>
                                        {props.mediums.map((m) => {
                                            const missingUserProp = Boolean(
                                                props.getRequiredKeyName(m) && !props.getRequiredPropValue(m),
                                            );

                                            const nc = props.existingConfigs
                                                .filter(isNotifConfigToggleable)
                                                .find((uc) => uc.category === c && uc.medium === m);

                                            return (
                                                <TableCell key={m} align={'center'}>
                                                    <TooltipOptional
                                                        title={'Unable to select'}
                                                        description={
                                                            missingUserProp &&
                                                            `You need a ${toTitleCase(
                                                                `${props.scope} ${props.getRequiredKeyName(m)}`,
                                                            )}`
                                                        }
                                                    >
                                                        <span>
                                                            <NotificationConfigSwitch
                                                                item={
                                                                    nc || {
                                                                        medium: m,
                                                                        category: c,
                                                                        enabled: false,
                                                                        ...props.newNotificationConfigParams,
                                                                    }
                                                                }
                                                                disabled={missingUserProp}
                                                            />
                                                        </span>
                                                    </TooltipOptional>
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </CalloutBox>
                <CalloutBox name={'Custom Alerts'} data-testid={'custom-notifications'}>
                    <>
                        {props.existingConfigs.filter(isNotifConfigVariable).map((uc) => (
                            <NotificationConfigCustom key={uc.id} item={uc} />
                        ))}
                        <LoadingButton
                            fullWidth
                            variant={'contained'}
                            color={'secondary'}
                            size={'small'}
                            loading={isCreating}
                            onClick={() => {
                                createCustomNotification({
                                    ...props.newNotificationConfigParams,
                                    category: 'any_neglect',
                                    analysis: 'any',
                                    medium: 'email',
                                    rateNumerator: 5,
                                    rateDenominator: 1,
                                    rateDenominatorUnit: 'hours',
                                });
                            }}
                        >
                            {'Create Custom Alert'}
                        </LoadingButton>
                    </>
                </CalloutBox>
            </Stack>
        </>
    );
}
