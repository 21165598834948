import { useState } from 'react';

type UseIsHoveredRes = [
    boolean,
    {
        onMouseEnter: () => void;
        onMouseLeave: () => void;
        onMouseMove: () => void;
    },
];

export function useIsHovered(): UseIsHoveredRes {
    const [isHovered, setIsHovered] = useState<boolean>(false);

    function onMouseEnter(): void {
        setIsHovered(true);
    }

    function onMouseLeave(): void {
        setIsHovered(false);
    }

    function onMouseMove(): void {
        setIsHovered(true);
    }

    return [
        isHovered,
        {
            onMouseMove,
            onMouseEnter,
            onMouseLeave,
        },
    ];
}
