// istanbul ignore file

import * as React from 'react';
import { ButtonBaseProps, CircularProgress } from '@mui/material';
import { ButtonBase } from '@mui/material';

type IconButtonSimpleProps = {
    children: React.ReactNode;
    loading?: boolean;
    disabled?: boolean;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
} & ButtonBaseProps;

export function IconButtonSimple({
    children,
    onClick,
    loading,
    disabled,
    color,
    sx,
    ...props
}: IconButtonSimpleProps): React.ReactElement {
    function _onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
        if (!disabled) {
            onClick(e);
        }
    }
    return (
        <ButtonBase
            onClick={_onClick}
            disabled={disabled}
            sx={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: color ? color : 'inherit',
                ':hover': {
                    opacity: 0.5,
                },
                ...(disabled
                    ? {
                          opacity: 0.5,
                          color: '#aaa',
                      }
                    : {}),
                ...(sx ? sx : {}),
            }}
            {...props}
            role="button"
        >
            {loading ? <CircularProgress size={14} data-testid="button-loading" /> : children}
        </ButtonBase>
    );
}
