import { CamelCasedPropertiesDeep, SnakeCasedPropertiesDeep } from 'type-fest';
import { isArray, isObject, transform, camelCase, snakeCase } from 'lodash';

type CaseTransformOptions = {
    ignoreKeys: string[];
};

export function toCamelCase<T extends Record<string, unknown>>(
    obj: T,
    opts?: CaseTransformOptions,
): CamelCasedPropertiesDeep<T> {
    return transform(obj, (acc, value, key, target) => {
        const camelKey = isArray(target) ? key : camelCase(key as string);

        if (opts?.ignoreKeys?.includes(camelKey)) {
            // @ts-expect-error  This is actually complicated to do in a correctly typed way.
            acc[camelKey] = value;
            return;
        }

        // @ts-expect-error  This is actually complicated to do in a correctly typed way.
        // We can trust that this function works
        acc[camelKey] = isObject(value) ? toCamelCase(value, opts) : value;
    });
}

export const toSnakeCase = <T extends Record<string, unknown> | unknown[]>(
    obj: T,
    opts?: CaseTransformOptions,
): SnakeCasedPropertiesDeep<T> =>
    transform(obj, (acc, value, key, target) => {
        const snakeKey = isArray(target) ? key : snakeCase(key as string);

        // @ts-expect-error  This is actually complicated to do in a correctly typed way.
        if (opts?.ignoreKeys?.includes(snakeKey)) {
            // @ts-expect-error  This is actually complicated to do in a correctly typed way.
            acc[snakeKey] = value;
            return;
        }

        // @ts-expect-error  This is actually complicated to do in a correctly typed way.
        // We can trust that this function works
        acc[snakeKey] = isObject(value) ? toSnakeCase(value, opts) : value;
    });

export function toTitleCase(str: string | null | undefined): string {
    return (
        str
            ?.replace(/([a-z])([A-Z])/g, '$1_$2')
            .split('_')
            .join(' ')
            .replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }) || ''
    );
}
