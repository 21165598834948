import { NotificationListenerDeps } from './notification-listener-types';
import { notificationSlice } from '../../store/slices/notificationSlice';
import { AppNotificationId } from '../../components/Notifications/notificationTypes';
import { AnyAction } from 'redux';

export function notificationListenerPush(action: AnyAction, deps: NotificationListenerDeps): void {
    const { steinInternalApi, store } = deps;
    if (steinInternalApi.endpoints.createPush.matchPending(action)) {
        store.dispatch(
            notificationSlice.actions.createNotification({
                type: 'push',
                args: action.meta.arg.originalArgs,
                id: action.meta.requestId as AppNotificationId,
                status: 'pending',
            }),
        );
    } else if (
        steinInternalApi.endpoints.createPush.matchFulfilled(action) ||
        steinInternalApi.endpoints.createPush.matchRejected(action)
    ) {
        const selector = steinInternalApi.endpoints.createPush.select(action.meta.requestId);
        const request = selector(store.getState());
        store.dispatch(
            notificationSlice.actions.updateNotification({
                id: action.meta.requestId as AppNotificationId,
                status: request.isError ? 'error' : 'success',
            }),
        );
    }
}
