import LoadingButton from '@mui/lab/LoadingButton';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    InputBase,
    MenuItem,
    Paper,
    Popper,
    InputBaseProps,
    ClickAwayListener,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import * as React from 'react';
import { useNotifConfigContext } from './NotifConfigContext';
import { SteinInternalApiClientToken } from '../../../../../clients/stein-internal-api';
import { CALIBRATION_STATUS } from '../../../../../constants/calibration-status-constants';
import { useStagedChanges } from '../../../../../hooks/use-staged-changes';
import { useFrame } from '../../../../../lib/frame-react';
import { appStyled, useAppTheme } from '../../../../../theme';

import {
    NotifConfigCategory,
    NotifConfigDenominatorUnit,
    NotifConfigMedium,
    NotifConfig,
    NOTIF_CONFIG_CATEGORY_NAME,
    NOTIF_CONFIG_DENOMINATOR_UNITS,
    NOTIF_CONFIG_MEDIUM_NAME,
} from '../../../../../types/stein.notification_config';
import { plural } from '../../../../../utils/string-utils';
import { GridEasy } from '../../../../material/GridEasy';
import { CopyToClipboardButton } from '../../../../material/CopyToClipboard';
import { CalibrationStatus, GeofenceId } from '../../../../../types/stein';
import { minutesToSeconds, secondsToMinutes } from 'date-fns';
import { TextField } from '@mui/material';
import { InfoTip } from '../../../../InfoTip';
import { useAppSelector } from '../../../../../hooks/hooks';
import { selectGeofencesByProjectSlug } from '../../../../../store/selectors';
import { useActiveProjectSlug } from '../../../../../hooks/use-active-project';

type NotificationConfigCustomEditProps = {
    item: NotifConfig;
    onClose: () => void;
} & Omit<React.ComponentProps<typeof Dialog>, 'onClose'>;

const Text = appStyled('div')({
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
});

function useOnChange(onChange: (a: React.SetStateAction<NotifConfig>) => void) {
    return function useOnChangeWrapped(change: Partial<NotifConfig>) {
        // @ts-expect-error this actually works
        onChange((item) => ({
            ...item,
            ...change,
        }));
    };
}

function getDefaultProps(category: NotifConfigCategory): Partial<NotifConfig> {
    const nc: Partial<NotifConfig> = {
        category,
    };

    switch (category) {
        case 'any_neglect':
        case 'distraction':
        case 'eye_closure':
        case 'early_fatigue':
        case 'harsh_maneuver':
            return {
                category,
                rateNumerator: 5,
                rateDenominator: 1,
                rateDenominatorUnit: 'hours',
                analysis: 'any',
            };
        case 'flagged':
            return {
                category,
                rateNumerator: 5,
                rateDenominator: 1,
                rateDenominatorUnit: 'hours',
            };
        case 'iphone_battery_level_drop':
            return {
                category,
                batteryLevelThreshold: 0.85,
            };

        case 'calibration_status_changed':
            return {
                category,
                analysis: 'any',
            };

        case 'sensor_covered_v2':
            return {
                category,
                remindIn: 60,
                reminderTemplate: '',
            };
        case 'geofence':
            return {
                category,
                analysis: 'enterorexit',
                template: '',
            };
    }

    // istanbul ignore next
    return nc;
}

export function NotificationConfigCustomEditDialog({
    item: storedItem,
    ...props
}: NotificationConfigCustomEditProps): React.ReactElement | null {
    const { categoryVariable, mediums, getRequiredPropValue, getRequiredKeyName } = useNotifConfigContext();
    const { useUpdateNotificationConfigMutation } = useFrame(SteinInternalApiClientToken);
    const [update, { isLoading }] = useUpdateNotificationConfigMutation();
    const { merged, hasChanges, setChanges, save } = useStagedChanges(update, storedItem);

    const handleChange = useOnChange(setChanges);
    async function handleSave(): Promise<void> {
        await save();
        props?.onClose ? props.onClose() : /* istanbul ignore next */ null;
    }

    return (
        <Dialog {...props}>
            <DialogContent>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} padding={1}>
                    <GridEasy>
                        <Text>{'When'}</Text>
                        <Trigger
                            item={merged}
                            onChange={handleChange}
                            selectDropdown={
                                <CustomEditTextField
                                    select
                                    value={merged.category}
                                    size={'small'}
                                    onChange={(e) =>
                                        handleChange(getDefaultProps(e.target.value as NotifConfigCategory))
                                    }
                                    label={'category'}
                                    name={'category'}
                                >
                                    {categoryVariable.map((c) => (
                                        <MenuItem value={c} key={c}>
                                            {NOTIF_CONFIG_CATEGORY_NAME[c]}
                                        </MenuItem>
                                    ))}
                                </CustomEditTextField>
                            }
                        />
                        <Text>{', '}</Text>
                        <Effect
                            item={merged}
                            onChange={handleChange}
                            selectDropdown={
                                <CustomEditTextField
                                    select
                                    value={merged.medium}
                                    size={'small'}
                                    onChange={(e) =>
                                        handleChange({
                                            medium: e.target.value as NotifConfigMedium,
                                        })
                                    }
                                    label={'medium'}
                                    name={'medium'}
                                >
                                    {mediums
                                        .filter(
                                            (m) =>
                                                !getRequiredKeyName(m) || typeof getRequiredPropValue(m) === 'string',
                                        )
                                        .map((m) => (
                                            <MenuItem value={m} key={m}>
                                                {NOTIF_CONFIG_MEDIUM_NAME[m]}
                                            </MenuItem>
                                        ))}
                                </CustomEditTextField>
                            }
                        />
                    </GridEasy>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => (props?.onClose ? props.onClose() : /* istanbul ignore next */ null)}>
                    {'Cancel'}
                </Button>
                <LoadingButton disabled={!hasChanges} onClick={handleSave} loading={isLoading} variant={'contained'}>
                    {'Save'}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

type LayoutProps = {
    item: NotifConfig;
    selectDropdown: React.ReactNode;
    onChange: (a: Partial<NotifConfig>) => void;
};

/* istanbul ignore next */
function GeofenceSelect({
    selected,
    onChange,
}: {
    selected: GeofenceId[];
    onChange: (a: GeofenceId[]) => void;
}): React.ReactElement {
    const projectSlug = useActiveProjectSlug();
    const geofences = useAppSelector((s) => selectGeofencesByProjectSlug(s, projectSlug));
    const geofenceState = React.useMemo(
        () =>
            geofences.reduce((o, g) => ({ ...o, [g.id]: selected.includes(g.id) }), {} as Record<GeofenceId, boolean>),
        [geofences, selected],
    );
    console.log({ geofenceState, geofences, selected });

    function handleChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
        console.log(event.target.name, checked);
        const newIds = Object.entries({
            ...geofenceState,
            [event.target.name]: checked,
        })
            .filter((e) => e[1])
            .map((e) => parseInt(e[0]) as GeofenceId);
        console.log({ newIds });
        onChange(newIds);
    }

    return (
        <Box width={'100%'}>
            {geofences.map((g) => (
                <FormControlLabel
                    key={g.id}
                    label={g.name}
                    control={<Checkbox checked={geofenceState[g.id]} onChange={handleChange} name={`${g.id}`} />}
                />
            ))}
        </Box>
    );
}

function Trigger({ item, selectDropdown, onChange }: LayoutProps): React.ReactElement {
    // istanbul ignore next
    function handleGeofenceChange(geofenceIds: GeofenceId[]): void {
        onChange({ geofenceIds });
    }

    switch (item.category) {
        case 'distraction':
        case 'eye_closure':
        case 'early_fatigue':
        case 'any_neglect':
        case 'harsh_maneuver':
            return (
                <>
                    <RateNumeratorInput item={item} handleChange={onChange} />
                    <CustomEditTextField
                        select
                        value={item.analysis}
                        onChange={(e) => onChange({ analysis: e.target.value as 'any' | 'verified' })}
                        label={'analysis'}
                    >
                        <MenuItem value={'any'}>{'any'}</MenuItem>
                        <MenuItem value={'verified'}>{'verified'}</MenuItem>
                    </CustomEditTextField>
                    {selectDropdown}
                    <Text>{`${plural(item.rateNumerator, 'event occurs', 'events occur')} in a`}</Text>
                    <RateDenominatorInput item={item} handleChange={onChange} />
                    <RateDenominatorUnitSelect item={item} handleChange={onChange} />
                    <Text>
                        {`window`}
                        <InfoTip
                            style={{ fontSize: 10 }}
                            text={`No matter how many events are detected, only one alert will be triggered per window.`}
                        />
                    </Text>
                </>
            );
        case 'flagged':
            return (
                <>
                    <RateNumeratorInput item={item} handleChange={onChange} />
                    {selectDropdown}
                    <Text>{`${plural(item.rateNumerator, 'occurs', 'occur')} in a`}</Text>
                    <RateDenominatorInput item={item} handleChange={onChange} />
                    <RateDenominatorUnitSelect item={item} handleChange={onChange} />
                    <Text>
                        {'window'}
                        <InfoTip
                            style={{ fontSize: 10 }}
                            text={`No matter how many events are detected, only one alert will be triggered per window.`}
                        />
                    </Text>
                </>
            );
        case 'geofence':
            return (
                <>
                    <Text>{'a vehicle '}</Text>
                    <CustomEditTextField
                        select
                        value={item.analysis}
                        onChange={(e) => onChange({ analysis: e.target.value as 'enter' | 'exit' | 'enterorexit' })}
                        label={'analysis'}
                    >
                        <MenuItem value={'enter'}>{'enters'}</MenuItem>
                        <MenuItem value={'exit'}>{'exits'}</MenuItem>
                        <MenuItem value={'enterorexit'}>{'enters or exits'}</MenuItem>
                    </CustomEditTextField>

                    <Text>{item.geofenceIds?.length ? 'any selected' : 'any'}</Text>
                    {selectDropdown}
                    <GeofenceSelect selected={item.geofenceIds || []} onChange={handleGeofenceChange} />
                </>
            );
        case 'iphone_battery_level_drop':
            return (
                <>
                    {selectDropdown}
                    <Text>{'drops below'}</Text>

                    <NumberInput
                        value={item.batteryLevelThreshold * 100}
                        onNumberChange={(percent) => onChange({ batteryLevelThreshold: percent / 100 })}
                        label={'batteryLevelThreshold'}
                    />
                    <Text>{'%'}</Text>
                </>
            );
        case 'calibration_status_changed':
            return (
                <>
                    {selectDropdown}
                    <Text>{'changes to'}</Text>
                    <CustomEditTextField
                        select
                        value={item.analysis}
                        size={'small'}
                        onChange={(e) =>
                            onChange({
                                analysis: e.target.value as CalibrationStatus,
                            })
                        }
                        label={'analysis'}
                    >
                        <MenuItem value={'any'}>{'any'}</MenuItem>
                        {CALIBRATION_STATUS.map((s) => (
                            <MenuItem key={s.id} value={s.id}>
                                {s.name}
                            </MenuItem>
                        ))}
                    </CustomEditTextField>
                </>
            );
    }

    return <>{selectDropdown}</>;
}

function RateNumeratorInput({
    item,
    handleChange,
}: {
    item: Extract<NotifConfig, { rateNumerator?: number }>;
    handleChange: (nc: Partial<NotifConfig>) => void;
}): React.ReactElement {
    return (
        <NumberInput
            value={item.rateNumerator}
            onNumberChange={(rateNumerator) => handleChange({ rateNumerator })}
            label={'rateNumerator'}
        />
    );
}
function RateDenominatorInput({
    item,
    handleChange,
}: {
    item: Extract<NotifConfig, { rateDenominator?: number }>;
    handleChange: (nc: Partial<NotifConfig>) => void;
}): React.ReactElement {
    return (
        <NumberInput
            value={item.rateDenominator}
            onNumberChange={(rateDenominator) => handleChange({ rateDenominator })}
            label={'rateDenominator'}
        />
    );
}
function RateDenominatorUnitSelect({
    item,
    handleChange,
}: {
    item: Extract<NotifConfig, { rateDenominator?: number }>;
    handleChange: (nc: Partial<NotifConfig>) => void;
}): React.ReactElement {
    return (
        <CustomEditTextField
            select
            value={item.rateDenominatorUnit}
            size={'small'}
            onChange={(e) => handleChange({ rateDenominatorUnit: e.target.value as NotifConfigDenominatorUnit })}
            label={'rateDenominatorUnit'}
        >
            {NOTIF_CONFIG_DENOMINATOR_UNITS.map((u) => (
                <MenuItem value={u} key={u}>
                    {u.slice(0, -1)}
                </MenuItem>
            ))}
        </CustomEditTextField>
    );
}

function NumberInput({
    value,
    onNumberChange,
    label,
}: {
    value: number | null;
    onNumberChange: (n: number) => void;
    label: string;
}): React.ReactElement | null {
    return (
        <CustomEditTextField
            type={'number'}
            value={value}
            onChange={(e) => {
                const val = parseInt(e.target.value);
                //istanbul ignore next
                if (typeof val === 'number' && val > 0 && val < 1000) {
                    onNumberChange(val);
                }
            }}
            onFocus={(e) => e.target.select()}
            sx={{
                maxWidth: 75,
            }}
            inputProps={{
                'aria-label': label,
            }}
        />
    );
}

function TemplateString({ text, description }: { text: string; description: string }): React.ReactElement {
    const theme = useAppTheme();
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
            }}
        >
            <code
                style={{
                    backgroundColor: theme.palette.grey[300],
                }}
            >
                {text}
            </code>
            <CopyToClipboardButton
                text={text}
                size={'inherit'}
                tooltipProps={{
                    sx: {
                        zIndex: 1500,
                    },
                }}
            />
            <span>
                {'—'} {description}
            </span>
        </div>
    );
}

function Effect({ item, selectDropdown, onChange }: LayoutProps): React.ReactElement | null {
    const { getRequiredPropValue } = useNotifConfigContext();

    switch (item.medium) {
        case 'email':
            return (
                <>
                    <Text>{'send an'}</Text>
                    {selectDropdown}
                </>
            );
        case 'slack_dm':
            return (
                <>
                    <Text>{'send a'}</Text>
                    {selectDropdown}
                    {getRequiredPropValue(item.medium) && (
                        <>
                            <Text>{'to '}</Text>
                            <Text>
                                <b>{getRequiredPropValue(item.medium)}</b>
                            </Text>
                        </>
                    )}
                    {item.category !== 'geofence' ? (
                        <>
                            <Text>{'with message:'}</Text>
                            <EditTemplateInput
                                value={item.template || ''}
                                onTextChange={(template) => onChange({ template })}
                                label={'template'}
                            />
                        </>
                    ) : null}

                    {item.category === 'sensor_covered_v2' ? (
                        <>
                            <Text>{'Then, after'}</Text>
                            <NumberInput
                                value={secondsToMinutes(item.remindIn || 60)}
                                onNumberChange={(remindInMin) => onChange({ remindIn: minutesToSeconds(remindInMin) })}
                                label={'remindIn'}
                            />
                            <Text>{'minutes,'}</Text>
                            <Text>{'send another Slack notification'}</Text>
                            <Text>{'with message:'}</Text>
                            <EditTemplateInput
                                value={item.reminderTemplate || ''}
                                onTextChange={(reminderTemplate) => onChange({ reminderTemplate })}
                                label={'reminderTemplate'}
                            />
                        </>
                    ) : null}
                </>
            );
        case 'voice_alert':
            return (
                <>
                    <Text>{'send a'}</Text>
                    {selectDropdown}
                    <Text>{'with message:'}</Text>
                    <FormControl variant="standard" fullWidth>
                        <CustomEditInput
                            value={item.voiceMessage || ''}
                            onChange={(e) => onChange({ voiceMessage: e.target.value })}
                            fullWidth
                            inputProps={{
                                'aria-label': 'voiceMessage',
                            }}
                        />
                    </FormControl>
                </>
            );
    }

    return (
        <>
            <Text>{'send a'}</Text>
            {selectDropdown}
        </>
    );
}

function EditTemplateInput({
    value,
    onTextChange,
    label,
    ...props
}: { value: string; onTextChange: (s: string) => void; label: string } & InputBaseProps): React.ReactElement {
    const theme = useAppTheme();
    const popperAnchor = React.useRef<HTMLDivElement | null>(null);
    const [popoverOpen, setPopoverOpen] = React.useState<boolean>(false);

    return (
        <ClickAwayListener onClickAway={() => setPopoverOpen(false)}>
            <Box ref={popperAnchor} width={'100%'}>
                <FormControl variant="standard" fullWidth>
                    <CustomEditInput
                        {...props}
                        value={value}
                        onChange={(e) => onTextChange(e.target.value)}
                        onFocus={() => setPopoverOpen(true)}
                        onBlur={() => setPopoverOpen(false)}
                        fullWidth
                        inputProps={{
                            'aria-label': label,
                        }}
                    />
                </FormControl>

                <Popper
                    open={popoverOpen}
                    anchorEl={popperAnchor.current}
                    style={{
                        zIndex: theme.zIndex.modal + 1,
                    }}
                >
                    <Paper
                        onMouseDown={(e) => {
                            // Prevent the popper from closing due to the onBlur event when
                            // copy to clipboard buttons are clicked.
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                        elevation={1}
                        sx={{
                            padding: theme.spacing(2),
                            fontSize: 12,
                            transform: 'translateY(10px)',
                            backgroundColor: theme.palette.grey[200],
                        }}
                    >
                        <p>{'Leave blank to use the default message.'}</p>
                        <p>{'The following tokens will be replaced by their repective value in the message.'}</p>
                        <ul style={{ listStyle: 'none' }}>
                            <li>
                                <TemplateString text={'{vehicle_name}'} description={'The vehicle name'} />
                            </li>
                            <li>
                                <TemplateString
                                    text={'{vehicle_name.upcase}'}
                                    description={'The uppercased vehicle name'}
                                />
                            </li>
                            <li>
                                <TemplateString
                                    text={'{link:Click here for details}'}
                                    description={'A link to the triggered event with the provided text.'}
                                />
                            </li>
                            <li>
                                <TemplateString
                                    text={'{battery_percent}'}
                                    description={'The battery level of the vehicle device.'}
                                />
                            </li>
                            <li>
                                <TemplateString
                                    text={'{event_count}'}
                                    description={'The number of events required to trigger this alert.'}
                                />
                            </li>
                        </ul>
                    </Paper>
                </Popper>
            </Box>
        </ClickAwayListener>
    );
}

const CustomEditTextField = appStyled(TextField)(({ theme }) => ({
    '& .MuiFormLabel-root': {
        display: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        display: 'none',
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        padding: '1px 5px 1px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}));

const CustomEditInput = appStyled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        padding: '1px 5px 1px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}));
