import { createSlice } from '@reduxjs/toolkit';
import { SoundEffect, SoundEffectId } from '../../types/stein';
import { setRailsContext } from './shared-actions';

type Navigation = {
    soundEffects: SoundEffect[];
    defaultSoundEffectId: SoundEffectId;
};

// Define the initial state using that type
const initialState: Navigation = {
    soundEffects: [],
    defaultSoundEffectId: 0 as SoundEffectId,
};

export const audioSlice = createSlice({
    name: 'audio',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setRailsContext, (s, a) => {
            s.soundEffects = a.payload.soundEffects;
            s.defaultSoundEffectId = a.payload.defaultSoundEffectId;
        });
    },
});
