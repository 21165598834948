import { History } from 'history';
import { createToken } from '../frame';

export type FrameLogger = Pick<typeof console, 'log' | 'error' | 'debug' | 'warn'>;
export const LoggerToken = createToken<FrameLogger>('LoggerToken');
import type { Cable } from '@rails/actioncable';
import { ReplaceReturnType } from '../../types/util-types';

type Window = typeof window;

export type FrameWindow = {
    location: Pick<Window['location'], 'reload' | 'replace' | 'search' | 'pathname' | 'href'>;
    navigator: Pick<Window['navigator'], 'clipboard'>;
    document: {
        fullscreenElement: unknown | null;
        exitFullscreen: () => Promise<void>;
        pictureInPictureElement: unknown | null;
        exitPictureInPicture: () => Promise<void>;
        addEventListener: Window['document']['addEventListener'];
        removeEventListener: Window['document']['removeEventListener'];
    };
    open: ReplaceReturnType<Window['open'], FrameWindow | null>;
    Intercom?: (s: string) => void;
};

export const WindowToken = createToken<FrameWindow>('WindowToken');

export const EnvToken = createToken<Record<string, string>>('EnvToken');

export type Fetch = typeof window.fetch;
export const FetchToken = createToken<Fetch>('FetchToken');

export const HistoryToken = createToken<History>('HistoryToken');

export const ActionCableToken = createToken<Cable>('ActionCableToken');

export const PathPrefixToken = createToken<string>('PathPrefixToken');
