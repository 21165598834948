import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import * as React from 'react';
import { useIsMountedRef } from './use-is-mounted-ref';
import LoadingButton from '@mui/lab/LoadingButton';

type UseConfirmationDialogProps = {
    message: string;
    buttonText?: string;
    func: () => Promise<unknown>;
};

type UseConfirmationDialogRes = {
    node: React.ReactNode;
    handleShouldConfirm: () => void;
};

export function useConfirmationDialog({
    func,
    message,
    buttonText,
}: UseConfirmationDialogProps): UseConfirmationDialogRes {
    const [open, setOpen] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const isMountedRef = useIsMountedRef();

    function handleClose(): void {
        isMountedRef.current && setOpen(false);
    }

    async function confirm(): Promise<void> {
        setLoading(true);
        func().finally(() => {
            setLoading(false);
            handleClose();
        });
    }

    const node = (
        <Dialog open={open} onClose={handleClose} data-testid={'confirmation-dialog'}>
            <DialogTitle>{message}</DialogTitle>
            <DialogActions>
                <Button onClick={handleClose}>{'Cancel'}</Button>
                <LoadingButton onClick={confirm} variant={'contained'} color={'error'} loading={loading}>
                    {buttonText ? buttonText : 'Confirm'}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );

    return {
        node,
        handleShouldConfirm: () => setOpen(true),
    };
}
