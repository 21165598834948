import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { Project, ProjectSlug } from '../types/stein';
import { projectSlugFromLocation } from '../utils/location-utils';
import { selectProjectBySlug } from '../store/selectors';
import { useFrame } from '../lib/frame-react';
import { HistoryToken, LoggerToken } from '../lib/frame-tokens';
import { useAppSelector } from './hooks';
import { ErrorPage } from '../components/pages/ErrorPage';

export function useActiveProjectSlugOptional(): ProjectSlug | undefined {
    const location = useLocation();
    return projectSlugFromLocation(location);
}

export function useActiveProjectOptional(): Project | undefined {
    const projectSlug = useActiveProjectSlugOptional();
    return useAppSelector((s) => selectProjectBySlug(s, projectSlug));
}

const activeProjectContext = React.createContext<Project | undefined>(undefined);

export function ActiveProjectProvider({ children }: { children: React.ReactNode }): React.ReactElement {
    const logger = useFrame(LoggerToken);
    const project = useActiveProjectOptional();
    const { location } = useFrame(HistoryToken);

    if (!project) {
        logger.error('No Active Project', { location: JSON.stringify(location) });
        return <ErrorPage type={'not-found'} content={'Project Not Found'} />;
    }

    return <activeProjectContext.Provider value={project}>{children}</activeProjectContext.Provider>;
}

export function useActiveProject(): Project {
    const project = React.useContext(activeProjectContext);
    // istanbul ignore next
    if (!project) {
        // istanbul ignore next this will not happen unless `useActiveProject` is used outside of `ActiveProjectProvider`
        throw new Error('no active project');
    }
    return project;
}

export function useActiveProjectSlug(): ProjectSlug {
    const projectSlug = useActiveProjectSlugOptional();
    // istanbul ignore next
    if (!projectSlug) {
        // istanbul ignore next this will not happen unless `useActiveProjectSlug` is used outside of `ActiveProjectProvider`
        throw new Error('no active project slug');
    }
    return projectSlug;
}
