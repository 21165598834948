import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { AppRootState, AppDispatch } from '../store';
import { useFrame } from '../lib/frame-react';
import { HistoryToken, WindowToken } from '../lib/frame-tokens';

import * as React from 'react';
import { SteinInternalApiClientToken } from '../clients/stein-internal-api';
import { AppInternalUrl } from '../utils/internal-url-utils';
import { selectDebugEnabled } from '../store/selectors';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppSelector: TypedUseSelectorHook<AppRootState> = useSelector;
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export function useUpdateDriverEvent() {
    const { useUpdateDriverEventMutation } = useFrame(SteinInternalApiClientToken);

    return useUpdateDriverEventMutation();
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export function useUpdateDriverLog() {
    const { useUpdateDriverLogMutation } = useFrame(SteinInternalApiClientToken);

    return useUpdateDriverLogMutation();
}

export function useOnLocationChange(fn: () => void): void {
    const locationChangeToggle = useAppSelector((s) => s.navigation.locationChangeToggle);

    React.useEffect(fn, [locationChangeToggle]);
}

export function useOnMount(fn: React.EffectCallback): void {
    return React.useEffect(fn, []);
}

type AppNavigateFunction = (url: AppInternalUrl) => void;
export function useAppNavigate(): AppNavigateFunction {
    const history = useFrame(HistoryToken);
    return (url: AppInternalUrl) => {
        return history.push(url);
    };
}

export function useIdleTime(): number {
    const [time, setTime] = React.useState<number>(0);
    const window = useFrame(WindowToken);
    const debugEnabled = useAppSelector(selectDebugEnabled);

    useOnMount(() => {
        const interval = setInterval(() => setTime((t) => t + 1), 1000);
        return () => clearInterval(interval);
    });

    React.useEffect(() => {
        function reset(): void {
            setTime(0);
        }
        window.document.addEventListener('mousemove', reset);
        window.document.addEventListener('keypress', reset);

        return () => {
            window.document.removeEventListener('mousemove', reset);
            window.document.removeEventListener('keypress', reset);
        };
    }, [window, setTime]);

    return debugEnabled ? /* istanbul ignore next */ 0 : time;
}
