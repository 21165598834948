import { ElementOfArray } from '../types/util-types';

export const CALIBRATION_STATUS_HEALTHY = {
    name: 'Healthy',
    id: 'healthy',
    tooltip: 'iPhone is properly calibrated.',
    icon: 'fa-expand-wide',
    color: 'success',
} as const;

export const CALIBRATION_STATUS_OBSTRUCTED = {
    name: 'Obstructed',
    id: 'wheel',
    tooltip: 'iPhone obstructed by steering wheel. Please adjust the dashboard mount and recalibrate.',
    icon: 'fa-expand-wide',
    color: 'error',
} as const;

export const CALIBRATION_STATUS_VERIFYING = {
    name: 'Analyzing',
    id: 'maybe_needs_recal',
    tooltip: 'Verifying calibration status.',
    icon: 'fa-expand-wide',
    color: 'warning',
} as const;

export const CALIBRATION_STATUS_RECALIBRATE = {
    name: 'Recalibrate',
    id: 'confirmed_needs_recal',
    tooltip:
        'Manual recalibration required. Please ensure the dashboard mount is in a secure location, and recalibrate.',
    icon: 'fa-expand-wide',
    color: 'error',
} as const;

export const CALIBRATION_STATUS_REMOTE_CALIBRATING = {
    name: 'Remote',
    id: 'vendor_is_recalibrating',
    tooltip: 'iPhone is currently being remotely recalibrated, and will be back online shortly.',
    icon: 'fa-expand-wide',
    color: 'primary',
} as const;

export const CALIBRATION_STATUS_UNKNOWN = {
    icon: 'fa-expand-wide',
    color: 'primary',
    name: 'Unknown',
    tooltip: 'Calibration status unknown',
} as const;

export const CALIBRATION_STATUS = [
    CALIBRATION_STATUS_HEALTHY,
    CALIBRATION_STATUS_OBSTRUCTED,
    CALIBRATION_STATUS_VERIFYING,
    CALIBRATION_STATUS_RECALIBRATE,
] as const;

export const CALIBRATION_STATUS_MAP_ID = CALIBRATION_STATUS.reduce(
    (obj, e) => ({
        ...obj,
        [e.id]: e,
    }),
    {},
) as CalibrationStatusMapId;

type CalibrationStatus = ElementOfArray<typeof CALIBRATION_STATUS>;

type CalibrationStatusMapId = {
    [K in CalibrationStatus['id']]: Extract<CalibrationStatus, { id: K }>;
};
