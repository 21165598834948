// istanbul ignore file

import * as React from 'react';
import { Box, MenuItem, Stack, TextField } from '@mui/material';
import { MultiBlock } from '../../MultiBlock';
import { VideoCall } from '../../../services/video-call-service/video-call';
import { useFrame } from '../../../lib/frame-react';
import { VideoCallServiceToken } from '../../../services/video-call-service/video-call-service';
import { useAppSelector, useOnMount } from '../../../hooks/hooks';
import { selectVehiclesByProjectSlug } from '../../../store/selectors';
import { useActiveProjectSlug } from '../../../hooks/use-active-project';
import { isVehicleOnline } from '../../Vehicle/vehicle_utils';
import { VehicleDeviceId } from '../../../types/stein';
import { VideoCallDisplay } from './VideoCall/VideoCall';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import { AppIconRefresh } from '../../AppIcons';
import LoadingButton from '@mui/lab/LoadingButton';

export function MonitoringPage(): React.ReactElement | null {
    const [items, setItems] = React.useState<VideoCall[]>([]);
    const videoCallService = useFrame(VideoCallServiceToken);
    const { useGetVehicleDevicesQuery, useLazyGetVehicleDevicesQuery } = useFrame(SteinInternalApiClientToken);
    const activeProjectSlug = useActiveProjectSlug();
    const vehicles = useAppSelector((s) => selectVehiclesByProjectSlug(s, activeProjectSlug));
    const activeVehciles = vehicles.filter(
        (v) => isVehicleOnline(v) && !items.some((call) => call.vehicleDeviceId === v.id),
    );
    useGetVehicleDevicesQuery({ projectSlug: activeProjectSlug, isBackground: true });
    const [getVehicles, { isFetching }] = useLazyGetVehicleDevicesQuery();

    useOnMount(() => {
        videoCallService.on('numCallsChanged', () => {
            setItems(videoCallService.getActiveCalls());
        });
    });

    return (
        <div style={{ height: 'calc(100vh - 200px)', width: '100%' }}>
            <Stack direction={'row'} spacing={1}>
                <TextField
                    size={'small'}
                    label={'Start live stream with vehicle'}
                    fullWidth
                    select
                    value={''}
                    onChange={(e) => videoCallService.startCall(parseInt(e.target.value) as VehicleDeviceId)}
                >
                    {activeVehciles.length ? (
                        activeVehciles.map((v) => (
                            <MenuItem value={v.id} key={v.id}>
                                {v.name}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem disabled>No active vehicles</MenuItem>
                    )}
                </TextField>
                <LoadingButton
                    variant={'contained'}
                    size={'small'}
                    loading={isFetching}
                    onClick={() => getVehicles({ projectSlug: activeProjectSlug, isBackground: true })}
                >
                    <AppIconRefresh />
                </LoadingButton>
            </Stack>
            {items.length ? (
                <MultiBlock
                    items={items.map((c) => (
                        <VideoCallDisplay call={c} key={c.vehicleDeviceId} />
                    ))}
                />
            ) : (
                <Box display={'flex'} justifyContent={'center'} p={2}>
                    Select a vehile from the above dropdown to start a Live Stream
                </Box>
            )}
        </div>
    );
}
