import * as React from 'react';
import { useAppSelector } from '../../hooks/hooks';
import { selectAbilities, selectDebugEnabled, selectHasPermission } from '../../store/selectors';
import { appStyled } from '../../theme';
import { useActiveProjectSlugOptional } from '../../hooks/use-active-project';

import { AbilitySubject, AbilityActionsForSubject, Ability } from '../../types/stein';
import { hasAbility } from '../../utils/has-ability';
import { CalloutBox } from '../material/CalloutBox';

export const AuhorizeDebugBase = appStyled(CalloutBox)(({ theme }) => ({
    '&.MuiPaper-root': {
        borderColor: theme.colors.debugBlock.auth.border,
        backgroundColor: theme.colors.debugBlock.auth.background,
        borderStyle: 'dashed',
    },
}));

type AuthorizeProps<T extends AbilitySubject = AbilitySubject> = {
    children: React.ReactNode;
} & (
    | {
          someOf: Ability[];
      }
    | Ability<T>
    | Record<never, never>
);

type AuthorizeMaybeProps<T extends AbilitySubject = AbilitySubject> = {
    auth?: Ability<T>;
    children: React.ReactNode;
};

export function AuthorizeMaybe<T extends AbilitySubject = AbilitySubject>({
    auth,
    children,
}: AuthorizeMaybeProps<T>): React.ReactElement {
    if (auth) {
        return (
            <Authorize subject={auth.subject} action={auth.action}>
                {children}
            </Authorize>
        );
    }
    return <>{children}</>;
}

function Authorize<T extends AbilitySubject = AbilitySubject>({
    children,
    ...props
}: AuthorizeProps<T>): React.ReactElement | null {
    const projectSlug = useActiveProjectSlugOptional();
    const abilities = useAppSelector((s) => selectAbilities(s, projectSlug));
    const debugEnabled = useAppSelector(selectDebugEnabled);

    let hasPermission = false;
    let debugName = '';

    // istanbul ignore next
    if (projectSlug) {
        if ('someOf' in props) {
            // istanbul ignore next
            hasPermission = props.someOf.some((a) => hasAbility(abilities, a.action, a.subject));
            // istanbul ignore next
            debugName = props.someOf.map((a) => `${a.subject}:${a.action}`).join(' or ');
        } else if ('action' in props) {
            hasPermission = hasAbility(abilities, props.action, props.subject);
            debugName = `${props.subject}:${props.action}`;
        }
    }

    if (debugEnabled) {
        return (
            <AuhorizeDebugBase name={debugName} disablePadding enableHighlight>
                {children}
            </AuhorizeDebugBase>
        );
    }

    if (hasPermission) {
        return <>{children}</>;
    }
    return null;
}

export function useHasPermission<T extends AbilitySubject>(subject: T, action: AbilityActionsForSubject<T>): boolean {
    const projectSlug = useActiveProjectSlugOptional();

    return useAppSelector((s) => (projectSlug ? selectHasPermission(s, action, subject, projectSlug) : false));
}
