import * as queryString from 'query-string';
import { toCamelCase, toSnakeCase } from '../lib/case-transforms';

export function makeQueryString(obj: Record<string, unknown>): string {
    return queryString.stringify(toSnakeCase(obj), { arrayFormat: 'bracket' });
}

export function parseQueryString(str: string): Record<string, null | string | Array<string>> {
    const query = queryString.parse(str, { arrayFormat: 'bracket' });
    return toCamelCase(query);
}
