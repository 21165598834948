import * as React from 'react';

import { Checkbox, Tooltip, Table, TableHead, TableCell, TableRow, TableBody, Skeleton } from '@mui/material';
import { CustomRole } from '../../types/stein';
import { useAppSelector } from '../../hooks/hooks';
import { hasAbility, toggleAbility } from '../../utils/has-ability';
import { toTitleCase } from '../../lib/case-transforms';
import { InfoTip } from '../InfoTip';
import { selectUserRoles } from '../../store/selectors';
import { useFrame } from '../../lib/frame-react';
import { SteinInternalApiClientToken } from '../../clients/stein-internal-api';
import { range } from 'lodash';
import { useActiveProjectSlug } from '../../hooks/use-active-project';
import { Permission } from '../Authorize/PermissionBlock';

type AbilitySelectionProps = {
    roles: Array<CustomRole>;
    loading: boolean;
};

function AbilitySelectionGridLoading(): React.ReactElement {
    const FAKE_ROLE_NUMBER = 8;
    return (
        <>
            <Skeleton width={100}>
                <h3>Title</h3>
            </Skeleton>
            <Skeleton width={'70%'}>
                <p>Desc</p>
            </Skeleton>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        {range(FAKE_ROLE_NUMBER).map((i) => (
                            <TableCell key={i}>
                                <Skeleton width={40} height={25} />
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {range(15).map((i) => (
                        <TableRow key={i}>
                            <TableCell>
                                <Skeleton width={140} height={25} />
                            </TableCell>
                            {range(FAKE_ROLE_NUMBER).map((j) => (
                                <TableCell key={j} align={'center'}>
                                    <Skeleton variant={'rectangular'} width={25} height={25} />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
}

export function AbilitySelectionGrid({ roles, loading }: AbilitySelectionProps): React.ReactElement {
    const configAbilities = useAppSelector((s) => s.abilities.config);
    const projectSlug = useActiveProjectSlug();
    const userRoles = useAppSelector((s) => selectUserRoles(s, projectSlug));
    const { useUpdateCustomRoleMutation } = useFrame(SteinInternalApiClientToken);
    const [updateCustomRole] = useUpdateCustomRoleMutation();

    if (loading) {
        return <AbilitySelectionGridLoading />;
    }

    return (
        <>
            {configAbilities.map((abilityConfig) => (
                <Permission feature={abilityConfig.feature} key={abilityConfig.subject}>
                    <h3>{abilityConfig.name ? abilityConfig.name : abilityConfig.subject}</h3>
                    <p>{abilityConfig.description}</p>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                {roles.map((r) => (
                                    <TableCell key={r.id} align={'center'} style={{ maxWidth: '30px' }}>
                                        {toTitleCase(r.name)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {abilityConfig?.actions?.map((action) => (
                                <TableRow key={action}>
                                    <TableCell>{toTitleCase(action)}</TableCell>
                                    {roles.map((r) => (
                                        <TableCell
                                            key={r.id}
                                            align={'center'}
                                            data-testid={`cell-role-${r.id}-${abilityConfig.subject}-${action}`}
                                        >
                                            <Tooltip title={toTitleCase(action)} placement={'top'} arrow>
                                                <Checkbox
                                                    onChange={() =>
                                                        updateCustomRole({
                                                            projectSlug,
                                                            id: r.id,
                                                            abilities: toggleAbility(
                                                                r.abilities,
                                                                action,
                                                                abilityConfig.subject,
                                                            ),
                                                        })
                                                    }
                                                    checked={hasAbility(r.abilities, action, abilityConfig.subject)}
                                                />
                                            </Tooltip>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                            {abilityConfig?.customActions?.map((action) => (
                                <Permission feature={action.feature} key={action.symbol}>
                                    <TableRow>
                                        <TableCell>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    maxWidth: 110,
                                                    minWidth: 110,
                                                }}
                                            >
                                                {action.name}
                                                <InfoTip text={action.description} />
                                            </div>
                                        </TableCell>
                                        {roles.map((r) => {
                                            const checked = hasAbility(
                                                r.abilities,
                                                action.symbol,
                                                abilityConfig.subject,
                                            );
                                            const prohibit =
                                                checked &&
                                                action.prohibitDisableSelf &&
                                                Boolean(userRoles.find((userRole) => userRole.id === r.id));
                                            return (
                                                <TableCell
                                                    key={r.id}
                                                    align={'center'}
                                                    data-testid={`cell-role-${r.id}-${abilityConfig.subject}-${action.symbol}`}
                                                >
                                                    <Tooltip title={toTitleCase(r.name)} placement={'top'} arrow>
                                                        <span>
                                                            <Checkbox
                                                                disabled={prohibit}
                                                                checked={checked}
                                                                onChange={() =>
                                                                    updateCustomRole({
                                                                        projectSlug,
                                                                        id: r.id,
                                                                        abilities: toggleAbility(
                                                                            r.abilities,
                                                                            action.symbol,
                                                                            abilityConfig.subject,
                                                                        ),
                                                                    })
                                                                }
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </Permission>
                            ))}
                        </TableBody>
                    </Table>
                </Permission>
            ))}
        </>
    );
}
