import * as React from 'react';

import { appStyled } from '../../theme';
import { GlobalStyles } from '../../theme/GlobalStyles';
import { DebugToggle } from '../Authorize/Debug/DebugToggle';
import { ApplicationBar } from '../ApplicationBar';
import { NotificationSnackbar } from '../Notifications/NotificationSnackbar';
import { Intercom } from '../Intercom';
import { SimulatedRole } from '../SimulatedRole';
import { Box } from '@mui/system';

import { NavigationDrawerProvider, useNavigationDrawerContext } from './NavigationDrawerContext';
import { NavigationDrawer } from './NavigationDrawer';
import { RailsContextLoader } from '../RailsContextLoader';
import { OmnibarProvider } from '../OmniBar/Omnibar';

export const ToolbarOffset = appStyled('div')(({ theme }) => theme.mixins.toolbar);

const ApplicationWrapper = appStyled('div')({
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
});

const ContentWrapper = appStyled('div')({
    width: '100%',
    display: 'flex',
    minHeight: '100vh',
    paddingTop: '64px',
});

type AppLayoutProps = {
    children: React.ReactNode;
};

function MainContent({ children }: { children: React.ReactNode }): React.ReactElement {
    const drawer = useNavigationDrawerContext();
    const padding = drawer.isDesktop ? (drawer.isOpen ? ' 210' : '50') : '20';
    return (
        <Box sx={{ flexGrow: 1, p: 3, maxWidth: `calc(100vw - ${padding}px)` }} data-testid={'layout-page-content'}>
            {children}
        </Box>
    );
}

export function AppLayout({ children }: AppLayoutProps): React.ReactElement {
    return (
        <OmnibarProvider>
            <ApplicationWrapper>
                <NavigationDrawerProvider>
                    <GlobalStyles />
                    <ApplicationBar data-testid={'layout-application-bar'} />

                    <ContentWrapper>
                        <ToolbarOffset />
                        <NavigationDrawer />
                        <RailsContextLoader>
                            <MainContent>{children}</MainContent>
                        </RailsContextLoader>
                    </ContentWrapper>
                    <NotificationSnackbar />
                    <DebugToggle />
                    <Intercom />
                    <SimulatedRole />
                </NavigationDrawerProvider>
            </ApplicationWrapper>
        </OmnibarProvider>
    );
}
