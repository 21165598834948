import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createToken, createPlugin } from '../lib/frame';
import { FetchToken, EnvToken, WindowToken } from '../lib/frame-tokens';
import { SignOutAndInRequest } from '../types/stein';

export type SteinAPI = ReturnType<typeof SteinRootApiPlugin>;
export type SteinClient = Omit<
    SteinAPI,
    | 'reducerPath'
    | 'reducer'
    | 'middleware'
    | 'endpoints'
    | 'enhanceEndpoints'
    | 'injectEndpoints'
    | 'enchanceEndpoints'
    | 'utils'
    | 'internalActions'
    | 'usePrefetch'
    | 'baseUrl'
>;

export const SteinRootApiToken = createToken<SteinAPI>('SteinRootApiToken');
// The same Token, but with a reduced functionality
export const SteinRootApiClientToken = createToken<SteinClient>('SteinRootApiToken');

export const SteinRootApiPlugin = createPlugin(({ resolve }) => {
    const env = resolve(EnvToken);
    const win = resolve(WindowToken);

    // In a test environment, we dont have a DOM, so there is no concept of relative URLs.
    const baseUrl = env.NODE_ENV === 'test' ? 'http://localhost:3000/' : '/';

    return {
        ...createApi({
            reducerPath: 'steinRoot',
            baseQuery: fetchBaseQuery({
                baseUrl,
                fetchFn: resolve(FetchToken),
            }),
            endpoints: (build) => ({
                signOut: build.mutation({
                    query: () => ({
                        url: `/users/sign_out`,
                        method: 'DELETE',
                    }),
                    async onQueryStarted(_, { queryFulfilled }) {
                        await queryFulfilled;
                        win.location.reload();
                    },
                }),
                signOutAndIn: build.mutation<unknown, SignOutAndInRequest>({
                    query: ({ email }) => ({
                        url: `/users/sessions/sign_out_and_sign_in?next=%2Fv2&user%5Bpassword%5D=password&user%5Bemail%5D=${encodeURIComponent(
                            email,
                        )}`,
                        method: 'POST',
                    }),
                    async onQueryStarted(_, { queryFulfilled }) {
                        try {
                            await queryFulfilled;
                        } catch (_) {}
                        win.location.replace('/');
                    },
                }),
            }),
        }),
        baseUrl,
    };
});
