import { AnyAction, createListenerMiddleware, ListenerMiddleware, ThunkDispatch } from '@reduxjs/toolkit';

import { createPlugin, createToken } from '../lib/frame';
import { WindowToken } from '../lib/frame-tokens';
import { changeLocation } from '../store/actions';

type IntercomListener = ListenerMiddleware<unknown, ThunkDispatch<unknown, unknown, AnyAction>, unknown>;
export const IntercomListenerToken = createToken<IntercomListener>('IntercomListenerToken');

export const IntercomListenerPlugin = createPlugin<IntercomListener>(({ resolve }) => {
    const listener = createListenerMiddleware();
    const window = resolve(WindowToken);

    listener.startListening({
        actionCreator: changeLocation,
        effect: async () => {
            if (window.Intercom) {
                window.Intercom('update');
            }
        },
    });

    return listener.middleware;
});
