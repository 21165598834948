// istanbul ignore file
import * as React from 'react';
import * as L from 'leaflet';
import * as ReactDOMServer from 'react-dom/server';

import { defineDefault } from '../../utils/undefined-utils';
import { Marker, Tooltip } from 'react-leaflet';
import { urlDriverEventShow } from '../../utils/internal-url-utils';
import { DriverEvent } from '../../types/stein';
import { useActiveProjectSlug } from '../../hooks/use-active-project';
import { useHistory } from 'react-router-dom';
import { DriverEventIcon } from '../DriverEvent/DriverEventIcon';
import { AppIconVehicle } from '../AppIcons';
import { useAppSelector } from '../../hooks/hooks';
import { selectVehicleById } from '../../store/selectors';
import { format } from 'date-fns';
import { parseISO8601 } from '../../utils/datetime-utils';

export function DriverEventMarker({ driverEvent }: { driverEvent: DriverEvent }): React.ReactElement {
    const projectSlug = useActiveProjectSlug();
    const history = useHistory();
    const vehicle = useAppSelector((s) => selectVehicleById(s, driverEvent.vehicleDeviceId));

    return (
        <Marker
            position={[defineDefault(driverEvent.latitude, 0), defineDefault(driverEvent.longitude, 0)]}
            eventHandlers={{
                click: /* istanbul ignore next*/ () => {
                    history.push(urlDriverEventShow(projectSlug, driverEvent.id));
                },
            }}
            icon={L.divIcon({
                className: 'leaflet-invisible-marker',
                html: ReactDOMServer.renderToStaticMarkup(
                    <div
                        data-testid={'driverevent-marker'}
                        style={{
                            color: '#FA824C',
                            display: 'flex',
                            height: '23px',
                            width: '23px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            alignContent: 'center',
                            backgroundColor: '#253842',
                            borderRadius: '15px',
                            transform: `translate(-50%, -50%)`,
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                width: '100%',
                                fontSize: '12px',
                            }}
                        >
                            <DriverEventIcon event={driverEvent} />
                        </div>
                    </div>,
                ),
            })}
        >
            <Tooltip>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 5,
                    }}
                >
                    <div style={{ fontSize: '8px' }}>
                        {format(parseISO8601(driverEvent?.occurredAt), 'MMM d, h:mm bbb')}
                    </div>
                    <div>{`${driverEvent?.eventName} event`}</div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <AppIconVehicle />
                        </div>

                        <div>{vehicle?.name}</div>
                    </div>
                </div>
            </Tooltip>
        </Marker>
    );
}
