import { Box } from '@mui/system';
import * as React from 'react';
import { appStyled, useAppTheme } from '../theme';

const ColumnLeft = appStyled('div')(({ theme }) => ({
    flex: 1,
    paddingTop: theme.spacing(1),
    '& > div': { marginBottom: theme.spacing(2) },
}));

const ColumnRight = appStyled('div')(({ theme }) => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    maxWidth: '480px',
    '& > div': { marginBottom: theme.spacing(2) },
    [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingTop: 0,
    },
}));

type PageLayoutColumnsProps = {
    left: React.ReactNode;
    right: React.ReactNode;
};

export function PageLayoutColumns({ left, right }: PageLayoutColumnsProps): React.ReactElement {
    const theme = useAppTheme();
    return (
        <Box
            sx={{
                display: 'flex',
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                },
            }}
        >
            <ColumnLeft>{left}</ColumnLeft>
            <ColumnRight>{right}</ColumnRight>
        </Box>
    );
}
