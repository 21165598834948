/* istanbul ignore file */
import * as React from 'react';

import { useAppNavigate } from '../../../hooks/hooks';
import { useActiveProjectSlug } from '../../../hooks/use-active-project';

import { AppInstall } from '../../../types/stein';

import { DataGrid } from '../../DataGrid';

import { filterIncludesSome } from '../../../utils/filter-utils';
import { urlAppInstallShow } from '../../../utils/internal-url-utils';

import { BATTERY_STATE } from '../../../constants/battery-status-constants';

import { useAppInstallColumns } from './useAppInstallColumns';
import { useFrame } from '../../../lib/frame-react';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';

import { GridSortModel, DATA_GRID_PRO_PROPS_DEFAULT_VALUES } from '@mui/x-data-grid-pro';
import { useFilters, useSearchParamSingular } from '../../../hooks/use-filters';
import { EditAppInstallDialog } from './EditAppInstallDialog';
import { FilterBar } from '../../FilterBar';
import { Button } from '@mui/material';
import { AppInstallRegistrationDialog } from '../../AppInstallRegistrationDialog';
import { Permission } from '../../Authorize/PermissionBlock';

const SORT_MODEL: GridSortModel = [
    { field: 'label', sort: 'asc' },
    { field: 'serialNumber', sort: 'asc' },
];

export function AppInstallList(): React.ReactElement {
    const [selectedAppInstallSlug, setSelected] = useSearchParamSingular('selected');
    const columns = useAppInstallColumns();

    const projectSlug = useActiveProjectSlug();
    const { filters } = useFilters();

    const { useGetAppInstallsQuery } = useFrame(SteinInternalApiClientToken);
    const { data } = useGetAppInstallsQuery({ projectSlug });
    const appInstalls = data?.appInstalls || [];
    const navigate = useAppNavigate();

    const onRowChosen = React.useCallback(
        function onRowChosenCallback(v: AppInstall): void {
            navigate(urlAppInstallShow(projectSlug, v));
        },
        [projectSlug],
    );

    const [open, setOpen] = React.useState(false);

    // istanbul ignore next
    function handleClose(): void {
        // istanbul ignore next
        setOpen(false);
    }

    const filteredAppInstalls = React.useMemo(
        () =>
            appInstalls
                .filter((v) => {
                    const versions = [];
                    v.bundleNeedsUpdate && versions.push('app_update');
                    v.operatingSystemNeedsUpdate && versions.push('ios_update');

                    const battery = [];
                    if (typeof v.batteryLevel === 'number') {
                        v.batteryLevel < 0.5 && battery.push('low');
                        v.batteryState != BATTERY_STATE.FULL &&
                            v.batteryState != BATTERY_STATE.CHARGING &&
                            battery.push('unplugged');
                    }

                    const vehicle = [];
                    !v.vehicleDeviceId && vehicle.push('unassinged');

                    const include =
                        filterIncludesSome(filters.versions, versions) &&
                        filterIncludesSome(filters.battery, battery) &&
                        filterIncludesSome(filters.vehicle, vehicle);

                    if (!include) {
                        return false;
                    }

                    return true;
                })
                .map((v) => ({
                    ...v,
                    url: urlAppInstallShow(projectSlug, v),
                })),
        [appInstalls, filters],
    );

    return (
        <>
            <span data-testid={'page-app-install-index'} />
            <Permission auth={{ subject: 'AppInstall', action: 'update' }}>
                <FilterBar icons={<></>}>
                    <Button color={'primary'} variant={'contained'} onClick={() => setOpen(true)}>
                        {'Register Device'}
                    </Button>
                </FilterBar>
            </Permission>

            <DataGrid
                hideFooter
                onRowChosen={onRowChosen}
                localeText={{
                    noRowsLabel: 'No Matching Devices',
                }}
                columns={columns}
                sortingMode="client"
                sortModel={SORT_MODEL}
                disableMultipleColumnsSorting={false}
                rows={filteredAppInstalls}
                rowHeight={DATA_GRID_PRO_PROPS_DEFAULT_VALUES.rowHeight}
            />
            <Permission auth={{ subject: 'AppInstall', action: 'update' }}>
                <EditAppInstallDialog
                    appInstall={appInstalls.find((a) => a.slug === selectedAppInstallSlug)}
                    onClose={() => setSelected(null)}
                />
                <AppInstallRegistrationDialog open={open} onClose={handleClose} />
            </Permission>
        </>
    );
}
