import { Button, Dialog } from '@mui/material';
import * as React from 'react';

import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { Menu } from '@mui/material';

type FilterBulkActionsProps = {
    children: React.ReactNode;
    disabled: boolean;
};

export function FilterBulkActions({ children, disabled }: FilterBulkActionsProps): React.ReactElement | null {
    return (
        <div data-testid={'filter-bulk-actions'}>
            <PopupState variant="popover">
                {(popupState) => (
                    <>
                        <Button disabled={disabled} size={'small'} variant={'contained'} {...bindTrigger(popupState)}>
                            {'Actions'}
                        </Button>
                        <Menu {...bindMenu(popupState)} onClick={popupState.close}>
                            {children}
                        </Menu>
                    </>
                )}
            </PopupState>
        </div>
    );
}

type BulkActionsDialogProps = {
    onClose: () => void;
    children: React.ReactNode;
    open: boolean;
};

export function BulkActionsDialog({ open, children, onClose }: BulkActionsDialogProps): React.ReactElement {
    return (
        <Dialog open={Boolean(open)} onClose={onClose} data-testid="bulk-actions-dialog">
            <div style={{ minWidth: '500px' }}>{children}</div>
        </Dialog>
    );
}
