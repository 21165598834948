import { DateStrISO8601, VehicleDevice } from '../../types/stein';
import { isRoughlyNow } from '../../utils/datetime-utils';

export function getDrivingState(vehicle: VehicleDevice | undefined): 'driving' | 'idling' | 'offline' {
    if (!isVehicleOnline(vehicle)) {
        return 'offline';
    }
    return vehicle?.lastSpeed && vehicle.lastSpeed > 5 ? 'driving' : 'idling';
}

export function isVehicleOnline(v?: VehicleDevice): boolean {
    return Boolean(v && isRoughlyNow(v.lastSeenAt));
}

type IWifiConnectedAt = {
    wifiLastConnectedAt?: DateStrISO8601 | null;
};

export function isWifiConnected(obj?: IWifiConnectedAt): boolean {
    return Boolean(obj?.wifiLastConnectedAt && isRoughlyNow(new Date(obj.wifiLastConnectedAt)));
}
