import * as React from 'react';
import { appStyled } from '../../../theme';
import { formatLocalizedDateTime } from '../../../utils/datetime-utils';
import { AppIconComponent } from '../../AppIcons';

type ActivityLogItemBaseProps = {
    Icon: AppIconComponent;
    timestamp: Date;
    description: React.ReactElement;
    detail: React.ReactElement;
};

const ActivityLogItemBaseWrapper = appStyled('div')(({ theme }) => ({
    display: 'table-row',
    '& > div': {
        display: 'table-cell',
        padding: theme.spacing(1),
        verticalAlign: 'middle',
    },
}));

export function ActivityLogItemBase({
    Icon,
    timestamp,
    detail,
    description,
}: ActivityLogItemBaseProps): React.ReactElement {
    return (
        <ActivityLogItemBaseWrapper>
            <div style={{ flex: 0 }}>
                <Icon />
            </div>
            <div>
                <div>{description}</div>
                <div>{formatLocalizedDateTime(timestamp)}</div>
            </div>
            <div>{detail}</div>
        </ActivityLogItemBaseWrapper>
    );
}
