import * as React from 'react';
import { Box, CircularProgress, Skeleton, Stack, Typography } from '@mui/material';

import { useAppSelector } from '../hooks/hooks';
import { SteinInternalApiClientToken } from '../clients/stein-internal-api';
import { useFrame } from '../lib/frame-react';

export function LoadingPage(): React.ReactElement {
    return (
        <Box sx={{ display: 'grid', height: '100%', width: '100%' }}>
            <Box sx={{ gridColumn: 1, gridRow: 1, height: '100%', width: '100%' }}>
                <Skeleton variant="rectangular" width={'100%'} height={'100%'} sx={{ bgcolor: 'white' }} />
            </Box>
            <Box
                sx={{
                    gridColumn: 1,
                    gridRow: 1,
                    height: '100%',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                }}
            >
                <Typography variant={'body1'} component={'div'}>
                    <Stack spacing={2} alignItems={'center'}>
                        <div>{`Loading`}</div>
                        <div>
                            <CircularProgress />
                        </div>
                    </Stack>
                </Typography>
            </Box>
        </Box>
    );
}

function Load(): React.ReactElement {
    const { useGetRailsContextQuery } = useFrame(SteinInternalApiClientToken);
    useGetRailsContextQuery({});

    return <LoadingPage />;
}

export function RailsContextLoader({ children }: { children: React.ReactNode }): React.ReactElement | null {
    const initialized = useAppSelector((s) => s.navigation.initialized);

    if (initialized) {
        return <>{children}</>;
    }

    return <Load />;
}
