import * as React from 'react';
import ReactCompareImage from 'react-compare-image';
import { appStyled } from '../../theme';
import { DateStrISO8601 } from '../../types/stein';
import { formatLocalizedDateTime } from '../../utils/datetime-utils';
import { useIsHovered } from '../../hooks/use-is-hovered';

type CalibrationSnapshot = {
    occurredAt: DateStrISO8601;
    imgSrc: string;
};

type VehicleCalibrationSnapshotProps = {
    start: CalibrationSnapshot;
    end: CalibrationSnapshot;
};

const Timestamps = appStyled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '8px',
});

const ImageWrapper = appStyled('div', {
    shouldForwardProp: (prop) => prop !== 'hide',
})<{ hide?: boolean }>(({ hide = false }) => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: hide ? 'none' : 'block',
    zIndex: 100,
}));

function Timestamp({
    date,
    opacity,
}: {
    date: DateStrISO8601 | undefined;
    opacity: number;
}): React.ReactElement | null {
    return date ? (
        /*istanbul ignore next*/ <div style={{ opacity }}>{formatLocalizedDateTime(new Date(date))}</div>
    ) : null;
}

export function VehicleCalibrationSnapshot({ start, end }: VehicleCalibrationSnapshotProps): React.ReactElement | null {
    const [isHovered, mouseFns] = useIsHovered();
    const [sliderPosition, setSliderPosition] = React.useState<number>(0);

    const startImg = start.imgSrc;
    const endImg = end.imgSrc;

    return (
        <div>
            <div {...mouseFns} style={{ position: 'relative', width: '100%' }}>
                <ImageWrapper>
                    <img src={startImg} style={{ width: '100%' }} />
                </ImageWrapper>
                <ImageWrapper>
                    <img src={endImg} style={{ width: '100%', filter: 'invert(1)', opacity: '50%' }} />
                </ImageWrapper>
                <ImageWrapper hide={!isHovered}>
                    <ReactCompareImage
                        leftImage={startImg}
                        rightImage={endImg}
                        hover
                        handle={<React.Fragment />}
                        sliderLineWidth={0}
                        onSliderPositionChange={setSliderPosition}
                    />
                </ImageWrapper>
                <img src={startImg} style={{ width: '100%', opacity: '0%', zIndex: '-1' }} />
            </div>
            <Timestamps>
                <Timestamp
                    date={start?.occurredAt}
                    opacity={isHovered ? /*istanbul ignore next*/ 1 - sliderPosition : 1}
                />
                <Timestamp date={end?.occurredAt} opacity={isHovered ? /*istanbul ignore next*/ sliderPosition : 1} />
            </Timestamps>
        </div>
    );
}
