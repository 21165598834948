import { AnyAction, Middleware } from '@reduxjs/toolkit';

import { findConfigItem } from '../hooks/use-config-items';
import { createPlugin, createToken } from '../lib/frame';
import { AnalyticsToken } from '../lib/frame-analytics';
import { changeLocation, setRailsContext } from '../store/actions';
import { configItemsSlice } from '../store/slices/configItemsSlice';
import { AnalyticsConfig } from '../types/stein';
import { EnvToken, LoggerToken } from '../lib/frame-tokens';

type AnalyticsProperties = {
    url: string;
    path: string;
    title: string;
    referrer: string;
};

//istanbul ignore next
function getAnalyticsProperties(): AnalyticsProperties {
    //istanbul ignore next
    return {
        url: window.location.toString(),
        path: window.location.pathname,
        title: window.document.title,
        referrer: window.document.referrer,
    };
}

export const AnalyticsMiddlewareToken = createToken<Middleware>('AnalyticsListenerToken');
export const AnalyticsMiddlewarePlugin = createPlugin<Middleware>(({ resolve }) => {
    const analytics = resolve(AnalyticsToken);
    const logger = resolve(LoggerToken);
    const env = resolve(EnvToken);
    let analyticsConfig: AnalyticsConfig = {};
    let userId = 'unknown';

    const segmentMiddleware: Middleware = () => (next) => (action: AnyAction) => {
        const result = next(action);

        try {
            if (setRailsContext.match(action)) {
                userId = `${action.payload.user.id}`;
                analytics.identify(userId, {
                    name: action.payload.user.displayName,
                    email: action.payload.user.email,
                });

                analytics.page({
                    userId,
                    properties: getAnalyticsProperties(),
                });

                const ci = findConfigItem(action.payload.configItems, 'analytics_config');
                if (ci) {
                    analyticsConfig = ci.jsonValue;
                }
            } else if (configItemsSlice.actions.setItems.match(action)) {
                const ci = findConfigItem(action.payload, 'analytics_config');
                if (ci) {
                    analyticsConfig = ci.jsonValue;
                }
            }
        } catch (e) {
            logger.log('[analytics] unable to initialize analytics', e);
        }

        if (changeLocation.match(action)) {
            // istanbul ignore next
            if (userId !== 'unknown') {
                // istanbul ignore next
                analytics.page();
            } else if (env.NODE_ENV !== 'test') {
                logger.error('Skipping analytics, userId is unknown');
            }
        }

        const type = analyticsConfig[action.type];
        if (typeof type !== 'undefined') {
            const analytic = {
                type: action.type,
                // properties: {
                //     ...action.payload,
                //     ...(type.additionalProps ? type.additionalProps : {}),
                // },
            };
            try {
                analytics.track('redux_event', analytic);
            } catch (e) {
                /* istanbul ignore next */
                logger.log('unable to track analytic', analytic, e);
            }
        }
        return result;
    };

    return segmentMiddleware;
});
