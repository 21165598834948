import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Geofence, GeofenceId } from '../../types/stein';
import { setRailsContext } from './shared-actions';

export const geofencesAdapter = createEntityAdapter<Geofence>({
    sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const geofencesSlice = createSlice({
    name: 'geofences',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState: geofencesAdapter.getInitialState(),
    reducers: {
        setGeofences(state, action: PayloadAction<Geofence[]>) {
            geofencesAdapter.setMany(state, action.payload);
        },
        delete(state, action: PayloadAction<GeofenceId>) {
            geofencesAdapter.removeOne(state, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setRailsContext, (s, a) => {
            geofencesAdapter.setAll(s, a.payload.geofences);
        });
    },
});
