import * as React from 'react';

import { NotificationItemWrapper } from './internal/NotificationItemWrapper';
import { AppNotificationTypePush } from '../notificationTypes';
import { CreatePushRequest } from '../../../types/stein-internal-api';
import { Typography } from '@mui/material';
import { VehicleChip } from '../../AppChip';

type NotificationItemPushProps = {
    notification: AppNotificationTypePush;
};

/* istanbul ignore next */
function pushTitle({ category }: CreatePushRequest): string {
    switch (category) {
        case 'custom_announcement':
            return 'Voice Alert';
    }

    return 'Push';
}

export function NotificationItemPush({ notification }: NotificationItemPushProps): React.ReactElement {
    const inProgress = notification.status === 'pending';
    const isSuccess = notification.status === 'success';
    const isError = notification.status === 'error';

    return (
        <NotificationItemWrapper
            type={isError ? 'error' : isSuccess ? 'success' : undefined}
            dismissable={isError}
            autoDismissMs={isSuccess ? 1000 : undefined}
        >
            <div>
                <Typography>{pushTitle(notification.args)}</Typography>
                <Typography variant="caption">
                    {inProgress ? 'Sending to ' : isSuccess ? 'Sent to ' : 'Failed to send to '}
                </Typography>
                <VehicleChip vehicleSlugOrId={notification.args.vehicleDeviceId} size="small" />
            </div>
        </NotificationItemWrapper>
    );
}
