import { AppRootState } from './';
import {
    AbilitySubject,
    AbilityActionsForSubject,
    Abilities,
    Project,
    ProjectSlug,
    ProjectId,
    FilterPreset,
} from '../types/stein';
import { hasAbility } from '../utils/has-ability';
import { FilterContextName, FilterStore } from './slices/filterConfigSlice';
import { projectsAdapter } from './slices/projectsSlice';
import { mergeAbilities } from '../utils/merge-abilities';
import { vehicleModelsAdapter } from './slices/vehicleModelsSlice';
import { vehiclesAdapter } from './slices/vehiclesSlice';
import { regionsAdapter } from './slices/regionsSlice';
import { driverLogAdapter } from './slices/driverLogsSlice';
import { IdentifiableSlugT, IdentifiableT, ProjectIdentifiableT } from '../types/util-types';
import { geofencesAdapter } from './slices/geofencesSlice';
import { notificationAdapter } from './slices/notificationSlice';
import { createSelector } from '@reduxjs/toolkit';
import { configItemsAdapter } from './slices/configItemsSlice';
import { notificationConfigAdapter } from './slices/notificationConfigsSlice';
import { ConfigItemFeatureKey } from '../types/stein.config_items';

type AllSelector<Entity> = (s: AppRootState) => Entity[];
type IdSelector<Entity extends IdentifiableT> = (s: AppRootState, id: Entity['id']) => Entity | undefined;

type WrappedIdSelector<Entity extends IdentifiableT> = (
    s: AppRootState,
    id: Entity['id'] | null | undefined,
) => Entity | undefined;
function wrapIdSelector<Entity extends IdentifiableT>(selector: IdSelector<Entity>): WrappedIdSelector<Entity> {
    return function wrappedSelector(s: AppRootState, id: Entity['id'] | null | undefined) {
        if (id) {
            return selector(s, id);
        }
    };
}

type WrappedSlugSelector<Entity extends IdentifiableSlugT> = (
    s: AppRootState,
    slug: Entity['slug'] | null | undefined,
) => Entity | undefined;
function wrapSlugSelector<Entity extends IdentifiableSlugT>(
    selector: AllSelector<Entity>,
): WrappedSlugSelector<Entity> {
    const regularSelector = createSelector([selector, (s, slug) => slug], (items, slug) => {
        for (const v of items) {
            if (v.slug === slug) {
                return v;
            }
        }
    });

    return function wrappedSelector(s: AppRootState, slug: Entity['slug'] | null | undefined) {
        if (slug) {
            return regularSelector(s, slug);
        }
        return undefined;
    };
}

type WrappedIdsSelector<Entity extends IdentifiableT> = (
    s: AppRootState,
    ids: Entity['id'][] | null | undefined,
) => (Entity | undefined)[];
function wrapIdsSelector<Entity extends IdentifiableT>(selector: IdSelector<Entity>): WrappedIdsSelector<Entity> {
    return createSelector([(s) => s, (s, ids: Entity['id'][]) => ids], (s, ids) =>
        ids ? ids.map((id: Entity['id']) => selector(s, id)) : [],
    );
}

type ProjectIdSelector<Entity> = (s: AppRootState, id: ProjectId) => Entity[];
function wrapProjectIdSelector<Entity extends ProjectIdentifiableT>(
    selector: AllSelector<Entity>,
): ProjectIdSelector<Entity> {
    return createSelector([selector, (_, projectId) => projectId], (items, projectId) =>
        items.filter((v) => v.projectId === projectId),
    );
}

type ProjectSlugSelector<Entity> = (s: AppRootState, slug: ProjectSlug) => Entity[];
function wrapProjectSlugSelector<Entity extends ProjectIdentifiableT>(
    selector: ProjectIdSelector<Entity>,
): ProjectSlugSelector<Entity> {
    return createSelector([(s) => s, selectProjectBySlug], (state, project) =>
        project ? selector(state, project.id) : [],
    );
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type */

///////////////////////////////////////////////////////////////////////////////////////
//  Project Selectors
const projectSelectors = projectsAdapter.getSelectors<AppRootState>((s) => s.projects);
export const selectAllProjects = projectSelectors.selectAll;
export const selectProjectById = wrapIdSelector(projectSelectors.selectById);
export function selectProjectBySlug(state: AppRootState, slug: ProjectSlug | undefined): Project | undefined {
    // istanbul ignore next
    if (slug) {
        return projectSelectors.selectAll(state).find((p) => p && p.slug === slug);
    }
}

export function selectProjectsAndRoles(s: AppRootState) {
    const user = selectUser(s);
    const projects = selectAllProjects(s);
    if (!user || !user.memberships || !projects) {
        return undefined;
    }

    const projectsWithRole = [];
    for (const p of Object.values(projects)) {
        const membership = user.memberships.find((m) => m.projectId === p.id);
        if (membership) {
            projectsWithRole.push({
                ...p,
                role: membership.role,
            });
        }
    }
    return projectsWithRole;
}

///////////////////////////////////////////////////////////////////////////////////////
//  Permission Selectors

export function selectDebugEnabled(s: AppRootState) {
    return s.navigation.debugPermitted && s.navigation.debugEnabled;
}

export function selectUserRoles(s: AppRootState, projectSlug: ProjectSlug) {
    const activeProject = selectProjectBySlug(s, projectSlug);
    const { roles } = s.abilities;
    return roles.filter((r) => r.projectId === activeProject?.id);
}

export function selectAbilities(s: AppRootState, projectSlug: ProjectSlug | undefined) {
    let abilities: Abilities = { can: {} };
    // istanbul ignore next
    if (!projectSlug) {
        // istanbul ignore next
        return abilities;
    }

    const activeProject = selectProjectBySlug(s, projectSlug);
    const { overrides, roles } = s.abilities;

    if (s.navigation.debugPermitted && s.navigation.debugUseAbilityOverrides) {
        return overrides;
    }

    if (s.abilities.simulatedRole) {
        return s.abilities.simulatedRole.abilities;
    }

    if (activeProject) {
        abilities = roles.reduce<Abilities>(
            function reduceRolesToAbilities(a, r) {
                if (r.projectId != activeProject.id) {
                    return a;
                }
                return mergeAbilities(a, r.abilities);
            },
            { can: {} },
        );
    }

    return abilities;
}

export function selectHasPermission<T extends AbilitySubject>(
    s: AppRootState,
    action: AbilityActionsForSubject<T>,
    subject: T,
    projectSlug: ProjectSlug,
): boolean {
    if (selectDebugEnabled(s) && !s.navigation.debugUseAbilityOverrides) {
        return true;
    }

    const ab = selectAbilities(s, projectSlug);

    return hasAbility(ab, action, subject);
}

export function selectHasFeature(s: AppRootState, key: ConfigItemFeatureKey, projectSlug: ProjectSlug): boolean {
    if (selectDebugEnabled(s)) {
        return true;
    }
    const configItems = selectConfigItemsByProjectSlug(s, projectSlug);
    return configItems.some((ci) => ci.attributeKey === key && ci.boolValue);
}

export function selectUser(s: AppRootState) {
    return s.user;
}

///////////////////////////////////////////////////////////////////////////////////////
//  Driver Log Selectors
const driverLogSelectors = driverLogAdapter.getSelectors<AppRootState>((s) => s.driverLogs);
export const selectDriverLogById = wrapIdSelector(driverLogSelectors.selectById);

///////////////////////////////////////////////////////////////////////////////////////
//  Region Selectors

const regionSelectors = regionsAdapter.getSelectors<AppRootState>((s) => s.regions);
export const selectAllRegions = regionSelectors.selectAll;
export const selectRegionById = wrapIdSelector(regionSelectors.selectById);
export const selectRegionsByProjectId = wrapProjectIdSelector(selectAllRegions);
export const selectRegionsByProjectSlug = wrapProjectSlugSelector(selectRegionsByProjectId);

///////////////////////////////////////////////////////////////////////////////////////
//  Geofence Selectors

const geofenceSelectors = geofencesAdapter.getSelectors<AppRootState>((s) => s.geofences);
export const selectAllGeofences = geofenceSelectors.selectAll;
export const selectGeofenceById = wrapIdSelector(geofenceSelectors.selectById);
export const selectGeofenceBySlug = wrapSlugSelector(geofenceSelectors.selectAll);

export const selectGeofencesByProjectId = wrapProjectIdSelector(selectAllGeofences);
export const selectGeofencesByProjectSlug = wrapProjectSlugSelector(selectGeofencesByProjectId);

///
const vehicleModelsSelector = vehicleModelsAdapter.getSelectors<AppRootState>((s) => s.vehicleModels);
export const selectVehicleModelById = wrapIdSelector(vehicleModelsSelector.selectById);
export const selectVehicleModelsByProjectId = wrapProjectIdSelector(vehicleModelsSelector.selectAll);
export const selectVehicleModelsByProjectSlug = wrapProjectSlugSelector(selectVehicleModelsByProjectId);

///////////////////////////////////////////////////////////////////////////////////////
//  Vehicle Selectors
const vehicleSelectors = vehiclesAdapter.getSelectors<AppRootState>((s) => s.vehicles);
export const selectVehicleById = wrapIdSelector(vehicleSelectors.selectById);
export const selectVehiclesByIds = wrapIdsSelector(vehicleSelectors.selectById);
export const selectVehiclesByProjectId = wrapProjectIdSelector(vehicleSelectors.selectAll);
export const selectVehiclesByProjectSlug = wrapProjectSlugSelector(selectVehiclesByProjectId);
export const selectVehicleBySlug = wrapSlugSelector(vehicleSelectors.selectAll);

type FilterConfig<T extends FilterContextName> = Pick<FilterStore[T], 'options' | 'presets'> & {
    activePreset: FilterPreset;
};
export function selectFilterConfig<T extends FilterContextName>(s: AppRootState, ctx: T): FilterConfig<T> {
    const config = s.filterConfig[ctx];
    return {
        options: config.options,
        activePreset: config.presets[config.activePresetIndex],
        presets: config.presets,
    };
}

///////////////////////////////////////////////////////////////////////////////////////
//  Notification Selectors
const notificationSelectors = notificationAdapter.getSelectors<AppRootState>((s) => s.notification);
export const selectNotifications = notificationSelectors.selectAll;
export const selectNotificationById = wrapIdSelector(notificationSelectors.selectById);

///////////////////////////////////////////////////////////////////////////////////////
//  Notification Selectors
const notificationConfigSelectors = notificationConfigAdapter.getSelectors<AppRootState>((s) => s.notificationConfigs);
export const selectNotificationConfigsByProjectId = wrapProjectIdSelector(notificationConfigSelectors.selectAll);

///////////////////////////////////////////////////////////////////////////////////////
//  Config Item Selectors
const configItemSelectors = configItemsAdapter.getSelectors<AppRootState>((s) => s.configItems);

export const selectConfigItems = configItemSelectors.selectAll;
export const selectConfigItemsByProjectId = wrapProjectIdSelector(configItemSelectors.selectAll);
export const selectConfigItemsByProjectSlug = wrapProjectSlugSelector(selectConfigItemsByProjectId);
