import { Stack, Link } from '@mui/material';
import * as React from 'react';

import { AppIconEmail } from './AppIcons';
import { Project } from '../types/stein';
import { SupportCard } from './SupportCard';

type ProjectAdminsProps = {
    project: Project;
};

export function ProjectAdmins({ project }: ProjectAdminsProps): React.ReactElement | null {
    if (!project.publicCustomAdmins?.length) {
        return null;
    }

    return (
        <SupportCard Icon={AppIconEmail} title={`${project.name} Administrator Emails`} key={project.id}>
            <p>{`Contact the ${project.name} administrators.`}</p>
            <Stack spacing={1}>
                {project.publicCustomAdmins?.map((a) => (
                    <div key={a.email}>
                        <Link href={`mailto:${a.email}`} key={a.email}>
                            {a.email}
                        </Link>
                    </div>
                ))}
            </Stack>
        </SupportCard>
    );
}
