export class Timer {
    private startTime: number;

    constructor() {
        this.startTime = performance.now();
    }

    restart = (): void => {
        this.startTime = performance.now();
    };

    elapsed = (): number => {
        return performance.now() - this.startTime;
    };
}
