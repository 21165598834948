import * as React from 'react';
import { useOnMount } from '../../hooks/hooks';
import { useFrame } from '../../lib/frame-react';
import { IntercomCall, IntercomService } from './intercom-types';
import { IntercomServiceToken } from '.';

type UseIntercomResponse = {
    call: IntercomCall | null;
    intercom: Omit<IntercomService, 'emitter'>;
    pending: boolean;
};

const context = React.createContext<UseIntercomResponse | null>(null);

export function IntercomProvider({ children }: { children: React.ReactNode }): React.ReactElement {
    const [call, setCall] = React.useState<IntercomCall | null>(null);
    const [pending, setPending] = React.useState<boolean>(false);

    const intercom = useFrame(IntercomServiceToken);
    useOnMount(() => {
        function onCallChanged(call: IntercomCall | null): void {
            setCall(call);
            setPending(false);
        }

        /* istanbul ignore next */
        function onCallPending(): void {
            setPending(true);
        }
        intercom.emitter.on('callChanged', onCallChanged);
        intercom.emitter.on('callPending', onCallPending);

        return () => {
            intercom.emitter.off('callChanged', onCallChanged);
            intercom.emitter.off('callPending', onCallPending);
        };
    });

    return <context.Provider value={{ call, intercom, pending }}>{children}</context.Provider>;
}

export function useIntercom(): UseIntercomResponse {
    const res = React.useContext(context);
    // istanbul ignore next
    if (!res) {
        // istanbul ignore next
        throw new Error('Trying to call useIntercom outside of <IntercomProvider/>');
    }

    return res;
}
