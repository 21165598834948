import * as React from 'react';
import { Box, Button, Stack } from '@mui/material';
import { AudioTrack, IntercomCall, VideoTrack } from '../../services/app-intercom-service/intercom-types';
import { VehicleChip } from '../AppChip';
import { useAppTheme } from '../../theme';
import { VideoPlayerBase } from '../VideoPlayer/VideoPlayerBase';
import { PictureInPictureButton } from '../VideoPlayer/VideoControls/PictureInPictureButton';
import { FullScreenButton } from '../VideoPlayer/VideoControls/FullScreenButton';
import { AppIconMicMute, AppIconMicUnmute, AppIconMuted, AppIconUnmuted } from '../AppIcons';
import { useIdleTime } from '../../hooks/hooks';
import { VideoMultiControl } from '../VideoPlayer/VideoControlLayouts';
import { Permission } from '../Authorize/PermissionBlock';

type IntercomCallProps = {
    call: IntercomCall;
};

function Audio({ track, mute }: { track: AudioTrack; mute: boolean }): React.ReactElement {
    const audioRef = React.useRef<HTMLAudioElement>(null);
    React.useEffect(
        function audioTrackAttachRef(): () => void {
            track.attach(audioRef.current || /*istanbul ignore next*/ undefined);
            return function unmount(): void {
                track.detach();
            };
        },
        [track],
    );

    return <audio ref={audioRef} autoPlay={true} muted={mute} />;
}

function Video({
    track,
    overlayCentered,
}: {
    overlayCentered: React.ReactNode;
    track: VideoTrack;
}): React.ReactElement {
    const videoRef = React.useRef<HTMLVideoElement>(null);
    const containerRef = React.useRef<HTMLDivElement>(null);
    React.useEffect(
        function videoTrackAttach(): () => void {
            track.attach(videoRef.current || /*istanbul ignore next*/ undefined);
            return function unmount(): void {
                track.detach();
            };
        },
        [track],
    );

    return (
        <VideoPlayerBase
            videoRef={videoRef}
            containerRef={containerRef}
            autoPlay={true}
            muted={false}
            overlayCentered={overlayCentered}
            controls={
                <VideoMultiControl
                    bottom={
                        <>
                            <FullScreenButton targetRef={containerRef} />
                            <PictureInPictureButton videoRef={videoRef} />
                        </>
                    }
                />
            }
        />
    );
}

const IDLE_DISCONNECT_THRESHOLD_S = 60;
const IDLE_WARN_THRESHOLD_S = IDLE_DISCONNECT_THRESHOLD_S - 15;

export function IntercomCallContent({ call }: IntercomCallProps): React.ReactElement | null {
    const theme = useAppTheme();
    const idleTime = useIdleTime();
    const [muteSpeaker, setMuteSpeaker] = React.useState(false);

    React.useEffect(() => {
        if (idleTime >= IDLE_DISCONNECT_THRESHOLD_S) {
            call.disconnect();
        }
    }, [idleTime, call]);

    const overlayCentered =
        idleTime > IDLE_WARN_THRESHOLD_S ? (
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={theme.spacing(1)}>
                <div>{'Idle'}</div>
                <div>{`Disconnecting in ${IDLE_DISCONNECT_THRESHOLD_S - idleTime}`}</div>
            </Stack>
        ) : null;

    return (
        <Box sx={{ padding: 2, height: '100%' }}>
            {call.audioTracks.map((a) => (
                <Audio track={a} key={a.sid} mute={muteSpeaker} />
            ))}
            <Stack spacing={2}>
                {call.videoTracks.length ? (
                    <Video overlayCentered={overlayCentered} track={call.videoTracks[0]} />
                ) : null}
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                    marginTop={theme.spacing(1)}
                >
                    <VehicleChip vehicleSlugOrId={call.vehicleSlug} size="small" color={theme.palette.info.main} />
                    {call.audioState === 'disconnected' ? (
                        <Permission auth={{ subject: 'VehicleDevice', action: 'video_call_join_audio' }}>
                            <Button variant={'contained'} onClick={call.joinAudio} color={'success'} size={'small'}>
                                {'Join Audio'}
                            </Button>
                        </Permission>
                    ) : (
                        <Button
                            variant={'contained'}
                            onClick={call.toggleMute}
                            color={call.audioState === 'muted' ? 'warning' : 'info'}
                            size={'small'}
                        >
                            {call.audioState === 'muted' ? (
                                <AppIconMicUnmute size={14} />
                            ) : (
                                <AppIconMicMute size={14} />
                            )}
                        </Button>
                    )}
                    <Button variant={'contained'} onClick={() => setMuteSpeaker((m) => !m)} size={'small'}>
                        {muteSpeaker ? <AppIconMuted size={14} /> : <AppIconUnmuted size={14} />}
                    </Button>
                    <Button variant={'contained'} onClick={call.disconnect} color={'error'} size={'small'}>
                        {'End'}
                    </Button>
                </Stack>
            </Stack>
        </Box>
    );
}
