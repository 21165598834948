// This file is generated ./bin/gen_notification_config_types

import type { NotifConfigId, ProjectId, UserId, DateStrISO8601, CalibrationStatus, GeofenceId } from './stein';
import { ElementOfArray } from './util-types';
export const NOTIF_CONFIG_CATEGORY_BOOLEAN = [
    'iphone_unplugged',
    'iphone_charging',
    'lost_ping',
    'found_ping',
] as const;
export const NOTIF_CONFIG_CATEGORY_VARIABLE = [
    'iphone_battery_level_drop',
    'tamper_v2',
    'sensor_covered_v2',
    'calibration_status_changed',
    'distraction',
    'eye_closure',
    'early_fatigue',
    'harsh_maneuver',
    'any_neglect',
    'flagged',
    'geofence',
] as const;
export const NOTIF_CONFIG_CATEGORY_NEGLECT = [
    'distraction',
    'eye_closure',
    'early_fatigue',
    'harsh_maneuver',
    'any_neglect',
] as const;

export const NOTIF_CONFIG_CATEGORY_NAME = {
    iphone_unplugged: 'iPhone unplugged',
    iphone_charging: 'iPhone charging',
    lost_ping: 'Lost connection',
    found_ping: 'Reestablished connection',
    iphone_battery_level_drop: 'Battery level',
    tamper_v2: 'Device tampering',
    sensor_covered_v2: 'Sensor covered',
    calibration_status_changed: 'Calibration status',
    distraction: 'Distraction',
    eye_closure: 'Severe fatigue',
    early_fatigue: 'Early fatigue',
    harsh_maneuver: 'Harsh maneuver',
    any_neglect: 'Distraction or Fatigue',
    flagged: 'Safety issue',
    geofence: 'Facility',
} as const;

export const NOTIF_CONFIG_MEDIUM_NAME = {
    email: 'Email',
    sms: 'SMS',
    slack_dm: 'Slack',
    voice_alert: 'Voice alert',
} as const;
export const NOTIF_CONFIG_MEDIUM_REQUIRES_PROJECT = {
    email: null,
    sms: null,
    slack_dm: 'slackChannel',
    voice_alert: null,
} as const;
export const NOTIF_CONFIG_MEDIUM_REQUIRES_USER = {
    email: 'email',
    sms: 'phoneNumber',
    slack_dm: 'slackUsername',
    voice_alert: null,
} as const;

export type NotifConfigCategoryToggleable = 'iphone_unplugged' | 'iphone_charging' | 'lost_ping' | 'found_ping';
export type NotifConfigCategoryVariable =
    | 'iphone_battery_level_drop'
    | 'tamper_v2'
    | 'sensor_covered_v2'
    | 'calibration_status_changed'
    | 'distraction'
    | 'eye_closure'
    | 'early_fatigue'
    | 'harsh_maneuver'
    | 'any_neglect'
    | 'flagged'
    | 'geofence';
export type NotifConfigCategory = NotifConfigCategoryToggleable | NotifConfigCategoryVariable;

type NotifConfigShared = {
    id: NotifConfigId;
    projectId: ProjectId | null;
    medium: 'email' | 'sms' | 'slack_dm' | 'voice_alert';
    voiceMessage?: string | null;
    template?: string | null;
    userId?: UserId | null;
    deletedAt?: DateStrISO8601 | null;
    createdAt?: DateStrISO8601;
    updatedAt?: DateStrISO8601;
};
export const NOTIF_CONFIG_DENOMINATOR_UNITS = ['hours', 'minutes'] as const;
export type NotifConfigDenominatorUnit = ElementOfArray<typeof NOTIF_CONFIG_DENOMINATOR_UNITS>;

export type NotifConfigToggleable = NotifConfigShared & {
    category: NotifConfigCategoryToggleable;
    enabled: boolean;
};

export type NotifConfigVariable = NotifConfigShared &
    (
        | {
              category: 'iphone_battery_level_drop';
              batteryLevelThreshold: number;
          }
        | {
              category: 'distraction' | 'eye_closure' | 'early_fatigue' | 'harsh_maneuver' | 'any_neglect' | 'flagged';
              rateDenominatorUnit: NotifConfigDenominatorUnit;
              rateDenominator: number;
              rateNumerator: number;
              analysis: 'verified' | 'any';
          }
        | {
              category: 'flagged';
              rateDenominatorUnit: NotifConfigDenominatorUnit;
              rateDenominator: number;
              rateNumerator: number;
          }
        | {
              category: 'sensor_covered_v2';
              remindIn?: number | null;
              reminderTemplate?: string | null;
          }
        | {
              category: 'tamper_v2';
          }
        | {
              category: 'calibration_status_changed';
              analysis: CalibrationStatus | 'any';
          }
        | {
              category: 'geofence';
              analysis: 'enter' | 'exit' | 'enterorexit';
              geofenceIds: GeofenceId[];
          }
    );

export type NotifConfig = NotifConfigToggleable | NotifConfigVariable;
export const NOTIF_CONFIG_MEDIUMS_USER = ['email', 'sms', 'slack_dm'] as const;

export const NOTIF_CONFIG_MEDIUMS_PROJECT = ['slack_dm'] as const;
export type NotifConfigMedium =
    | ElementOfArray<typeof NOTIF_CONFIG_MEDIUMS_USER>
    | ElementOfArray<typeof NOTIF_CONFIG_MEDIUMS_PROJECT>;
