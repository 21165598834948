import * as React from 'react';
import { CircularProgress, FormControl, InputLabel, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';

import { appStyled, useAppTheme } from '../../theme';
import { FilterValueId, FilterValueType } from '../../store/slices/filterConfigSlice';
import { FilterIcon } from './FilterIcon';

type FilterSelectProps = Omit<SelectProps<FilterValueId>, 'onChange' | 'value' | 'children' | 'label' | 'selected'> & {
    onChange?: (v: string | undefined) => void;
    label: string;
    options: FilterValueType[];
    value: string | null | undefined;
    saving: boolean;
};

const SelectInputLabel = appStyled(InputLabel)(({ theme }) => ({
    backgroundColor: 'white',
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    '&.MuiFormLabel-filled': {
        transform: 'translate(14px, -12px) scale(0.75)',
    },
}));

function SavingIcon(): React.ReactElement {
    const theme = useAppTheme();
    return (
        <div style={{ marginRight: theme.spacing(2), display: 'flex', justifyContent: 'center' }}>
            <CircularProgress size={16} />
        </div>
    );
}

export function FilterSelect({
    onChange,
    label,
    value,
    options,
    saving,
    ...props
}: FilterSelectProps): React.ReactElement {
    function _onChange(event: SelectChangeEvent<FilterValueId>): void {
        const changed = event?.target.value as FilterValueId | undefined;
        onChange && onChange(changed);
    }

    const id = `filter-select-${label}`;

    // istanbul ignore next
    const safeValue = value || '';

    return (
        <FormControl fullWidth>
            <SelectInputLabel id={id}>{label}</SelectInputLabel>
            <Select
                labelId={id}
                value={safeValue}
                onChange={_onChange}
                disabled={saving}
                IconComponent={saving ? SavingIcon : undefined}
                MenuProps={{
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    transformOrigin: { vertical: 'top', horizontal: 'left' },
                    PaperProps: {
                        style: {
                            maxHeight: '600px',
                        },
                    },
                }}
                renderValue={(newSelectedId) => {
                    const selected: FilterValueType | undefined = options.find((v) => v.id === newSelectedId);

                    //istanbul ignore next
                    if (selected) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <FilterIcon value={selected} showName />
                            </div>
                        );
                    }
                }}
                {...props}
            >
                {options.map((v) => (
                    <MenuItem value={v.id} key={v.id}>
                        <FilterIcon value={v} showName />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
