import { Tooltip, TooltipProps } from '@mui/material';
import * as React from 'react';
import { useFrame } from '../../lib/frame-react';
import { WindowToken } from '../../lib/frame-tokens';
import { AppIconCopy, AppIconProps } from '../AppIcons';

type CopyToClipboardButtonProps = {
    tooltipProps?: Partial<TooltipProps>;
    text: string;
} & AppIconProps;

const DEFAULT_TOOLTIP_PROPS = {
    leaveDelay: 1,
    title: 'Copy to Clipboard',
} as const;

const COPIED_TOOLTIP_PROPS = {
    leaveDelay: 500,
    title: 'Copied!',
} as const;

type CopyToClipboardProps = {
    tooltipProps?: Partial<TooltipProps>;
    text?: string;
    children: React.ReactNode;
};

export function CopyToClipboardButton({
    text,
    tooltipProps,
    ...iconProps
}: CopyToClipboardButtonProps): React.ReactElement {
    return (
        <CopyToClipboard text={text} tooltipProps={tooltipProps}>
            <AppIconCopy {...iconProps} />
        </CopyToClipboard>
    );
}

type UseCopyToClipboard = {
    showCopiedText: boolean;
    copyToClipboard: (text: string) => void;
};

export function useCopyToClipboard(): UseCopyToClipboard {
    const window = useFrame(WindowToken);
    const [showCopiedText, setShowCopiedText] = React.useState<boolean>(false);
    async function copyToClipboard(text: string): Promise<void> {
        if (text && !showCopiedText) {
            await window.navigator.clipboard.writeText(text);
            setShowCopiedText(true);
            await new Promise((res) => setTimeout(res, 1000));
            setShowCopiedText(false);
        }
    }

    return {
        showCopiedText,
        copyToClipboard,
    };
}

export function CopyToClipboard({ text, tooltipProps, children }: CopyToClipboardProps): React.ReactElement {
    const { showCopiedText, copyToClipboard } = useCopyToClipboard();
    const mergedTooltipProps = {
        ...DEFAULT_TOOLTIP_PROPS,
        ...(tooltipProps ? tooltipProps : {}),
        ...(showCopiedText ? COPIED_TOOLTIP_PROPS : {}),
    };
    if (!text) {
        return <div>{children}</div>;
    }
    return (
        <Tooltip placement={'top'} {...mergedTooltipProps}>
            <div
                style={{
                    cursor: 'pointer',
                }}
                onClick={() => copyToClipboard(text)}
            >
                {children}
            </div>
        </Tooltip>
    );
}
