// istanbul ignore file

import * as React from 'react';
import { Dialog, DialogProps } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
    LocalizationProvider,
    PickersShortcutsItem,
    StaticDateTimePicker,
    renderTimeViewClock,
} from '@mui/x-date-pickers';
import { subDays } from 'date-fns';

type DateTimePickerPopoverProps = {
    date: Date;
    onDateChanged: (d: Date | undefined) => void;
    shortcutItems: PickersShortcutsItem<Date | null>[];
    onClose: () => void;
} & DialogProps;

export function DateTimePickerPopover({
    date,
    onDateChanged,
    shortcutItems,
    onClose,
    ...popperProps
}: DateTimePickerPopoverProps): React.ReactElement {
    const [dateValue, setDateValue] = React.useState<Date | null>(date);
    return (
        <Dialog onClose={onClose} {...popperProps}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDateTimePicker
                    value={dateValue}
                    onChange={(newDate) => {
                        setDateValue(newDate);
                    }}
                    viewRenderers={{
                        hours: renderTimeViewClock,
                    }}
                    views={['month', 'day', 'hours']}
                    minDate={subDays(new Date(), 31)}
                    disableFuture
                    slotProps={{
                        shortcuts: {
                            items: shortcutItems,
                        },
                    }}
                    onClose={onClose}
                    onAccept={(d) => {
                        onDateChanged(d || undefined);
                        onClose();
                    }}
                />
            </LocalizationProvider>
        </Dialog>
    );
}
