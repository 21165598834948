// istanbul ignore file

import * as React from 'react';

import { ConfigurationItem } from '../../../types/stein';

import { useFrame } from '../../../lib/frame-react';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';

import {
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    Stack,
    TextField,
} from '@mui/material';
import { JsonValue } from 'type-fest';
import { useStagedChanges } from '../../../hooks/use-staged-changes';
import { AppIconRefresh, AppIconSave, AppIconTrash } from '../../AppIcons';
import { useConfirmationDialog } from '../../../hooks/use-confirmation-dialog';
import { isEqual } from 'lodash';
import { IconButtonSimple } from '../../material/IconButtonSimple';
import { useAppTheme } from '../../../theme';

export function setConfigItemValue(
    ci: Partial<ConfigurationItem> | null,
    change: Omit<ConfigurationItem, 'attributeKey' | 'vehicleDeviceId'> & { attributeKey?: string },
): Partial<ConfigurationItem> {
    const newVal: Partial<ConfigurationItem> = change;
    if (ci?.attributeKey) {
        newVal.attributeKey = ci?.attributeKey;
    }

    return change;
}

function JsonEditDialog(props: { value: JsonValue; onChange: (v: JsonValue) => void }): React.ReactElement {
    const [show, setShow] = React.useState(false);

    return (
        <>
            <Button onClick={() => setShow(true)} fullWidth variant={'outlined'}>
                {'Edit Json'}
            </Button>
            <Dialog open={show} onClose={() => setShow(false)} maxWidth={'lg'}>
                <DialogContent sx={{ width: '600px', maxWidth: '100%' }}>
                    <JsonField {...props} />
                </DialogContent>
            </Dialog>
        </>
    );
}

function JsonField({ value, onChange }: { value: JsonValue; onChange: (v: JsonValue) => void }): React.ReactElement {
    const [stringValue, setStringValue] = React.useState<string>(JSON.stringify(value));
    const [error, setError] = React.useState<string>();

    React.useEffect(() => {
        try {
            const obj = JSON.parse(stringValue);
            onChange(obj);
            setError(undefined);
            setStringValue(JSON.stringify(obj, null, 2));
        } catch (e) {
            setError(e.message);
        }
    }, [stringValue]);

    return (
        <TextField
            fullWidth
            label={'JSON'}
            multiline
            value={stringValue}
            error={!!error}
            helperText={error}
            onChange={(e) => setStringValue(e.target.value)}
        />
    );
}

export const CONFIG_ITEM_ATTRIBUTE_DEFAULTS = {
    stringValue: '',
    integerValue: 0,
    boolValue: false,
    floatValue: 0,
    jsonValue: {},
} as const;

export type ConfigItemAttributeKey = keyof typeof CONFIG_ITEM_ATTRIBUTE_DEFAULTS;

export function ConfigItemAttributeEditor({
    ci,
    setCi,
    type,
}: {
    ci: ConfigurationItem;
    setCi: React.Dispatch<React.SetStateAction<Partial<ConfigurationItem>>>;
    type: ConfigItemAttributeKey;
}): React.ReactElement | null {
    return type === 'stringValue' ? (
        <TextField
            fullWidth
            label={'String Value'}
            size={'small'}
            value={ci.stringValue || ''}
            onChange={(e) => {
                const stringValue = e.target.value;
                setCi((ci) => setConfigItemValue(ci, { stringValue }));
            }}
        />
    ) : type === 'integerValue' ? (
        <TextField
            fullWidth
            label={'Integer Value'}
            value={ci.integerValue || 0}
            size={'small'}
            type={'number'}
            onChange={(e) => {
                const integerValue = parseInt(e.target.value);
                setCi((ci) => setConfigItemValue(ci, { integerValue }));
            }}
        />
    ) : type === 'floatValue' ? (
        <TextField
            fullWidth
            label={'Float Value'}
            value={ci.floatValue || 0}
            size={'small'}
            type={'number'}
            onChange={(e) => {
                const floatValue = parseFloat(e.target.value);
                setCi((ci) => setConfigItemValue(ci, { floatValue }));
            }}
        />
    ) : type === 'boolValue' ? (
        <FormControl>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={ci.boolValue}
                        onChange={(e) => {
                            const boolValue = e.target.checked;
                            setCi((ci) => setConfigItemValue(ci, { boolValue }));
                        }}
                    />
                }
                label="Boolean Value"
            />
        </FormControl>
    ) : type === 'jsonValue' ? (
        <JsonEditDialog
            value={ci.jsonValue || {}}
            onChange={(v) => {
                const jsonValue = v;
                setCi((ci) => setConfigItemValue(ci, { jsonValue }));
            }}
        />
    ) : null;
}

function EditConfigItemCommon({
    configurationItem,
    setChanges,
    save,
    isSaving,
    hasChanges,
    reset,
}: {
    configurationItem: ConfigurationItem;
    setChanges: React.Dispatch<React.SetStateAction<Partial<ConfigurationItem>>>;
    save: () => unknown;
    isSaving: boolean;
    hasChanges: boolean;
    reset: () => void;
}): React.ReactElement {
    const { useDeleteConfigItemMutation } = useFrame(SteinInternalApiClientToken);

    const [deleteItem, { isLoading: isDeleting }] = useDeleteConfigItemMutation();
    const onDelete = useConfirmationDialog({
        func: async () => (configurationItem?.id ? deleteItem({ configurationItemId: configurationItem.id }) : null),
        message: 'Are you sure you want to delete this?',
    });

    const theme = useAppTheme();

    const type = Object.keys(CONFIG_ITEM_ATTRIBUTE_DEFAULTS).find(
        (k: ConfigItemAttributeKey) => typeof configurationItem[k] !== 'undefined' && configurationItem[k] !== null,
    ) as ConfigItemAttributeKey | undefined;

    return (
        <>
            <Stack spacing={2} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <div>
                    <strong>{configurationItem.attributeKey}</strong>
                </div>
                <div style={{ flexGrow: 2 }}>
                    {type ? <ConfigItemAttributeEditor ci={configurationItem} setCi={setChanges} type={type} /> : null}
                </div>

                <Stack spacing={1} direction={'row'}>
                    <IconButtonSimple
                        onClick={reset}
                        color={theme.palette.warning.main}
                        disabled={isSaving || !hasChanges}
                        aria-label="reset"
                    >
                        <AppIconRefresh />
                    </IconButtonSimple>
                    <IconButtonSimple
                        onClick={save}
                        disabled={!hasChanges || isSaving}
                        color={theme.palette.primary.main}
                        loading={isSaving}
                        aria-label="save"
                    >
                        <AppIconSave />
                    </IconButtonSimple>
                    <IconButtonSimple
                        onClick={onDelete.handleShouldConfirm}
                        color={theme.palette.error.main}
                        loading={isDeleting}
                        aria-label="delete"
                    >
                        <AppIconTrash />
                    </IconButtonSimple>
                </Stack>
                {onDelete.node}
            </Stack>

            <Divider />
        </>
    );
}

export function EditVehicleConfigItem({
    configurationItem,
}: {
    configurationItem: ConfigurationItem;
}): React.ReactElement {
    const { useSetVehicleConfigItemMutation } = useFrame(SteinInternalApiClientToken);

    const [update, { isLoading }] = useSetVehicleConfigItemMutation();
    const staged = useStagedChanges(
        (configurationItem: ConfigurationItem) => {
            if (configurationItem.vehicleDeviceId) {
                update({
                    configurationItem,
                    vehicleDeviceId: configurationItem.vehicleDeviceId,
                });
            }
        },
        configurationItem,
        {
            hasChangesFn: (o, c) => c !== null && !isEqual(o, c),
        },
    );

    return <EditConfigItemCommon configurationItem={staged.merged} isSaving={isLoading} {...staged} />;
}

export function EditProjectConfigItem({
    configurationItem,
}: {
    configurationItem: ConfigurationItem;
}): React.ReactElement {
    const { useSetProjectConfigItemsMutation } = useFrame(SteinInternalApiClientToken);

    const [update, { isLoading }] = useSetProjectConfigItemsMutation();
    const staged = useStagedChanges(
        (configurationItem: ConfigurationItem) => {
            if (configurationItem.projectId) {
                update({
                    // @ts-expect-error config items are normally strongly typed. Since this is a developer page we don't want that
                    configurationItems: [configurationItem],
                    projectId: configurationItem.projectId,
                });
            }
        },
        configurationItem,
        {
            hasChangesFn: (o, c) => c !== null && !isEqual(o, c),
        },
    );
    const confirmSave = useConfirmationDialog({
        func: staged.save,
        message: `Changing this configuration item will also change the value for all other vehicles in the project. Are you sure you want to continue?`,
    });

    return (
        <>
            <EditConfigItemCommon
                configurationItem={staged.merged}
                isSaving={isLoading}
                {...staged}
                save={confirmSave.handleShouldConfirm}
            />
            {confirmSave.node}
        </>
    );
}
