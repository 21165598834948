import * as React from 'react';
import { TimeoutId } from '../types/app';

export function useTimeout(timeMs: number): boolean {
    const [isTimedOut, setIsTimedOut] = React.useState<boolean>(false);
    React.useEffect(() => {
        const timeout = setTimeout(() => setIsTimedOut(true), timeMs);
        return () => {
            clearTimeout(timeout);
            setIsTimedOut(false);
        };
    }, [timeMs, setIsTimedOut]);
    return isTimedOut;
}

export function useTimer(): [boolean, (time: number) => void] {
    const [running, setRunning] = React.useState<boolean>(false);
    const timeoutRef = React.useRef<TimeoutId>();

    const start = React.useCallback(
        (timeMs: number) => {
            setRunning(true);
            // istanbul ignore if
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            timeoutRef.current = setTimeout(() => {
                setRunning(false);
            }, timeMs);
        },
        [running, setRunning],
    );
    return [running, start];
}
