import * as React from 'react';
import { Skeleton } from '@mui/material';
import { DriverEvent } from '../../../types/stein';
import { ExportArchiveButton } from '../../ExportArchiveButton';
import { useAppTheme } from '../../../theme';
import LoadingButton from '@mui/lab/LoadingButton';
import { Permission } from '../../Authorize/PermissionBlock';

type DriverEventExportArchiveProps = {
    event: DriverEvent | undefined;
};

export function DriverEventExportArchive({ event }: DriverEventExportArchiveProps): React.ReactElement {
    const theme = useAppTheme();
    return (
        <Permission auth={{ subject: 'DriverEvent', action: 'export_archive' }}>
            {event ? (
                <div style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}>
                    <ExportArchiveButton type={'driver_event'} id={event.id} />
                </div>
            ) : (
                <Skeleton>
                    <LoadingButton />
                </Skeleton>
            )}
        </Permission>
    );
}
