import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import * as React from 'react';

import { VehicleDeviceId } from '../../../types/stein';
import { AppIconSend } from '../../AppIcons';
import { MenuItem, TextField } from '@mui/material';
import { toTitleCase } from '../../../lib/case-transforms';
import { useFrame } from '../../../lib/frame-react';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import LoadingButton from '@mui/lab/LoadingButton';
import { VehicleGrid } from '../../Vehicle/VehicleGrid';
import { plural } from '../../../utils/string-utils';
import { useActiveProjectSlug } from '../../../hooks/use-active-project';
import { BulkActionsDialog, FilterBulkActions } from '../../FilterBar/FilterBulkActions';

const BULK_ACTIONS = ['custom_annoucement'] as const;
type BulkActionKey = (typeof BULK_ACTIONS)[number];

type VehicleDeviceBulkActionsProps = {
    vehicleIds: Array<VehicleDeviceId>;
};

export function VehicleDeviceBulkActions({ vehicleIds }: VehicleDeviceBulkActionsProps): React.ReactElement | null {
    const [modalContext, setModalContext] = React.useState<BulkActionKey | null>(null);

    function handleClose(): void {
        setModalContext(null);
    }

    return (
        <div data-testid={'bulk-actions'}>
            <FilterBulkActions disabled={false}>
                {BULK_ACTIONS.map((a) => (
                    <MenuItem
                        id={a}
                        key={a}
                        onClick={() => {
                            setModalContext(a);
                        }}
                    >
                        {toTitleCase(a)}
                    </MenuItem>
                ))}
            </FilterBulkActions>

            <BulkActionsDialog onClose={handleClose} open={Boolean(modalContext)}>
                {modalContext &&
                    {
                        custom_annoucement: <CustomAnnouncementDialog vehicleIds={vehicleIds} onClose={handleClose} />,
                    }[modalContext]}
            </BulkActionsDialog>
        </div>
    );
}

type BulkActionsDialogProps = VehicleDeviceBulkActionsProps & {
    onClose: () => void;
};

function VehicleList({ vehicleIds }: { vehicleIds: VehicleDeviceId[] }): React.ReactElement {
    return (
        <DialogContent>
            <DialogContentText>{'Will be sent to:'}</DialogContentText>
            <VehicleGrid vehicleIds={vehicleIds} />
        </DialogContent>
    );
}

function CustomAnnouncementDialog({ vehicleIds, onClose }: BulkActionsDialogProps): React.ReactElement {
    const { useCreatePushMultiMutation } = useFrame(SteinInternalApiClientToken);
    const [createMultiPush, { isLoading, isUninitialized }] = useCreatePushMultiMutation();
    const [text, setText] = React.useState<string>('');
    const projectSlug = useActiveProjectSlug();

    React.useEffect(() => {
        if (!isUninitialized && !isLoading) {
            onClose();
        }
    }, [isLoading, isUninitialized]);

    function sendAnnouncements(): void {
        createMultiPush({
            vehicleDeviceIds: vehicleIds,
            category: 'custom_announcement',
            announcementMessage: text,
            projectSlug,
        });
    }

    const numVehicles = vehicleIds.length;

    return (
        <>
            <DialogTitle>{'Broadcast Custom Announcement'}</DialogTitle>
            <DialogContent>
                <TextField
                    style={{
                        marginTop: 4,
                    }}
                    autoFocus
                    value={text}
                    fullWidth
                    label={'Announcement Text'}
                    onChange={(e) => setText(e.target.value)}
                />
            </DialogContent>
            <VehicleList vehicleIds={vehicleIds} />
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <LoadingButton
                    endIcon={<AppIconSend />}
                    color={'primary'}
                    variant={'contained'}
                    onClick={sendAnnouncements}
                    loading={isLoading}
                >
                    {`Send to ${numVehicles} ${plural(numVehicles, 'Vehicle', 'Vehicles')}`}
                </LoadingButton>
            </DialogActions>
        </>
    );
}
