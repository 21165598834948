import {
    NotifConfig,
    NotifConfigToggleable,
    NotifConfigVariable,
    NotifConfigCategory,
    NOTIF_CONFIG_CATEGORY_BOOLEAN,
    NOTIF_CONFIG_CATEGORY_VARIABLE,
    NotifConfigCategoryToggleable,
    NotifConfigCategoryVariable,
} from '../../../../../types/stein.notification_config';
import { includes } from '../../../../../utils/array-utils';

export function isNotifConfigCategoryToggleable(c: NotifConfigCategory): c is NotifConfigCategoryToggleable {
    return includes(NOTIF_CONFIG_CATEGORY_BOOLEAN, c);
}
export function isNotifConfigToggleable(n: Pick<NotifConfig, 'category'>): n is NotifConfigToggleable {
    return isNotifConfigCategoryToggleable(n.category);
}

export function isNotifConfigCategoryVariable(c: NotifConfigCategory): c is NotifConfigCategoryVariable {
    return includes(NOTIF_CONFIG_CATEGORY_VARIABLE, c);
}
export function isNotifConfigVariable(n: Pick<NotifConfig, 'category'>): n is NotifConfigVariable {
    return isNotifConfigCategoryVariable(n.category);
}
