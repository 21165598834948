// istanbul ignore file
import * as React from 'react';
import { useHotkeys, Options as HotkeysOptions } from 'react-hotkeys-hook';
import type { KeyHandler, HotkeysEvent } from 'hotkeys-js';
import { Dialog, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

function wrapHotkey(fn: KeyHandler) {
    return (e: KeyboardEvent, h: HotkeysEvent) => {
        e.stopPropagation();
        e.preventDefault();
        fn(e, h);
    };
}

type HotkeyAdditionalOptions<Key extends string> = {
    description: string;
} & (Key extends '*'
    ? {
          keyDisplay: string;
      }
    : { keyDisplay?: string; unwrap?: boolean });

export function useHotkey<T extends Element, Key extends string>(
    key: Key,
    callback: KeyHandler,
    opts: HotkeysOptions & HotkeyAdditionalOptions<Key>,
    deps: unknown[],
): React.MutableRefObject<T | null> {
    const setHotkeys = React.useContext(hotkeyContext);
    const enabled = typeof opts.enabled === 'undefined' || opts.enabled;

    React.useEffect(() => {
        const keyToDisplay = opts.keyDisplay || key;
        if (enabled) {
            setHotkeys((s) => ({
                ...s,
                [keyToDisplay]: opts.description,
            }));
        }
        return () => {
            setHotkeys((s) => {
                delete s[keyToDisplay];
                return s;
            });
        };
    }, [setHotkeys, enabled]);
    if (key === '*') {
        return useHotkeys<T>(key, callback, opts, deps);
    } else if ('unwrap' in opts && opts.unwrap) {
        return useHotkeys<T>(key, callback, opts, deps);
    } else {
        return useHotkeys<T>(key, wrapHotkey(callback), opts, deps);
    }
}

type HotkeyDescriptions = Record<string, string>;

const hotkeyContext = React.createContext<React.Dispatch<React.SetStateAction<HotkeyDescriptions>>>(() => null);

export function HotkeyProvider({ children }: { children: React.ReactNode }): React.ReactElement {
    const [state, setState] = React.useState<HotkeyDescriptions>({
        h: 'Show and hide hotkey popover',
    });
    const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
    useHotkeys('h', () => {
        setDialogOpen((open) => !open);
    });

    return (
        <hotkeyContext.Provider value={setState}>
            {children}
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth={'lg'}>
                <DialogTitle>{'Active Hotkeys'}</DialogTitle>
                <DialogContent sx={{ width: '100%' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{'Key'}</TableCell>
                                <TableCell>{'Description'}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(state)
                                .sort((a, b) => a[0].localeCompare(b[0]))
                                .map(([key, decsription]) => (
                                    <TableRow key={key}>
                                        <TableCell>{key}</TableCell>
                                        <TableCell>{decsription}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </DialogContent>
            </Dialog>
        </hotkeyContext.Provider>
    );
}
