// istanbul ignore file
import * as React from 'react';

import { Audit, AuditTag } from '../../../../types/stein';
import { AuditTagEditModal } from './AuditTagEditModal';
import { CreateAuditTagReq } from '../../../../types/stein-internal-api';
import { noop } from '../../../../utils/noop';

type AuditTagCreateContext = {
    setTagToCreate: React.Dispatch<React.SetStateAction<CreateAuditTagReq['auditTag']>>;
};

const context = React.createContext<AuditTagCreateContext>({ setTagToCreate: noop });

export function useAuditTagCreateContext(): AuditTagCreateContext {
    return React.useContext(context);
}

export function AuditTagCreateProvider({
    audit,
    existingTags,
    children,
}: {
    audit: Audit;
    existingTags: AuditTag[];
    children: React.ReactNode;
}): React.ReactElement {
    const [tagToCreate, setTagToCreate] = React.useState<CreateAuditTagReq['auditTag'] | null>(null);

    return (
        <>
            <context.Provider
                value={{
                    setTagToCreate,
                }}
            >
                {children}
            </context.Provider>
            {tagToCreate ? (
                <AuditTagEditModal
                    auditSlug={audit.slug}
                    auditTag={tagToCreate}
                    open={Boolean(tagToCreate)}
                    existingTags={existingTags}
                    onClose={() => setTagToCreate(null)}
                    mode={'create'}
                />
            ) : null}
        </>
    );
}
