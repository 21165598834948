import * as React from 'react';

import { DriverEvent } from '../../../types/stein';
import { CalloutBox, CalloutSkeleton } from '../../material/CalloutBox';
import { useFrame } from '../../../lib/frame-react';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import { ActivityLog } from '../../ActivityLog/ActivityLog';
import { groupAndSortActivity } from '../../ActivityLog/activity_log_utils';

type DriverEventActivityLogProps = {
    event: DriverEvent;
};

function DriverEventActivityLogCore({ event }: DriverEventActivityLogProps): React.ReactElement {
    const { useGetActivityLogQuery } = useFrame(SteinInternalApiClientToken);
    const { data } = useGetActivityLogQuery({ type: 'driver_event', id: event.id });

    return (
        <CalloutBox name={'Activity Log'}>
            {data && <ActivityLog items={groupAndSortActivity(data)} driverEvent={event} />}
        </CalloutBox>
    );
}

export function DriverEventActivityLog({ event }: Partial<DriverEventActivityLogProps>): React.ReactElement {
    if (!event) {
        return <CalloutSkeleton name={'Activity Log'} />;
    }

    return <DriverEventActivityLogCore event={event} />;
}
