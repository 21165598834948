import * as React from 'react';
import { DriverEvent, DriverLog, DriverLogId, DriverLogTimelineItem } from '../../../types/stein';
import { TimelineChart } from '../../TimelineChart/TimelineChart';
import { appStyled, useAppTheme } from '../../../theme';
import {
    AppIconCollapse,
    AppIconExpand,
    AppIconAudioAlertSounded,
    AppIconComponent,
    AppIconRecordingStarted,
    AppIconWarningTriangle,
    AppIconMuted,
} from '../../AppIcons';
import { differenceInMilliseconds, parseJSON } from 'date-fns';
import { format } from 'date-fns-tz';
import { neglectPreterite } from './driver_event_show_utils';
import { getTimezone } from '../../../utils/datetime-utils';
import { EventTypeName } from '../../../constants/event-type-constants';
import { useAppSelector } from '../../../hooks/hooks';
import { selectDriverLogById } from '../../../store/selectors';

type DriverEventVideoTimelineProps = {
    event: DriverEvent | undefined;
    videoId: DriverLogId | undefined;
};

const TimelineChartWrapper = appStyled('div')(({ theme }) => ({
    backgroundColor: theme.palette.grey[300],
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    padding: theme.spacing(0.5),
    display: 'flex',
    cursor: 'pointer',
}));

const ExpandIconWrapper = appStyled('div')(({ theme }) => ({
    flex: 0,
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(-0.5),
}));

export function DriverEventVideoTimeline({ event, videoId }: DriverEventVideoTimelineProps): React.ReactElement | null {
    const [collapsed, setCollapsed] = React.useState(true);
    const theme = useAppTheme();
    const video = useAppSelector((s) => selectDriverLogById(s, videoId));

    if (!event || !video) {
        return null;
    }

    const timelineItems = video?.timeline?.map((i) => ({
        id: i.id,
        startTime: parseJSON(i.startTime),
        endTime: parseJSON(i.endTime),
        ...driverLogTimelineProps(i, video, event),
    }));

    if (!timelineItems) {
        return null;
    }

    return (
        <TimelineChartWrapper onClick={() => setCollapsed(!collapsed)} data-testid={'driver-event-timeline'}>
            <TimelineChart items={timelineItems} collapsed={collapsed} />
            <ExpandIconWrapper>
                {collapsed ? (
                    <AppIconExpand color={theme.palette.grey[500]} />
                ) : (
                    <AppIconCollapse color={theme.palette.grey[500]} />
                )}
            </ExpandIconWrapper>
        </TimelineChartWrapper>
    );
}

type TimelineItem = {
    color: string;
    tooltip?: React.ReactNode;
    icon?: AppIconComponent;
    text?: string;
};

export function driverLogTimelineProps(i: DriverLogTimelineItem, l: DriverLog, e: DriverEvent): TimelineItem {
    const durationMs = differenceInMilliseconds(parseJSON(i.endTime), parseJSON(i.startTime));
    const durationStr = `${durationMs / 1000}s`;

    const startedStr = format(parseJSON(i.startTime), 'hh:mm:ss aaa zzz', {
        timeZone: getTimezone(),
    });

    switch (i.id) {
        case 'pre_neglect':
            return {
                color: 'orange',
                icon: AppIconWarningTriangle,
                tooltip: `Driver ${neglectPreterite(
                    e.eventName as EventTypeName,
                )} intermittently for ${durationStr} before the alert.`,
                text: durationStr,
            };
        case 'neglect':
            return {
                color: 'red',
                icon: AppIconWarningTriangle,
                tooltip: (
                    <div>
                        <div>{`Driver ${neglectPreterite(
                            e.eventName as EventTypeName,
                        )} for ${durationStr} during the alert.`}</div>
                        <br />
                        <div>{`Started: ${startedStr}`}</div>
                        <div>{`Duration: ${durationStr}`}</div>
                    </div>
                ),
            };
        case 'video':
            return {
                color: 'green',
                icon: AppIconRecordingStarted,
                tooltip: (
                    <div>
                        <div>{`Video recording.`}</div>
                        <br />
                        <div>{`Started: ${startedStr}`}</div>
                        <div>{`Duration: ${durationStr}`}</div>
                    </div>
                ),
                text: durationStr,
            };
        case 'alert':
            return {
                color: 'yellow',
                icon: i.muted ? /* istanbul ignore next */ AppIconMuted : AppIconAudioAlertSounded,
                tooltip: i.muted ? (
                    /* istanbul ignore next */ <div>{`Audio alert muted.`}</div>
                ) : (
                    <div>
                        <div>{`Audio alert.`}</div>
                        <br />
                        <div>{`Started: ${startedStr}`}</div>
                        <div>{`Sounded for: ${durationStr}`}</div>
                    </div>
                ),
            };
        default:
            return {
                color: 'grey',
            };
    }
}
