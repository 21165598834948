// istanbul ignore file
import * as React from 'react';
import { Circle, useMap } from 'react-leaflet';
import { defineDefault } from '../../utils/undefined-utils';

import { LatLng } from 'leaflet';
import { AutoCenter } from './AutoCenter';

export type Circle = {
    latitude: number;
    longitude: number;
    radius: number;
};

type MapCircleEditorProps = {
    circle: Circle;
    onChange: (c: Partial<Circle>) => void;
};

export function MapCircleEditor({ circle, onChange }: MapCircleEditorProps): React.ReactElement {
    const map = useMap();
    const [editing, setEditing] = React.useState(false);

    React.useEffect(() => {
        function onMove({ latlng }: { latlng: LatLng }): void {
            if (editing) {
                onChange({ latitude: latlng.lat, longitude: latlng.lng });
            }
        }

        function onClick(): void {
            if (editing) {
                setEditing(false);
                map.dragging.enable();
            }
        }

        map.addEventListener('mousemove', onMove);
        map.addEventListener('click', onClick);
        return () => {
            map.removeEventListener('mousemove', onMove);
            map.removeEventListener('click', onClick);
        };
    }, [map, editing]);

    return (
        <>
            <AutoCenter position={circle} enable={!editing} />
            <Circle
                center={[defineDefault(circle.latitude, 0), defineDefault(circle.longitude, 0)]}
                radius={circle.radius}
                pathOptions={{ color: '#21c5dd', fillColor: '#21c5dd' }}
                eventHandlers={{
                    mousedown: () => {
                        setEditing(true);
                        map.dragging.disable();
                    },
                }}
            />
        </>
    );
}
