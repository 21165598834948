import * as React from 'react';

import { VehicleDevice, PingLocated } from '../../../types/stein';
import { CalloutBox, CalloutSkeleton } from '../../material/CalloutBox';
import { DriverEventMap } from '../../DriverEvent/DriverEventMap';
import { Box, Button, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { DateTimePickerPopover } from '../../DateTimePicker/DateTimePickerPopover';
import { formatLocalizedDateTime, toISO8601 } from '../../../utils/datetime-utils';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import { useFrame } from '../../../lib/frame-react';
import { Aspect } from '../../AspectRatio';
import { AppIconRemoveItem } from '../../AppIcons';
import { formatDistanceStrict, isAfter } from 'date-fns';
import { useDialogState } from '../../../hooks/use-dialog-state';

type VehicleDeviceMapBoxProps = {
    vehicle: VehicleDevice | undefined;
    locatedPing: PingLocated | undefined;
};

export function VehicleDeviceMapBox({ vehicle, locatedPing }: VehicleDeviceMapBoxProps): React.ReactElement {
    const ref = React.useRef<HTMLDivElement>(null);
    const [date, setDate] = React.useState<Date>();
    const { useLazyGetVehicleDevicePingQuery } = useFrame(SteinInternalApiClientToken);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [getPing, { data, isFetching }] = useLazyGetVehicleDevicePingQuery();
    const dateTimePicker = useDialogState();

    React.useEffect(() => {
        if (date && vehicle) {
            getPing({
                idOrSlug: vehicle.id,
                time: toISO8601(date),
            });
        }
    }, [date]);

    // istanbul ignore next
    if (!vehicle) {
        // istanbul ignore next
        return <CalloutSkeleton name="Location" />;
    }

    return (
        <CalloutBox name="Location">
            <Stack spacing={1} ref={ref}>
                {isFetching ? (
                    <Aspect ratio={4 / 3} sx={{ display: 'flex' }}>
                        <div style={{ flex: '1' }}>
                            <Skeleton variant="rectangular" width="100%" height="100%" />
                        </div>
                    </Aspect>
                ) : date && !data?.ping ? (
                    <Aspect ratio={4 / 3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Stack spacing={1} sx={{ '& div': { textAlign: 'center' } }}>
                            <div>{'No location reported within 12 hours of'}</div>
                            <div>{formatLocalizedDateTime(date)}</div>
                        </Stack>
                    </Aspect>
                ) : (
                    <DriverEventMap
                        ping={
                            date && /* istanbul ignore next */ data?.ping
                                ? /* istanbul ignore next */ data.ping
                                : locatedPing
                        }
                        geofence={undefined}
                    />
                )}
                <Box width={'100%'}>
                    <Stack spacing={1}>
                        <Stack direction={'row'} spacing={1}>
                            {date ? (
                                <>
                                    <Button
                                        size={'small'}
                                        variant={'contained'}
                                        onClick={dateTimePicker.onOpen}
                                        fullWidth
                                    >
                                        {formatLocalizedDateTime(date)}
                                    </Button>
                                    <IconButton
                                        color={'warning'}
                                        size={'small'}
                                        onClick={() => {
                                            setDate(undefined);
                                            dateTimePicker.onClose();
                                        }}
                                    >
                                        <AppIconRemoveItem />
                                    </IconButton>
                                </>
                            ) : (
                                <Button size={'small'} variant={'outlined'} onClick={dateTimePicker.onOpen} fullWidth>
                                    {'Last Known Location'}
                                </Button>
                            )}
                        </Stack>

                        {date && /*istanbul ignore next*/ data?.ping ? (
                            /*istanbul ignore next*/ <Typography variant={'caption'} textAlign={'center'}>
                                {`Location is from ${formatDistanceStrict(date, new Date(data.ping.occurredAt))} ${
                                    /*istanbul ignore next*/ isAfter(new Date(data.ping.occurredAt), date)
                                        ? 'after'
                                        : 'before'
                                } the selected time.`}
                            </Typography>
                        ) : null}
                    </Stack>

                    <DateTimePickerPopover
                        date={date || new Date()}
                        onClose={dateTimePicker.onClose}
                        shortcutItems={[
                            {
                                label: 'Reset',
                                getValue: () => null,
                            },
                        ]}
                        onDateChanged={setDate}
                        open={dateTimePicker.isOpen}
                        data-testid={'datetime-picker'}
                    />
                </Box>
            </Stack>
        </CalloutBox>
    );
}
