// istanbul ignore file
import { DateTimestampMs, DriverLog } from '../../types/stein';
import { toTimestampMs } from '../../utils/datetime-utils';

export interface VideoSegment {
    url: string;
    startTime: DateTimestampMs;
    durationSeconds: number;
}

export function videoSegmentFromDriverLogs(
    driverLogs: Pick<DriverLog, 'url' | 'duration' | 'occurredAt' | 'uploadStatus'>[],
): VideoSegment[] {
    return driverLogs
        .filter((s) => s.uploadStatus == 'ready' && s.url)
        .map((dl) => ({
            url: dl.url || '',
            durationSeconds: dl.duration || 0,
            startTime: toTimestampMs(new Date(dl.occurredAt || '')),
            uploadStatus: dl.uploadStatus || '',
        }));
}
