import * as React from 'react';
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    FormLabel,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Slider,
    Stack,
    TextField,
} from '@mui/material';
import { useActiveProjectSlug } from '../../../hooks/use-active-project';

import { SettingsPage } from './components/SettingsShared';
import { selectGeofencesByProjectSlug } from '../../../store/selectors';
import { filterGeofenceLocated } from '../../../utils/filter-utils';
import { useAppNavigate, useAppSelector } from '../../../hooks/hooks';
import LoadingButton from '@mui/lab/LoadingButton';
import { GeofenceId } from '../../../types/stein';
import { Link as RouterLink } from 'react-router-dom';
import { urlFacilitiesShow } from '../../../utils/internal-url-utils';
import { useFrame } from '../../../lib/frame-react';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import { AppIconNew } from '../../AppIcons';
import { Circle, MapContainer } from 'react-leaflet';
import { MapTiles } from '../../Map/MapTiles';
import { AutoZoom } from '../../Map/AutoZoom';
import { AutoCenter } from '../../Map/AutoCenter';
import { Permission } from '../../Authorize/PermissionBlock';
import { GeoResult, useLocationSearch } from '../../../hooks/useLocationSearch';

export function SettingsFacilitiesList(): React.ReactElement | null {
    const projectSlug = useActiveProjectSlug();
    const geofences = useAppSelector((s) => selectGeofencesByProjectSlug(s, projectSlug)).filter(filterGeofenceLocated);

    const [isCreateOpen, setIsCreateOpen] = React.useState(false);
    const navigate = useAppNavigate();
    function onClose(): void {
        setIsCreateOpen(false);
    }

    return (
        <>
            <div data-testid={'page-settings-facilities'} />
            <SettingsPage title={`Facilities`}>
                <List>
                    {geofences?.length
                        ? geofences.map((g) => {
                              return (
                                  <Permission debugOnly={g.hidden} key={g.id}>
                                      <ListItemButton
                                          LinkComponent={RouterLink}
                                          onClick={() => {
                                              navigate(urlFacilitiesShow(projectSlug, g.slug));
                                          }}
                                      >
                                          {g.name}
                                      </ListItemButton>
                                  </Permission>
                              );
                          })
                        : /* istanbul ignore next */ 'No facilities'}
                    <Permission auth={{ subject: 'Geofence', action: 'update' }}>
                        <ListItemButton onClick={() => setIsCreateOpen(true)}>
                            <ListItemIcon>
                                <AppIconNew />
                            </ListItemIcon>
                            <ListItemText primary={'New'} />
                        </ListItemButton>
                    </Permission>
                </List>

                <Permission auth={{ subject: 'Geofence', action: 'update' }}>
                    <CreateGeofence
                        open={isCreateOpen}
                        onGeofenceCreated={onClose}
                        maxWidth={'md'}
                        fullWidth
                        onClose={onClose}
                    />
                </Permission>
            </SettingsPage>
        </>
    );
}

type CreateGeofenceProps = {
    onGeofenceCreated(id: GeofenceId): void;
} & DialogProps;

function CreateGeofence({ onGeofenceCreated, ...props }: CreateGeofenceProps): React.ReactElement {
    const [name, setName] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [radius, setRadius] = React.useState(75);
    const [selectedAddress, setSelectedAddress] = React.useState<GeoResult>();
    const { useCreateGeofenceMutation } = useFrame(SteinInternalApiClientToken);
    const projectSlug = useActiveProjectSlug();

    const { loading, data } = useLocationSearch(address);

    const [create, { isLoading }] = useCreateGeofenceMutation();

    return (
        <Dialog {...props}>
            <DialogTitle>Add Facility</DialogTitle>
            <DialogContent>
                <Stack spacing={2} alignItems={'center'} flex={1} data-testid={'new-facility'}>
                    <TextField
                        label={'Facility Name'}
                        size={'small'}
                        sx={{ mt: 2 }}
                        onChange={(e) => setName(e.target.value)}
                        autoFocus
                        fullWidth
                    />
                    <TextField
                        label={'Address'}
                        size={'small'}
                        onChange={(e) => setAddress(e.target.value)}
                        fullWidth
                    />
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <List sx={{ width: '100%' }}>
                            {data.slice(0, 2).map((m) => {
                                const [street, ...rest] = m.address.split(',');
                                return (
                                    <ListItemButton
                                        key={m.id}
                                        selected={selectedAddress?.id === m.id}
                                        onClick={() => setSelectedAddress(m)}
                                    >
                                        <ListItemText primary={street} secondary={rest.join(',')} />
                                    </ListItemButton>
                                );
                            })}
                        </List>
                    )}

                    <MapContainer style={{ height: '300px', width: '100%' }} center={[0, 0]} zoom={1}>
                        <AutoZoom zoom={selectedAddress ? 15 : 1} />
                        {selectedAddress ? <AutoCenter position={selectedAddress} enable /> : null}
                        <MapTiles />
                        {selectedAddress ? (
                            <Circle center={[selectedAddress.latitude, selectedAddress.longitude]} radius={radius} />
                        ) : null}
                    </MapContainer>
                    <Stack direction={'row'} spacing={2} sx={{ width: '100%' }}>
                        <FormLabel>Radius: </FormLabel>
                        <Slider
                            min={50}
                            max={10000}
                            size={'small'}
                            value={radius}
                            onChange={
                                /* istanbul ignore next*/ (_, newR) => {
                                    // istanbul ignore next
                                    if (typeof newR === 'number') {
                                        // istanbul ignore next
                                        setRadius(newR);
                                    }
                                }
                            }
                        />
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    loading={isLoading}
                    disabled={!name || !selectedAddress}
                    variant={'contained'}
                    onClick={async () => {
                        // istanbul ignore else
                        if (name && selectedAddress) {
                            const res = await create({
                                projectSlug,
                                geofence: {
                                    name,
                                    latitude: selectedAddress.latitude,
                                    longitude: selectedAddress.longitude,
                                    radius,
                                },
                            });
                            // istanbul ignore else
                            if ('data' in res) {
                                onGeofenceCreated(res.data.geofence.id);
                            }
                        }
                    }}
                >
                    Create
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
