import * as React from 'react';
import { Box, BoxProps } from '@mui/system';
import { appStyled } from '../../../../../theme';
import { DateTimestampMs } from '../../../../../types/stein';
import { useTimelineContext } from './TimelineProvider';

export const TimelineRow = appStyled('div')({
    marginTop: 3,
    position: 'relative',
    height: 5,
    pointerEvents: 'none',
});

type TimelinePointProps = {
    time: DateTimestampMs;
} & BoxProps;

export const TimelinePoint = React.forwardRef(function TimelinePoint(
    { time, sx = {}, ...boxProps }: TimelinePointProps,
    ref: React.RefObject<HTMLDivElement>,
): React.ReactElement {
    const { getPositionLeft } = useTimelineContext();
    return (
        <Box
            ref={ref}
            {...boxProps}
            sx={{
                ...sx,
                position: 'absolute',
                transform: 'translateX(-50%)',
                pointerEvents: 'all',
                marginLeft: getPositionLeft(time),
            }}
        />
    );
});

type TimelineSpanProps = {
    startTime: DateTimestampMs;
    durationSeconds: number;
} & BoxProps;

export const TimelineSpan = React.forwardRef(function TimelineSpan(
    { startTime, durationSeconds, sx = {}, ...boxProps }: TimelineSpanProps,
    ref: React.RefObject<HTMLDivElement>,
): React.ReactElement {
    const { getPositionLeft, durationMsToWidth } = useTimelineContext();

    return (
        <Box
            ref={ref}
            {...boxProps}
            sx={{
                ...sx,
                position: 'absolute',
                top: 0,
                height: '100%',
                borderRadius: '3px',
                pointerEvents: 'all',
                cursor: 'pointer',
                left: getPositionLeft(startTime),
                width: durationMsToWidth(durationSeconds * 1000),
                minWidth: '4px',
            }}
        />
    );
});
