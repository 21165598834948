import * as React from 'react';
import { Button, FormControlLabel, FormGroup, Stack, Switch, TextField } from '@mui/material';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import { useAppSelector } from '../../../hooks/hooks';
import { useConfigItems } from '../../../hooks/use-config-items';
import { mergeStagedChanges, useStagedChanges } from '../../../hooks/use-staged-changes';
import { useFrame } from '../../../lib/frame-react';
import { SFXToken } from '../../../services/app-sfx-plugin';
import { selectUser } from '../../../store/selectors';
import { SetConfigItemsRequest } from '../../../types/stein-internal-api';
import { ConfigItemForKey, ConfigItemWebKey } from '../../../types/stein.config_items';
import { lastDefined } from '../../../utils/undefined-utils';
import { AppIconPlay } from '../../AppIcons';
import { NumberFormatPhone } from '../../material/NumberFormat/NumberFormatPhone';

import { SettingsPage, SettingsSaveBar } from './components/SettingsShared';

type ConfigItemWebToggleable = Extract<ConfigItemForKey[ConfigItemWebKey], { boolValue: boolean }>;
type ConfigItemWebToggleableKey = ConfigItemWebToggleable['attributeKey'];
type StagedConfigItemChanges = Partial<{ [K in ConfigItemWebToggleableKey]: ConfigItemForKey[K] }>;

export function MyProfile(): React.ReactElement {
    const user = useAppSelector(selectUser);
    const { useUpdateUserProfileMutation, useSetUserConfigItemsMutation } = useFrame(SteinInternalApiClientToken);
    const [updateProfile, { isLoading: profileLoading }] = useUpdateUserProfileMutation();
    const [updateConfigItems, { isLoading: configLoading }] = useSetUserConfigItemsMutation();
    const stagedProfile = useStagedChanges(updateProfile, {
        phoneNumber: user.phoneNumber || /* istanbul ignore next */ undefined,
        slackUsername: user.slackUsername || /* istanbul ignore next */ undefined,
    });
    const stagedConfigItems = useStagedChanges(updateConfigItems, { configurationItems: [] });

    const getConfigItem = useConfigItems();

    const ciStaged = stagedConfigItems.merged.configurationItems.reduce<StagedConfigItemChanges>(
        (acc, ci) =>
            Object.assign(acc, {
                [ci.attributeKey]: ci,
            }),
        {},
    );

    const { saveAll, resetAll, hasChanges } = mergeStagedChanges(stagedProfile, stagedConfigItems);

    const distractionSound = getConfigItem('browser_notification_sound_enabled_distraction');
    const eyeClosureSound = getConfigItem('browser_notification_sound_enabled_eye_closure');
    const sfx = useFrame(SFXToken);

    return (
        <>
            <div data-testid={'page-settings-my-profile'} />
            <SettingsPage title={'My Profile'}>
                <Stack spacing={2}>
                    <TextField
                        fullWidth
                        label="Phone number"
                        value={stagedProfile.merged.phoneNumber || /* istanbul ignore next */ ''}
                        type={'tel'}
                        onChange={(e) => {
                            const newValue = e.target.value;
                            stagedProfile.setChanges((c) => ({ ...c, phoneNumber: newValue }));
                        }}
                        variant="outlined"
                        InputProps={{
                            inputComponent: NumberFormatPhone,
                        }}
                    />
                    <TextField
                        fullWidth
                        label="Slack username"
                        value={stagedProfile.merged.slackUsername || /* istanbul ignore next */ ''}
                        onChange={(e) => {
                            const newValue = e.target.value;
                            stagedProfile.setChanges((c) => ({ ...c, slackUsername: newValue }));
                        }}
                        variant="outlined"
                    />
                    <div>
                        <ConfigItemSwitch
                            stagedChanges={ciStaged}
                            item={distractionSound}
                            onChange={stagedConfigItems.setChanges}
                        />
                        <Button
                            onClick={() => sfx['eventReceived']?.play()}
                            variant={'outlined'}
                            startIcon={<AppIconPlay />}
                            size={'small'}
                        >
                            {'Preview'}
                        </Button>
                    </div>
                    <div>
                        <ConfigItemSwitch
                            stagedChanges={ciStaged}
                            item={eyeClosureSound}
                            onChange={stagedConfigItems.setChanges}
                        />
                        <Button
                            onClick={() => sfx['eventReceived']?.play()}
                            variant={'outlined'}
                            startIcon={<AppIconPlay />}
                            size={'small'}
                        >
                            {'Preview'}
                        </Button>
                    </div>
                </Stack>
            </SettingsPage>
            <SettingsSaveBar
                show={hasChanges}
                onSave={saveAll}
                onReset={resetAll}
                loading={profileLoading || configLoading}
            />
        </>
    );
}

type ConfigItemSwitchProps = {
    item: (ConfigItemWebToggleable & { name: string }) | undefined;
    stagedChanges: StagedConfigItemChanges | undefined;
    onChange: (c: React.SetStateAction<SetConfigItemsRequest<ConfigItemWebToggleable> | null>) => void;
};
function ConfigItemSwitch({ item, stagedChanges, onChange }: ConfigItemSwitchProps): React.ReactElement | null {
    if (!item) {
        return null;
    }
    const stagedChange = stagedChanges
        ? stagedChanges[item.attributeKey]?.boolValue
        : /* istanbul ignore next */ undefined;
    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Switch
                        checked={lastDefined(item.boolValue, stagedChange)}
                        onChange={(_, checked) =>
                            onChange((ci) => {
                                const items = ci?.configurationItems || [];
                                const currentIdx = items.findIndex((c) => c.id === item.id);

                                if (checked === item.boolValue) {
                                    /*istanbul ignore next*/
                                    if (typeof currentIdx === 'number' && currentIdx >= 0) {
                                        items.splice(currentIdx, 1);
                                    }
                                } else {
                                    items.push({
                                        ...item,
                                        boolValue: checked,
                                    });
                                }

                                if (items.length === 0) {
                                    return null;
                                }

                                return {
                                    configurationItems: items,
                                };
                            })
                        }
                    />
                }
                label={item.name}
            />
        </FormGroup>
    );
}
