// istanbul ignore file

import EventEmitter from '@foxify/events';
import { DateTimestampMs } from '../../types/stein';
import { VideoSegment } from './video-segment';
import { MultiVideoController } from './multi-video';

export type VideoTrackState = {
    videoStatus: 'no-video' | 'ready';
    loading: boolean;
    playbackState: 'playing' | 'paused';
    error: boolean;
};

type VideoTrackEvents = {
    stateChanged: (t: VideoTrackState) => void;
};

type VideoTrackOptions = {
    segments: VideoSegment[];
    controller: MultiVideoController;
    log?: boolean;
};

export class VideoTrack extends EventEmitter<VideoTrackEvents> {
    segments: VideoSegment[];
    currentSegmentIndex: number | null;
    videoElement: HTMLVideoElement;
    log: boolean | undefined;
    controller: MultiVideoController;
    state: VideoTrackState = {
        loading: false,
        videoStatus: 'no-video',
        playbackState: 'paused',
        error: false,
    };

    constructor({ segments, controller, log }: VideoTrackOptions) {
        super();
        this.segments = segments.sort((a, b) => a.startTime - b.startTime);
        this.controller = controller;
        this.log = log;
    }

    play = async (): Promise<void> => {
        this.updateState({ playbackState: 'playing' });
        if (!this.videoElement.currentSrc) {
            return;
        }
        const i = this.currentSegmentIndex;
        if (i != null && i < this.segments.length) {
            try {
                return await this.videoElement.play();
            } catch (e) {
                // ignore error
            }
        }

        return Promise.resolve();
    };

    pause = (): void => {
        this.updateState({ playbackState: 'paused' });

        this.videoElement.pause();
    };

    setVideoElement = (element: HTMLVideoElement): void => {
        if (element === this.videoElement) {
            return;
        }
        if (this.videoElement) {
            this.videoElement.removeEventListener('loadeddata', this.setLoaded);
            this.videoElement.removeEventListener('canplay', this.setLoaded);
            this.videoElement.removeEventListener('waiting', this.setLoading);
            this.videoElement.removeEventListener('ended', this.segmentEnded);
            this.videoElement.removeEventListener('error', this.setError);
        }
        if (element) {
            this.videoElement = element;
            element.addEventListener('loadeddata', this.setLoaded);
            element.addEventListener('canplay', this.setLoaded);
            element.addEventListener('waiting', this.setLoading);
            element.addEventListener('ended', this.segmentEnded);
            element.addEventListener('error', this.setError);

            const playbackTime = this.controller.playbackTime;
            this.currentSegmentIndex = null;
            if (playbackTime) {
                this.seek(playbackTime);
            }
        }
    };

    seek = (playbackTime: DateTimestampMs): void => {
        const segmentIndex = this.segmentIndexAtTime(playbackTime);
        if (segmentIndex === null) {
            this.unsetVideo();
            return;
        }

        let segmentChanged = false;
        if (segmentIndex !== this.currentSegmentIndex) {
            this.setupSegmentAtIndex(segmentIndex);
            segmentChanged = true;
        }

        const segment = this.getCurrentSegment();

        if (segment === null) {
            this.unsetVideo();
            return;
        }

        const offsetTime = playbackTime - segment.startTime;
        const offsetTimeSeconds = offsetTime / 1000;

        this.videoElement.currentTime = offsetTimeSeconds;

        if (segmentChanged && this.state.playbackState === 'playing') {
            //this.play();
            this.videoElement.play();
        }
    };

    getPlaybackTime(): DateTimestampMs | null {
        const seg = this.getCurrentSegment();
        if (!seg) {
            return null;
        }
        return (seg.startTime + this.videoElement.currentTime * 1000) as DateTimestampMs;
    }

    private unsetVideo = (): void => {
        this.updateState({ videoStatus: 'no-video' });
        this.videoElement.src = '';
        this.currentSegmentIndex = null;
    };

    public get startTime(): DateTimestampMs {
        return Math.min(...this.segments.map((s) => s.startTime)) as DateTimestampMs;
    }

    public get endTime(): DateTimestampMs {
        return Math.max(...this.segments.map((s) => s.startTime + s.durationSeconds * 1000)) as DateTimestampMs;
    }

    private getCurrentSegment = (): VideoSegment | null => {
        if (this.currentSegmentIndex == null) {
            return null;
        }
        return this.segments[this.currentSegmentIndex];
    };

    private setPlaying = (): void => {
        this.updateState({ playbackState: 'playing' });
    };

    private segmentEnded = (): void => {
        this.updateState({ videoStatus: 'no-video' });
        this.unsetVideo();
    };

    private setPaused = (): void => {
        this.updateState({ playbackState: 'paused' });
    };

    private setLoading = (): void => {
        this.updateState({ loading: true, error: false });
    };

    private setLoaded = (): void => {
        this.updateState({ loading: false });
    };

    private setError = (): void => {
        // this.updateState({ error: true });
    };

    private updateState = (s: Partial<VideoTrackState>): void => {
        this.state = {
            ...this.state,
            ...s,
        };

        this.emit('stateChanged', this.state);
    };

    private setupSegmentAtIndex(i: number): void {
        if (i < this.segments.length) {
            const segment = this.segments[i];
            this.videoElement.src = segment.url;

            this.videoElement.currentTime = 0;
            this.currentSegmentIndex = i;
            this.updateState({ videoStatus: 'ready', loading: true });
        }
    }

    private segmentIndexAtTime(time: DateTimestampMs): number | null {
        for (let i = 0; i < this.segments.length; i++) {
            const segment = this.segments[i];
            const segmentEndTimeMs = segment.startTime + segment.durationSeconds * 1000;

            if (segment.startTime > time) {
                return null;
            }

            if (segmentEndTimeMs > time) {
                return i;
            }
        }
        return null;
    }
}
