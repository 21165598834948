import { Box, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import * as React from 'react';
import { useAppTheme } from '../../theme';
import { AppIconError, AppIconErrorNotFound } from '../AppIcons';

type ErrorPageType = 'not-found' | 'error';

type ErrorPageProps = {
    content: string | React.ReactNode;
    type: ErrorPageType;
};

export function ErrorPage({ content, type }: ErrorPageProps): React.ReactElement {
    const theme = useAppTheme();

    function renderIcon(type: ErrorPageType): React.ReactElement {
        switch (type) {
            case 'not-found':
                return <AppIconErrorNotFound color={theme.palette.warning.main} />;
            case 'error':
                return <AppIconError color={theme.palette.warning.main} />;
        }
    }

    return (
        <Box sx={{ width: '100%', height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Stack justifyContent={'center'} alignItems={'center'}>
                <Typography variant={'h3'}>{renderIcon(type)}</Typography>
                {typeof content === 'string' ? <Typography variant={'body1'}>{content}</Typography> : content}
            </Stack>
        </Box>
    );
}
