import { CircularProgress, Switch, SwitchProps } from '@mui/material';
import * as React from 'react';
import { appStyled } from '../theme';

type AutosaveSwitchProps = Omit<SwitchProps, 'onChange' | 'checked'> & {
    onSave: (s: boolean) => void;
    saving: boolean;
    checked: boolean;
};

const ToggleButton = appStyled('div', {
    shouldForwardProp: (prop) => prop !== 'checked',
})<{ checked: boolean }>(({ theme, checked }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: checked ? theme.palette.primary.main : theme.palette.background.default,
    boxShadow: theme.shadows[1],
}));

export function AutosaveSwitch({ checked, saving, onSave, ...props }: AutosaveSwitchProps): React.ReactElement {
    function Icon(): React.ReactElement {
        return (
            <ToggleButton checked={checked}>
                {saving && <CircularProgress size={13} color="secondary" thickness={5} />}
            </ToggleButton>
        );
    }

    return (
        <Switch
            checked={checked}
            onChange={() => !saving && onSave(!checked)}
            icon={<Icon />}
            checkedIcon={<Icon />}
            {...props}
        />
    );
}
