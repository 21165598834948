import * as React from 'react';
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Slider,
    SliderProps,
    Stack,
    TextField,
} from '@mui/material';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import { useAppSelector } from '../../../hooks/hooks';
import { useActiveProject } from '../../../hooks/use-active-project';

import { mergeStagedChanges, useStagedChanges } from '../../../hooks/use-staged-changes';
import { useConfigItems } from '../../../hooks/use-config-items';

import { useFrame } from '../../../lib/frame-react';
import { SoundEffect, SoundEffectId, Threshold } from '../../../types/stein';

import { SettingsPage, SettingsSaveBar } from './components/SettingsShared';
import { ConfigItemWebDurationThresholds } from '../../../types/stein.config_items';
import { ThresholdChart } from './components/ThresholdSettings/ThresholdChart';
import { ThresholdEditor } from './components/ThresholdSettings/ThresholdEditor';

import { CalloutBox } from '../../material/CalloutBox';
import { isEmpty, omit } from 'lodash';
import { AppIconPlay, AppIconVolumeDown, AppIconVolumeUp } from '../../AppIcons';
import { useSoundEffect } from '../../../hooks/use-sound-effect';

function sortBySpeed(thresholds: Threshold[]): Threshold[] {
    return [...thresholds].sort((a, b) => a.speed - b.speed);
}

type Thresholds = ConfigItemWebDurationThresholds['jsonValue'];

export function SettingsProjectDevice(): React.ReactElement | null {
    const project = useActiveProject();
    const { useGetProjectConfigItemsQuery, useSetProjectConfigItemsMutation, useUpdateProjectMutation } =
        useFrame(SteinInternalApiClientToken);
    useGetProjectConfigItemsQuery({ projectId: project.id });
    const { soundEffects, defaultSoundEffectId } = useAppSelector((s) => s.audio);

    const getConfigItem = useConfigItems();
    const harshSensativityConfig = getConfigItem('harsh_maneuver_sensitivity');

    const thresholdsConfigItem = getConfigItem('duration_thresholds');

    const thresholds = thresholdsConfigItem?.jsonValue;

    const [setConfigItems, { isLoading: isThresholdSaving }] = useSetProjectConfigItemsMutation();
    const stagedThreshold = useStagedChanges(
        (thresholds: Thresholds) =>
            setConfigItems({
                configurationItems: [{ attributeKey: 'duration_thresholds', jsonValue: thresholds }],
                projectId: project.id,
            }),
        thresholds || /* istanbul ignore next */ {
            eyeClosure: [],
            distraction: [],
        },
    );

    const stagedHarshManeuver = useStagedChanges(
        // istanbul ignore next
        ({ integerValue }: { integerValue: number }) =>
            // istanbul ignore next
            setConfigItems({
                configurationItems: [{ attributeKey: 'harsh_maneuver_sensitivity', integerValue }],
                projectId: project.id,
            }),
        {
            integerValue: harshSensativityConfig?.integerValue || 0,
        },
    );

    const [updateProject, { isLoading: isProjectSaving }] = useUpdateProjectMutation();
    const stagedProject = useStagedChanges(updateProject, project);

    const distractionThresholds = sortBySpeed(stagedThreshold.merged.distraction || /* istanbul ignore next */ []);
    const eyeClosureThresholds = sortBySpeed(stagedThreshold.merged.eyeClosure || /* istanbul ignore next */ []);

    const { saveAll, resetAll, hasChanges } = mergeStagedChanges(stagedThreshold, stagedProject, stagedHarshManeuver);
    const isSaving = isThresholdSaving || isProjectSaving;

    return (
        <>
            <SettingsPage title={`${project?.name} Device Settings`}>
                <Stack spacing={2}>
                    <CalloutBox name={'Alert thresholds'}>
                        <Stack spacing={2}>
                            <h3>{'Harsh Maneuver Sensitivity'}</h3>
                            <TextField
                                label={'Sensitivity'}
                                select
                                value={stagedHarshManeuver.merged.integerValue}
                                onChange={(e) =>
                                    stagedHarshManeuver.setChanges({ integerValue: parseInt(e.target.value) })
                                }
                            >
                                <MenuItem value={0}>Off</MenuItem>
                                <MenuItem value={1}>Low</MenuItem>
                                <MenuItem value={2}>Med</MenuItem>
                                <MenuItem value={3}>High</MenuItem>
                            </TextField>

                            <h3>{'Distraction duration thresholds'}</h3>
                            <ThresholdChart thresholds={distractionThresholds} />
                            <ThresholdEditor
                                thresholds={distractionThresholds}
                                onChange={(t) =>
                                    stagedThreshold.setChanges({
                                        distraction: t,
                                    })
                                }
                            />
                            <h3>{'Severe fatigue duration thresholds'}</h3>
                            <ThresholdChart thresholds={eyeClosureThresholds} />
                            <ThresholdEditor
                                thresholds={eyeClosureThresholds}
                                onChange={(t) => stagedThreshold.setChanges({ eyeClosure: t })}
                            />
                        </Stack>
                    </CalloutBox>
                    <CalloutBox name={'In Cabin Alerts'}>
                        <Stack spacing={2}>
                            <SoundEffectSelector
                                name={'Tier 1 sound'}
                                id={'tier-1-sound-input'}
                                options={soundEffects}
                                effectId={
                                    stagedProject.merged.distractionSoundEffectId ||
                                    /* istanbul ignore next */ defaultSoundEffectId
                                }
                                onChange={(id) =>
                                    stagedProject.setChanges((c) =>
                                        Object.assign({}, c, {
                                            distractionSoundEffectId:
                                                id || /* istanbul ignore next */ defaultSoundEffectId,
                                        }),
                                    )
                                }
                            />
                            <SoundEffectSelector
                                name={'Tier 2 sound'}
                                id={'tier-2-sound-input'}
                                options={soundEffects}
                                effectId={
                                    stagedProject.merged.distractionSoundEffectTier2Id ||
                                    /* istanbul ignore next */ defaultSoundEffectId
                                }
                                onChange={(id) =>
                                    stagedProject.setChanges((c) =>
                                        Object.assign({}, c, {
                                            distractionSoundEffectTier2Id:
                                                id || /* istanbul ignore next */ defaultSoundEffectId,
                                        }),
                                    )
                                }
                            />
                        </Stack>
                        <p>
                            {
                                'In-cabin alerts escalate to Tier 2 when 4+ verified distraction events occur within one hour.'
                            }
                        </p>
                        <Stack direction={'row'} spacing={2} alignItems="center">
                            <h3>{'In cabin volume'}</h3>
                            <AppIconVolumeDown />
                            <CachedSlider
                                sx={{ maxWidth: 300 }}
                                valueLabelDisplay="auto"
                                value={stagedProject.merged?.volume || /* istanbul ignore next */ 0}
                                data-testid={'in-cabin-alert-volume-slider'}
                                min={0}
                                max={1}
                                step={0.01}
                                valueLabelFormat={(v) => `${Math.round(v * 100)}%`}
                                onChangeCommitted={(_, volume) =>
                                    stagedProject.setChanges((c) => Object.assign({}, c, { volume }))
                                }
                            />
                            <AppIconVolumeUp />
                            {stagedProject.changes?.hasOwnProperty('volume') && (
                                <Button
                                    data-testid={'button-volume-reset'}
                                    size={'small'}
                                    onClick={() =>
                                        stagedProject.setChanges((p) => {
                                            const newChanges = omit(p, 'volume');
                                            return isEmpty(newChanges) ? null : /* istanbul ignore next */ newChanges;
                                        })
                                    }
                                >
                                    {'Reset'}
                                </Button>
                            )}
                        </Stack>
                    </CalloutBox>
                </Stack>
            </SettingsPage>
            <SettingsSaveBar show={hasChanges} onSave={saveAll} onReset={resetAll} loading={isSaving} />
        </>
    );
}

type SoundEffectSelectorProps = {
    name: string;
    id: string;
    options: SoundEffect[];
    effectId: SoundEffectId;
    onChange: (id: SoundEffectId | null) => void;
};
function SoundEffectSelector({ name, id, options, effectId, onChange }: SoundEffectSelectorProps): React.ReactElement {
    const [effect, { state }] = useSoundEffect(effectId);
    return (
        <Stack direction={'row'} spacing={2} alignItems="center">
            <h3>{name}</h3>
            <FormControl sx={{ minWidth: 300 }}>
                <InputLabel id={id}>{name}</InputLabel>
                <Select
                    labelId={id}
                    value={effectId}
                    size={'small'}
                    label={name}
                    onChange={(e) =>
                        onChange(typeof e.target.value === 'number' ? e.target.value : /* istanbul ignore next */ null)
                    }
                >
                    {options.map((s) => (
                        <MenuItem value={s.id} key={s.id}>
                            {s.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button
                size={'small'}
                startIcon={<AppIconPlay />}
                onClick={() => (state === 'play' ? effect?.stop() : effect?.play())}
            >
                {state === 'play' ? 'Stop' : 'Preview'}
            </Button>
        </Stack>
    );
}

function CachedSlider(props: Omit<SliderProps, 'onChange'>): React.ReactElement {
    const [value, setValue] = React.useState<SliderProps['value']>(props.value || /* istanbul ignore next */ 0);
    React.useEffect(() => {
        setValue(props.value);
    }, [props.value]);
    return <Slider {...props} value={value} onChange={(_, newValue) => setValue(newValue)} />;
}
