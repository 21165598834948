// istanbul ignore file
import { CircularProgress, Fade, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { useIsHovered } from '../../hooks/use-is-hovered';
import { appStyled } from '../../theme';
import { AppIconRefresh } from '../AppIcons';
import { Aspect } from '../AspectRatio';
import { VideoRef } from './VideoTypes';
import { Noise } from '../pages/VehicleTimelineShow/Noise';
import { useAppSelector } from '../../hooks/hooks';
import { selectDebugEnabled } from '../../store/selectors';

const VideoContainer = appStyled('div')({
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    overflow: 'hidden',
});

const VideoOverlay = appStyled('div')({
    position: 'absolute',
    width: '100%',
    height: 'auto',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    display: 'flex',
});

export const VideoOverlayCentered = appStyled('div')(({ theme }) => ({
    padding: theme.spacing(0.25),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255,0.4)',
    height: '100%',
    width: '100%',
    display: 'flex',
}));

export const VideoOverlayBottom = appStyled('div')(({ theme }) => ({
    padding: theme.spacing(1),
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    display: 'flex',
}));

export const VideoFlippable = appStyled('video', {
    shouldForwardProp: (prop) => prop !== 'flipped',
})<{ flipped?: boolean }>(({ flipped }) => ({
    transform: flipped ? 'rotate(180deg)' : undefined,
}));

const Video = appStyled(VideoFlippable)({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    top: 0,
    left: 0,
});

export type VideoPlayerBaseProps = {
    controls?: React.ReactElement<unknown, React.JSXElementConstructor<unknown>> | undefined;
    flipped?: boolean;
    containerRef?: React.RefObject<HTMLDivElement>;
    hideControlsUnlessHover?: boolean;
    overlayLoading?: boolean;
    overlayNoise?: boolean;
    overlayCentered?: React.ReactNode;
    overlayTextB?: string;
    overlayDebug?: React.ReactNode;
    videoRef: VideoRef | React.RefCallback<HTMLVideoElement>;
    onReload?: () => void;
} & Omit<React.ComponentProps<'video'>, 'controls'>;
export function VideoPlayerBase({
    controls,
    flipped,
    videoRef,
    overlayLoading,
    overlayNoise,
    overlayCentered,
    overlayTextB,
    containerRef,
    overlayDebug,
    hideControlsUnlessHover,
    onReload,
    ...props
}: VideoPlayerBaseProps): React.ReactElement {
    const [isHovered, hoveredProps] = useIsHovered();
    const [isErrorHovered, isErrorHoveredProps] = useIsHovered();
    const debugEnabled = useAppSelector(selectDebugEnabled);

    const hasError = false; // useVideoHasError(videoRef);

    const showControls = controls && (!hideControlsUnlessHover || isHovered);

    const centerOverlay = overlayCentered ? <VideoOverlayCentered>{overlayCentered}</VideoOverlayCentered> : null;

    return (
        <>
            <Aspect ratio={4 / 3} ref={containerRef}>
                <VideoContainer {...hoveredProps}>
                    <Video ref={videoRef} flipped={flipped} {...props} />
                    <>
                        {overlayNoise ? (
                            <VideoOverlay>
                                <Noise />
                            </VideoOverlay>
                        ) : null}
                        {centerOverlay}
                        {overlayTextB ? (
                            <VideoOverlayBottom>
                                <Stack spacing={1}>
                                    <Typography>{overlayTextB}</Typography>
                                </Stack>
                            </VideoOverlayBottom>
                        ) : null}
                        {overlayLoading ? (
                            <VideoOverlay>
                                <VideoOverlayCentered>
                                    <CircularProgress data-testid={'video-loading-spinner'} />
                                </VideoOverlayCentered>
                            </VideoOverlay>
                        ) : null}
                        {hasError ? (
                            <VideoOverlay>
                                <VideoOverlayCentered
                                    key="error"
                                    {...isErrorHoveredProps}
                                    sx={onReload ? { cursor: 'pointer' } : /* istanbul ignore next */ undefined}
                                    onClick={onReload}
                                    data-testid={'error-overlay'}
                                >
                                    <Stack>
                                        <div>{'Error overlayLoading Video'}</div>
                                        {onReload ? (
                                            <Box
                                                sx={{
                                                    transition: 'transform 100ms ease-in',
                                                    transform: isErrorHovered ? 'rotate(180deg)' : null,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <AppIconRefresh />
                                            </Box>
                                        ) : /*istanbul ignore next*/ null}
                                    </Stack>
                                </VideoOverlayCentered>
                            </VideoOverlay>
                        ) : null}
                        {debugEnabled && overlayDebug ? (
                            <VideoOverlay>
                                <VideoOverlayCentered>
                                    <Box sx={{ backgroundColor: 'red', fontSize: '8pt' }}>{overlayDebug}</Box>
                                </VideoOverlayCentered>
                            </VideoOverlay>
                        ) : null}
                        {controls && !hasError ? (
                            <Fade in={showControls}>
                                <div>{controls}</div>
                            </Fade>
                        ) : null}
                    </>
                </VideoContainer>
            </Aspect>
        </>
    );
}
