import * as React from 'react';

type GeoResultRaw = {
    place_id: string;
    formatted_address: string;
    geometry: {
        location: {
            lat: number;
            lng: number;
        };
    };
};

export type GeoResult = {
    id: string;
    address: string;
    latitude: number;
    longitude: number;
};

export function useLocationSearch(searchText: string): { loading: boolean; data: GeoResult[] } {
    const [loading, setIsLoading] = React.useState(false);
    const [data, setData] = React.useState<GeoResult[]>([]);

    React.useEffect(() => {
        if (searchText) {
            setIsLoading(true);
            const timeout = setTimeout(async () => {
                const res = await fetch(
                    `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                        searchText,
                    )}&key=AIzaSyBvsb1K0BqfYHkuiWa7IQe_OJzRdzchB2I`,
                );
                const { results } = await res.json();
                setData(
                    results.map(
                        // istanbul ignore next
                        (r: GeoResultRaw) =>
                            ({
                                id: r.place_id,
                                address: r.formatted_address,
                                latitude: r.geometry.location.lat,
                                longitude: r.geometry.location.lng,
                            }) as GeoResult,
                    ),
                );
                setIsLoading(false);
            }, 300);

            return () => {
                setIsLoading(false);
                clearTimeout(timeout);
            };
        }
    }, [searchText]);

    return { loading, data };
}
