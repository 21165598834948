import * as React from 'react';
import { AppIconActivityEdit, AppIconArrowRight } from '../../AppIcons';
import { ActivityLogItemVersionType } from '../activity_log_utils';
import { ActivityLogItemBase } from './ActivityLogItemBase';

type ActivityLogItemVersionProps = {
    version: ActivityLogItemVersionType;
};

type ActivityLogVersionChangeProps = {
    changes: ActivityLogItemVersionType['obsrChanges'];
};
function ActivityLogVersionChange({ changes }: ActivityLogVersionChangeProps): React.ReactElement {
    return (
        <>
            {changes.map((c) => (
                <div key={`${c.attribute}_${c.newValue}`} style={{ display: 'flex', alignItems: 'center' }}>
                    <b>{c.attribute}</b> <AppIconArrowRight /> {c.newValue}
                </div>
            ))}
        </>
    );
}

export function ActivityLogItemVersion({ version }: ActivityLogItemVersionProps): React.ReactElement {
    return (
        <ActivityLogItemBase
            timestamp={version.timestamp}
            description={
                <div>
                    <b>{version.userDisplayName}</b>
                    {' made changes'}
                </div>
            }
            Icon={AppIconActivityEdit}
            detail={<ActivityLogVersionChange changes={version.obsrChanges} />}
        />
    );
}
