import { Collapse, IconButton, List, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { TransitionGroup } from 'react-transition-group';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import { useConfigItems } from '../../../hooks/use-config-items';
import { useFrame } from '../../../lib/frame-react';
import { AnalyticsConfig } from '../../../types/stein';
import { CONFIG_ITEM_PLATFORM_WEB } from '../../../types/stein.config_items';
import { AppIconRemoveItem, AppIconSave } from '../../AppIcons';

export function GodModeAnalyticsPage(): React.ReactElement {
    const getItem = useConfigItems();
    const ci = getItem('analytics_config');
    const { useSetGlobalConfigItemsMutation } = useFrame(SteinInternalApiClientToken);
    const [setConfigItems, { isLoading: isSaving }] = useSetGlobalConfigItemsMutation();

    const analyticsConfig: AnalyticsConfig = ci?.jsonValue || {};

    const keys = Object.keys(analyticsConfig).sort();
    const [value, setValue] = React.useState('');

    return (
        <div>
            <Typography variant={'h3'}>{'Analytics Config'}</Typography>
            <List sx={{ maxWidth: '400px' }}>
                <TransitionGroup>
                    {keys.map((k) => (
                        <Collapse key={k}>
                            <ListItem
                                secondaryAction={
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => {
                                            const jsonValue = { ...analyticsConfig };
                                            delete jsonValue[k];
                                            setConfigItems({
                                                configurationItems: [
                                                    {
                                                        attributeKey: 'analytics_config',
                                                        jsonValue,
                                                        platform: CONFIG_ITEM_PLATFORM_WEB,
                                                    },
                                                ],
                                            });
                                        }}
                                    >
                                        <AppIconRemoveItem />
                                    </IconButton>
                                }
                            >
                                <ListItemText primary={k} />
                            </ListItem>
                        </Collapse>
                    ))}
                </TransitionGroup>
                <ListItem
                    disablePadding
                    secondaryAction={
                        <IconButton
                            edge="end"
                            aria-label="insert"
                            disabled={isSaving}
                            onClick={() => {
                                const jsonValue = { ...analyticsConfig, [value]: {} };
                                setConfigItems({
                                    configurationItems: [
                                        {
                                            platform: CONFIG_ITEM_PLATFORM_WEB,
                                            attributeKey: 'analytics_config',
                                            jsonValue,
                                        },
                                    ],
                                });
                            }}
                        >
                            <AppIconSave />
                        </IconButton>
                    }
                >
                    <TextField
                        label={'New Item'}
                        value={value}
                        onChange={(e) => setValue(e.target.value.replaceAll(' ', ''))}
                    />
                </ListItem>
            </List>
        </div>
    );
}
