import { RouterLocation } from '../store/actions';
import { ProjectSlug } from '../types/stein';

const slugRE = /\/projects\/([^\/]+)\/?/;
export function projectSlugFromLocation(location: RouterLocation | undefined): ProjectSlug | undefined {
    const pathname = location?.pathname;
    // istanbul ignore next
    if (pathname) {
        const match = pathname.match(slugRE);
        if (match && match.length >= 2) {
            return match[1] as ProjectSlug;
        }
    }
}
