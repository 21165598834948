import { EnvToken } from '../lib/frame-tokens';
import { getApp } from '../main';
import { InitialStateOverridesToken } from '../store';
import { RailsContext } from '../store/slices/shared-actions';
import { CustomRole } from '../types/stein';

const app = getApp();

// Define a type for the slice state
export type RawRailsContext = Omit<RailsContext, 'customRoles'> & {
    customRoles: Array<Omit<CustomRole, 'abilities'> & { abilities: string }>;
};

type UJSRootProps = {
    railsContext: RawRailsContext;
    env: string;
};

export default function UJSRoot(props: UJSRootProps): React.ReactElement {
    app.register(InitialStateOverridesToken, {});
    app.register(EnvToken, JSON.parse(props.env));

    const { render } = app.start();

    return render();
}
