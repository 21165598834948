import { Typography } from '@mui/material';
import * as React from 'react';
import Draggable from 'react-draggable';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { useActiveProjectSlug } from '../../../hooks/use-active-project';
import { overrideAbility } from '../../../store/actions';
import { selectAbilities, selectProjectBySlug } from '../../../store/selectors';
import { OverrideAbilityArgs } from '../../../store/slices/abilitiesSlice';
import { useAppTheme } from '../../../theme';
import { AbilitySelection } from '../../AbilitySelection/AbilitySelection';
import { InvisiblePresentation } from '../../InvisiblePresentation';

type DebugModalProps = {
    open: boolean;
};

export function DebugModal({ open }: DebugModalProps): React.ReactElement | null {
    const debugPermitted = useAppSelector((s) => s.navigation.debugPermitted);
    if (!open || !debugPermitted) {
        return null;
    }
    return <ModalContent />;
}

function ModalContent(): React.ReactElement {
    const slug = useActiveProjectSlug();
    const project = useAppSelector((s) => selectProjectBySlug(s, slug));
    const abilities = useAppSelector((s) => selectAbilities(s, slug));
    const dispatch = useAppDispatch();
    const theme = useAppTheme();

    const style: React.CSSProperties = {
        pointerEvents: 'all',
        position: 'absolute',
        cursor: 'move',
        right: 10,
        bottom: 55,
        width: 400,
        height: 400,
        overflow: 'hidden',
        boxShadow: theme.shadows[4],
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    };

    function handleAbilityChange(c: OverrideAbilityArgs): void {
        dispatch(overrideAbility(c));
    }

    return (
        <InvisiblePresentation>
            <Draggable bounds={'parent'}>
                <div style={style} data-testid={'debug-ability-override-modal'}>
                    <Typography variant={'h6'}>{`Abilities for ${project?.name}`}</Typography>
                    <Typography variant="caption">{"These permissions are used instead of your user's."}</Typography>
                    <div
                        style={{
                            overflow: 'scroll',
                        }}
                    >
                        <AbilitySelection abilities={abilities} onChange={handleAbilityChange} />
                    </div>
                </div>
            </Draggable>
        </InvisiblePresentation>
    );
}
