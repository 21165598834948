import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import * as React from 'react';
import { useActiveProject } from '../../hooks/use-active-project';
import { ProjectAdmins } from '../ProjectAdmins';

export function ErrorUnauthorized(): React.ReactElement {
    const project = useActiveProject();
    return (
        <Stack padding={2} spacing={1}>
            <Typography variant={'h4'}>{'Unauthorized'}</Typography>
            <Typography variant={'body1'}>
                {'If you think you should have permission to this page, contact one of your site administrators.'}
            </Typography>
            <ProjectAdmins project={project} />
        </Stack>
    );
}
