import * as React from 'react';
import { DriverEvent, PingLocated, Geofence } from '../../types/stein';
import { parseLatLng } from '../../utils/latlng-utils';
import { Paper, Skeleton, Stack, Typography } from '@mui/material';
import { MapContainer, Marker, Circle, Tooltip, useMap } from 'react-leaflet';
import { Aspect } from '../AspectRatio';
import { MapTiles } from '../Map/MapTiles';
import { formatLocalizedDateTime } from '../../utils/datetime-utils';
import { formatDistance } from 'date-fns';
import { useCopyToClipboard } from '../material/CopyToClipboard';

type DriverEventMapProps = {
    ping: DriverEvent | PingLocated | undefined;
    geofence: Geofence | undefined;
};

export function AutoCenter({ position }: { position: [number, number] }): React.ReactElement {
    const map = useMap();
    React.useEffect(() => {
        // istanbul ignore next
        if (map) {
            map.panTo(position);
        }
    }, [map, position[0], position[1]]);

    return <></>;
}

export function DriverEventMap({ ping, geofence }: DriverEventMapProps): React.ReactElement | null {
    const { showCopiedText, copyToClipboard } = useCopyToClipboard();

    if (!ping || !ping.occurredAt) {
        return null;
    }

    const position = parseLatLng(ping.latitude, ping.longitude);
    const geofenceCenter = parseLatLng(geofence?.latitude, geofence?.longitude);

    // istanbul ignore next
    if (!position) {
        // istanbul ignore next
        return (
            <div style={{ flex: '1' }}>
                <Skeleton variant="rectangular" width="100%" height="100%" />
            </div>
        );
    }

    let geofenceMarker: React.ReactNode = null;
    const geofenceRadius = geofence?.radius;
    // istanbul ignore else
    if (geofenceCenter && geofenceRadius) {
        geofenceMarker = <Circle center={geofenceCenter} radius={geofenceRadius} />;
    }

    const occurredAt = new Date(ping.occurredAt);

    return (
        <Aspect ratio={4 / 3}>
            <Paper style={{ display: 'flex', height: '100%' }}>
                <MapContainer
                    center={position}
                    zoomControl={true}
                    dragging={true}
                    doubleClickZoom={false}
                    boxZoom={true}
                    scrollWheelZoom={true}
                    zoom={13}
                    style={{
                        flex: 1,
                    }}
                >
                    <AutoCenter position={position} />
                    <MapTiles />
                    <Marker
                        position={position}
                        eventHandlers={{
                            click: /* istanbul ignore next */ () => copyToClipboard(`${position[0]},${position[1]}`),
                        }}
                    >
                        <Tooltip direction={'top'}>
                            <Stack alignItems={'center'}>
                                <Typography variant={'caption'}>
                                    {formatDistance(new Date(occurredAt), new Date(), {
                                        addSuffix: true,
                                    })}
                                </Typography>
                                <Typography variant={'body2'}>
                                    {`${formatLocalizedDateTime(new Date(occurredAt))}`}
                                </Typography>
                                <Typography variant={'caption'}>
                                    {showCopiedText
                                        ? /*istanbul ignore next*/ 'copied'
                                        : 'Click to copy GPS to clipboard.'}
                                </Typography>
                            </Stack>
                        </Tooltip>
                    </Marker>

                    {geofenceMarker}
                </MapContainer>
            </Paper>
        </Aspect>
    );
}
