import { Stack } from '@mui/system';
import * as React from 'react';
import { DateTimestampMs } from '../../../types/stein';
import { IconButtonSimple } from '../../material/IconButtonSimple';
import { VideoMultiControl } from '../../VideoPlayer/VideoControlLayouts';

import { MultiVideoController } from '../../../lib/multi-video/multi-video';
import { AppIconPause, AppIconPlay, AppIconSkipBack, AppIconSkipForward } from '../../AppIcons';

const TIME_10SECONDS = 10000;

export const VehicleTimelineVideoControls = React.forwardRef(function VehicleTimelineVideoControls(
    {
        controller,
    }: {
        controller: MultiVideoController;
    },
    ref: React.ForwardedRef<HTMLDivElement>,
): React.ReactElement {
    const paused = controller.playbackState === 'paused';
    async function handleTogglePlayback(): Promise<void> {
        if (paused) {
            await controller.play();
        } else {
            controller.pause();
        }
    }

    return (
        <VideoMultiControl
            ref={ref}
            onClick={handleTogglePlayback}
            data-testid={'video-controls'}
            bottom={
                <Stack spacing={1} direction={'row'}>
                    <IconButtonSimple
                        onClick={() =>
                            controller && controller.seek((controller.playbackTime - TIME_10SECONDS) as DateTimestampMs)
                        }
                    >
                        <AppIconSkipBack />
                    </IconButtonSimple>

                    <IconButtonSimple sx={{ fontSize: 20 }} onClick={handleTogglePlayback}>
                        {paused ? <AppIconPlay /> : <AppIconPause />}
                    </IconButtonSimple>

                    <IconButtonSimple
                        onClick={() => controller.seek((controller.playbackTime + TIME_10SECONDS) as DateTimestampMs)}
                    >
                        <AppIconSkipForward />
                    </IconButtonSimple>
                </Stack>
            }
        ></VideoMultiControl>
    );
});
