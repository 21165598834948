import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { VehicleModel } from '../../types/stein';
import { setRailsContext } from './shared-actions';

export const vehicleModelsAdapter = createEntityAdapter<VehicleModel>({
    selectId: (v) => v.id,
    sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const vehicleModelsSlice = createSlice({
    name: 'vehicles',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState: vehicleModelsAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setRailsContext, (s, a) => {
            vehicleModelsAdapter.setAll(s, a.payload.vehicleModels);
        });
    },
});
