// istanbul ignore file
import * as React from 'react';

import { useAuditTagProfile } from './AuditTagProfile';
import { useFrame } from '../../../../lib/frame-react';
import { SteinInternalApiClientToken } from '../../../../clients/stein-internal-api';
import { useChanges } from '../../../../hooks/use-staged-changes';
import { AuditSlug, AuditTag, AuditTagBehavior, AuditTagCategory, AuditTagSlug } from '../../../../types/stein';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { AuditTagBehaviorIcon } from './AuditTagBehaviorIcon';
import {
    auditTagBehaviorToHumanReadable,
    auditTagCategoryToHumanReadable,
    AUDIT_TAG_BEHAVIORS,
} from './AuditTagConstants';
import { formatLocalizedTime } from '../../../../utils/datetime-utils';
import LoadingButton from '@mui/lab/LoadingButton';
import { CreateAuditTagReq } from '../../../../types/stein-internal-api';

type AuditTagEditModalProps = {
    auditSlug: AuditSlug;
    auditTag: CreateAuditTagReq['auditTag'];
    existingTags: AuditTag[];
} & (
    | {
          mode: 'create';
      }
    | {
          mode: 'edit';
          auditTagSlug: AuditTagSlug;
      }
) &
    DialogProps;

export function AuditTagEditModal(props: AuditTagEditModalProps): React.ReactElement {
    const { existingTags, auditTag, auditSlug, ...modalProps } = props;
    const { useUpdateAuditTagMutation, useCreateAuditTagMutation } = useFrame(SteinInternalApiClientToken);
    const [updateAuditTag, { isLoading: isUpdating }] = useUpdateAuditTagMutation();
    const [createAuditTag, { isLoading: isCreating }] = useCreateAuditTagMutation();

    const stagedAuditTag = useChanges(auditTag);

    function onClose(): void {
        // @ts-expect-error onClose expect props but they aren't used
        modalProps.onClose && modalProps.onClose();
    }

    async function onSave(): Promise<void> {
        if (props.mode === 'edit') {
            updateAuditTag({
                auditSlug,
                auditTag: stagedAuditTag.merged,
                auditTagSlug: props.auditTagSlug,
            });
        } else {
            createAuditTag({
                auditSlug,
                auditTag: stagedAuditTag.merged,
            });
        }
        onClose();
    }

    return (
        <Dialog className="d-flex justify-content-center align-items-center" {...modalProps}>
            <DialogContent sx={{ width: '400px' }}>
                <Stack spacing={2}>
                    <Typography variant="subtitle1">
                        {`Tag at ${formatLocalizedTime(new Date(auditTag.startedAt))}`}
                    </Typography>
                    <SelectCateogry
                        value={stagedAuditTag.merged.category}
                        onChange={(category) => stagedAuditTag.setChanges((c) => ({ ...c, category }))}
                    />
                    <SelectBehavior
                        value={stagedAuditTag.merged.behavior}
                        onChange={(behavior) => stagedAuditTag.setChanges((c) => ({ ...c, behavior }))}
                        staged={stagedAuditTag.merged}
                        existingTags={existingTags}
                    />
                    <CommentInput
                        value={stagedAuditTag.merged.comment || ''}
                        onChange={(comment) => stagedAuditTag.setChanges((c) => ({ ...c, comment }))}
                        onSave={onSave}
                        staged={stagedAuditTag.merged}
                    />
                </Stack>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>{'Cancel'}</Button>
                <LoadingButton variant="contained" color="primary" onClick={onSave} loading={isCreating || isUpdating}>
                    {'Save'}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

function SelectCateogry({
    value,
    onChange,
}: {
    value: AuditTagCategory | null;
    onChange: (c: AuditTagCategory) => void;
}): React.ReactElement {
    const { profile } = useAuditTagProfile();
    const options = profile?.groups.map((g) => g.categories).flat();
    return (
        <FormControl fullWidth>
            <InputLabel id={'audit-tag-category-select'}>{'Tag Category'}</InputLabel>
            <Select
                value={value}
                label={'Tag Category'}
                labelId={'audit-tag-category-select'}
                onChange={(e) => onChange(e.target.value as AuditTagCategory)}
            >
                {options?.map((c) => (
                    <MenuItem key={c} value={c}>
                        <div>{auditTagCategoryToHumanReadable(c)}</div>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

function SelectBehavior({
    value,
    onChange,
    staged,
    existingTags,
}: {
    value: AuditTagBehavior | null;
    onChange: (c: AuditTagBehavior) => void;
    staged: Partial<AuditTag>;
    existingTags: AuditTag[];
}): React.ReactElement {
    return (
        <FormControl fullWidth>
            <InputLabel id={'audit-tag-behavior-select'}>{'Tag Behavior'}</InputLabel>
            <Select
                value={value}
                label={'Tag Behavior'}
                labelId={'audit-tag-behavior-select'}
                onChange={(e) => onChange(e.target.value as AuditTagBehavior)}
            >
                {AUDIT_TAG_BEHAVIORS.map((b) => (
                    <MenuItem key={b} value={b}>
                        <Stack direction={'row'} spacing={2} alignItems={'center'}>
                            <AuditTagBehaviorIcon behavior={b} />
                            {`${auditTagBehaviorToHumanReadable(b)} (Count: ${
                                existingTags.filter((a) => a.category === staged.category && a.behavior === b).length
                            })`}
                        </Stack>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

function CommentInput({
    value,
    onChange,
    onSave,
    staged,
}: {
    value: string;
    onChange: (c: string) => void;
    staged: Partial<AuditTag>;
    onSave: () => void;
}): React.ReactElement {
    return (
        <TextField
            id="audit-tag-comment-input"
            label="Comment"
            fullWidth
            multiline
            rows={4}
            onChange={(e) => onChange(e.target.value || '')}
            value={value}
            variant="outlined"
            autoFocus={Boolean(staged.category && staged.behavior)}
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    onSave();
                }
            }}
        />
    );
}
