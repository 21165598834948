import * as React from 'react';

import { MenuItem, TextField } from '@mui/material';
import { useAppTheme } from '../../../theme';
import { Audit, VehicleDevice } from '../../../types/stein';
import { CalloutBox, CalloutSkeleton } from '../../material/CalloutBox';
import { addMinutes, formatDuration, intervalToDuration, minutesToMilliseconds } from 'date-fns';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import { useFrame } from '../../../lib/frame-react';
import { useActiveProjectSlug } from '../../../hooks/use-active-project';
import { toISO8601 } from '../../../utils/datetime-utils';
import LoadingButton from '@mui/lab/LoadingButton';
import { urlAudit } from '../../../utils/internal-url-utils';
import { NavLink } from 'react-router-dom';

type VehicleDeviceAuditBoxProps = {
    vehicle: VehicleDevice | undefined;
};

const OPTION_MINUTES = [0, 1, 2, 3, 4, 5, 6].map((v) => 60 + v * 15);

export function VehicleDeviceAuditBox({ vehicle }: VehicleDeviceAuditBoxProps): React.ReactElement {
    // istanbul ignore next
    if (!vehicle) {
        // istanbul ignore next
        return <CalloutSkeleton name="Audit" />;
    }

    return <IVehicleDeviceAuditBox vehicle={vehicle} />;
}

type IVehicleDeviceAuditBoxProps = {
    vehicle: VehicleDevice;
};

function IVehicleDeviceAuditBox({ vehicle }: IVehicleDeviceAuditBoxProps): React.ReactElement {
    const { useGetAuditsQuery } = useFrame(SteinInternalApiClientToken);
    const projectSlug = useActiveProjectSlug();

    const { data, isLoading } = useGetAuditsQuery({
        vehicleDevices: [vehicle.slug],
        projectSlug,
        active: true,
    });

    if (isLoading) {
        return <CalloutSkeleton name="Audit" />;
    }

    return (
        <CalloutBox name="Audit" data-testid={'audit-box'}>
            {data?.audits.length ? <AuditInProgress audit={data?.audits[0]} /> : <NoCurrentAudit vehicle={vehicle} />}
        </CalloutBox>
    );
}

function NoCurrentAudit({ vehicle }: IVehicleDeviceAuditBoxProps): React.ReactElement {
    const theme = useAppTheme();
    const [durationMins, setDurationMins] = React.useState(OPTION_MINUTES[0]);
    const { useStartAuditMutation } = useFrame(SteinInternalApiClientToken);

    const [startAudit, { isLoading: isStarting }] = useStartAuditMutation();

    function handleStartAuditClick(): void {
        const now = new Date();
        startAudit({
            vehicleDeviceSlug: vehicle.slug,
            startedAt: toISO8601(now),
            endedAt: toISO8601(addMinutes(now, durationMins)),
        });
    }

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ flex: 2 }}>
                <TextField
                    label={'Audit Duration'}
                    value={durationMins}
                    onChange={(e) => setDurationMins(e.target.value as unknown as number)}
                    select
                    fullWidth
                    size={'small'}
                >
                    {OPTION_MINUTES.map((minutes) => (
                        <MenuItem value={minutes} key={minutes}>
                            {formatDuration(intervalToDuration({ start: 0, end: minutesToMilliseconds(minutes) }))}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            <LoadingButton
                variant="contained"
                sx={{ marginLeft: theme.spacing(1) }}
                onClick={handleStartAuditClick}
                loading={isStarting}
                size={'small'}
            >
                {'Start Audit'}
            </LoadingButton>
        </div>
    );
}

function AuditInProgress({ audit }: { audit: Audit }): React.ReactElement {
    const projectSlug = useActiveProjectSlug();
    const url = urlAudit(projectSlug, audit);
    const theme = useAppTheme();
    const { useUpdateAuditMutation } = useFrame(SteinInternalApiClientToken);
    const [update, { isLoading }] = useUpdateAuditMutation();

    function onStop(): void {
        update({
            auditSlug: audit.slug,
            updates: {
                endedAt: toISO8601(new Date()),
            },
        });
    }
    return (
        <div>
            <div style={{ textAlign: 'center', padding: theme.spacing(1), width: '100%' }}>
                <NavLink to={url}>{'Go to audit in progress'}</NavLink>
            </div>
            <LoadingButton variant="contained" fullWidth onClick={onStop} loading={isLoading}>
                {'Stop Audit'}
            </LoadingButton>
        </div>
    );
}
