import * as React from 'react';

import { ListItemButton, ListItemIcon, ListItemText, ButtonBase, buttonClasses, Box } from '@mui/material';
import { appStyled } from '../../theme';
import { AppInternalUrl } from '../../utils/internal-url-utils';
import { AppIconChevronRight, AppIconComponent } from '../AppIcons';
import { Stack } from '@mui/system';
import { DebugInformation, Permission } from '../Authorize/PermissionBlock';
import { defineDefault } from '../../utils/undefined-utils';

export type OmnibarResult = {
    Icon: AppIconComponent;
    name: string;
    url: AppInternalUrl;
    parentItems?: OmnibarResult[];
    subItems?: OmnibarResult[];
    debug?: DebugInformation;
};

type OmnibarItemProps = OmnibarResult & {
    goToUrl: (url: AppInternalUrl) => void;
};

export function OmnibarItem({
    Icon,
    name,
    parentItems,
    url,
    subItems,
    goToUrl,
    debug = {},
}: OmnibarItemProps): React.ReactElement {
    return (
        <Permission {...debug}>
            <ListItemButton
                onClick={() => goToUrl(url)}
                sx={{
                    transition: 'font-size 100ms ease',
                    fontSize: '1rem',
                    '&:focus-within': { fontSize: '1.15rem' },
                }}
            >
                <Stack direction={'row'}>
                    {parentItems?.map((si) => (
                        <Box sx={{ display: 'flex', alignItems: 'center' }} key={si.url}>
                            <ListItemIcon
                                sx={{
                                    fontSize: '1.25em',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <si.Icon />
                            </ListItemIcon>
                            <ListItemText primary={si.name} />
                            <AppIconChevronRight size={'0.75em'} style={{ marginLeft: '5px' }} />
                        </Box>
                    ))}
                    <ListItemIcon
                        sx={{
                            fontSize: '1.25em',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Icon />
                    </ListItemIcon>
                    <ListItemText primary={name} />
                </Stack>

                <Stack direction={'row'}>
                    {subItems?.map((si) => (
                        <Permission {...defineDefault(si.debug, {})} key={si.name}>
                            <ChipButton
                                sx={{ textAlign: 'center', ml: 2 }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    goToUrl(si.url);
                                }}
                            >
                                {si.name}
                            </ChipButton>
                        </Permission>
                    ))}
                </Stack>
            </ListItemButton>
        </Permission>
    );
}

const ChipButton = appStyled(ButtonBase)(`
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1;
    padding: 4px 8px;
    border-radius: 2px;
    transition: all 150ms ease;
    cursor: pointer;
    border: none;
  
    &:hover {
      background-color: #eaeef2;
    }
  
    &.${buttonClasses.disabled} {
      background-color: #eaeef2;
    }
  
    &.${buttonClasses.focusVisible} {
      background-color: #eaeef2;
      outline: none;
    }
  
    &.${buttonClasses.disabled} {
      opacity: 0.5;
      cursor: not-allowed;
    }
    `);
