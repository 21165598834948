import * as React from 'react';

import { AppInstallRegistrationId, VehicleDeviceId } from '../types/stein';

import { Box, Dialog, DialogProps, FormLabel, Skeleton, Stack, Typography } from '@mui/material';
import { useActiveProjectSlug } from '../hooks/use-active-project';
import { SteinInternalApiClientToken } from '../clients/stein-internal-api';
import { useFrame } from '../lib/frame-react';
import { usePrevious } from '../hooks/use-previous';
import { Aspect } from './AspectRatio';
import { EnvToken } from '../lib/frame-tokens';
import { AppQrCode, QRPayload } from './AppQrCode';

type VehicleDeviceAppInstallLinkProps = {
    vehicleDeviceId?: VehicleDeviceId;
} & DialogProps;

export function AppInstallRegistrationDialog({
    vehicleDeviceId,
    ...props
}: VehicleDeviceAppInstallLinkProps): React.ReactElement {
    const projectSlug = useActiveProjectSlug();
    const { useCreateAppInstallRegistrationMutation } = useFrame(SteinInternalApiClientToken);
    const [createRegistration, { isError, data: registration }] = useCreateAppInstallRegistrationMutation();
    const wasJustOpened = !usePrevious(props.open) && props.open;
    const [success, setSuccess] = React.useState(false);
    const env = useFrame(EnvToken);

    const registrationToken = registration?.registrationToken;

    React.useEffect(() => {
        if (wasJustOpened) {
            createRegistration({
                projectSlug,
            });
        }
    }, [wasJustOpened, projectSlug, createRegistration]);

    // istanbul ignore next
    const host = env['OBSERVANT_HOST'] ? env['OBSERVANT_HOST'] : `${env['HEROKU_APP_NAME']}.herokuapp.com`;

    const payload = {
        action: 'app_link_vehicle',
        host,
        token: registrationToken,
        vehicleDeviceId,
    };

    return (
        <Dialog
            {...props}
            data-testid={'app-registration-dialog'}
            maxWidth={'xs'}
            PaperProps={{
                sx: {
                    width: '100%',
                },
            }}
        >
            <Box p={2} width={'100%'}>
                {success ? (
                    <Aspect ratio={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {'Registration Successful'}
                    </Aspect>
                ) : (
                    <Stack spacing={2} justifyContent={'center'} alignItems={'center'}>
                        <Typography variant="body1">
                            {vehicleDeviceId
                                ? 'Scan this code with any Observant iPhone to link the device with this vehicle.'
                                : 'Scan this code with any Observant iPhone to register it with this project.'}
                        </Typography>

                        {registration?.registrationToken ? (
                            <QRCodeTest
                                payload={payload}
                                token={registration?.registrationToken}
                                onSuccess={() => setSuccess(true)}
                            />
                        ) : (
                            <>
                                <Skeleton>
                                    <Aspect ratio={1}>
                                        <div>{'.'} </div>
                                    </Aspect>
                                </Skeleton>
                                {isError ? <FormLabel error>{'Could not generate QR code.'}</FormLabel> : null}
                            </>
                        )}
                    </Stack>
                )}
            </Box>
        </Dialog>
    );
}

function QRCodeTest({
    payload,
    token,
    onSuccess,
}: {
    payload: QRPayload;
    onSuccess: () => void;
    token: AppInstallRegistrationId;
}): React.ReactElement {
    const projectSlug = useActiveProjectSlug();

    const { useGetAppInstallRegistrationQuery } = useFrame(SteinInternalApiClientToken);
    const { data } = useGetAppInstallRegistrationQuery(
        {
            projectSlug,
            id: token,
        },
        {
            pollingInterval: 500,
        },
    );

    const wasRegistered = data?.wasRegistered;

    React.useEffect(() => {
        if (wasRegistered) {
            onSuccess();
        }
    }, [wasRegistered, onSuccess]);

    return <AppQrCode payload={payload} />;
}
