import * as React from 'react';

import { Circle, Tooltip } from 'react-leaflet';
import { urlFacilitiesShow, urlVehicleDeviceList } from '../../utils/internal-url-utils';
import { useActiveProjectSlug } from '../../hooks/use-active-project';
import { NavLink } from 'react-router-dom';
import { GeofenceLocated } from '../../utils/filter-utils';
import { VehicleDevice } from '../../types/stein';
import { plural } from '../../utils/string-utils';

export function GeofenceMarker({
    geofence,
    vehicles,
}: {
    geofence: GeofenceLocated;
    vehicles?: VehicleDevice[];
}): React.ReactElement {
    const projectSlug = useActiveProjectSlug();
    const vehiclesInGarage = vehicles?.length || /*istanbul ignore next*/ 0;

    return (
        <Circle
            center={[geofence.latitude, geofence.longitude]}
            radius={geofence.radius}
            pathOptions={{ color: '#2E5EAA', fillColor: '#2E5EAA' }}
        >
            <Tooltip direction="top">
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingRight: 5,
                            paddingLeft: 3,
                        }}
                    >
                        <div>
                            <strong>{geofence.name}</strong>
                        </div>
                        {vehiclesInGarage ? (
                            <>
                                <div>{`${vehiclesInGarage} ${plural(vehiclesInGarage, 'vehicle', 'vehicles')} in facility`}</div>
                                <div>
                                    <NavLink
                                        style={{ color: 'black' }}
                                        to={urlVehicleDeviceList(projectSlug, {
                                            facilities: [geofence.id],
                                        })}
                                    >
                                        show vehicles
                                    </NavLink>
                                </div>
                            </>
                        ) : null}

                        <div>
                            <NavLink style={{ color: 'black' }} to={urlFacilitiesShow(projectSlug, geofence.slug)}>
                                edit facility
                            </NavLink>
                        </div>
                    </div>
                </div>
            </Tooltip>
        </Circle>
    );
}
