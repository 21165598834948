import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConfigItemConfig } from '../../types/stein';
import { ConfigItem } from '../../types/stein.config_items';
import { setRailsContext } from './shared-actions';

export const configItemsAdapter = createEntityAdapter<ConfigItem>({
    sortComparer: (a, b) => a.attributeKey.localeCompare(b.attributeKey),
});

export const configItemsSlice = createSlice({
    name: 'configItems',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState: {
        config: [] as ConfigItemConfig,
        ...configItemsAdapter.getInitialState(),
    },
    reducers: {
        setItems(state, action: PayloadAction<ConfigItem[]>) {
            configItemsAdapter.setMany(state, action.payload);
        },
    },

    extraReducers: (builder) => {
        builder.addCase(setRailsContext, (s, a) => {
            s.config = a.payload.configItemsConfig;
            configItemsAdapter.setMany(s, a.payload.configItems);
        });
    },
});
