import { NotificationListenerDeps } from './notification-listener-types';
import { notificationSlice } from '../../store/slices/notificationSlice';
import { AppNotificationId } from '../../components/Notifications/notificationTypes';
import { AnyAction } from 'redux';
import { Push, VehicleDeviceId } from '../../types/stein';
import { filterUndefinedAndNull } from '../../utils/filter-utils';
import { GetPushesRequest } from '../../types/stein-internal-api';

export function notificationListenerPushMulti(action: AnyAction, deps: NotificationListenerDeps): void {
    const { steinInternalApi, store } = deps;

    if (steinInternalApi.endpoints.createPushMulti.matchFulfilled(action)) {
        const notificationId = action.meta.requestId as AppNotificationId;
        store.dispatch(
            notificationSlice.actions.createNotification({
                type: 'pushMulti',
                args: action.meta.arg.originalArgs,
                id: notificationId,
                successes: [],
                pending: action.meta.arg.originalArgs.vehicleDeviceIds,
                failures: [],
            }),
        );

        const getPushesArgs: GetPushesRequest = {
            projectSlug: action.meta.arg.originalArgs.projectSlug,
            batchUuid: action.payload.batchUuid,
        };
        const getPushesSubscription = store.dispatch(
            steinInternalApi.endpoints.getPushes.initiate(getPushesArgs, {
                subscriptionOptions: {
                    pollingInterval: 1000,
                },
            }),
        );

        const getPushesSelector = steinInternalApi.endpoints.getPushes.select(getPushesArgs);

        const interval = setInterval(() => {
            const res = getPushesSelector(store.getState());
            const { data, isUninitialized, isLoading } = res;

            // istanbul ignore next ignore this because we only test when the data is here
            if (data) {
                const pending = filterPushToVehicleId(
                    data?.pushes,
                    (p) => !p.clientProcessedAt && !p.errorType && !p.clientProcessingErrorCode,
                );

                const failures = filterPushToVehicleId(data?.pushes, (p) =>
                    Boolean(p.clientProcessingErrorCode || p.errorType),
                );
                const successes = filterPushToVehicleId(data?.pushes, (p) =>
                    Boolean(p.clientProcessedAt && !p.clientProcessingErrorCode && !p.errorType),
                );

                store.dispatch(
                    notificationSlice.actions.updateNotification({
                        id: notificationId,
                        pending,
                        failures,
                        successes,
                    }),
                );

                const isPending = Boolean(isUninitialized || isLoading || pending.length);

                // istanbul ignore next
                if (!isPending) {
                    getPushesSubscription.unsubscribe();
                    clearInterval(interval);
                }
            }
        }, 1000);
    }
}

function filterPushToVehicleId(p: Push[] | undefined, fn: (p: Push) => boolean): VehicleDeviceId[] {
    return (
        p
            ?.filter(fn)
            .map((p) => p.vehicleDeviceId)
            .filter(filterUndefinedAndNull) || /* istanbul ignore next */ []
    );
}
