import { NotificationListenerDeps } from './notification-listener-types';
import { notificationSlice } from '../../store/slices/notificationSlice';
import { AppNotificationId } from '../../components/Notifications/notificationTypes';
import { AnyAction } from 'redux';

export function notificationListenerArchive(action: AnyAction, deps: NotificationListenerDeps): void {
    const { steinInternalApi, store } = deps;

    // if (steinInternalApi.endpoints.startArchive.matchRejected(action)) {
    //     store.dispatch(notificationSlice.actions.createNotification({
    //         type: ''
    //     })
    // }

    if (steinInternalApi.endpoints.startArchive.matchFulfilled(action)) {
        const notificationId = action.payload.id as AppNotificationId;
        store.dispatch(
            notificationSlice.actions.createNotification({
                id: notificationId,
                type: 'archive',
                name: action.meta.arg.originalArgs.type,
                archiveId: action.payload.id,
                status: 'pending',
                progress: 0,
            }),
        );

        const getArchiveProgressSubscription = store.dispatch(
            steinInternalApi.endpoints.getArchiveProgress.initiate(action.payload, {
                subscriptionOptions: {
                    pollingInterval: 100,
                },
            }),
        );

        const getStatusSelector = steinInternalApi.endpoints.getArchiveProgress.select(action.payload);

        const interval = setInterval(() => {
            function clear(): void {
                getArchiveProgressSubscription.unsubscribe();
                clearInterval(interval);
            }

            const res = getStatusSelector(store.getState());
            const { data } = res;

            // istanbul ignore next ignore this because we only test when the data is here
            if (data) {
                if (data.status === 'error') {
                    store.dispatch(
                        notificationSlice.actions.updateNotification({ id: notificationId, status: 'error' }),
                    );
                    clear();
                } else if (data.status === 'finished') {
                    store.dispatch(
                        notificationSlice.actions.updateNotification({
                            id: notificationId,
                            status: 'success',
                            url: data.url,
                        }),
                    );
                    clear();
                } else {
                    store.dispatch(
                        notificationSlice.actions.updateNotification({ id: notificationId, progress: data.progress }),
                    );
                }
            }
        }, 1000);
    }
}
