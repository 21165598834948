import { NotificationListenerDeps } from './notification-listener-types';
import { notificationSlice } from '../../store/slices/notificationSlice';
import { actionCableDriverEvent } from '../../store/actions';
import { selectConfigItems, selectProjectById } from '../../store/selectors';
import { urlDriverEventShow } from '../../utils/internal-url-utils';

import { AnyAction } from 'redux';
import { DriverEventId } from '../../types/stein';
import { driverEventsSlice } from '../../store/slices/driverEventsSlice';
import { AppNotificationId } from '../../components/Notifications/notificationTypes';
import { configItemFind } from '../../hooks/use-config-items';
import {
    EVENT_TYPE_DISTRACTION,
    EVENT_TYPE_EARLY_FATIGUE,
    EVENT_TYPE_SEVERE_FATIGUE,
} from '../../constants/event-type-constants';

function eventNotificationId(id: DriverEventId): AppNotificationId {
    return `driver-event-${id}` as AppNotificationId;
}

export function notificationListenerDriverEvent(action: AnyAction, deps: NotificationListenerDeps): void {
    const { store, log, sfx } = deps;

    if (actionCableDriverEvent.match(action)) {
        const state = store.getState();
        const project = selectProjectById(state, action.payload.projectId);
        if (!project) {
            log.error('[NotificationListenerPlugin] received notification with unknown projectId', { action });
            return;
        }

        const ci = configItemFind(selectConfigItems(state), 'driver_event_web_alerts', project.id);
        const eventWhitelist = ci?.jsonValue.eventWhitelist || [
            EVENT_TYPE_DISTRACTION.id,
            EVENT_TYPE_SEVERE_FATIGUE.id,
            EVENT_TYPE_EARLY_FATIGUE.id,
        ];

        if (eventWhitelist.includes(action.payload.eventName)) {
            const eventUrl = urlDriverEventShow(project, action.payload.id);
            sfx['eventReceived']?.play();
            store.dispatch(
                notificationSlice.actions.createNotification({
                    id: eventNotificationId(action.payload.id),
                    type: 'event',
                    event: action.payload,
                    url: eventUrl,
                    dismissConditions: [
                        {
                            type: 'urlVisited',
                            url: eventUrl,
                        },
                    ],
                }),
            );
        }
    }

    if (driverEventsSlice.actions.markAsSeen.match(action)) {
        action.payload.map((id) => {
            store.dispatch(notificationSlice.actions.ackNotification(eventNotificationId(id)));
        });
    }
}
