import * as React from 'react';
import { useActiveProject } from '../../../hooks/use-active-project';
import { SettingsPage } from './components/SettingsShared';

import { NotificationEditor } from './components/NotificationEditor';
import { NotifConfigContextProviderProject } from './components/NotificationEditor/NotifConfigContext';

export function ProjectNotifications(): React.ReactElement {
    const project = useActiveProject();

    return (
        <>
            <div data-testid={'page-settings-project-notifications'} />
            <SettingsPage title={`${project.name} Project Alerts`}>
                <NotifConfigContextProviderProject>
                    <NotificationEditor />
                </NotifConfigContextProviderProject>
            </SettingsPage>
        </>
    );
}
