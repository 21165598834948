import { endOfDay, fromUnixTime, getUnixTime, startOfDay } from 'date-fns';
import { isEqual } from 'lodash';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { useFrame } from '../lib/frame-react';
import { HistoryToken } from '../lib/frame-tokens';
import { FilterValueId } from '../store/slices/filterConfigSlice';
import { DateStrISO8601, DateStrUTCTimestamp, Filters } from '../types/stein';
import { makeQueryString, parseQueryString } from '../utils/query-string';
import { DateRange } from '@mui/x-date-pickers-pro';
import { toISO8601 } from '../utils/datetime-utils';

// istanbul ignore next
function dateStringToUTCTimestamp(d: DateStrISO8601 | null, mod: (d: Date) => Date): DateStrUTCTimestamp | null {
    if (!d) {
        return null;
    }

    const modifiedDate = mod(new Date(d));
    return getUnixTime(modifiedDate).toString() as DateStrUTCTimestamp;
}

function timestampToDate(ts: string): Date {
    return fromUnixTime(parseInt(ts));
}

type UseDateRangeFilterResult = {
    setSelected: (selected: DateRange<Date>) => void;
    selected: DateRange<Date>;
    isDefault: boolean;
};

const DEFAULT_DATE_RANGE = [null, null] as const;
const DATE_RANGE_KEY = 'dateRange' as const;
export function useFilterDateRange(): UseDateRangeFilterResult {
    const [dateRange, setDateRange] = useSearchParam(DATE_RANGE_KEY);

    const start = dateRange?.[0] || null;
    const end = dateRange?.[1] || null;
    const range: DateRange<Date> = [start ? timestampToDate(start) : null, end ? timestampToDate(end) : null];

    const isDefault = isEqual(range, DEFAULT_DATE_RANGE);
    // istanbul ignore next
    function setSelected(newDateRange: DateRange<Date>): void {
        const newStart = newDateRange[0];
        const newEnd = newDateRange[1];
        setDateRange([
            newStart ? dateStringToUTCTimestamp(toISO8601(newStart), startOfDay) : null,
            newEnd ? dateStringToUTCTimestamp(toISO8601(newEnd), endOfDay) : null,
        ]);
    }

    return { setSelected, selected: range, isDefault };
}

type UseDateTimeFilterResult = {
    setDateTime: (selected: Date) => void;
    date: Date | null;
    isDefault: boolean;
};

const DATE_KEY = 'date' as const;
export function useFilterDateTime(): UseDateTimeFilterResult {
    const [dateTime, setDateTime] = useSearchParamSingular(DATE_KEY);

    const isDefault = !dateTime;
    const date = dateTime ? /* istanbul ignore next */ timestampToDate(dateTime) : null;
    // istanbul ignore next
    function setSelected(newDate: Date): void {
        setDateTime(dateStringToUTCTimestamp(toISO8601(newDate), (d) => d));
    }

    return { setDateTime: setSelected, date, isDefault };
}

type GeoLocation = {
    latitude: number;
    longitude: number;
    radius: number;
};

type UseGeoResultFilterResult = {
    setLocation: (selected: GeoLocation) => void;
    location: GeoLocation;
};

export function useFilterGeoLocation(defaultValue: GeoLocation): UseGeoResultFilterResult {
    const [params, setParams] = useSearchParams('latitude', 'longitude', 'radius');
    const location: GeoLocation = {
        ...defaultValue,
    };

    const lat = params.latitude ? parseFloat(params.latitude) : null;
    const lng = params.longitude ? parseFloat(params.longitude) : null;
    const rad = params.radius ? parseInt(params.radius) : null;

    if (lat) {
        location.latitude = lat;
    }
    if (lng) {
        location.longitude = lng;
    }
    if (rad) {
        location.radius = rad;
    }

    // istanbul ignore next
    function setLocation(newLoc: GeoLocation): void {
        setParams({
            latitude: `${newLoc.latitude}`,
            longitude: `${newLoc.longitude}`,
            radius: `${newLoc.radius}`,
        });
    }

    return { setLocation, location };
}

export function shouldIncludeFilter(f: string[] | undefined): f is string[] {
    return Boolean(f && f.length);
}

function resolveFilterValue(value: null | string[] | string): string[] | null {
    if (value) {
        if (typeof value !== 'object') {
            return [value];
        } else {
            return value;
        }
    }
    return null;
}

function resolveFilterValueSingular(value: null | string[] | string): string | null {
    if (value) {
        if (Array.isArray(value)) {
            return value[0];
        } else {
            return value;
        }
    }
    return null;
}

export function resolveFilterValueNum(
    value: string | string[] | undefined,
    defaultValue?: number | undefined,
): number | undefined {
    return (
        parseInt(
            Array.isArray(value) ? /* istanbul ignore file */ value[0] : value ? /* istanbul ignore file */ value : '',
        ) || defaultValue
    );
}

type UseSearchParamResult = [null | FilterValueId[], (selected: (FilterValueId | null)[] | null) => void];
export function useSearchParam(name: string): UseSearchParamResult {
    const { search, pathname } = useLocation();
    const history = useFrame(HistoryToken);

    const value = React.useMemo(() => {
        const params = parseQueryString(search);
        return resolveFilterValue(params[name]) || null;
    }, [search, name]);

    const update = React.useCallback(
        (value: string[] | null) => {
            const params = parseQueryString(search);
            if (null === value) {
                delete params[name];
            } else {
                params[name] = value;
            }
            const newSearch = makeQueryString(params);
            history.replace(`${pathname}?${newSearch}`);
        },
        [search, pathname, history, name],
    );

    return [value, update];
}

type UseSearchParamsResult = [Record<string, string | null>, (selected: Record<string, string | null>) => void];

export function useSearchParams(...names: string[]): UseSearchParamsResult {
    const { search, pathname } = useLocation();
    const history = useFrame(HistoryToken);

    const values = React.useMemo(() => {
        const params = parseQueryString(search);
        return names.reduce(
            (values, name) => {
                values[name] = resolveFilterValueSingular(params[name]);
                return values;
            },
            {} as Record<string, string | null>,
        );
    }, [search, names]);

    const update = React.useCallback(
        // istanbul ignore next
        (values: Record<string, string | null>) => {
            const params = parseQueryString(search);
            Object.entries(values).forEach(([key, value]) => {
                if (null === value) {
                    delete params[key];
                } else {
                    params[key] = value;
                }
            });

            const newSearch = makeQueryString(params);
            history.replace(`${pathname}?${newSearch}`);
        },
        [search, pathname, history, name],
    );

    return [values, update];
}

export function useSearchParamSingular(name: string): [string | null, (v: string | null) => void] {
    const [vals, setVals] = useSearchParam(name);
    const val = vals ? /* istanbul ignore next */ vals[0] : null;

    /* istanbul ignore next */
    function setVal(v: string | null): void {
        /* istanbul ignore next */
        setVals(typeof v === 'string' ? [v] : null);
    }

    return [val, setVal];
}

type UseSearchParamSingleResult = [null | number, (selected: number | null) => void];
export function useSearchParamNumber(name: string): UseSearchParamSingleResult {
    const [values, setValues] = useSearchParam(name);

    let value = null;
    if (values?.length && parseInt(values[0])) {
        value = parseInt(values[0]);
    }

    const setNumberValue = React.useCallback(
        (s: number | null) => setValues(s === null ? null : [`${s}`]),
        [setValues],
    );

    return [value, setNumberValue];
}

type UseFiltersResult = {
    filters: Filters;
    updateFilters: (updates: Filters) => void;
    setFilters: (filters: Filters) => void;
};

export function useFilters(): UseFiltersResult {
    const { search, pathname } = useLocation();
    const history = useFrame(HistoryToken);

    const filters = React.useMemo(() => {
        const filters: Filters = {};
        const params = parseQueryString(search);

        for (const key of Object.keys(params)) {
            filters[key] = resolveFilterValue(params[key]) || [];
        }
        return filters;
    }, [search]);

    const setFilters = React.useCallback(
        (filters?: Filters) => {
            const newSearch = makeQueryString({
                ...filters,
            });
            history.replace(`${pathname}?${newSearch}`);
        },
        [pathname, history],
    );

    const updateFilters = React.useCallback(
        (filters: Filters) => {
            const newSearch = makeQueryString({
                ...parseQueryString(search),
                ...filters,
            });
            history.replace(`${pathname}?${newSearch}`);
        },
        [search, pathname, history],
    );

    return { filters, updateFilters, setFilters };
}
