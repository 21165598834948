import * as React from 'react';
import { AppIconBattery, AppIconBolt, AppIconBoltOutline, AppIconPast, AppIconQuestion } from '../AppIcons';
import { PaletteColor, Tooltip } from '@mui/material';
import { useAppTheme } from '../../theme';
import { BatteryState, BATTERY_STATE } from '../../constants/battery-status-constants';
import { DecimalPercentage } from '../../types/util-types';
import { UIStack, UIStackIconLayer, UIStackLayer } from '../UIStack';
import { TooltipContent } from '../TooltipContent';

type BatteryStatusIndicatorProps = {
    isOnline: boolean;
    batteryLevel: DecimalPercentage | null;
    batteryState: BatteryState | null;
};

function useColor(isPluggedIn: boolean, level: DecimalPercentage | null): PaletteColor {
    const theme = useAppTheme();
    if (isPluggedIn) {
        return theme.palette.success;
    }

    if (level && level > 0.5) {
        return theme.palette.warning;
    }
    return theme.palette.error;
}

function getTooltip({ isOnline, batteryLevel, batteryState }: BatteryStatusIndicatorProps): React.ReactElement {
    const title = isOnline ? 'Current Battery State' : 'Last Known Battery State';

    let state = 'Unknown';
    let percent = null;

    if (batteryLevel !== null && batteryState !== null) {
        percent = Math.round(batteryLevel * 100);

        if (batteryState === BATTERY_STATE.CHARGING) {
            state = `Charging`;
        } else if (batteryState === BATTERY_STATE.FULL) {
            state = `Full`;
        } else if (batteryState === BATTERY_STATE.UNPLUGGED) {
            state = `Unplugged`;
        }
    }

    return (
        <TooltipContent title={title}>
            {percent ? <p>{`${percent}% Charged`}</p> : null}
            <p>{state}</p>
        </TooltipContent>
    );
}

export function BatteryStatusIndicator({
    isOnline,
    batteryLevel,
    batteryState,
}: BatteryStatusIndicatorProps): React.ReactElement {
    const percent = Math.round((batteryLevel || 0) * 100);
    const isPluggedIn = batteryState === BATTERY_STATE.CHARGING || batteryState === BATTERY_STATE.FULL;
    const isUnknown = batteryState === null || batteryLevel === null || batteryState === BATTERY_STATE.UNKNOWN;
    const color = useColor(isPluggedIn, batteryLevel);
    const tooltip = getTooltip({ isOnline, batteryLevel, batteryState });

    return (
        <Tooltip title={tooltip}>
            <UIStack>
                <UIStackIconLayer icon={<AppIconBattery size={'20px'} />} yOffset={2} />
                <UIStackLayer>
                    <div
                        style={{
                            transform: 'translate(-1.5px, 1.8px)',
                            width: '15px',
                            height: '8px',
                            borderRadius: '2px',
                            overflow: 'hidden',
                        }}
                    >
                        <div
                            style={{
                                height: '100%',
                                width: `${percent}%`,
                                backgroundColor: color.main,
                            }}
                        />
                    </div>
                </UIStackLayer>

                {isOnline ? (
                    isPluggedIn ? (
                        <>
                            <UIStackIconLayer
                                xOffset={-2}
                                yOffset={1.5}
                                icon={<AppIconBolt size={'11px'} color={color.dark} />}
                            />
                            <UIStackIconLayer
                                xOffset={-2}
                                yOffset={1.5}
                                icon={<AppIconBoltOutline size={'11px'} color={color.contrastText} />}
                            />
                        </>
                    ) : isUnknown ? (
                        <UIStackIconLayer
                            xOffset={-2}
                            yOffset={1.5}
                            icon={<AppIconQuestion size={'11px'} color={color.dark} />}
                        />
                    ) : null
                ) : (
                    <UIStackIconLayer
                        xOffset={-2}
                        yOffset={1.5}
                        icon={<AppIconPast size={'11px'} color={color.dark} />}
                    />
                )}
            </UIStack>
        </Tooltip>
    );
}
