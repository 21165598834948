import * as React from 'react';
import { Tooltip } from '@mui/material';

import { AppIconInfo } from './AppIcons';

type InfoTipProps = {
    text: string;
} & React.HTMLAttributes<HTMLDivElement>;

export function InfoTip({ text, ...divProps }: InfoTipProps): React.ReactElement {
    return (
        <Tooltip title={text}>
            <div {...divProps}>
                <AppIconInfo />
            </div>
        </Tooltip>
    );
}
