import * as React from 'react';
import { FeatureGroup as FeatureGroupType } from 'leaflet';
import { MapContainer, FeatureGroup } from 'react-leaflet';
import { useAppSelector } from '../../../hooks/hooks';
import { useActiveProjectSlug } from '../../../hooks/use-active-project';

import { useForceRefresh } from '../../../hooks/use-force-refresh';
import { selectGeofencesByProjectSlug, selectVehiclesByProjectSlug } from '../../../store/selectors';
import { GeofenceId, VehicleDevice } from '../../../types/stein';
import { MapTiles } from '../../Map/MapTiles';
import { AppIconRefresh } from '../../AppIcons';
import { appStyled } from '../../../theme';
import { filterGeofenceLocated } from '../../../utils/filter-utils';
import { AutoCenterGroup } from '../../Map/AutoCenter';
import { Button } from '@mui/material';
import { CustomControl } from '../../Map/CustomControl';
import { MapInteraction } from '../../Map/MapInteraction';
import { VehicleMarker } from '../../Map/VehicleMarker';
import { GeofenceMarker } from '../../Map/GeofenceMarker';
import { useFrame } from '../../../lib/frame-react';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';

const MapWrapper = appStyled('div')({
    height: '100%',
    '& .leaflet-div-icon': {
        background: 'none',
        border: 'none',
    },
});

export function MapPage(): React.ReactElement {
    const projectSlug = useActiveProjectSlug();
    const vehicles = useAppSelector((s) => selectVehiclesByProjectSlug(s, projectSlug)).filter(
        (v) => v.lastLatitude && v.lastLongitude,
    );

    const { useGetVehicleDevicesQuery } = useFrame(SteinInternalApiClientToken);
    useGetVehicleDevicesQuery({ projectSlug, isBackground: false });

    const geofences = useAppSelector((s) => selectGeofencesByProjectSlug(s, projectSlug)).filter(filterGeofenceLocated);
    const refresh = useForceRefresh();
    const groupRef = React.useRef<FeatureGroupType<unknown>>(null);
    const [autoMap, setAutoMap] = React.useState(true);

    const [vehiclesDriving, vehiclesGeofenced] = React.useMemo(() => {
        const geofencedVehicles: Record<GeofenceId, VehicleDevice[]> = {};
        const activeVehciles: VehicleDevice[] = [];

        for (const v of vehicles) {
            const maintenanceGeofenceIds = v.geofenceIds?.filter((id) => {
                const g = geofences.find((g) => g.id === id);
                /* istanbul ignore if */
                if (!g) {
                    return false;
                }

                return g.maintenancePermitted;
            });

            if (maintenanceGeofenceIds?.length) {
                maintenanceGeofenceIds.forEach((geofenceId) => {
                    // istanbul ignore next
                    if (geofenceId in geofencedVehicles) {
                        // istanbul ignore next
                        geofencedVehicles[geofenceId].push(v);
                    } else {
                        geofencedVehicles[geofenceId] = [v];
                    }
                });
            } else {
                activeVehciles.push(v);
            }
        }
        return [activeVehciles, geofencedVehicles];
    }, [vehicles]);

    //istanbul ignore next
    function enableAutoMap(): void {
        //istanbul ignore next
        setAutoMap(true);
    }

    //istanbul ignore next
    function disableAutoMap(): void {
        //istanbul ignore next
        setAutoMap(false);
    }

    React.useEffect(() => {
        // istanbul ignore else
        if (autoMap) {
            refresh();
        }
    }, [vehiclesDriving.length]);

    return (
        <MapWrapper data-testid={'page-map'}>
            <MapContainer
                worldCopyJump={true}
                attributionControl={false}
                zoom={13}
                center={[33.4942, 111.9261]}
                style={{
                    height: '100%',
                }}
                whenReady={refresh}
            >
                {!autoMap ? (
                    /*istanbul ignore next*/ <CustomControl position="topright">
                        <Button color="inherit" onClick={enableAutoMap} sx={{ backgroundColor: 'white' }}>
                            <AppIconRefresh />
                        </Button>
                    </CustomControl>
                ) : null}
                <AutoCenterGroup groupRef={groupRef} enable={autoMap} />
                <MapTiles />
                <FeatureGroup ref={groupRef} eventHandlers={{ add: refresh, remove: refresh }}>
                    {vehiclesDriving.map((v) => (
                        <VehicleMarker vehicle={v} key={v.id} />
                    ))}
                    {geofences.map((g) => (
                        <GeofenceMarker key={g.id} geofence={g} vehicles={vehiclesGeofenced[g.id]} />
                    ))}
                </FeatureGroup>

                <MapInteraction onInteraction={disableAutoMap} />
            </MapContainer>
        </MapWrapper>
    );
}
