import * as React from 'react';

import { Collapse, Dialog, Grow } from '@mui/material';

import { appStyled } from '../../../../theme';

type NotificationItemDetailProps = {
    children: React.ReactElement;
};

const ShowMore = appStyled('div')(({ theme }) => ({
    ...theme.typography.button,
    color: theme.palette.primary.main,
    fontSize: '0.1em',
    paddingTop: theme.spacing(1),
    cursor: 'pointer',
}));

// istanbul ignore next
export function NotificationItemDetail({ children }: NotificationItemDetailProps): React.ReactElement {
    // istanbul ignore next
    const [showMore, setShowMore] = React.useState(false);

    // istanbul ignore next
    return (
        <>
            <Collapse in={showMore}>
                <Grow in={showMore}>{children}</Grow>
            </Collapse>
            <ShowMore onClick={() => setShowMore(!showMore)}>{showMore ? 'collapse' : 'show detail'}</ShowMore>
        </>
    );
}

type NotificationItemDetailDialogProps = {
    children: (onClose: () => void) => React.ReactElement;
};

export function NotificationItemDetailDialog({ children }: NotificationItemDetailDialogProps): React.ReactElement {
    const [showMore, setShowMore] = React.useState(false);

    function onClose(): void {
        setShowMore(false);
    }

    return (
        <>
            <Dialog open={showMore} onClose={/* istanbul ignore next */ () => setShowMore(false)}>
                <div style={{ minWidth: '500px' }}>{children(onClose)}</div>
            </Dialog>
            <ShowMore onClick={() => setShowMore(true)}>{'show detail'}</ShowMore>
        </>
    );
}
