import * as React from 'react';
import { MultiVideoController } from '../../../../../lib/multi-video/multi-video';
import { DateTimestampMs } from '../../../../../types/stein';
import { useResizeDetector } from 'react-resize-detector';
import { hoursToMilliseconds } from 'date-fns';

type TimelineContextState = {
    startTime: DateTimestampMs;
    durationMs: number;
    zoom: number;
};

type TimelineContext = TimelineContextState & {
    cursorTime: DateTimestampMs | undefined;
    setCursorTime: React.Dispatch<React.SetStateAction<DateTimestampMs | undefined>>;
    controller: MultiVideoController;
    visibleWidthPx: number;
};

const timelineContext = React.createContext<TimelineContext>(
    // @ts-expect-error this can't be null, but we set it first in TimelineProvider
    null,
);

export function TimelineProvider({
    children,
    controller,
    startTime,
    durationMs,
    zoom,
}: {
    children: React.ReactNode;
    controller: MultiVideoController;
    startTime: DateTimestampMs;
    durationMs?: number;
    zoom?: number;
}): React.ReactElement {
    const [cursorTime, setCursorTime] = React.useState<DateTimestampMs | undefined>(undefined);
    const duration = durationMs || hoursToMilliseconds(24);
    const { width, ref } = useResizeDetector();

    // The negative 50 here is to make up for the space the chevron left button takes up
    const visibleWidthPx = width ? /* istanbul ignore next */ width - 50 : 10;

    return (
        <timelineContext.Provider
            value={{
                startTime,
                zoom: zoom || /* istanbul ignore next*/ 1,
                durationMs: duration,
                cursorTime,
                setCursorTime,
                controller,
                visibleWidthPx,
            }}
        >
            <div ref={ref} style={{ width: '100%', height: '100%' }}>
                {children}
            </div>
        </timelineContext.Provider>
    );
}

type TimelineHelpers = {
    getPositionLeft: (t: DateTimestampMs) => string;
    durationMsToWidth: (ms: number) => string;
    timeFromPosition: (n: number) => DateTimestampMs;
    commitCursorTime: () => void;
    visibleDurationMs: number;
} & TimelineContext;

export function useTimelineContext(): TimelineHelpers {
    const context = React.useContext(timelineContext);
    const { controller, startTime, durationMs, cursorTime, zoom, visibleWidthPx } = context;
    const visibleDurationMs = durationMs / zoom;

    const getPositionLeft = React.useCallback(
        function getPositionLeftImpl(x: DateTimestampMs) {
            return `${((x - startTime) * visibleWidthPx) / visibleDurationMs}px`;
        },
        [startTime, visibleDurationMs, visibleWidthPx],
    );

    const timeFromPosition = React.useCallback(
        // istanbul ignore next
        function timeFromPositionImpl(p: number) {
            // istanbul ignore next
            return (startTime + (p / visibleWidthPx) * visibleDurationMs) as DateTimestampMs;
        },
        [startTime, visibleDurationMs, visibleWidthPx],
    );

    const durationMsToWidth = React.useCallback(
        function getOffsetImpl(ms: number) {
            return `${(ms / durationMs) * 100}%`;
        },
        [startTime, durationMs],
    );

    const commitCursorTime = React.useCallback(
        // istanbul ignore next
        function commitCursorTimeImpl(): void {
            // istanbul ignore next
            cursorTime && controller.seek(cursorTime);
        },
        [cursorTime, controller],
    );

    return {
        ...context,
        getPositionLeft,
        durationMsToWidth,
        timeFromPosition,
        commitCursorTime,
        visibleDurationMs,
    };
}
