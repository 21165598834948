import { Tooltip } from '@mui/material';
import { differenceInMilliseconds } from 'date-fns';
import * as React from 'react';
import { appStyled } from '../../theme';
import { AppIconComponent } from '../AppIcons';

type TimelineItem = {
    id: string;
    startTime: Date;
    endTime: Date;
    icon?: AppIconComponent;
    text?: string;
    tooltip?: React.ReactNode;
    color: string;
};

type TimelineChartProps = {
    items: TimelineItem[];
    collapsed: boolean;
};

const TimelineChartRows = appStyled('div')(({ theme }) => ({
    '& > div': {
        marginBottom: theme.spacing(0.5),
    },
    flex: 1,
}));

const TimelineChartItem = appStyled('div')(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    fontSize: '0.75em',
    minWidth: '18px',
    ':hover': {
        boxShadow: theme.shadows[3],
    },
}));

export function TimelineChart({ items, collapsed }: TimelineChartProps): React.ReactElement {
    const startTimeMs = Math.min(...items.map((i) => i.startTime.getTime()));
    const endTimeMs = Math.max(...items.map((i) => i.endTime.getTime()));
    const duration = endTimeMs - startTimeMs;
    return (
        <TimelineChartRows>
            {items.map((i) => {
                const width = (differenceInMilliseconds(i.endTime, i.startTime) / duration) * 100;
                const startPadding = ((i.startTime.getTime() - startTimeMs) / duration) * 100;
                return (
                    <Tooltip title={i.tooltip ? i.tooltip : ''} key={i.id} placement={'top'} arrow>
                        <TimelineChartItem
                            style={{
                                width: `${width}%`,
                                backgroundColor: i.color,
                                left: `${startPadding}%`,
                                minHeight: '5px',
                            }}
                        >
                            {collapsed ? null : (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {i.icon ? <i.icon /> : null}
                                    {i.text ? i.text : null}
                                </div>
                            )}
                        </TimelineChartItem>
                    </Tooltip>
                );
            })}
        </TimelineChartRows>
    );
}
