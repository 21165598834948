import { ElementOfArray } from '../types/util-types';

const EVENT_ANALYSIS_VERIFIED = {
    name: 'Verified',
    id: 'verified',
    icon: 'fa-check-circle',
    color: '#21c5dd',
} as const;

const EVENT_ANALYSIS_FALSE_POSITIVE = {
    name: 'False Positive',
    id: 'false_positive',
    icon: 'fa-times-circle',
    color: '#c30000',
} as const;

const EVENT_ANALYSIS_INDETERMINATE = {
    name: 'Indeterminate',
    id: 'indeterminate',
    icon: 'fa-question-circle',
    color: '#7b7b7b',
} as const;

const EVENT_ANALYSIS_DEFAULT = {
    name: 'Unreviewed',
    id: '',
    color: '#7b7b7b',
} as const;

export const EVENT_ANALYSIS = [
    EVENT_ANALYSIS_DEFAULT,
    EVENT_ANALYSIS_VERIFIED,
    EVENT_ANALYSIS_FALSE_POSITIVE,
    EVENT_ANALYSIS_INDETERMINATE,
];
export type EventAnalysis = ElementOfArray<typeof EVENT_ANALYSIS>;
export type EventAnalysisId = EventAnalysis['id'];
