import * as React from 'react';

import Bugsnag from '@bugsnag/browser';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { Stack } from '@mui/system';
import { ErrorPage } from './pages/ErrorPage';
import { Typography } from '@mui/material';
Bugsnag.start({
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ['review', 'staging', 'production'],
    apiKey: '00000000000000000000000000000000',
    releaseStage: 'local',
    // @ts-expect-error this is injected by app/views/layouts/_head_v2.html.haml and overrides the other settings
    ...BUGSNAG_GLOBAL_CONFIG,
});

//istanbul ignore next
if (typeof window !== undefined) {
    //istanbul ignore next
    // @ts-expect-error this is injected for debugging
    window.Bugsnag = Bugsnag;
}

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);

function FallbackComponent(): React.ReactElement {
    return (
        <ErrorPage
            type={'error'}
            content={
                <Stack spacing={2} textAlign={'center'}>
                    <Typography variant="h5">Observant Dashboard has encountered an error</Typography>
                    <Typography variant="body1">
                        The Observant team has been notified. Please refresh the page and try again.
                    </Typography>
                </Stack>
            }
        />
    );
}

// ErrorBoundary
export function ErrorReporting({ children }: { children: React.ReactNode }): React.ReactElement {
    // React.useEffect(() => {
    //     const prevOnError = window.onerror;
    //     window.onerror = function onerror(messageOrEvent, url, lineNo, charNo, error) {
    //         console.error('ErrorReporting Caught Error', {
    //             messageOrEvent,
    //             url,
    //             lineNo,
    //             charNo,
    //             error,
    //         });
    //         // istanbul ignore next
    //         if (typeof prevOnError === 'function')
    //             prevOnError.apply(this, [messageOrEvent, url, lineNo, charNo, error]);

    //         return true;
    //     };
    // }, []);

    //
    return ErrorBoundary ? (
        <ErrorBoundary FallbackComponent={FallbackComponent}>{children}</ErrorBoundary>
    ) : (
        // istanbul ignore next
        <FallbackComponent />
    );
}

ErrorReporting.displayName = 'ErrorReporting';

type BrowserBugsnagStatic = typeof Bugsnag;

// istanbul ignore next
export function getBugsnag(): BrowserBugsnagStatic {
    // istanbul ignore next
    return Bugsnag;
}
