import * as React from 'react';
import { useAppTheme } from '../../theme';
import { VehicleDevice } from '../../types/stein';
import { AppIconAlertsMuted } from '../AppIcons';
import { TooltipContent } from '../TooltipContent';
import { Tooltip } from '@mui/material';

type AlertsMutedIndicatorProps = {
    vehicle: VehicleDevice;
};
export function AlertsMutedIndicator({ vehicle }: AlertsMutedIndicatorProps): React.ReactElement {
    const theme = useAppTheme();

    if (!vehicle.muted) {
        return <></>;
    }

    return (
        <Tooltip
            style={{ width: 'auto' }}
            title={
                <TooltipContent title={'Alerts Muted'}>
                    <p>{'Observant will not play alerts or messages in this vehicle.'}</p>
                </TooltipContent>
            }
        >
            <AppIconAlertsMuted size="16px" color={theme.palette.warning.main} />
        </Tooltip>
    );
}
