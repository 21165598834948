// istanbul ignore file

import * as React from 'react';
import { Box, Button, Slider, Stack } from '@mui/material';
import { VideoCall, VideoCallState } from '../../../../services/video-call-service/video-call';
import { AppIconMicMute, AppIconMicUnmute, AppIconMuted, AppIconRemoveItem, AppIconUnmuted } from '../../../AppIcons';
import { VideoPlayerBase } from '../../../VideoPlayer/VideoPlayerBase';
import { VideoMultiControl } from '../../../VideoPlayer/VideoControlLayouts';
import { IconButtonSimple } from '../../../material/IconButtonSimple';
import { VehicleChip } from '../../../AppChip';
import { AudioTrack } from 'twilio-video';

export function VideoCallDisplay({ call }: { call: VideoCall }): React.ReactElement {
    const [callState, setCallState] = React.useState<VideoCallState>(call.getState());
    const [muted, setMuted] = React.useState(true);
    const [volume, setVolume] = React.useState(0);

    React.useEffect(() => {
        if (volume > 0.05) {
            setMuted(false);
        } else {
            setMuted(true);
        }
    }, [volume]);

    React.useEffect(() => {
        if (!muted && volume < 0.05) {
            setVolume(0.3);
        }
    }, [muted]);

    React.useEffect(() => {
        function updateState(): void {
            setCallState(call.getState());
        }
        call.on('changed', updateState);
        return () => void call.off('changed', updateState);
    }, [call, setCallState]);

    const videoRef = React.useRef<HTMLVideoElement>(null);
    const containerRef = React.useRef<HTMLDivElement>(null);

    const { status, localAudioStatus, videoTracks, audioTracks } = callState;
    const videoTrack = videoTracks.length ? videoTracks[0] : null;

    React.useEffect(
        function videoTrackAttach(): () => void {
            const track = videoTrack;
            track?.attach(videoRef.current || /*istanbul ignore next*/ undefined);
            return function unmount(): void {
                track?.detach();
            };
        },
        [videoTrack],
    );
    const overlayNoise = !videoTrack && status === 'active';
    const overlayTextB = videoTrack
        ? undefined
        : status === 'initializing'
          ? 'connecting...'
          : status === 'active'
            ? 'Waiting for participant'
            : status === 'reconnecting'
              ? 'Reconnecting'
              : status === 'error'
                ? 'an error has occurred'
                : status === 'ended'
                  ? 'call has ended'
                  : undefined;

    const overlayCentered = (
        <Stack
            direction="row"
            padding={1}
            spacing={1}
            key={'vehicle-chip'}
            sx={{
                justifyContent: 'center',
                color: 'white',
                flexGrow: 0,
            }}
        >
            <VehicleChip vehicleSlugOrId={call.vehicleDeviceId} color="#fff" />
            {muted ? null : (
                <Box
                    sx={{
                        borderRadius: 16,
                        height: '32px',
                        width: '32px',
                        backgroundColor: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'black',
                    }}
                >
                    <AppIconUnmuted />
                </Box>
            )}
            {localAudioStatus === 'unmuted' ? (
                <Box
                    sx={{
                        borderRadius: 16,
                        height: '32px',
                        width: '32px',
                        backgroundColor: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'black',
                    }}
                >
                    <AppIconMicMute />
                </Box>
            ) : null}
        </Stack>
    );

    // if (!muted) {
    //     overlays.push(
    //         <Box
    //             padding={1}
    //             sx={{
    //                 display: 'flex',
    //                 left: 0,
    //                 right: 0,
    //                 justifyContent: 'center',
    //                 color: 'green',
    //                 alignItems: 'flex-end',
    //             }}
    //         ></Box>,
    //     );
    // }

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
            }}
        >
            {audioTracks.map((a) => (
                <Audio track={a} mute={muted} key={a.sid} volume={volume} />
            ))}
            <VideoPlayerBase
                videoRef={videoRef}
                containerRef={containerRef}
                autoPlay={true}
                muted={true}
                overlayCentered={overlayCentered}
                overlayNoise={overlayNoise}
                overlayTextB={overlayTextB}
                hideControlsUnlessHover
                controls={
                    <VideoMultiControl
                        backgroundColor="none"
                        key={status}
                        topRight={
                            <IconButtonSimple onClick={() => call.service.removeCall(call.vehicleDeviceId)}>
                                <AppIconRemoveItem />
                            </IconButtonSimple>
                        }
                        bottom={
                            status === 'active' ? (
                                <>
                                    {localAudioStatus === 'disconnected' ? (
                                        <Button
                                            onClick={() => call.joinAudio()}
                                            size={'small'}
                                            color={'error'}
                                            variant={'contained'}
                                            endIcon={<AppIconMicMute />}
                                        >
                                            Talk
                                        </Button>
                                    ) : localAudioStatus === 'muted' ? (
                                        <Button
                                            onClick={() => call.setMute(false)}
                                            size={'small'}
                                            color={'error'}
                                            variant={'contained'}
                                            endIcon={<AppIconMicMute />}
                                        >
                                            Talk
                                        </Button>
                                    ) : (
                                        <Button
                                            onClick={() => call.setMute(true)}
                                            size={'small'}
                                            color={'info'}
                                            variant={'outlined'}
                                            endIcon={<AppIconMicUnmute />}
                                        >
                                            Mute
                                        </Button>
                                    )}
                                </>
                            ) : null
                        }
                        bottomRight={
                            audioTracks.length || true ? (
                                <Stack
                                    direction={'column'}
                                    spacing={1}
                                    sx={{ p: 1, backgroundColor: 'rgba(255,255,255,0.4)', borderRadius: 1 }}
                                >
                                    <Slider
                                        min={0}
                                        max={1}
                                        step={0.01}
                                        value={muted ? 0 : volume}
                                        orientation={'vertical'}
                                        onChange={(e, v) => {
                                            setVolume(Array.isArray(v) ? v[0] : v);
                                        }}
                                        sx={{
                                            mb: 2,
                                            color: 'white',
                                            height: '100px',
                                            '& .MuiSlider-thumb': {
                                                width: 8,
                                                height: 8,
                                                transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                                                '&::before': {
                                                    boxShadow: '0 2px 8px 0 rgba(0,0,0,0.4)',
                                                },
                                                '&:hover, &.Mui-focusVisible': {
                                                    boxShadow: 'none',
                                                },
                                                '&.Mui-active': {
                                                    width: 10,
                                                    height: 10,
                                                },
                                            },
                                            '& .MuiSlider-rail': {
                                                opacity: 0.28,
                                            },
                                        }}
                                    ></Slider>
                                    <IconButtonSimple onClick={() => setMuted((m) => !m)}>
                                        {muted ? <AppIconMuted /> : <AppIconUnmuted />}
                                    </IconButtonSimple>
                                </Stack>
                            ) : null
                        }
                    />
                }
            />
        </Box>
    );
}

function Audio({ track, mute, volume }: { track: AudioTrack; mute: boolean; volume: number }): React.ReactElement {
    const audioRef = React.useRef<HTMLAudioElement>(null);
    React.useEffect(
        function audioTrackAttachRef(): () => void {
            track.attach(audioRef.current || /*istanbul ignore next*/ undefined);
            return function unmount(): void {
                track.detach();
            };
        },
        [track],
    );

    React.useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = Math.min(volume, 1);
        }
    }, [volume]);

    return <audio ref={audioRef} autoPlay={true} muted={mute} />;
}
