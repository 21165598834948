import { Tooltip } from '@mui/material';
import * as React from 'react';
import { useAppTheme } from '../../theme';
import { VehicleDevice } from '../../types/stein';
import { AppIconIOSUpdateNeeded, AppIconProps } from '../AppIcons';
import { TooltipContent } from '../TooltipContent';

type IOSUpdateIndicatorProps = {
    vehicle?: VehicleDevice;
    showVersionIfNoUpdate?: boolean;
} & Partial<AppIconProps>;
export function IOSUpdateIndicator({
    vehicle,
    showVersionIfNoUpdate,
    ...props
}: IOSUpdateIndicatorProps): React.ReactElement | null {
    const theme = useAppTheme();

    return vehicle?.belowMinRequiredOperatingSystemVersion ? (
        <Tooltip
            title={
                <TooltipContent title={'IOS Update Needed'}>
                    <p>
                        {vehicle.operatingSystemVersion
                            ? `This vehicle's device is running iOS ${vehicle.operatingSystemVersion}, which is unsupported.`
                            : "This vehicle's iPhone is running an outdated version of iOS."}
                    </p>
                    <p>
                        {
                            'Observant will continue to run, however this iPhone may experience degraded performance. Please update to the latest iOS as soon as possible.'
                        }
                    </p>
                </TooltipContent>
            }
        >
            <AppIconIOSUpdateNeeded color={theme.palette.error.main} {...props} />
        </Tooltip>
    ) : showVersionIfNoUpdate ? (
        <>{vehicle?.operatingSystemVersion}</>
    ) : null;
}
