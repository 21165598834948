import * as React from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    TextField,
} from '@mui/material';
import { useActiveProjectSlug } from '../../../hooks/use-active-project';

import { SettingsPage } from './components/SettingsShared';
import { selectRegionsByProjectSlug } from '../../../store/selectors';
import { useAppSelector } from '../../../hooks/hooks';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFrame } from '../../../lib/frame-react';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import { AppIconEdit, AppIconNew, AppIconTrash } from '../../AppIcons';
import { useConfirmationDialog } from '../../../hooks/use-confirmation-dialog';
import { Region } from '../../../types/stein';
import { useStagedChanges } from '../../../hooks/use-staged-changes';

function RegionItem({ region }: { region: Region }): React.ReactElement {
    const [editOpen, setEditOpen] = React.useState(false);
    const { useDeleteRegionMutation } = useFrame(SteinInternalApiClientToken);
    const [deleteRegion, { isLoading: isDeleting }] = useDeleteRegionMutation();
    const confirmDelete = useConfirmationDialog({
        func: () => deleteRegion(region.id),
        message: `Are you sure you want to delete region: ${region.name}?`,
        buttonText: 'Delete',
    });
    return (
        <>
            <ListItem>
                <ListItemText primary={region.name} secondary={region.description} />
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="Edit Region" onClick={() => setEditOpen(true)}>
                        <AppIconEdit />
                    </IconButton>{' '}
                    <IconButton edge="end" aria-label="Delete Region" onClick={confirmDelete.handleShouldConfirm}>
                        {isDeleting ? <CircularProgress /> : <AppIconTrash />}
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>

            {confirmDelete.node}
            <EditRegion open={editOpen} onClose={() => setEditOpen(false)} region={region} maxWidth={'md'} fullWidth />
        </>
    );
}

export function SettingsRegions(): React.ReactElement | null {
    const projectSlug = useActiveProjectSlug();
    const regions = useAppSelector((s) => selectRegionsByProjectSlug(s, projectSlug));

    const [isCreateOpen, setIsCreateOpen] = React.useState(false);
    function onClose(): void {
        setIsCreateOpen(false);
    }

    return (
        <>
            <div data-testid={'page-settings-regions'} />
            <SettingsPage title={`Regions`}>
                <List>
                    {regions?.map((r) => <RegionItem key={r.id} region={r} />)}
                    <ListItemButton onClick={() => setIsCreateOpen(true)}>
                        <ListItemIcon>
                            <AppIconNew />
                        </ListItemIcon>
                        <ListItemText primary={'New'} />
                    </ListItemButton>
                </List>

                <CreateRegion open={isCreateOpen} maxWidth={'md'} fullWidth onClose={onClose} />
            </SettingsPage>
        </>
    );
}

function CreateRegion(props: DialogProps): React.ReactElement {
    const [name, setName] = React.useState('');
    const [description, setDescription] = React.useState('');
    const { useCreateRegionMutation } = useFrame(SteinInternalApiClientToken);
    const projectSlug = useActiveProjectSlug();

    const [create, { isLoading }] = useCreateRegionMutation();

    return (
        <Dialog {...props}>
            <DialogTitle>Create Region</DialogTitle>
            <DialogContent>
                <Stack spacing={2} alignItems={'center'} flex={1} data-testid={'new-region'}>
                    <TextField
                        label={'Name'}
                        size={'small'}
                        sx={{ mt: 2 }}
                        onChange={(e) => setName(e.target.value)}
                        autoFocus
                        fullWidth
                    />
                    <TextField
                        label={'Description'}
                        size={'small'}
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    loading={isLoading}
                    disabled={!name}
                    variant={'contained'}
                    onClick={async () => {
                        // istanbul ignore else
                        if (name) {
                            await create({
                                projectSlug,
                                region: {
                                    name,
                                    description,
                                },
                            });
                            props.onClose ? props.onClose({}, 'escapeKeyDown') : /*istanbul ignore next */ null;
                        }
                    }}
                >
                    Create
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

type EditRegionDialogProps = {
    region: Region;
} & DialogProps;

function EditRegion({ region, ...props }: EditRegionDialogProps): React.ReactElement {
    const { useUpdateRegionMutation } = useFrame(SteinInternalApiClientToken);
    const [update, { isLoading: isSaving }] = useUpdateRegionMutation();
    const staged = useStagedChanges(update, region);

    return (
        <Dialog {...props}>
            <DialogTitle>Edit Region</DialogTitle>
            <DialogContent>
                <Stack spacing={2} alignItems={'center'} flex={1} data-testid={'edit-region'}>
                    <TextField
                        label={'Name'}
                        size={'small'}
                        sx={{ mt: 2 }}
                        value={staged.merged.name}
                        onChange={(e) => staged.mergeChanges({ name: e.target.value })}
                        autoFocus
                        fullWidth
                    />
                    <TextField
                        label={'Description'}
                        size={'small'}
                        value={staged.merged.description}
                        onChange={(e) => staged.mergeChanges({ description: e.target.value })}
                        fullWidth
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={staged.reset} disabled={!staged.hasChanges}>
                    Reset
                </Button>
                <LoadingButton
                    loading={isSaving}
                    disabled={!staged.hasChanges}
                    variant={'contained'}
                    onClick={async () => {
                        await staged.save();
                        props.onClose ? props.onClose({}, 'escapeKeyDown') : /*istanbul ignore next */ null;
                    }}
                >
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
