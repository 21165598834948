import { Stack, Link } from '@mui/material';
import * as React from 'react';
import { useAppSelector } from '../../hooks/hooks';
import { selectAllProjects } from '../../store/selectors';
import { AppIconEmail } from '../AppIcons';
import { ProjectAdmins } from '../ProjectAdmins';
import { SupportCard } from '../SupportCard';

export function SupportPage(): React.ReactElement {
    const projects = useAppSelector(selectAllProjects);
    return (
        <Stack spacing={2}>
            <SupportCard Icon={AppIconEmail} title={'Observant Email'}>
                <p>{'Contact the Observant team.'}</p>
                <Link href={'mailto:support@observantai.com'}>{'support@observantai.com'}</Link>
            </SupportCard>
            {projects
                .filter((p) => p.publicCustomAdmins?.length)
                .map((p) => (
                    <ProjectAdmins project={p} key={p.id} />
                ))}
        </Stack>
    );
}
