import * as React from 'react';

import { ApplicationBarPopover } from '../ApplicationBar/ApplicationBarPopover';

import { useAppTheme } from '../../theme';

import { AppIconNotification, AppIconWarning } from '../AppIcons';
import { Badge, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { ListSubheader } from '@mui/material';
import { useAppSelector } from '../../hooks/hooks';

import { NotificationItem } from './NotificationItem';
import { selectNotifications } from '../../store/selectors';
import { useTimeout } from '../../hooks/use-timeout';
import { useDispatch } from 'react-redux';
import { notificationSlice } from '../../store/slices/notificationSlice';

type ThemedIconProps = {
    badgeContent: React.ReactNode;
    error: boolean;
};

function ThemedIcon({ badgeContent, error }: ThemedIconProps): React.ReactElement {
    const theme = useAppTheme();
    const canShowError = useTimeout(1000);

    const showError = canShowError && error;

    return (
        <Badge
            badgeContent={showError ? '!' : badgeContent}
            color={showError ? 'error' : 'primary'}
            componentsProps={{
                badge: {
                    // @ts-expect-error data-testid is not a valid material propery, but it works on the dom element
                    ['data-testid']: 'notification-badge',
                },
            }}
        >
            <AppIconNotification color={theme.colors.appbar.text} />
        </Badge>
    );
}

function ListItemError({ text }: { text: string }): React.ReactElement {
    const theme = useAppTheme();
    return (
        <ListItem style={{ backgroundColor: theme.palette.error.light, color: theme.palette.error.contrastText }} dense>
            <ListItemIcon style={{ color: 'inherit' }}>
                <AppIconWarning color={'inherit'} />
            </ListItemIcon>

            <ListItemText primary={text} primaryTypographyProps={{}} />
        </ListItem>
    );
}

export function Notifications(): React.ReactElement {
    const notifications = useAppSelector(selectNotifications);
    const isConnected = useAppSelector((s) => s.notification.isConnected);
    const dispatch = useDispatch();

    return (
        <ApplicationBarPopover
            name={'notifications'}
            icon={<ThemedIcon badgeContent={notifications.filter((n) => !n.acked).length} error={!isConnected} />}
        >
            <List>
                {!isConnected && <ListItemError text={'You are not connected to live updates'} />}
                <ListSubheader>{'Notifications'}</ListSubheader>
                {notifications.map((n) => (
                    <NotificationItem
                        notification={n}
                        key={n.id}
                        onAck={() => dispatch(notificationSlice.actions.ackNotification(n.id))}
                    />
                ))}
            </List>
        </ApplicationBarPopover>
    );
}
