import * as React from 'react';
import { usePlaybackPosition } from '../videoPlayerHooks';
import { VideoRef } from '../VideoTypes';
import { useAppTheme } from '../../../theme';
import { addSeconds } from 'date-fns';
import { formatLocalizedTime } from '../../../utils/datetime-utils';
import { VideoSlider } from './VideoSlider';

type VideoSeekProps = {
    startTime?: Date;
    videoRef: VideoRef;
};

export function VideoSeek({ videoRef, startTime }: VideoSeekProps): React.ReactElement {
    const { progress, setProgress } = usePlaybackPosition(videoRef);
    const theme = useAppTheme();

    function handleChange(_: unknown, newValue: number): void {
        setProgress(newValue / 100);
    }

    return (
        <div
            style={{
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
                display: 'flex',
                flex: 2,
            }}
        >
            <VideoSlider
                value={progress * 100 || 0}
                valueLabelDisplay="auto"
                onChange={handleChange}
                valueLabelFormat={(n: number): React.ReactNode => {
                    if (startTime && videoRef.current?.duration) {
                        const elapsed = n > 0 ? (videoRef.current.duration * n) / 100 : 0;
                        return formatLocalizedTime(addSeconds(startTime, elapsed));
                    }
                    return n;
                }}
            />
        </div>
    );
}
