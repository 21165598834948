// istanbul ignore file
import * as React from 'react';
import * as L from 'leaflet';
import * as ReactDOMServer from 'react-dom/server';

import { defineDefault } from '../../utils/undefined-utils';
import { Marker, useMap, useMapEvent } from 'react-leaflet';
import { urlVehicleDeviceShow } from '../../utils/internal-url-utils';
import { VehicleDevice } from '../../types/stein';
import { useActiveProjectSlug } from '../../hooks/use-active-project';
import { useHistory } from 'react-router-dom';
import { AppIconVehicleTopDown, AppIconVehicle } from '../AppIcons';

function useMapZoom(): number {
    const map = useMap();
    const [zoom, setZoom] = React.useState(map.getZoom());
    useMapEvent('zoomend', () => {
        setZoom(map.getZoom());
    });
    return zoom;
}

export function VehicleMarker({ vehicle }: { vehicle: VehicleDevice }): React.ReactElement {
    const projectSlug = useActiveProjectSlug();
    const history = useHistory();
    const zoom = useMapZoom();

    return (
        <Marker
            position={[defineDefault(vehicle.lastLatitude, 0), defineDefault(vehicle.lastLongitude, 0)]}
            eventHandlers={{
                click: /* istanbul ignore next*/ () => {
                    history.push(urlVehicleDeviceShow(projectSlug, vehicle.slug));
                },
            }}
            icon={L.divIcon({
                className: 'leaflet-invisible-marker',
                html: ReactDOMServer.renderToStaticMarkup(
                    <div
                        style={{
                            color: 'black',
                            display: 'flex',
                            height: '15px',
                            width: '15px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingRight: '5px',
                            paddingLeft: '3px',
                            transform: `translate(-50%, -50%)`,
                        }}
                    >
                        <div
                            className="show-sibling-on-hover"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                transform: `rotate(${vehicle.lastHeading}deg)`,
                                height: '100%',
                                width: '100%',
                                scale: `${zoom * 25}%`,
                            }}
                        >
                            <AppIconVehicleTopDown />
                        </div>
                        <div
                            style={{
                                position: 'absolute',
                            }}
                        >
                            <div
                                style={{
                                    width: 120,
                                    color: 'white',
                                    display: 'flex',
                                    height: 15,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    transform: 'translateY(-100%)',
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: 'black',
                                        borderRadius: 5,
                                        color: 'white',
                                        display: 'flex',
                                        height: 15,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        paddingRight: 5,
                                        paddingLeft: 3,
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <AppIconVehicle />
                                    </div>
                                    <div>{vehicle.name}</div>
                                </div>
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    width: 0,
                                    height: 0,
                                    borderLeft: '4px solid transparent',
                                    borderRight: '4px solid transparent',
                                    borderTop: '4px solid black',
                                    clear: 'both',
                                }}
                            />
                        </div>
                    </div>,
                ),
            })}
        />
    );
}
