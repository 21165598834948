import { Paper, Skeleton } from '@mui/material';
import * as React from 'react';
import { useIsHovered } from '../../hooks/use-is-hovered';
import { appStyled } from '../../theme';

type CalloutProps = {
    name: string;
    icon?: React.ReactNode;
};

type CalloutBoxProps = CalloutProps & {
    children: React.ReactNode;
    className?: string;
    disablePadding?: boolean;
    enableHighlight?: boolean;
};

const CalloutBoxBase = appStyled(Paper, {
    shouldForwardProp: (prop) => prop !== 'highlight',
})<{ highlight?: boolean }>(({ highlight }) => ({
    position: 'relative',
    borderColor: 'rgba(0, 0, 0, 0.25)',
    borderStyle: 'solid',
    borderWidth: highlight ? /* istanbul ignore next */ '3px' : '1px',
}));

const CalloutLabel = appStyled('div', {
    shouldForwardProp: (prop) => prop !== 'highlight',
})<{ highlight?: boolean }>(({ theme, highlight }) => ({
    transform: 'translate(10px, -8px)',
    fontSize: '.75em',
    transformOrigin: 'left',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    position: 'absolute',
    left: 0,
    top: 0,
    backgroundColor: 'inherit',
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    color: 'rgba(0, 0, 0, 0.6)',
    zIndex: 1,
    ...(highlight
        ? /* istanbul ignore next */ {}
        : {
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: '80%',
              overflow: 'hidden',
          }),
}));

const CalloutBoxContent = appStyled('div', {
    shouldForwardProp: (prop) => prop !== 'disablePadding',
})<{ disablePadding: boolean }>(({ theme, disablePadding }) =>
    disablePadding
        ? /* istanbul ignore next */ {
              paddingTop: theme.spacing(1.5),
          }
        : {
              paddingTop: theme.spacing(2),
              paddingBottom: theme.spacing(2),
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
          },
);

export function CalloutBox({
    name,
    icon,
    children,
    className,
    disablePadding,
    enableHighlight,
    ...props
}: CalloutBoxProps): React.ReactElement {
    const [hovered, onHoverProps] = useIsHovered();
    const highlight = Boolean(enableHighlight && hovered);
    return (
        <CalloutBoxBase
            variant="outlined"
            className={className}
            data-testid={`callout-${name.toLowerCase()}`}
            highlight={highlight}
            {...props}
        >
            <CalloutBoxContent disablePadding={disablePadding || false}>{children}</CalloutBoxContent>
            <CalloutLabel {...onHoverProps} highlight={highlight}>
                {icon} {name}
            </CalloutLabel>
        </CalloutBoxBase>
    );
}

type CalloutSkeletonProps = CalloutProps & {
    height?: number;
};

export function CalloutSkeleton({ name, icon, height }: CalloutSkeletonProps): React.ReactElement {
    return (
        <CalloutBoxBase variant="outlined">
            <Skeleton variant="rectangular" height={height || 44} />
            <CalloutLabel>
                {icon} {name}
            </CalloutLabel>
        </CalloutBoxBase>
    );
}
