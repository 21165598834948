import * as React from 'react';
import { useFrame } from '../../../lib/frame-react';
import { LoggerToken } from '../../../lib/frame-tokens';
import { DriverEventChip } from '../../AppChip';
import {
    AppIconComponent,
    AppIconEmail,
    AppIconGenericNotification,
    AppIconSlack,
    AppIconSMS,
    AppIconVoiceAlert,
} from '../../AppIcons';
import { useActivityLogContext } from '../ActivityLogContext';
import { ActivityLogItemNotificationType } from '../activity_log_utils';
import { ActivityLogItemBase } from './ActivityLogItemBase';

function sentToRecipient(recipient: string): React.ReactElement {
    return (
        <>
            {' sent to '}
            <b>{recipient}</b>
        </>
    );
}

function NotificationVerb({ notification }: ActivityLogItemNotificationProps): React.ReactElement {
    const log = useFrame(LoggerToken);
    switch (notification.notificationConfiguration.medium) {
        case 'slack_dm':
            const recipient = notification.recipientSlack;
            return recipient ? sentToRecipient(recipient) : <>{' notification sent'}</>;
        case 'voice_alert':
            return <>{' played in vehicle'}</>;
        case 'sms':
        case 'email':
            return <>{' notification sent'}</>;
        default:
            log.warn('[NotificationVerb] trying to apply verb to unknown medium', { notification });
            return <>{' notification sent'}</>;
    }
}

function getMedium(medium: string): { Icon: AppIconComponent; name: string } {
    switch (medium) {
        case 'slack_dm':
            return {
                Icon: AppIconSlack,
                name: 'Slack',
            };
        case 'voice_alert':
            return {
                Icon: AppIconVoiceAlert,
                name: 'Voice Alert',
            };
        case 'sms':
            return {
                Icon: AppIconSMS,
                name: 'SMS',
            };
        case 'email':
            return {
                Icon: AppIconEmail,
                name: 'Email',
            };
        default:
            return {
                Icon: AppIconGenericNotification,
                name: 'Unknown',
            };
    }
}

function NotificationDescription({ notification }: ActivityLogItemNotificationProps): React.ReactElement {
    const { driverEvent } = useActivityLogContext();
    if (!notification.lastDriverEventId || !driverEvent) {
        return <></>;
    }

    if (notification.lastDriverEventId === driverEvent?.id) {
        return <>{'triggered by this event'}</>;
    }

    return (
        <>
            {'triggered by '} <DriverEventChip eventId={notification.lastDriverEventId} size={'small'} />
        </>
    );
}

type ActivityLogItemNotificationProps = {
    notification: ActivityLogItemNotificationType;
};

export function ActivityLogItemNotification({ notification }: ActivityLogItemNotificationProps): React.ReactElement {
    const medium = getMedium(notification.notificationConfiguration.medium);

    return (
        <ActivityLogItemBase
            timestamp={notification.timestamp}
            description={
                <div>
                    <b>{medium.name}</b>
                    <NotificationVerb notification={notification} />
                </div>
            }
            Icon={medium.Icon}
            detail={<NotificationDescription notification={notification} />}
        />
    );
}
