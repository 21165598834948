import { Card, CardContent, Typography } from '@mui/material';
import * as React from 'react';
import { AppIconComponent } from './AppIcons';

type SupportCardProps = {
    title: string;
    Icon: AppIconComponent;
    children: React.ReactNode;
};

export function SupportCard({ Icon, title, children }: SupportCardProps): React.ReactElement {
    return (
        <Card>
            <CardContent>
                <Typography
                    variant="h5"
                    component="div"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Icon /> {title}
                </Typography>
                {children}
            </CardContent>
        </Card>
    );
}
