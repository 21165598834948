// istanbul ignore file
import * as React from 'react';
import { VideoPlayerBaseProps } from '../VideoPlayer/VideoPlayerBase';

import { MultiVideoController, MultiVideoControllerState } from '../../lib/multi-video/multi-video';
import { VideoSegment } from '../../lib/multi-video/video-segment';
import { VideoTrack } from '../../lib/multi-video/video-track';

export type VideoPlayerMultiProps = {
    controller: MultiVideoController;
} & Partial<VideoPlayerBaseProps>;

export function useMultiVideoController(sources: VideoSegment[][]): MultiVideoController {
    const controller = React.useMemo(() => new MultiVideoController(), []);
    React.useEffect(() => {
        const tracks = sources.map((segments) => new VideoTrack({ segments, controller, log: true }));
        controller.setTracks(tracks);
    }, [controller, sources]);

    return controller;
}

export function useMultiVideoState(controller: MultiVideoController): MultiVideoControllerState {
    // @ts-expect-error controller.state is private, but this is an acceptable use of it
    const [state, setState] = React.useState<MultiVideoState>(controller.state);
    React.useEffect(() => {
        controller.on('stateChanged', (s) => {
            setState(s);
        });
    }, [controller]);

    return state;
}

export type VideoBadgeType =
    | {
          type: 'finished';
      }
    | {
          type: 'skip';
          seconds: number;
      };
