import { Button } from '@mui/material';
import * as React from 'react';
import { useFrame } from '../../lib/frame-react';
import { WindowToken } from '../../lib/frame-tokens';
import { URL_V2_DISABLE } from '../../utils/internal-url-utils';

export function ErrorUnimplemented(): React.ReactElement {
    const window = useFrame(WindowToken);

    function backToV1Dashboard(): void {
        window.location.replace(URL_V2_DISABLE);
    }
    return (
        <div>
            <h3>{'Version 2 of this page is in development'}</h3>
            <p>{'Until this page is released, please continue to use the current dashboard'}</p>
            <Button variant={'contained'} onClick={backToV1Dashboard}>
                {'Go to current dashboard'}
            </Button>
        </div>
    );
}
