import { Button, Typography, Stack } from '@mui/material';
import * as React from 'react';

function RenderErrorButton(): React.ReactElement {
    const [text, setText] = React.useState<{ text: string }>({ text: '' });

    function handleClick(): void {
        // @ts-expect-error this purposefully nullifies a non
        // nullable so we can trigger a null lookup error
        setText(null);
    }

    return (
        <Button
            onClick={handleClick}
            variant={'contained'}
            color={'error'}
        >{`Trigger Error: Render${text.text}`}</Button>
    );
}

export function GodModeDashboardPage(): React.ReactElement {
    return (
        <div>
            <Typography variant={'h3'}>{'God Mode'}</Typography>
            <Stack spacing={2}>
                <RenderErrorButton />
                <Button
                    onClick={
                        /* istanbul ignore next */ () => {
                            // istanbul ignore next
                            throw new Error('GOD MODE Test Click Handler Error');
                        }
                    }
                    variant={'contained'}
                    color={'error'}
                >{`Trigger Error: Click Handler`}</Button>
            </Stack>
        </div>
    );
}
