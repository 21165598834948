import { OutlinedInputProps } from '@mui/material';
import * as React from 'react';
import { AppIconSave } from './AppIcons';
import { TextInputWithAction } from './material/TextInputWithAction';

type AutosaveTextInputProps = Omit<OutlinedInputProps, 'onChange' | 'sx' | 'value'> & {
    onSave: (s: string | null) => void;
    label: string;
    saving: boolean;
    value: string;
};

export function AutosaveTextInput({
    value,
    label,
    saving,
    onSave,
    ...props
}: AutosaveTextInputProps): React.ReactElement {
    return (
        <TextInputWithAction
            Icon={AppIconSave}
            label={label}
            onAction={onSave}
            value={value}
            loading={saving}
            {...props}
        />
    );
}
