import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import Collapse from '@mui/material/Collapse';
import { useAppTheme } from '../../../theme';
import { useFrame } from '../../../lib/frame-react';
import { SteinRootApiToken } from '../../../clients/stein-root-api';
import { Permission } from '../../Authorize/PermissionBlock';

const DEVELOPER_ACCOUNTS = [
    {
        email: 'steingodmode@observantai.com',
        role: 'God mode',
    },
    {
        email: 'gavin.belson@observantai.com',
        role: 'Admin',
    },
    {
        email: 'richard.hendricks@observantai.com',
        role: 'Manager',
    },
    {
        email: 'monica@observantai.com',
        role: 'Review Manager',
    },
    {
        email: 'big.head@observantai.com',
        role: 'Reviewer',
    },
    {
        email: 'jared.dunn@observantai.com',
        role: 'Auditor',
    },
    {
        email: 'jian-yang@observantai.com',
        role: 'Fleet Audit Operator',
    },
    {
        email: 'gilfoyle@observantai.com',
        role: 'Fleet Operator',
    },
];

type DeveloperAccount = {
    email: string;
    role: string;
};

function DeveloperAccountLink({ email, role }: DeveloperAccount): React.ReactElement {
    const { useSignOutAndInMutation } = useFrame(SteinRootApiToken);
    const [signOutAndIn] = useSignOutAndInMutation();
    const theme = useAppTheme();
    return (
        <ListItemButton onClick={() => signOutAndIn({ email })} dense sx={{ width: '100%' }}>
            <ListItemText primary={email} secondary={role} style={{ paddingLeft: theme.spacing(1) }} />
        </ListItemButton>
    );
}

export function AccountDevelopmentOptions(): React.ReactElement {
    const [open, setOpen] = React.useState(false);

    const handleClick = (): void => {
        setOpen(!open);
    };

    return (
        <Permission debugOnly>
            <List component="div" dense>
                <ListItemButton onClick={handleClick} dense sx={{ width: '100%' }}>
                    <ListItemText primary="Change Developer Account" />
                </ListItemButton>
            </List>

            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" dense>
                    {DEVELOPER_ACCOUNTS.map((d) => (
                        <DeveloperAccountLink key={d.email} {...d} />
                    ))}
                </List>
            </Collapse>
        </Permission>
    );
}
