// istanbul ignore file
import { Box, Stack, Tooltip } from '@mui/material';
import * as React from 'react';
import { useAppTheme } from '../../../../theme';
import { Audit, AuditTag, DateTimestampMs } from '../../../../types/stein';
import { CreateAuditTagReq } from '../../../../types/stein-internal-api';
import { toISO8601 } from '../../../../utils/datetime-utils';
import {
    AppIconAuditTagPositive,
    AppIconWarningTriangle,
    AppIconAuditTagNegative,
    AppIconComponent,
} from '../../../AppIcons';
import {
    auditTagCategoryHelperText,
    auditTagCategoryToHumanReadable,
    tagGroupAndIndexToHotkey,
} from './AuditTagConstants';
import { useAuditTagCreateContext } from './AuditTagCreateProvider';
import { useAuditTagProfile } from './AuditTagProfile';

type AuditTagListAddProps = {
    audit: Audit;
    playbackTime: DateTimestampMs;
    existingAuditTags: AuditTag[];
};

function CreateTagButton({
    auditTag,
    existing,
    Icon,
}: CreateAuditTagReq & { Icon: AppIconComponent; existing: number }): React.ReactElement {
    const { setTagToCreate } = useAuditTagCreateContext();
    const theme = useAppTheme();

    let color = theme.palette.success.main;
    if (auditTag.behavior === 'major_negative') {
        color = theme.palette.error.main;
    } else if (auditTag.behavior === 'minor_negative') {
        color = theme.palette.warning.main;
    }

    return (
        <Box
            sx={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                ':hover': {
                    color,
                },
            }}
        >
            <div onClick={() => setTagToCreate(auditTag)}>
                {existing}
                <Icon />
            </div>
        </Box>
    );
}

export function AuditTagListAdd({ audit, playbackTime, existingAuditTags }: AuditTagListAddProps): React.ReactElement {
    const { profile } = useAuditTagProfile();
    const theme = useAppTheme();

    return (
        <Box width={'100%'}>
            {profile?.groups.map((g, groupIdx) => (
                <div key={groupIdx}>
                    {g.groupName && <strong>{g.groupName}</strong>}
                    <div>
                        {g.categories.map((c, tagIdx) => (
                            <Tooltip
                                key={c}
                                title={auditTagCategoryHelperText(c) || ''}
                                placement={'top'}
                                arrow
                                followCursor
                            >
                                <Stack
                                    direction={'row'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                    sx={{
                                        ':hover': {
                                            backgroundColor: theme.palette.grey[100],
                                        },
                                        paddingX: theme.spacing(1),
                                    }}
                                >
                                    <div style={{ paddingRight: theme.spacing(2) }}>
                                        {`[${tagGroupAndIndexToHotkey(
                                            groupIdx,
                                            tagIdx,
                                        )}] ${auditTagCategoryToHumanReadable(c)}`}
                                    </div>
                                    {playbackTime ? (
                                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                            <CreateTagButton
                                                existing={
                                                    existingAuditTags.filter(
                                                        (a) => a.category === c && a.behavior === 'major_positive',
                                                    ).length
                                                }
                                                auditSlug={audit.slug}
                                                auditTag={{
                                                    startedAt: toISO8601(new Date(playbackTime)),
                                                    category: c,
                                                    behavior: 'major_positive',
                                                    comment: '',
                                                }}
                                                Icon={AppIconAuditTagPositive}
                                            />

                                            <CreateTagButton
                                                existing={
                                                    existingAuditTags.filter(
                                                        (a) => a.category === c && a.behavior === 'minor_negative',
                                                    ).length
                                                }
                                                auditSlug={audit.slug}
                                                auditTag={{
                                                    startedAt: toISO8601(new Date(playbackTime)),
                                                    category: c,
                                                    behavior: 'minor_negative',
                                                    comment: '',
                                                }}
                                                Icon={AppIconWarningTriangle}
                                            />
                                            <CreateTagButton
                                                existing={
                                                    existingAuditTags.filter(
                                                        (a) => a.category === c && a.behavior === 'major_negative',
                                                    ).length
                                                }
                                                auditSlug={audit.slug}
                                                auditTag={{
                                                    startedAt: toISO8601(new Date(playbackTime)),
                                                    category: c,
                                                    behavior: 'major_negative',
                                                    comment: '',
                                                }}
                                                Icon={AppIconAuditTagNegative}
                                            />
                                        </Stack>
                                    ) : null}
                                </Stack>
                            </Tooltip>
                        ))}
                    </div>
                </div>
            ))}
        </Box>
    );
}
