/* istanbul ignore file */
import * as React from 'react';

import { AuditSlug, DateStrUTCTimestamp } from '../../../types/stein';
import { MenuItem } from '@mui/material';
import { FilterBulkActions } from '../../FilterBar/FilterBulkActions';
import { useFrame } from '../../../lib/frame-react';
import { FetchToken, WindowToken } from '../../../lib/frame-tokens';
import { makeQueryString } from '../../../utils/query-string';
import { useAppDispatch } from '../../../hooks/hooks';
import { useActiveProjectSlug } from '../../../hooks/use-active-project';
import { notificationSlice } from '../../../store/slices/notificationSlice';

type AuditBulkActionsProps = {
    auditSlugs: Array<AuditSlug>;
    startTime?: DateStrUTCTimestamp;
    endTime?: DateStrUTCTimestamp;
};

export function AuditBulkActions({ auditSlugs, startTime, endTime }: AuditBulkActionsProps): React.ReactElement | null {
    const window = useFrame(WindowToken);
    const fetch = useFrame(FetchToken);
    const projectSlug = useActiveProjectSlug();
    const dispatch = useAppDispatch();

    const useRange = startTime && endTime;
    const useSelected = Boolean(auditSlugs && auditSlugs.length);

    const queryParams = useSelected
        ? {
              slugs: auditSlugs,
          }
        : useRange
          ? {
                startTime,
                endTime,
            }
          : {};

    return (
        <FilterBulkActions disabled={!(useRange || useSelected)}>
            <MenuItem disabled>
                {useSelected
                    ? `Export ${auditSlugs?.length} selected audits`
                    : useRange
                      ? 'Export all in selected range'
                      : null}
            </MenuItem>
            <MenuItem
                onClick={() => {
                    window.open(`/projects/${projectSlug}/audits/export.csv?${makeQueryString(queryParams)}`, '_blank');
                }}
            >
                {'Download CSV'}
            </MenuItem>
            <MenuItem
                onClick={() =>
                    fetch(`/projects/${projectSlug}/audits/export.csv?email=true&${makeQueryString(queryParams)}`)
                        .then(() =>
                            dispatch(
                                notificationSlice.actions.createNotification({
                                    type: 'generic',
                                    title: 'Email sent to the address listed in your profile.',
                                    kind: 'success',
                                }),
                            ),
                        )
                        .catch(() => {
                            dispatch(
                                notificationSlice.actions.createNotification({
                                    type: 'generic',
                                    title: 'Unable to send email. Please try again.',
                                    kind: 'error',
                                }),
                            );
                        })
                }
            >
                {'Email CSV'}
            </MenuItem>
        </FilterBulkActions>
    );
}
