import { Button, FormControlLabel, FormLabel, MenuItem, Slider, Switch, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import * as React from 'react';
import { MapContainer } from 'react-leaflet';
import { GeofenceSlug, RegionId } from '../../../types/stein';
import { defineDefault } from '../../../utils/undefined-utils';
import { MapTiles } from '../../Map/MapTiles';
import { useFrame } from '../../../lib/frame-react';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import { useStagedChanges } from '../../../hooks/use-staged-changes';
import { useAppNavigate, useAppSelector } from '../../../hooks/hooks';
import { selectGeofenceBySlug, selectRegionsByProjectSlug } from '../../../store/selectors';
import LoadingButton from '@mui/lab/LoadingButton';
import { useParams } from 'react-router-dom';
import { SettingsPage, SettingsSaveBar } from './components/SettingsShared';
import { AppIconFacilities, AppIconRefresh } from '../../AppIcons';
import { urlFacilitiesList } from '../../../utils/internal-url-utils';
import { useActiveProjectSlug } from '../../../hooks/use-active-project';
import { Breadcrumbs } from '../../Breadcrumbs';
import { GeofenceLocated } from '../../../utils/filter-utils';
import { useConfirmationDialog } from '../../../hooks/use-confirmation-dialog';
import { MapCircleEditor } from '../../Map/CircleEditor';
import { AutoCenter } from '../../Map/AutoCenter';
import { MapInteraction } from '../../Map/MapInteraction';
import { CustomControl } from '../../Map/CustomControl';
import { Permission } from '../../Authorize/PermissionBlock';

export function ProjectFacilityShow(): React.ReactElement {
    const projectSlug = useActiveProjectSlug();
    const { geofenceId } = useParams<{ geofenceId: string }>();
    const geofence = useAppSelector((s) => selectGeofenceBySlug(s, geofenceId as GeofenceSlug)) as GeofenceLocated;

    return (
        <SettingsPage
            title={
                <Breadcrumbs
                    items={[
                        {
                            Icon: AppIconFacilities,
                            name: 'Facilities',
                            url: urlFacilitiesList(projectSlug),
                        },
                        {
                            name: geofence?.name || /* istanbul ignore next */ 'Unknown',
                        },
                    ]}
                />
            }
        >
            {geofence ? <EditFacility geofence={geofence} /> : /* istanbul ignore next */ null}
        </SettingsPage>
    );
}

function EditFacility({ geofence }: { geofence: GeofenceLocated }): React.ReactElement {
    const projectSlug = useActiveProjectSlug();
    const { useUpdateGeofenceMutation, useDeleteGeofenceMutation } = useFrame(SteinInternalApiClientToken);
    const [updateGeofence, { isLoading }] = useUpdateGeofenceMutation();
    const geofenceChanges = useStagedChanges(updateGeofence, geofence);
    const [_deleteGeofence, { isLoading: isDeleting }] = useDeleteGeofenceMutation();
    const [autoCenter, setAutoCenter] = React.useState(true);
    const regions = useAppSelector((s) => selectRegionsByProjectSlug(s, projectSlug));
    const navigate = useAppNavigate();

    async function deleteGeofence(): Promise<void> {
        await _deleteGeofence(geofence.id).unwrap();
        navigate(urlFacilitiesList(projectSlug));
    }
    const confirmDelete = useConfirmationDialog({
        func: deleteGeofence,
        message: 'Are you sure you want to delete this Facility?',
        buttonText: 'Delete',
    });

    //istanbul ignore next
    function reset(): void {
        //istanbul ignore next
        geofenceChanges.setChanges(null);
    }

    //istanbul ignore next
    function enableAutoCenter(): void {
        //istanbul ignore next
        setAutoCenter(true);
    }
    //istanbul ignore next
    function disableAutoCenter(): void {
        //istanbul ignore next
        setAutoCenter(false);
    }

    return (
        <Stack spacing={2} flexGrow={2}>
            <div data-testid={'page-facility-show'} />
            <Stack spacing={2} direction={'row'}>
                <TextField
                    fullWidth
                    label={'Facility Name'}
                    size={'small'}
                    value={geofenceChanges.merged.name || /* istanbul ignore next*/ ''}
                    onChange={(e) => {
                        const name = e.target.value;
                        geofenceChanges.mergeChanges({ name });
                    }}
                />
                <TextField
                    fullWidth
                    label={'Facility Region'}
                    size={'small'}
                    select
                    value={typeof geofenceChanges.merged.regionId === 'number' ? geofenceChanges.merged.regionId : ''}
                    onChange={(e) =>
                        geofenceChanges.mergeChanges({
                            regionId: (parseInt(e.target.value) as RegionId) || /*istanbul ignore next*/ undefined,
                        })
                    }
                    helperText={'Region set when vehicle arrives at facility.'}
                >
                    <MenuItem value={-1}>- Select Region -</MenuItem>
                    {regions.map((r) => (
                        <MenuItem key={r.id} value={r.id}>
                            {r.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Stack>

            <Stack direction={'row'} spacing={2}>
                <FormLabel>Radius: </FormLabel>
                <Slider
                    min={50}
                    max={10000}
                    size={'small'}
                    value={geofenceChanges.merged.radius || /* istanbul ignore next*/ 0}
                    onChange={
                        /* istanbul ignore next*/ (_, radius) => {
                            // istanbul ignore next
                            if (typeof radius === 'number') {
                                // istanbul ignore next
                                geofenceChanges.mergeChanges({
                                    radius,
                                });
                            }
                        }
                    }
                />
            </Stack>
            <Permission debugOnly>
                <Stack direction={'row'}>
                    <FormControlLabel
                        label={'Maintenance'}
                        control={
                            <Switch
                                checked={geofenceChanges.merged.maintenancePermitted || false}
                                onChange={(_, maintenancePermitted) =>
                                    //istanbul ignore next
                                    geofenceChanges.mergeChanges({ maintenancePermitted })
                                }
                            />
                        }
                    />
                    <FormControlLabel
                        label={'Hidden'}
                        control={
                            <Switch
                                checked={geofenceChanges.merged.hidden || false}
                                onChange={(_, hidden) =>
                                    //istanbul ignore next
                                    geofenceChanges.mergeChanges({ hidden })
                                }
                            />
                        }
                    />
                    <FormControlLabel
                        label={'Notify on Enter'}
                        control={
                            <Switch
                                checked={geofenceChanges.merged.notifyEnter || false}
                                onChange={(_, notifyEnter) =>
                                    //istanbul ignore next
                                    geofenceChanges.mergeChanges({ notifyEnter })
                                }
                            />
                        }
                    />
                    <FormControlLabel
                        label={'Notify on Exit'}
                        control={
                            <Switch
                                checked={geofenceChanges.merged.notifyExit || false}
                                onChange={(_, notifyExit) =>
                                    //istanbul ignore next
                                    geofenceChanges.mergeChanges({ notifyExit })
                                }
                            />
                        }
                    />
                </Stack>
            </Permission>
            <MapContainer
                attributionControl={false}
                zoom={15}
                center={[defineDefault(geofence.latitude, 0), defineDefault(geofence.longitude, 0)]}
                style={{
                    height: '500px',
                    flexGrow: 2,
                    zIndex: 0,
                }}
            >
                {!autoCenter ? (
                    /*istanbul ignore next*/ <CustomControl position="topright">
                        <Button color="inherit" onClick={enableAutoCenter} sx={{ backgroundColor: 'white' }}>
                            <AppIconRefresh />
                        </Button>
                    </CustomControl>
                ) : null}
                <MapTiles />
                <MapInteraction onInteraction={disableAutoCenter} />
                <AutoCenter
                    position={{
                        latitude: defineDefault(geofenceChanges.merged.latitude, 0),
                        longitude: defineDefault(geofenceChanges.merged.longitude, 0),
                    }}
                    enable={autoCenter}
                />
                <MapCircleEditor
                    circle={geofenceChanges.merged as GeofenceLocated}
                    onChange={
                        /* istanbul ignore next */ (c) => {
                            // istanbul ignore next
                            geofenceChanges.mergeChanges(c);
                        }
                    }
                />
            </MapContainer>

            <SettingsSaveBar
                show={geofenceChanges.hasChanges}
                onSave={geofenceChanges.save}
                onReset={reset}
                loading={isLoading}
                extraButtons={
                    <LoadingButton
                        onClick={confirmDelete.handleShouldConfirm}
                        variant={'outlined'}
                        loading={isDeleting}
                        color={'error'}
                    >
                        Delete
                    </LoadingButton>
                }
            />
            {confirmDelete.node}
        </Stack>
    );
}
