import { addMinutes, Duration, format, getTime, isWithinInterval, subMinutes } from 'date-fns';
import { format as formatTz } from 'date-fns-tz';
import { DateStrISO8601, DateTimestampMs } from '../types/stein';

const LAST_SEEN_THRESHOLD_MINUTES = 4;

export function isRoughlyNow(
    time: undefined | null | Date | DateStrISO8601,
    threshold: number = LAST_SEEN_THRESHOLD_MINUTES,
): boolean {
    if (!time) {
        return false;
    }
    const referenceData = typeof time === 'string' ? new Date(time) : time;
    const now = new Date();
    return isWithinInterval(referenceData, { start: subMinutes(now, threshold), end: addMinutes(now, threshold) });
}

export function formatDate(v: string): string {
    return format(new Date(v), 'EE, MMM do, hh:mm:ss aa');
}

export function formatLocalizedTime(d: Date): string {
    return formatTz(d, 'hh:mm:ss aa zzz');
}

export function formatLocalizedDateTime(d: Date): string {
    return formatTz(d, 'EE, MMM do, hh:mm:ss aa zzz');
}

export function formatShortDuration(d: Duration): string {
    let out = '';
    if (d.years) {
        out += `${d.years}y`;
    }
    if (d.months) {
        out += `${d.months}m`;
    }
    if (d.days) {
        out += `${d.days}d`;
    }
    if (d.hours) {
        out += `${d.hours}h`;
    }
    if (d.minutes) {
        out += `${d.minutes}m`;
    }
    if (d.seconds) {
        out += `${d.seconds}s`;
    }
    return out;
}

export function getTimezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function getTimezoneStr(): string {
    return formatTz(new Date(), 'zzz', {
        timeZone: getTimezone(),
    });
}

export function parseISO8601(d: DateStrISO8601): Date {
    return new Date(d as string);
}
export function toISO8601(d: Date): DateStrISO8601 {
    return d.toISOString() as DateStrISO8601;
}

export function toTimestampMs(d: Date): DateTimestampMs {
    return getTime(d) as DateTimestampMs;
}

export function sortTimeDesc(first: DateStrISO8601, second: DateStrISO8601): number {
    return getTime(new Date(second)) - getTime(new Date(first));
}
