import * as React from 'react';
import { VehicleDevice } from '../../../types/stein';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import { useFrame } from '../../../lib/frame-react';

type SelectDriverEventTypeModalProps = {
    vehicle: VehicleDevice;
    open: boolean;
    onClose: () => void;
};

export function VehicleDeviceNameEditModal({
    vehicle,
    open,
    onClose,
}: SelectDriverEventTypeModalProps): React.ReactElement {
    const { useUpdateVehicleDeviceMutation } = useFrame(SteinInternalApiClientToken);

    const [update, { isLoading }] = useUpdateVehicleDeviceMutation();
    const [name, setName] = React.useState('');

    function save(): void {
        // istanbul ignore else
        if (name) {
            update({
                id: vehicle.id,
                name,
            })
                .unwrap()
                .then(onClose);
        }
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{'Edit Vehicle'}</DialogTitle>
            <DialogContent sx={{ minWidth: '400px' }}>
                <TextField
                    label="Vehicle Name"
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                    autoFocus
                    sx={{ mt: 1 }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{'Cancel'}</Button>
                <LoadingButton onClick={save} disabled={!name.length} loading={isLoading} variant={'contained'}>
                    {'Save'}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
