import * as React from 'react';
import { FilterValueType } from '../../store/slices/filterConfigSlice';
import { AppIconBase } from '../AppIcons';
import { appStyled, useAppTheme } from '../../theme';
import { isStrColorHash } from '../../utils/color-utils';

type FilterIconProps = Partial<React.ComponentProps<typeof AppIconBase>> & {
    value: FilterValueType;
    showName?: boolean;
};

const Wrapper = appStyled('div')({
    display: 'flex',
    alignItems: 'center',
});

export function FilterIcon({ value, showName, ...props }: FilterIconProps): React.ReactElement | null {
    const theme = useAppTheme();
    const color = value.color && !isStrColorHash(value.color) ? theme.palette[value.color].main : value.color;
    const icon = value.icon ? (
        <AppIconBase {...props} iconClass={value.icon} name={value.name} color={color} showTooltip />
    ) : null;

    if (showName) {
        return (
            <Wrapper>
                {icon} <span style={{ marginLeft: theme.spacing(1) }}>{value.name}</span>
            </Wrapper>
        );
    }

    return icon;
}
