import * as React from 'react';
import { appStyled, useAppTheme } from '../../../theme';
import { Button } from '@mui/material';
import { AppIconBug } from '../../AppIcons';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { Switch, Tooltip } from '@mui/material';
import { toggleDebugEnabled, toggleDebugModal } from '../../../store/actions';
import { DebugModal } from './DebugModal';

const DebugToggleContainer = appStyled('div')(({ theme }) => ({
    position: 'fixed',
    bottom: 0,
    right: 0,
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.25),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    backgroundColor: theme.colors.page.background,
    borderRadius: '5px 0 0 0',
    boxShadow: theme.shadows[2],
    zIndex: 2147483004, // one higher than the intercom launcher
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
}));

export function DebugToggle(): React.ReactElement | null {
    const { debugEnabled, debugPermitted, debugUseAbilityOverrides } = useAppSelector((s) => s.navigation);
    const dispatch = useAppDispatch();
    const theme = useAppTheme();

    if (!debugPermitted) {
        return null;
    }

    function onButtonClick(): void {
        dispatch(toggleDebugModal());
    }

    function onToggleClick(): void {
        dispatch(toggleDebugEnabled());
    }

    return (
        <DebugToggleContainer>
            <Tooltip title={'Toggle the Debug Options modal.'}>
                <Button
                    onClick={onButtonClick}
                    aria-label={'debug-button'}
                    style={{
                        color: debugUseAbilityOverrides ? theme.palette.warning.main : theme.palette.secondary.main,
                    }}
                >
                    <AppIconBug style={{ fontSize: '14px' }} />
                </Button>
            </Tooltip>
            <Tooltip title={'Toggle Debug Mode.'}>
                <Switch
                    size="small"
                    checked={debugEnabled}
                    color="warning"
                    aria-label={'debug-toggle'}
                    onChange={onToggleClick}
                />
            </Tooltip>
            <DebugModal open={debugUseAbilityOverrides} />
        </DebugToggleContainer>
    );
}
