import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button } from '@mui/material';
import { secondsToMinutes } from 'date-fns';
import { lowerCase } from 'lodash';
import * as React from 'react';
import { useNotifConfigContext } from '../NotificationEditor/NotifConfigContext';
import { SteinInternalApiClientToken } from '../../../../../clients/stein-internal-api';
import { useAppNavigate, useAppSelector } from '../../../../../hooks/hooks';
import { useActiveProjectSlug } from '../../../../../hooks/use-active-project';

import { useConfirmationDialog } from '../../../../../hooks/use-confirmation-dialog';
import { useFrame } from '../../../../../lib/frame-react';
import { appStyled, useAppTheme } from '../../../../../theme';

import {
    NotifConfig,
    NotifConfigVariable,
    NOTIF_CONFIG_CATEGORY_NAME,
    NOTIF_CONFIG_MEDIUM_NAME,
} from '../../../../../types/stein.notification_config';
import { urlSettingsNotificationShow } from '../../../../../utils/internal-url-utils';
import { plural } from '../../../../../utils/string-utils';
import { AppIconArrowRight } from '../../../../AppIcons';
import { GeofenceId } from '../../../../../types/stein';
import { selectGeofencesByProjectSlug } from '../../../../../store/selectors';

type NotificationConfigCustomProps = {
    item: NotifConfigVariable;
};

const Centered = appStyled('div')<{ flex?: number }>(({ flex }) => ({
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flex: flex ? flex : 1,
    flexDirection: 'column',
}));

const Detail = appStyled('div')({
    justifyContent: 'center',
    alignItems: 'flex-start',
    display: 'flex',
    flex: 2,
    flexDirection: 'column',
});

const DetailText = appStyled('div')({
    fontSize: 12,
    textAlign: 'center',
});

const ErrorText = appStyled('b')(({ theme }) => ({
    color: theme.palette.error.main,
}));

export function NotificationConfigCustom({ item }: NotificationConfigCustomProps): React.ReactElement | null {
    const projectSlug = useActiveProjectSlug();
    const navigate = useAppNavigate();
    const categoryName = NOTIF_CONFIG_CATEGORY_NAME[item.category];
    const { useDeleteNotificationConfigMutation } = useFrame(SteinInternalApiClientToken);
    const [deleteItem, { isLoading: isDeleting }] = useDeleteNotificationConfigMutation();
    const theme = useAppTheme();

    const { node, handleShouldConfirm } = useConfirmationDialog({
        func: () => deleteItem({ notificationConfigId: item.id }).unwrap(),
        message: 'Delete this notification?',
        buttonText: 'Delete',
    });

    const geofences = useAppSelector((s) => selectGeofencesByProjectSlug(s, projectSlug));
    const triggerText = notificationConfigTrigger(
        item,
        geofences.reduce((obj, g) => ({ ...obj, [g.id]: g.name }), {} as Record<GeofenceId, string>),
    );

    return (
        <>
            <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                padding={1}
                data-testid={`notification-custom-${item.category}-${item.medium}`}
            >
                <Centered flex={2}>
                    <div style={{ fontWeight: 'bold' }}>{categoryName}</div>
                    <DetailText>{triggerText}</DetailText>
                </Centered>
                <AppIconArrowRight />
                <div style={{ display: 'flex', flex: 4, justifyContent: 'space-between' }}>
                    <Centered>
                        <div style={{ fontWeight: 'bold', textAlign: 'center', paddingLeft: theme.spacing(1) }}>
                            {NOTIF_CONFIG_MEDIUM_NAME[item.medium]}
                        </div>
                    </Centered>
                    <NotificationMediumDetail item={item} />
                    <div style={{ display: 'flex', flex: 0, justifyContent: 'flex-end' }}>
                        <Button onClick={() => navigate(urlSettingsNotificationShow(projectSlug, item.id))}>
                            {'Edit'}
                        </Button>
                        <LoadingButton onClick={handleShouldConfirm} loading={isDeleting} disabled={isDeleting}>
                            {'Delete'}
                        </LoadingButton>
                    </div>
                </div>
            </Box>
            {node}
        </>
    );
}

function notificationConfigTrigger(
    item: NotifConfigVariable,
    geofenceNames: Record<GeofenceId, string>,
): string | null {
    switch (item.category) {
        case 'distraction':
        case 'eye_closure':
        case 'any_neglect':
        case 'early_fatigue':
        case 'harsh_maneuver':
        case 'flagged':
            const verified = item.category !== 'flagged' && item.analysis === 'verified' ? 'verified ' : '';
            return `${item.rateNumerator} ${verified}${plural(item.rateNumerator, 'event', 'events')} in ${
                item.rateDenominator
            } ${item.rateDenominatorUnit.slice(0, -1)} window`;

        case 'iphone_battery_level_drop':
            return `drops below ${item.batteryLevelThreshold * 100}%`;
        case 'calibration_status_changed':
            return item.analysis !== 'any' ? `changes to ${item.analysis}` : 'changes';
        case 'tamper_v2':
        case 'sensor_covered_v2':
            return null;
        case 'geofence':
            const action =
                item.analysis === 'enter' ? 'enters' : item.analysis === 'exit' ? 'exits' : 'enters or exits';
            return ` a vehicle ${action} ${item.geofenceIds?.length ? `any of: ${item.geofenceIds.map((id) => geofenceNames[id]).join(', ')}` : 'any facility'}`;
    }
}
function NotificationMediumDetail({ item }: { item: NotifConfig }): React.ReactElement | null {
    const { getRequiredPropValue, getRequiredKeyName, scope } = useNotifConfigContext();

    switch (item.medium) {
        case 'slack_dm':
            return (
                <Detail>
                    <DetailText>
                        {'send '}
                        {item.template ? <b>{`"${item.template}"`}</b> : 'default message'}
                    </DetailText>
                    <DetailText>
                        {'to '}
                        {getRequiredPropValue(item.medium) ? (
                            <b>{getRequiredPropValue(item.medium)}</b>
                        ) : (
                            <ErrorText>{`no ${scope} ${lowerCase(
                                getRequiredKeyName(item.medium) as string,
                            )}`}</ErrorText>
                        )}
                    </DetailText>
                    {item.category === 'sensor_covered_v2' ? (
                        <>
                            <DetailText>
                                {'and after '} <b>{secondsToMinutes(item.remindIn || /* istanbul ignore next */ 0)}</b>
                                {plural(
                                    secondsToMinutes(item.remindIn || /* istanbul ignore next */ 0),
                                    ' minute',
                                    ' minutes',
                                )}
                            </DetailText>
                            <DetailText>
                                {'send '}
                                {item.reminderTemplate ? <b>{`"${item.reminderTemplate}"`}</b> : 'default message'}
                            </DetailText>
                        </>
                    ) : null}
                </Detail>
            );
        case 'voice_alert':
            return (
                <Detail>
                    <DetailText>{'speak in vehicle: '}</DetailText>
                    <DetailText>
                        {item.voiceMessage ? (
                            <b>{`"${item.voiceMessage}"`}</b>
                        ) : (
                            <ErrorText>{'no voice alert text'}</ErrorText>
                        )}
                    </DetailText>
                </Detail>
            );
        default:
            return <></>;
    }
}
