import { appStyled } from '../../theme';
export const GridEasy = appStyled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(-1),
    '& > div': {
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
}));
