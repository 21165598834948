import * as React from 'react';
import { useHasUnsavedChanges } from './use-has-unsaved-changes';
import { useIsMountedRef } from './use-is-mounted-ref';

type Changes<TReq> = Partial<TReq> | null;

type UseChangesRes<T = Record<string, unknown>> = {
    merged: T;
    changes: Changes<T>;
    setChanges: (change: UpdateStagedChangesAction<T>) => void;
    mergeChanges: (change: Changes<T>) => void;
    hasChanges: boolean;
    reset: () => void;
};
export function useChanges<T extends Record<string, unknown>>(currentValue: T): UseChangesRes<T> {
    const [changes, setChanges] = React.useState<Changes<T>>(null);
    const merged = Object.assign({}, currentValue, changes);
    const hasChanges = changes !== null;
    const mergeChanges = React.useCallback(
        (newChanges: Changes<T>) => setChanges((c) => (c ? { ...c, ...newChanges } : newChanges)),
        [setChanges],
    );
    useHasUnsavedChanges(hasChanges);

    return { changes, setChanges, mergeChanges, merged, hasChanges, reset: () => setChanges(null) };
}

export type UpdateStagedChangesAction<TReq> = React.SetStateAction<Changes<TReq | null>>;

export type UseStagedChangesRes<
    TReq = Record<string, unknown>,
    TRes = Promise<Record<string, unknown>>,
> = UseChangesRes<TReq> & {
    save: () => Promise<unknown>;
    res: Awaited<TRes> | undefined;
};

type UseStagedChangesOpts<TReq> = {
    saveTransform?: (changes: Changes<TReq>) => TReq;
    hasChangesFn?: (original: TReq, changes: Changes<TReq>) => boolean;
};

export function useStagedChanges<TReq extends Record<string, unknown>, TRes>(
    updateFunction: (args: TReq) => TRes,
    currentValue: TReq,
    opts?: UseStagedChangesOpts<TReq>,
): UseStagedChangesRes<TReq, TRes> {
    const { changes, setChanges, hasChanges, reset, mergeChanges } = useChanges(currentValue);
    const [res, setRes] = React.useState<Awaited<TRes> | undefined>();
    const isMountedRef = useIsMountedRef();

    const merged = Object.assign({}, currentValue, changes);

    const save = React.useCallback(
        async function saveStagedChanges() {
            /*istanbul ignore next*/
            if (changes !== null) {
                const updateChanges = opts?.saveTransform ? opts.saveTransform(changes) : merged;
                const res = await updateFunction(updateChanges);

                // @ts-expect-error the error field is returned if there is an error in the response
                if (isMountedRef.current && !res?.error) {
                    setChanges(null);
                    setRes(res);
                }
            }
        },
        [changes, updateFunction],
    );

    const hasChangedO = opts?.hasChangesFn ? opts.hasChangesFn(currentValue, changes) : hasChanges;

    return {
        changes,
        merged,
        hasChanges: hasChangedO,
        save,
        reset,
        setChanges,
        res,
        mergeChanges,
    };
}

type MergeStagedChangesRes = {
    hasChanges: boolean;
    saveAll: () => Promise<unknown>;
    resetAll: () => void;
};

export function mergeStagedChanges(...changes: UseStagedChangesRes[]): MergeStagedChangesRes {
    return {
        hasChanges: changes.some((c) => c.hasChanges),
        saveAll: () => Promise.all(changes.map((c) => c.save())),
        resetAll: () => changes.forEach((c) => c.reset()),
    };
}
