import React from 'react';
import { useMap } from 'react-leaflet';
import { FeatureGroup as FeatureGroupType } from 'leaflet';

type Position = {
    latitude: number;
    longitude: number;
};

export function AutoCenter({ position, enable }: { position?: Position; enable: boolean }): React.ReactElement {
    const map = useMap();
    const lat = position?.latitude;
    const lng = position?.longitude;
    React.useEffect(() => {
        // istanbul ignore next
        if (enable && map && lat && lng) {
            map.panTo([lat, lng]);
        }
    }, [map, lat, lng, enable]);

    return <></>;
}

export function AutoCenterGroup({
    groupRef,
    maxZoom,
    enable,
}: {
    groupRef: React.RefObject<FeatureGroupType<unknown>>;
    maxZoom?: number;
    enable: boolean;
}): React.ReactElement {
    const map = useMap();
    const bounds = groupRef.current?.getBounds();
    React.useEffect(() => {
        // istanbul ignore next
        if (enable && map && bounds?.isValid()) {
            // istanbul ignore next
            map.fitBounds(bounds, {
                maxZoom,
            });
        }
    }, [map, bounds, enable]);

    return <></>;
}
