import * as React from 'react';
import { Box, Breadcrumbs as MBreadcrumbs, Link, Typography } from '@mui/material';
import { AppIconComponent } from './AppIcons';
import { Link as RouterLink } from 'react-router-dom';

type BreadcrumbItem =
    | {
          Icon?: AppIconComponent;
          url?: string;
          name: string;
      }
    | React.ReactNode;

type BreadcrumbProps = {
    items: BreadcrumbItem[];
};

export function Breadcrumbs({ items }: BreadcrumbProps): React.ReactElement {
    return (
        <MBreadcrumbs>
            {items.map((i) => {
                if (i && typeof i === 'object' && 'name' in i) {
                    let main = (
                        <Typography color="text.primary" key={i.name}>
                            {i.name}
                        </Typography>
                    );

                    if (i.url) {
                        main = (
                            <Link
                                key={i.name}
                                underline="hover"
                                color="inherit"
                                to={i.url}
                                component={RouterLink}
                                sx={{ display: 'flex' }}
                            >
                                {i.name}
                            </Link>
                        );
                    }

                    return i.Icon ? (
                        <Box
                            key={i.name}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mr: 0.5,
                                    fontSize: '0.8rem',
                                    ['&:hover']: {
                                        textDecoration: 'none',
                                    },
                                }}
                            >
                                <i.Icon />
                            </Box>
                            {main}
                        </Box>
                    ) : (
                        main
                    );
                } else {
                    return i;
                }
            })}
        </MBreadcrumbs>
    );
}
