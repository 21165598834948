import * as React from 'react';

import { Button } from '@mui/material';
import { VehicleDevice } from '../../../types/stein';
import { CalloutBox, CalloutSkeleton } from '../../material/CalloutBox';
import { urlDriverEventList, urlVehicleDeviceTimeline } from '../../../utils/internal-url-utils';
import { useActiveProject } from '../../../hooks/use-active-project';
import { Permission } from '../../Authorize/PermissionBlock';
import { Stack } from '@mui/system';
import { Link } from 'react-router-dom';

type VehicleDeviceLinksProps = {
    vehicle: VehicleDevice | undefined;
};

export function VehicleDeviceLinks({ vehicle }: VehicleDeviceLinksProps): React.ReactElement {
    const project = useActiveProject();
    // istanbul ignore next
    if (!vehicle) {
        // istanbul ignore next
        return <CalloutSkeleton name="Timeline" />;
    }

    return (
        <CalloutBox name="Links" data-testid={'vehicle-links-box'}>
            <Stack spacing={1}>
                <Permission auth={{ subject: 'DriverEvent', action: 'list' }}>
                    {/* @ts-expect-error wants href but link component wants "to" */}
                    <Button
                        LinkComponent={Link}
                        to={urlDriverEventList(project.slug, { vehicles: [vehicle.slug] })}
                        fullWidth
                        size={'small'}
                        variant={'outlined'}
                    >
                        {'Vehicle Events'}
                    </Button>
                </Permission>
                <Permission auth={{ subject: 'VehicleDevice', action: 'view_timeline' }}>
                    {/* @ts-expect-error wants href but link component wants "to" */}
                    <Button
                        LinkComponent={Link}
                        to={urlVehicleDeviceTimeline(project.slug, vehicle)}
                        fullWidth
                        size={'small'}
                        variant={'outlined'}
                    >
                        {'Vehicle Timeline'}
                    </Button>
                </Permission>
            </Stack>
        </CalloutBox>
    );
}
