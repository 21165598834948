// istanbul ignore file

import { EventEmitter } from '@foxify/events';
import { VehicleDeviceId } from '../../types/stein';
import { RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';
import type { VideoCallService } from './video-call-service';
import * as TwilioVideo from 'twilio-video';

export type VideoCallAudioStatus = 'disconnected' | 'muted' | 'unmuted';
export type VideoCallStatus = 'initializing' | 'active' | 'reconnecting' | 'ended' | 'error';

export type VideoCallEvents = {
    changed: () => void;
    requestingAudio: () => void;
    ended: () => void;
};

type VideoCallProps = {
    vehicleDeviceId: VehicleDeviceId;
    videoCallService: VideoCallService;
};

export type VideoCallState = Readonly<{
    status: VideoCallStatus;
    localAudioStatus: VideoCallAudioStatus;
    audioTracks: readonly RemoteAudioTrack[];
    videoTracks: readonly RemoteVideoTrack[];
}>;

const LOCAL_PARTICIPANT_VIDEO_ENABLED = false;
const LOCAL_PARTICIPANT_AUDIO_ENABLED = false;

export class VideoCall extends EventEmitter<VideoCallEvents> {
    public readonly vehicleDeviceId: VehicleDeviceId;
    public readonly startedAt: Date;

    private status: VideoCallStatus = 'initializing';
    private localAudioStatus: VideoCallAudioStatus = 'disconnected';
    private audioTracks: RemoteAudioTrack[] = [];
    private videoTracks: RemoteVideoTrack[] = [];

    // internal
    public readonly service: VideoCallService;
    private room: TwilioVideo.Room | null = null;

    constructor({ vehicleDeviceId, videoCallService }: VideoCallProps) {
        super();
        this.vehicleDeviceId = vehicleDeviceId;
        this.service = videoCallService;
        this.startedAt = new Date();

        this.joinRoom = this.joinRoom.bind(this);

        this.addTrack = this.addTrack.bind(this);
        this.removeTrack = this.removeTrack.bind(this);
        this.joinAudio = this.joinAudio.bind(this);
        this.leaveAudio = this.leaveAudio.bind(this);
        this.handleAudioStateChanged = this.handleAudioStateChanged.bind(this);

        this.notifyChange = this.notifyChange.bind(this);
        this.disconnect = this.disconnect.bind(this);
        this.setStatus = this.setStatus.bind(this);
        this.setLocalAudioStatus = this.setLocalAudioStatus.bind(this);
        this.trackSubscribed = this.trackSubscribed.bind(this);
        this.trackUnsubscribed = this.trackUnsubscribed.bind(this);
        this.handleParticipantConnected = this.handleParticipantConnected.bind(this);
        this.handleParticipantDisconnected = this.handleParticipantDisconnected.bind(this);

        this.emit('changed');
        this.joinRoom();
    }

    public getState(): VideoCallState {
        return {
            status: this.status,
            localAudioStatus: this.localAudioStatus,
            audioTracks: this.audioTracks,
            videoTracks: this.videoTracks,
        };
    }

    public async joinAudio(): Promise<void> {
        if (!this.room) {
            return;
        }

        this.emit('requestingAudio');
        const track = await this.service.getLocalAudio();
        await this.room.localParticipant.publishTrack(track);

        track.on('unmuted', this.handleAudioStateChanged);
        track.on('muted', this.handleAudioStateChanged);
        track.on('enabled', this.handleAudioStateChanged);
        track.on('disabled', this.handleAudioStateChanged);
        this.handleAudioStateChanged();
    }

    public async leaveAudio(): Promise<void> {
        if (!this.room) {
            return;
        }

        const track = await this.service.getLocalAudio();
        this.room.localParticipant.unpublishTrack(track);

        track.off('unmuted', this.handleAudioStateChanged);
        track.off('muted', this.handleAudioStateChanged);
        track.off('enabled', this.handleAudioStateChanged);
        track.off('disabled', this.handleAudioStateChanged);
        this.handleAudioStateChanged();
    }

    public disconnect(): void {
        this.room?.disconnect();
    }

    public setMute(mute: boolean): void {
        this.room?.localParticipant.audioTracks.forEach((t) => (mute ? t.track.disable() : t.track.enable()));
    }

    // Private

    private notifyChange(): void {
        if (!this.room) {
            this.status = 'ended';
        }
        this.emit('changed');
    }

    private setStatus(s: VideoCallStatus): void {
        if (this.status != s) {
            this.status = s;
            this.notifyChange();
        }
    }

    private setLocalAudioStatus(s: VideoCallAudioStatus): void {
        if (this.localAudioStatus != s) {
            this.localAudioStatus = s;
            this.notifyChange();
        }
    }

    private handleAudioStateChanged(): void {
        if (!this.room) {
            return;
        }

        const isDisabled = !this.room?.localParticipant.audioTracks.size;
        const isMuted = !Array.from(this.room.localParticipant.audioTracks.values()).some((a) => a.isTrackEnabled);

        if (isDisabled) {
            this.setLocalAudioStatus('disconnected');
        } else if (isMuted) {
            this.setLocalAudioStatus('muted');
        } else {
            this.setLocalAudioStatus('unmuted');
        }
    }

    private async joinRoom(): Promise<void> {
        try {
            this.setStatus('initializing');
            const callRes = await this.service.startVideoCall(this.vehicleDeviceId);

            this.room = await this.service.twilioConnect(callRes.callerAccessToken, {
                name: callRes.room,
                video: LOCAL_PARTICIPANT_VIDEO_ENABLED,
                audio: LOCAL_PARTICIPANT_AUDIO_ENABLED,
            });
            this.setStatus('active');

            for (const p of this.room.participants.values()) {
                this.handleParticipantConnected(p);
            }
            this.room.on('participantConnected', this.handleParticipantConnected);
            this.room.on('participantDisconnected', this.handleParticipantDisconnected);
            this.room.on('disconnected', () => {
                this.status = 'ended';
                this.emit('ended');
                this.notifyChange();
            });
            this.room.on('reconnecting', () => {
                this.status = 'reconnecting';
                this.notifyChange();
            });
            this.room.on('reconnected', () => {
                this.status = 'active';
                this.notifyChange();
            });
            this.notifyChange();
        } catch (e) {
            this.setStatus('error');
        }
    }

    private addTrack<T extends TwilioVideo.RemoteAudioTrack | TwilioVideo.RemoteVideoTrack>(track: T): void {
        // istanbul ignore else
        if (track.kind === 'audio') {
            this.audioTracks.push(track);
        } else {
            this.videoTracks.push(track);
        }
    }
    private removeTrack<T extends TwilioVideo.RemoteAudioTrack | TwilioVideo.RemoteVideoTrack>(track: T): void {
        // istanbul ignore else
        if (track.kind === 'audio') {
            const idx = this.audioTracks.indexOf(track);
            // istanbul ignore next
            if (idx >= 0) {
                this.audioTracks.splice(idx, 1);
            }
        } else {
            const idx = this.videoTracks.indexOf(track);
            // istanbul ignore next
            if (idx >= 0) {
                this.videoTracks.splice(idx, 1);
            }
        }
    }

    // istanbul ignore next
    private trackSubscribed(t: TwilioVideo.RemoteAudioTrack | TwilioVideo.RemoteVideoTrack): void {
        // istanbul ignore next
        this.addTrack(t);
        // istanbul ignore next
        this.notifyChange();
    }
    // istanbul ignore next
    private trackUnsubscribed(t: TwilioVideo.RemoteAudioTrack | TwilioVideo.RemoteVideoTrack): void {
        // istanbul ignore next
        this.removeTrack(t);
        // istanbul ignore next
        this.notifyChange();
    }

    private handleParticipantConnected(p: TwilioVideo.RemoteParticipant): void {
        p.audioTracks.forEach((p) => p.track && this.addTrack(p.track));
        p.videoTracks.forEach((p) => p.track && this.addTrack(p.track));

        this.notifyChange();

        p.on('trackSubscribed', this.trackSubscribed);
        p.on('trackUnsubscribed', this.trackUnsubscribed);
    }

    private handleParticipantDisconnected(p: TwilioVideo.RemoteParticipant): void {
        p.audioTracks.forEach((p) => p.track && this.removeTrack(p.track));
        p.videoTracks.forEach((p) => p.track && this.removeTrack(p.track));

        p.off('trackSubscribed', this.trackSubscribed);
        p.off('trackUnsubscribed', this.trackUnsubscribed);
        this.notifyChange();
        if (!this.room?.participants.size) {
            this.disconnect();
        }
    }
}
