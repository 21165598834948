// istanbul ignore file
import { createPlugin, createToken } from '../../lib/frame';
import { AppReduxStoreToken, AppStore } from '../../store';
import { FrameLogger, LoggerToken } from '../../lib/frame-tokens';
import { SteinClient, SteinInternalApiToken } from '../../clients/stein-internal-api';
import { VideoCall } from './video-call';
import { VehicleDeviceId } from '../../types/stein';
import { EventEmitter } from '@foxify/events';
import * as TwilioVideo from 'twilio-video';
import { StartVideoCallResponse } from '../../types/stein-internal-api';
import { TwilioVideoConnectToken } from '../app-intercom-service';
import { TwilioVideoConnect } from '../app-intercom-service/intercom-types';

export const VideoCallServiceToken = createToken<VideoCallService>('VideoCallServiceToken');

type VideoCallServiceEvents = {
    numCallsChanged: () => void;
};

export class VideoCallService extends EventEmitter<VideoCallServiceEvents> {
    private readonly stein: SteinClient;
    private readonly store: AppStore;
    private readonly logger: FrameLogger;
    public readonly twilioConnect: TwilioVideoConnect;

    constructor({
        stein,
        store,
        logger,
        connect,
    }: {
        stein: SteinClient;
        store: AppStore;
        logger: FrameLogger;
        connect: TwilioVideoConnect;
    }) {
        super();

        this.stein = stein;
        this.store = store;
        this.logger = logger;
        this.twilioConnect = connect;
    }

    private activeCalls: Record<VehicleDeviceId, VideoCall> = {};
    private localAudioTrack: TwilioVideo.LocalAudioTrack | null = null;

    public async getLocalAudio(): Promise<TwilioVideo.LocalAudioTrack> {
        if (!this.localAudioTrack) {
            this.localAudioTrack = await TwilioVideo.createLocalAudioTrack({ name: 'local-audio' });
        }
        return this.localAudioTrack;
    }

    public startCall(vehicleDeviceId: VehicleDeviceId): VideoCall {
        const call = new VideoCall({
            vehicleDeviceId,
            videoCallService: this,
        });
        this.activeCalls[vehicleDeviceId] = call;
        this.emit('numCallsChanged');
        call.on('requestingAudio', () => Object.values(this.activeCalls).forEach((c) => c.leaveAudio()));
        return call;
    }

    public endCall(roomName: VehicleDeviceId): void {
        const call = this.activeCalls[roomName];
        if (call) {
            call.disconnect();
        }
    }

    public removeCall(vehicleDeviceId: VehicleDeviceId): void {
        const call = this.activeCalls[vehicleDeviceId];
        if (call) {
            call.disconnect();
            delete this.activeCalls[vehicleDeviceId];
            if (!Object.values(this.activeCalls).length) {
                console.log('kill local audio');
                this.localAudioTrack?.stop();
                this.localAudioTrack = null;
            }
            this.emit('numCallsChanged');
        }
    }

    public getActiveCalls(): VideoCall[] {
        const calls = Object.values(this.activeCalls);
        calls.sort((a, b) => a.startedAt.getTime() - b.startedAt.getTime());
        return calls;
    }

    public async startVideoCall(vehicleId: VehicleDeviceId): Promise<StartVideoCallResponse> {
        return await this.store
            .dispatch(
                this.stein.endpoints.startVideoCall.initiate(
                    { vehicleDeviceId: vehicleId },
                    {
                        track: false,
                    },
                ),
            )
            .unwrap();
    }
}

export const VideoCallServicePlugin = createPlugin<VideoCallService>(({ resolve }) => {
    const stein = resolve(SteinInternalApiToken);
    const store = resolve(AppReduxStoreToken);
    const logger = resolve(LoggerToken);
    const connect = resolve(TwilioVideoConnectToken);

    return new VideoCallService({ stein, store, logger, connect });
});
