// istanbul ignore file
import * as React from 'react';
import * as L from 'leaflet';
import * as ReactDOMServer from 'react-dom/server';

import { defineDefault } from '../../utils/undefined-utils';
import { Marker, Tooltip } from 'react-leaflet';
import { urlVehicleDeviceShow } from '../../utils/internal-url-utils';
import { Ping } from '../../types/stein';
import { useActiveProjectSlug } from '../../hooks/use-active-project';
import { useHistory } from 'react-router-dom';
import { AppIconVehicle } from '../AppIcons';
import { useAppSelector } from '../../hooks/hooks';
import { selectVehicleById } from '../../store/selectors';
import { format } from 'date-fns';
import { parseISO8601 } from '../../utils/datetime-utils';

export function PingMarker({ ping }: { ping: Ping }): React.ReactElement {
    const projectSlug = useActiveProjectSlug();
    const history = useHistory();
    const vehicle = useAppSelector((s) => selectVehicleById(s, ping.vehicleDeviceId));

    return (
        <Marker
            position={[defineDefault(ping.latitude, 0), defineDefault(ping.longitude, 0)]}
            eventHandlers={{
                click: /* istanbul ignore next*/ () => {
                    vehicle ? history.push(urlVehicleDeviceShow(projectSlug, vehicle)) : null;
                },
            }}
            icon={L.divIcon({
                className: 'leaflet-invisible-marker',
                html: ReactDOMServer.renderToStaticMarkup(
                    <div
                        data-testid={'ping-marker'}
                        style={{
                            color: 'black',
                            display: 'flex',
                            height: '5px',
                            width: '5px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            alignContent: 'center',
                            backgroundColor: '#253842',
                            borderRadius: '5px',
                            transform: `translate(-50%, -50%)`,
                        }}
                    >
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        ></div>
                        <div
                            style={{
                                position: 'absolute',
                                transform: 'translateY(calc(-100%))',
                            }}
                        >
                            <div
                                style={{
                                    width: 120,
                                    color: 'white',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: 'black',
                                        borderRadius: 5,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        paddingRight: 5,
                                        paddingLeft: 3,
                                    }}
                                ></div>
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    width: 0,
                                    height: 0,
                                    borderLeft: '4px solid transparent',
                                    borderRight: '4px solid transparent',
                                    borderTop: '4px solid black',
                                    clear: 'both',
                                }}
                            />
                        </div>
                    </div>,
                ),
            })}
        >
            <Tooltip>
                <div>{format(parseISO8601(ping?.occurredAt), 'MMM d, h:mm bbb')}</div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <AppIconVehicle />
                    </div>
                    <div>{vehicle?.name}</div>
                </div>
            </Tooltip>
        </Marker>
    );
}
