import { configureStore } from '@reduxjs/toolkit';
import { createPlugin, createToken } from '../lib/frame';
import { EnvToken } from '../lib/frame-tokens';
import { AppReducer, AppReducerToken } from './reducer';
import { SteinInternalApiToken } from '../clients/stein-internal-api';
import { SteinRootApiToken } from '../clients/stein-root-api';
import { mergeWith, isArray } from 'lodash';
import { PartialDeepNoArray } from '../types/util-types';
import { NotificationListenerToken } from '../services/app-notification-listener';
import { DataListenerToken } from '../services/app-data-listener';
import { SFXListenerToken } from '../services/app-sfx-plugin';
import { IntercomListenerToken } from '../services/app-intercom-listener';
import { AnalyticsMiddlewareToken } from '../services/app-analytics-listener';

export const InitialStateOverridesToken = createToken<AppStateOverrides>('InitialStateOverridesToken');
export const AppReduxStoreToken = createToken<AppStore>('AppReduxStoreToken');

export const AppReduxStorePlugin = createPlugin(({ resolve }) => {
    const preloadedState = resolve(InitialStateOverridesToken);
    const env = resolve(EnvToken);
    const reducer = resolve(AppReducerToken);
    const steinInternalAPI = resolve(SteinInternalApiToken);
    const steinRootAPI = resolve(SteinRootApiToken);
    const notificationListener = resolve(NotificationListenerToken);
    const dataListener = resolve(DataListenerToken);
    const analyticsMiddleware = resolve(AnalyticsMiddlewareToken);
    const sfxListener = resolve(SFXListenerToken);
    const intercomListener = resolve(IntercomListenerToken);

    // @ts-expect-error we are getting the initial reducer default value
    const initialState = reducer(undefined, {});

    return configureStore({
        reducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware()
                .concat(steinInternalAPI.middleware)
                .concat(steinRootAPI.middleware)
                .concat(analyticsMiddleware)
                .prepend(sfxListener.middleware)
                .prepend(notificationListener)
                .prepend(dataListener)
                .prepend(intercomListener),
        preloadedState: mergeWith({}, initialState, preloadedState, (objValue, srcValue) => {
            if (isArray(objValue)) {
                return srcValue;
            }
        }),
        devTools: env.NODE_ENV !== 'production',
    });
});

// Infer the `AppStore`, `AppState`, and `AppDispatch` types from the store itself
export type AppStore = ReturnType<typeof AppReduxStorePlugin>;
export type AppRootState = ReturnType<AppStore['getState']>;
type AppRootStateRaw = ReturnType<AppReducer>;

export type AppStateOverrides = PartialDeepNoArray<AppRootStateRaw>;
export type AppDispatch = AppStore['dispatch'];
