import * as React from 'react';
import { ToolbarOffset } from '../components/AppLayout';
import { ApplicationBarWrapper } from '../components/ApplicationBar/ApplicationBar';
import { ThemeProvider } from '../theme';

export default function UJSRoot(): React.ReactElement {
    return (
        <ThemeProvider>
            <ApplicationBarWrapper />
            <ToolbarOffset />
        </ThemeProvider>
    );
}
