import { Stack, InputAdornment, Button, IconButton, TextField } from '@mui/material';
import * as React from 'react';
import { useAppTheme } from '../../../../../theme';

import { Threshold } from '../../../../../types/stein';
import { AppIconRemoveItem } from '../../../../AppIcons';

type ThresholdEditorProps = {
    thresholds: Threshold[];
    onChange: (thresholds: Threshold[]) => void;
};

function createThreshold(thresholds: Threshold[]): Threshold[] {
    if (!thresholds.length) {
        return [
            {
                speed: 5,
                maxDuration: 5,
            },
        ];
    }

    // copy the last threshold and add 5 MPH
    const lastThreshold = thresholds[thresholds.length - 1];
    return [...thresholds, { ...lastThreshold, speed: lastThreshold.speed + 5 }];
}

export function ThresholdEditor({ thresholds, onChange }: ThresholdEditorProps): React.ReactElement {
    const theme = useAppTheme();
    function handleChange(idx: number, change: Partial<Threshold>): void {
        onChange(thresholds.map((t, i) => (i === idx ? { ...t, ...change } : t)));
    }
    const canDelete = thresholds.length > 2;
    return (
        <div style={{ paddingLeft: theme.spacing(10), maxWidth: 500 }}>
            <Stack spacing={2}>
                {thresholds.map((t, idx) => (
                    <Stack spacing={1} direction={'row'} key={idx}>
                        <TextField
                            variant={'outlined'}
                            style={{
                                maxWidth: '160px',
                            }}
                            onChange={(c) => handleChange(idx, { maxDuration: parseFloat(c.target.value) })}
                            size={'small'}
                            value={t.maxDuration}
                            type={'number'}
                            InputProps={{ endAdornment: <InputAdornment position="end">{'seconds'}</InputAdornment> }}
                        />
                        <div style={{ display: 'flex', alignItems: 'center' }}>{'at'}</div>
                        <TextField
                            variant={'outlined'}
                            style={{
                                maxWidth: '300px',
                            }}
                            onChange={(c) =>
                                handleChange(idx, {
                                    speed: parseFloat(c.target.value),
                                })
                            }
                            size={'small'}
                            value={t.speed}
                            type={'number'}
                            error={t.speed < 5}
                            helperText={t.speed < 5 ? 'Min speed is 5mph' : undefined}
                            InputProps={{ endAdornment: <InputAdornment position="end">{'mph'}</InputAdornment> }}
                        />
                        {canDelete && (
                            <IconButton onClick={() => onChange(thresholds.filter((_, i) => i !== idx))} size={'small'}>
                                <AppIconRemoveItem />
                            </IconButton>
                        )}
                    </Stack>
                ))}
                <Button
                    size={'small'}
                    variant={'contained'}
                    fullWidth
                    onClick={() => onChange(createThreshold(thresholds))}
                >
                    {'Add new threshold'}
                </Button>
            </Stack>
        </div>
    );
}
