import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VehicleDevice } from '../../types/stein';
import { setRailsContext } from './shared-actions';

export const vehiclesAdapter = createEntityAdapter<VehicleDevice>({
    selectId: (v) => v.id,
    sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const vehiclesSlice = createSlice({
    name: 'vehicles',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState: vehiclesAdapter.getInitialState(),
    reducers: {
        setVehicles(s, p: PayloadAction<VehicleDevice[]>) {
            vehiclesAdapter.upsertMany(s, p.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setRailsContext, (s, a) => {
            vehiclesAdapter.setAll(s, a.payload.vehicles);
        });
    },
});
