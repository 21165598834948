import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setRailsContext } from './shared-actions';

import { NotifConfig, NotifConfigId } from '../../types/stein';

export const notificationConfigAdapter = createEntityAdapter<NotifConfig>({
    selectId: (e) => e.id,
    sortComparer: (a, b) => (a.id > b.id ? 1 : -1),
});

export const notificationConfigsSlice = createSlice({
    name: 'notificationConfigs',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState: {
        ...notificationConfigAdapter.getInitialState(),
    },
    reducers: {
        setNotificationConfig(state, action: PayloadAction<NotifConfig>) {
            notificationConfigAdapter.setOne(state, action.payload);
        },
        removeNotificationConfig(state, action: PayloadAction<NotifConfigId>) {
            notificationConfigAdapter.removeOne(state, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setRailsContext, (s, a) => {
            notificationConfigAdapter.setAll(s, a.payload.notificationConfig);
        });
    },
});
