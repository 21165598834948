// istanbul ignore file
import * as React from 'react';
import { Box } from '@mui/material';

import { AppIconChevronLeft, AppIconChevronRight } from '../AppIcons';
import { resolveFilterValueNum, useFilters } from '../../hooks/use-filters';
import { FilterBarBox } from './FilterBarBaseTypes';
import { IconButtonSimple } from '../material/IconButtonSimple';

function toStrArr(n?: number): string[] | undefined {
    return n ? [`${n}`] : undefined;
}

export function FilterPageNumber({ hasNextPage }: { hasNextPage: boolean }): React.ReactElement {
    const { filters, updateFilters } = useFilters();
    const page = resolveFilterValueNum(filters.page);
    const changed = Boolean(page);

    return (
        <FilterBarBox highlight={changed} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            {page ? (
                <>
                    <IconButtonSimple
                        sx={{ p: 1, flexGrow: 1 }}
                        onClick={() => updateFilters({ page: toStrArr(page - 1) })}
                        disabled={!page}
                    >
                        <AppIconChevronLeft />
                    </IconButtonSimple>
                    <Box
                        sx={{
                            width: 10,
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexGrow: 4,
                            backgroundColor: 'rgba(255, 255, 255, 0.15)',
                        }}
                    >
                        {page}
                    </Box>
                </>
            ) : null}

            <IconButtonSimple
                sx={{ p: 1, flexGrow: 1 }}
                onClick={() => updateFilters({ page: toStrArr((page || 0) + 1) })}
                disabled={!hasNextPage}
            >
                {!changed ? <Box sx={{ p: 1, fontSize: '14px' }}>More</Box> : null}
                <AppIconChevronRight />
            </IconButtonSimple>
        </FilterBarBox>
    );
}
