import { Tooltip } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import * as React from 'react';
import { useAppTheme } from '../../theme';
import { VehicleDevice } from '../../types/stein';
import { AppIconWifiConnected, AppIconWifiDisconnected } from '../AppIcons';
import { TooltipContent } from '../TooltipContent';
import { isWifiConnected } from './vehicle_utils';

type WifiStatusIndicatorProps = {
    vehicle: VehicleDevice;
};
export function WifiStatusIndicator({ vehicle }: WifiStatusIndicatorProps): React.ReactElement {
    const theme = useAppTheme();

    const isConnected = isWifiConnected(vehicle);
    const connectedAt = vehicle.wifiLastConnectedAt;

    const tooltip = isConnected ? (
        <TooltipContent title={'Wifi Connected'}>
            <p>{'iPhone will upload large files.'}</p>
        </TooltipContent>
    ) : (
        <TooltipContent title={'Wifi Disconnected'}>
            {connectedAt && <p>{`Wifi has been disconnected for ${formatDistanceToNow(new Date(connectedAt))}`}</p>}
            <p>{'iPhone will not upload large files until a steady WiFi connection is found.'}</p>
            <p>{' Normal files (event videos, etc) will be uploaded over cellular.'}</p>
        </TooltipContent>
    );

    const icon = isConnected ? (
        <AppIconWifiConnected size={'16px'} color={theme.palette.primary.main} />
    ) : (
        <AppIconWifiDisconnected size={'16px'} color={theme.palette.warning.main} />
    );

    return (
        <Tooltip sx={{ width: 'auto' }} title={tooltip}>
            {icon}
        </Tooltip>
    );
}
