// istanbul ignore file
import * as React from 'react';
export function VehicleSVG(): React.ReactElement {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 35.6 63.5"
            xmlSpace="preserve"
        >
            <path
                className="st0"
                d="M9.9,0.8l0.2,2l-4,1.8l0.6-1.8l2.8-0.9l-0.1-1l-3,0.9L4.3,6l0,13.4c-3,0.6-4.7,0.8-4.2,2.8l4.2-0.9L4.1,59.5
      l1.1,3.3l1.5,0.7c7.8,0.1,14.3,0.1,22.1,0l1.5-0.7l1.1-3.3l-0.1-38.2l4.1,0.9c0.5-2-1.2-2.2-4.1-2.8l0-13.4l-2.2-4.1l-3-0.9l-0.1,1
      l2.8,0.9l0.6,1.8l-4-1.8l0.2-2C15.7-0.3,20-0.3,9.9,0.8L9.9,0.8z M11.2,57.1l-4.2-1l2.6-6.7c5.5,0,11,0,16.5,0l2.6,6.7l-4.2,1
      C19.4,57,16.3,57,11.2,57.1L11.2,57.1z M8.4,28l-2.1-6.3c3.6-1.6,5.8-2.9,9.9-2.6h3.3c4.1-0.2,6.3,1.1,9.9,2.6L27.3,28
      C25,26.9,10.7,26.9,8.4,28L8.4,28z M29.7,25.3l-1.6,4l-0.4,17.2l2-1V25.3z M6,25.3l1.6,4L8,46.5l-2-1L6,25.3z"
            />
        </svg>
    );
}
