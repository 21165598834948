import * as React from 'react';
import { SettingsPage } from './components/SettingsShared';

import { NotificationEditor } from './components/NotificationEditor';
import { NotifConfigContextProviderUser } from './components/NotificationEditor/NotifConfigContext';

export function MyNotifications(): React.ReactElement {
    return (
        <>
            <div data-testid={'page-settings-my-notifications'} />
            <SettingsPage title={'My Alerts'}>
                <NotifConfigContextProviderUser>
                    <NotificationEditor />
                </NotifConfigContextProviderUser>
            </SettingsPage>
        </>
    );
}
