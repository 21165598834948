import { createListenerMiddleware, ListenerMiddleware, ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { SteinInternalApiClientToken } from '../clients/stein-internal-api';
import { createPlugin, createToken } from '../lib/frame';
import { AppReduxStoreToken } from '../store';
import { actionCableDriverEvent } from '../store/actions';
import { selectAllProjects } from '../store/selectors';

type DataListener = ListenerMiddleware<unknown, ThunkDispatch<unknown, unknown, AnyAction>, unknown>;
export const DataListenerToken = createToken<DataListener>('DataListenerToken');

export const DataListenerPlugin = createPlugin<DataListener>(({ resolve }) => {
    const stein = resolve(SteinInternalApiClientToken);
    const dataListener = createListenerMiddleware();

    function refreshVehicleDevices(): void {
        const store = resolve(AppReduxStoreToken);
        const projects = selectAllProjects(store.getState());
        for (const project of Object.values(projects)) {
            store.dispatch(
                stein.endpoints.getVehicleDevices.initiate(
                    {
                        projectSlug: project.slug,
                        isBackground: true,
                    },
                    {
                        forceRefetch: true,
                    },
                ),
            );
        }
    }

    setInterval(refreshVehicleDevices, 60000);

    dataListener.startListening({
        predicate: (action) => {
            return typeof action?.type === 'string';
        },
        effect: async (action) => {
            if (actionCableDriverEvent.match(action)) {
                const store = resolve(AppReduxStoreToken);
                store.dispatch(stein.util.invalidateTags(['DriverEvent']));
            }
        },
    });

    return dataListener.middleware;
});
