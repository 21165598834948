import * as React from 'react';
import { Select, FormControl, InputLabel } from '@mui/material';
import { TooltipOptional } from './TooltipOptional';

type SelectWithLabelProps = Omit<React.ComponentProps<typeof Select>, 'label' | 'labelId'> & {
    tooltip?: React.ReactNode;
    label: string;
};

export function SelectWithLabel({ label, tooltip, ...props }: SelectWithLabelProps): React.ReactElement {
    const id = `select-with-label-${label.replace(/\s/g, '')}`;
    return (
        <TooltipOptional title={label} description={tooltip}>
            <FormControl fullWidth>
                <InputLabel id={id}>{label}</InputLabel>
                <Select label={label} labelId={id} {...props} />
            </FormControl>
        </TooltipOptional>
    );
}
