import { ValueOf } from 'type-fest';

export const BATTERY_STATE = {
    UNKNOWN: 0,
    UNPLUGGED: 1,
    CHARGING: 2,
    FULL: 3,
} as const;

export type BatteryState = ValueOf<typeof BATTERY_STATE>;
