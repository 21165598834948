import * as React from 'react';
import { Chip, CircularProgress } from '@mui/material';
import { DriverEventId, RegionId, VehicleDeviceSlug, VehicleDeviceId, GeofenceId } from '../types/stein';
import { AppIconBase, AppIconRegions, AppIconVehicle } from './AppIcons';
import { LoggerToken } from '../lib/frame-tokens';
import { useFrame } from '../lib/frame-react';
import { useAppNavigate, useAppSelector } from '../hooks/hooks';

import {
    selectRegionById,
    selectVehicleBySlug,
    selectVehicleById,
    selectGeofenceById,
    selectProjectById,
    selectDebugEnabled,
} from '../store/selectors';
import { SteinInternalApiClientToken } from '../clients/stein-internal-api';
import { EVENT_TYPE_MAP_ID } from '../constants/event-type-constants';
import { FilterIcon } from './FilterBar/FilterIcon';
import { appStyled, useAppTheme } from '../theme';
import { AppInternalUrl, urlDriverEventShow, urlRegion, urlVehicleDeviceShow } from '../utils/internal-url-utils';

type ChipProps = Omit<React.ComponentProps<typeof Chip>, 'color'> & {
    color?: string | React.ComponentProps<typeof Chip>['color'];
};

const StyledChip = appStyled(Chip)({
    '& > .MuiChip-icon': {
        fontSize: 'inherit',
    },
});

type AppChipProps = ChipProps & {
    url: AppInternalUrl | null;
};
function AppChip({ url, color, ...props }: AppChipProps): React.ReactElement {
    const theme = useAppTheme();
    const navigate = useAppNavigate();
    props.clickable = true;
    props.onClick = function (e) {
        // istanbul ignore next
        if (url) {
            e.stopPropagation();
            e.preventDefault();
            navigate(url);
        }
    };
    props.onMouseDown = function (e) {
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <StyledChip
            {...props}
            style={color ? { backgroundColor: color, color: theme.palette.getContrastText(color) } : undefined}
        />
    );
}

type DriverEventChipProps = ChipProps & {
    eventId: DriverEventId;
};

export function DriverEventChip({ eventId, ...props }: DriverEventChipProps): React.ReactElement {
    const { useGetDriverEventQuery } = useFrame(SteinInternalApiClientToken);

    const { data } = useGetDriverEventQuery({ id: eventId });
    const event = data?.driverEvent;

    const project = useAppSelector((s) => selectProjectById(s, event?.projectId));

    const eventParams = event ? EVENT_TYPE_MAP_ID[event.eventName] : null;

    props.label = eventParams ? `${eventParams.name} #${eventId}` : `Event #${eventId}`;
    props.icon = eventParams ? <FilterIcon value={eventParams} /> : <CircularProgress size={'10px'} />;

    // istanbul ignore next
    const url = project ? urlDriverEventShow(project, eventId) : null;

    return <AppChip {...props} url={url} />;
}

type VehicleChipProps = ChipProps & {
    vehicleSlugOrId: VehicleDeviceSlug | VehicleDeviceId;
};

export function VehicleChip({ vehicleSlugOrId, ...props }: VehicleChipProps): React.ReactElement {
    const { useLazyGetVehicleDeviceQuery } = useFrame(SteinInternalApiClientToken);
    const [getVehicleDevice] = useLazyGetVehicleDeviceQuery();

    const vehicle = useAppSelector((s) => {
        if (typeof vehicleSlugOrId === 'number') {
            return selectVehicleById(s, vehicleSlugOrId);
        }
        return selectVehicleBySlug(s, vehicleSlugOrId);
    });

    const project = useAppSelector((s) => selectProjectById(s, vehicle?.projectId));

    React.useEffect(() => {
        if (!vehicle) {
            const { abort } = getVehicleDevice({ idOrSlug: vehicleSlugOrId }, true);
            return abort;
        }
    }, [vehicle]);

    props.label = vehicle ? vehicle.name : 'Vehicle';
    props.icon = <AppIconVehicle />;
    const url = project && vehicle ? urlVehicleDeviceShow(project, vehicle) : null;

    return <AppChip {...props} url={url} />;
}

type RegionChipProps = ChipProps & {
    regionId: RegionId;
};

export const RegionChip = React.memo(
    function RegionChip({ regionId, ...props }: RegionChipProps): React.ReactElement {
        const log = useFrame(LoggerToken);
        const region = useAppSelector((s) => selectRegionById(s, regionId));
        const project = useAppSelector((s) => selectProjectById(s, region?.projectId));

        if (!region) {
            log.warn('[RegionChip] No region with id', { regionId });
        }

        props.icon = <AppIconRegions />;
        props.label = region ? region.name : 'Region';

        const url = project ? urlRegion(project, regionId) : null;

        return <AppChip {...props} url={url} />;
    },
    (p, n) => p.regionId === n.regionId,
);

type GeofenceChipProps = ChipProps & {
    geofenceId: GeofenceId;
};

export const GeofenceChip = React.memo(
    function GeofenceChipMemo({ geofenceId, ...props }: GeofenceChipProps): React.ReactElement | null {
        const geofence = useAppSelector((s) => selectGeofenceById(s, geofenceId));
        const theme = useAppTheme();
        const debugEnabled = useAppSelector(selectDebugEnabled);

        if (!geofence) {
            // Probably a hidden geofence
            return null;
        }

        if (geofence.hidden && !debugEnabled) {
            return null;
        }

        const name = geofence.name || 'Facility';

        const backgroundColor = geofence?.hidden ? theme.colors.debugBlock.dev.background : geofence.color;

        props.icon = (
            <AppIconBase
                name={name}
                iconClass={geofence?.hidden ? 'fa-bug' : geofence?.iconName || 'fa-hexagon'}
                color={backgroundColor ? theme.palette.getContrastText(backgroundColor) : undefined}
            />
        );
        props.label = name;

        return <AppChip {...props} color={backgroundColor} url={null} />;
    },
    /*istanbul ignore next*/ (p, n) => p.geofenceId === n.geofenceId,
);
