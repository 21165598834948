// istanbul ignore file

import * as React from 'react';

import { ConfigurationItem, ProjectId, VehicleDeviceId } from '../../../types/stein';

import { useFrame } from '../../../lib/frame-react';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';

import { Box, FormControl, InputLabel, MenuItem, Paper, Select, Stack, TextField } from '@mui/material';
import { toTitleCase } from '../../../lib/case-transforms';
import LoadingButton from '@mui/lab/LoadingButton';
import { useStagedChanges } from '../../../hooks/use-staged-changes';
import {
    CONFIG_ITEM_ATTRIBUTE_DEFAULTS,
    ConfigItemAttributeEditor,
    ConfigItemAttributeKey,
    setConfigItemValue,
} from './EditConfigItem';

function NewItemEditor({
    value,
    onChange,
    onSave,
    isLoading,
}: {
    value: ConfigurationItem;
    onChange: React.Dispatch<React.SetStateAction<Partial<ConfigurationItem>>>;
    onSave: () => void;
    isLoading: boolean;
}): React.ReactElement {
    const readyToCreate = Boolean(value.attributeKey);
    const type: ConfigItemAttributeKey =
        (Object.keys(CONFIG_ITEM_ATTRIBUTE_DEFAULTS).find(
            (k: ConfigItemAttributeKey) => typeof value[k] !== 'undefined' && value[k] !== null,
        ) as ConfigItemAttributeKey) || 'stringValue';

    return (
        <Paper>
            <Box p={2}>
                <Stack spacing={2}>
                    <div>{'New Config Item'}</div>
                    <TextField
                        label={'Attribute Key'}
                        value={value.attributeKey || ''}
                        onChange={(e) => {
                            const attributeKey = e.target.value;
                            onChange((ci) => ({ ...ci, attributeKey }));
                        }}
                        size={'small'}
                    />

                    <Stack spacing={1} direction={'row'}>
                        <FormControl size={'small'}>
                            <InputLabel id="select-config-item-type">Type</InputLabel>
                            <Select
                                labelId="select-config-item-type"
                                value={type}
                                label="Type"
                                onChange={(e) => {
                                    const keys = e.target.value as ConfigItemAttributeKey;
                                    onChange((ci) =>
                                        setConfigItemValue(ci, { [keys]: CONFIG_ITEM_ATTRIBUTE_DEFAULTS[keys] }),
                                    );
                                }}
                            >
                                {Object.keys(CONFIG_ITEM_ATTRIBUTE_DEFAULTS).map((v) => (
                                    <MenuItem value={v} key={v}>
                                        {toTitleCase(v)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <ConfigItemAttributeEditor ci={value} setCi={onChange} type={type} />
                    </Stack>

                    <LoadingButton
                        onClick={onSave}
                        variant={'contained'}
                        size={'small'}
                        fullWidth
                        disabled={!readyToCreate}
                        loading={isLoading}
                    >
                        {'Create'}
                    </LoadingButton>
                </Stack>
            </Box>
        </Paper>
    );
}

export function NewVehicleConfigItem({ vehicleDeviceId }: { vehicleDeviceId: VehicleDeviceId }): React.ReactElement {
    const { useSetVehicleConfigItemMutation } = useFrame(SteinInternalApiClientToken);

    const [create, { isLoading }] = useSetVehicleConfigItemMutation();

    const staged = useStagedChanges(
        (configurationItem: ConfigurationItem) =>
            create({
                configurationItem,
                vehicleDeviceId,
            }),
        {
            vehicleDeviceId,
            attributeKey: '',
        },
    );

    return (
        <NewItemEditor value={staged.merged} onChange={staged.setChanges} onSave={staged.save} isLoading={isLoading} />
    );
}

export function NewProjectConfigItem({ projectId }: { projectId: ProjectId }): React.ReactElement {
    const { useSetProjectConfigItemsMutation } = useFrame(SteinInternalApiClientToken);

    const [create, { isLoading }] = useSetProjectConfigItemsMutation();

    const staged = useStagedChanges(
        (configurationItem: ConfigurationItem) =>
            create({
                // @ts-expect-error config items are normally strongly typed. Since this is a developer page we don't want that
                configurationItems: [configurationItem],
                projectId,
            }),
        {
            projectId,
            attributeKey: '',
        },
    );

    return (
        <NewItemEditor value={staged.merged} onChange={staged.setChanges} onSave={staged.save} isLoading={isLoading} />
    );
}
