// istanbul ignore file
import * as React from 'react';
import { useAppTheme } from '../../../../theme';
import { AuditTagBehavior } from '../../../../types/stein';
import { AppIconAuditTagNegative, AppIconWarningTriangle, AppIconAuditTagPositive } from '../../../AppIcons';

export function useAuditTagBehaviorColor(behavior: AuditTagBehavior): string {
    const theme = useAppTheme();

    switch (behavior) {
        case 'major_negative':
            return theme.palette.error.main;
        case 'minor_negative':
            return theme.palette.warning.main;
        case 'major_positive':
            return theme.palette.success.dark;
    }
}

export function AuditTagBehaviorIcon({ behavior }: { behavior: AuditTagBehavior }): React.ReactElement {
    const color = useAuditTagBehaviorColor(behavior);

    switch (behavior) {
        case 'major_negative':
            return <AppIconAuditTagNegative color={color} />;
        case 'minor_negative':
            return <AppIconWarningTriangle color={color} />;
        case 'major_positive':
            return <AppIconAuditTagPositive color={color} />;
    }

    return <></>;
}
