import { Tooltip } from '@mui/material';
import * as React from 'react';
import { useAppTheme } from '../../theme';
import { VehicleDevice } from '../../types/stein';
import { AppIconAppUpdate, AppIconProps } from '../AppIcons';
import { TooltipContent } from '../TooltipContent';

type ObservantUpdateIndicatorProps = {
    vehicle?: VehicleDevice;
} & Partial<AppIconProps>;
export function ObservantUpdateIndicator({ vehicle, ...props }: ObservantUpdateIndicatorProps): React.ReactElement {
    const theme = useAppTheme();

    return vehicle?.appNeedsUpdate ? (
        <Tooltip
            title={
                <TooltipContent title={'Observant App Update Needed'}>
                    <p>
                        {`Update Observant app from ${
                            vehicle.lastBundleVersion
                                ? /*istanbul ignore next*/ vehicle.lastBundleVersion
                                : vehicle.lastBundleShortVersion
                        } to ${vehicle.availableBundleShortVersion}`}
                    </p>
                </TooltipContent>
            }
        >
            <AppIconAppUpdate color={theme.palette.error.main} {...props} />
        </Tooltip>
    ) : (
        <></>
    );
}
