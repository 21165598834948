import * as React from 'react';

export type TooltipContentProps = {
    title: string;
    children: React.ReactNode;
};

export function TooltipContent({ title, children }: TooltipContentProps): React.ReactElement {
    return (
        <div>
            <strong>{title}</strong>
            <div>{children}</div>
        </div>
    );
}
