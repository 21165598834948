export function parseLatLng(lat: number | null | undefined, lng: number | null | undefined): [number, number] | null {
    //istanbul ignore if
    if (!lat || !lng) {
        return null;
    }

    return [lat, lng];
}

// Converts from degrees to radians.
function toRadians(degrees: number): number {
    return (degrees * Math.PI) / 180;
}

// Converts from radians to degrees.
function toDegrees(radians: number): number {
    return (radians * 180) / Math.PI;
}

export function computeBearing(startLat: number, startLng: number, destLat: number, destLng: number): number {
    startLat = toRadians(startLat);
    startLng = toRadians(startLng);
    destLat = toRadians(destLat);
    destLng = toRadians(destLng);

    const y = Math.sin(destLng - startLng) * Math.cos(destLat);
    const x =
        Math.cos(startLat) * Math.sin(destLat) - Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);

    const brng = toDegrees(Math.atan2(y, x));
    return (brng + 360) % 360;
}
