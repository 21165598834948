import * as React from 'react';
import { Box, Button, Skeleton, Stack, TextField } from '@mui/material';
import { useActiveProject } from '../../../hooks/use-active-project';

import { SettingsPage } from './components/SettingsShared';

import { CalloutBox } from '../../material/CalloutBox';
import { AppIconDownload } from '../../AppIcons';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import { useFrame } from '../../../lib/frame-react';
import { useConfirmationDialog } from '../../../hooks/use-confirmation-dialog';
import LoadingButton from '@mui/lab/LoadingButton';
import { CopyToClipboard } from '../../material/CopyToClipboard';
import { useAppTheme } from '../../../theme';
import { urlProjectAuditCSV } from '../../../utils/internal-url-utils';
import { WindowToken } from '../../../lib/frame-tokens';
import { define } from '../../../utils/undefined-utils';
import { Permission } from '../../Authorize/PermissionBlock';

export function SettingsProjectAdvanced(): React.ReactElement | null {
    const project = useActiveProject();
    const window = useFrame(WindowToken);

    return (
        <SettingsPage title={`${project?.name} Advanced Settings`}>
            <Stack spacing={2}>
                <Permission auth={{ subject: 'ProjectSettings', action: 'edit_scim' }}>
                    <SCIM />
                </Permission>
                <CalloutBox name={'Security Audit'}>
                    <Button
                        fullWidth
                        endIcon={<AppIconDownload />}
                        onClick={function onDownloadAuditCsv(): void {
                            window.open(urlProjectAuditCSV(project), '_blank');
                        }}
                    >
                        {'Download Audit CSV'}
                    </Button>
                </CalloutBox>
            </Stack>
        </SettingsPage>
    );
}

function SCIM(): React.ReactElement {
    const project = useActiveProject();
    const theme = useAppTheme();
    const { useGetProjectSCIMQuery, useRegenerateProjectSCIMMutation } = useFrame(SteinInternalApiClientToken);
    const { data: scim, isLoading } = useGetProjectSCIMQuery({ projectId: project.id });
    const [regerateScim, { data: newSCIM, isLoading: isRegenerating }] = useRegenerateProjectSCIMMutation();
    const { node, handleShouldConfirm } = useConfirmationDialog({
        func: () => regerateScim({ projectId: project.id }).unwrap(),
        message:
            'Are you sure you want to to re-generate your SCIM credentials? This will invalidate your existing SCIM credentials.',
        buttonText: 'Regenerate',
    });

    return (
        <CalloutBox name={'SCIM Credentials'}>
            <Stack spacing={2}>
                <div>{'Credentials for Okta SCIM (System for Cross-domain Identity Management)'}</div>
                {isLoading ? (
                    <>
                        <Skeleton width={'100%'}>
                            <TextField fullWidth />
                        </Skeleton>
                        <Skeleton width={'100%'}>
                            <TextField fullWidth />
                        </Skeleton>
                        <Skeleton width={'100%'}>
                            <Button fullWidth />
                        </Skeleton>
                    </>
                ) : (
                    <>
                        <TextField
                            label={'SCIM Username'}
                            disabled
                            fullWidth
                            value={define(newSCIM?.scimUsername || scim?.scimUsername) || /* istanbul ignore next */ ''}
                        />
                        <CopyToClipboard text={newSCIM?.scimPassword}>
                            <TextField
                                label={'SCIM Password'}
                                disabled
                                fullWidth
                                value={
                                    define(newSCIM?.scimPassword || scim?.scimPassword) || /* istanbul ignore next */ ''
                                }
                            />
                        </CopyToClipboard>
                        {newSCIM?.scimPassword ? (
                            <Box
                                paddingX={2}
                                sx={{
                                    backgroundColor: theme.palette.success.light,
                                    color: theme.palette.success.contrastText,
                                }}
                            >
                                <p>New SCIM credentials have been successfully generated.</p>
                                <p>
                                    Please copy your password now, as you wont’t be able to view it again without
                                    re-generating new credentials.
                                </p>
                            </Box>
                        ) : (
                            <LoadingButton onClick={handleShouldConfirm} loading={isRegenerating} fullWidth>
                                {'Regenerate Credentials'}
                            </LoadingButton>
                        )}
                    </>
                )}
            </Stack>
            {node}
        </CalloutBox>
    );
}
