import * as React from 'react';
import { DefaultTheme } from './default-theme';
import { useTheme, styled, ThemeProvider as MaterialThemeProvider } from '@mui/material/styles';
import { createMaterialTheme } from './create-material-theme';

type ThemeProviderProps = {
    children: React.ReactNode;
};

export function ThemeProvider({ children }: ThemeProviderProps): React.ReactElement {
    const theme = createMaterialTheme(DefaultTheme);
    return <MaterialThemeProvider theme={theme}>{children}</MaterialThemeProvider>;
}

export type ThemeObject = typeof DefaultTheme;
export type ThemeColorsObject = typeof DefaultTheme.colors;

export const useAppTheme = useTheme;
export const appStyled = styled;
