import { NotificationListenerDeps } from './notification-listener-types';
import { notificationSlice } from '../../store/slices/notificationSlice';
import { AnyAction } from 'redux';
import {
    NotifConfig,
    NOTIF_CONFIG_CATEGORY_NAME,
    NOTIF_CONFIG_MEDIUM_NAME,
} from '../../types/stein.notification_config';
import { isNotifConfigToggleable } from '../../components/pages/Settings/components/NotificationEditor/notif-config-utils';

function getFields(nc: Omit<NotifConfig, 'id'>): {
    categoryName: string;
    mediumName: string;
    enabled: boolean;
} | null {
    //istanbul ignore next
    if (!isNotifConfigToggleable(nc)) {
        //istanbul ignore next
        return null;
    }

    return {
        categoryName: NOTIF_CONFIG_CATEGORY_NAME[nc.category],
        mediumName: NOTIF_CONFIG_MEDIUM_NAME[nc.medium],
        enabled: nc.enabled,
    };
}

export function notificationListenerRequests(action: AnyAction, deps: NotificationListenerDeps): void {
    const { steinInternalApi, store } = deps;
    if (steinInternalApi.endpoints.createOrUpdateNotifConfig.matchFulfilled(action)) {
        const fields = getFields(action.payload.notificationConfiguration);

        //istanbul ignore next
        if (!fields) {
            // istanbul ignore next
            return;
        }
        const { categoryName, mediumName, enabled } = fields;

        store.dispatch(
            notificationSlice.actions.createNotification({
                type: 'generic',
                title: `${categoryName}: ${mediumName} notificaitons ${enabled ? 'enabled' : 'disabled'}.`,
                kind: 'success',
            }),
        );
    } else if (steinInternalApi.endpoints.createOrUpdateNotifConfig.matchRejected(action)) {
        const fields = getFields(action.meta.arg.originalArgs);

        //istanbul ignore next
        if (!fields) {
            // istanbul ignore next
            return;
        }
        const { categoryName, mediumName } = fields;

        store.dispatch(
            notificationSlice.actions.createNotification({
                type: 'generic',
                title: `${categoryName}: Error toggling ${mediumName} notifications.`,
                kind: 'error',
            }),
        );
    } else if (steinInternalApi.endpoints.sendTestNotification.matchFulfilled(action)) {
        const { category, medium } = action.meta.arg.originalArgs;
        const categoryName = NOTIF_CONFIG_CATEGORY_NAME[category];
        const mediumName = NOTIF_CONFIG_MEDIUM_NAME[medium];

        store.dispatch(
            notificationSlice.actions.createNotification({
                type: 'generic',
                title: `Test "${categoryName}" notification sent via ${mediumName}.`,
                kind: 'success',
            }),
        );
    } else if (steinInternalApi.endpoints.sendTestNotification.matchRejected(action)) {
        const { category, medium } = action.meta.arg.originalArgs;
        const categoryName = NOTIF_CONFIG_CATEGORY_NAME[category];
        const mediumName = NOTIF_CONFIG_MEDIUM_NAME[medium];

        store.dispatch(
            notificationSlice.actions.createNotification({
                type: 'generic',
                title: `Error sending test "${categoryName}" notification via ${mediumName}.`,
                kind: 'error',
            }),
        );
    }
}
